import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/styles/withStyles';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { useTranslation } from 'react-i18next';
import styles from './styles';

import {
  SuccessCheckIcon,
  FailIcon
} from '../joabet.icons';

const CustomNotification = ({
  classes,
  horizontal,
  message,
  onClose,
  open,
  type, // success | error
  vertical,
  mode // mode === 1 if display with icon, by default display message only
}) => {
  const { t } = useTranslation();

  const messageProps = mode === 1 ? (
    <div className='notifContainer'>
      <div className='iconContainer'>
        {
          type === 'success' ? <SuccessCheckIcon className='success-icon'/> : <FailIcon />
        }
      </div>
      <div>
        <div>
          <span className='bodyText'>{t(message)}</span>
        </div>
      </div>
   </div>
  ) : 
  <div style={{padding:'5px'}}>
    {t(message)}
  </div>

  return (
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      autoHideDuration={3000}
      onClose={onClose}
      open={open}
      TransitionComponent={'Fade'}
      style={{display: 'block'}}
    >
      <SnackbarContent
        className={`${classes[type]} ${classes.root}`}
        message={messageProps}
      />

    </Snackbar>
  );
};

CustomNotification.defaultProps = {
  horizontal: 'center',
  message: '',
  open: false,
  type: 'success',
  vertical: 'top',
};

CustomNotification.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
  }).isRequired,
  horizontal: PropTypes.string,
  message: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  type: PropTypes.string,
  vertical: PropTypes.string,
};

export default withStyles(styles)(CustomNotification);
