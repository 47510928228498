import { SET_NOTIFICATION } from "../constants/notification";

const initialState = {
  notificationContent: {
    msg: '',
    type: 'success'
  }
};

export default function notificationReducer(state = initialState, action) {
  switch (action.type) {
    case SET_NOTIFICATION:
      return {
        ...state,
        notificationContent: action.payload
      };
    default:
      return state;
  }
}
