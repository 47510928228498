const styles = theme => ({
  navAction: {
    height: '80px',
  },
  coloredIcon: {
    color: theme.palette.secondary.main,
    padding: '0px 10px',
  },
  btn: {
    minWidth: '53px',
    padding: '8px 0px 8px',
    color: '#fff',
  },
  icon: {
    marginBottom: '6px',
  },
  label: {
    fontSize: '10px',
    textTransform: 'uppercase',
    '&.Mui-selected': {
      fontSize: '10px',
    },
    ['@media (max-width:280px)']: {
      fontSize: '8px',
      '&.Mui-selected': {
        fontSize: '8px',
      },
    },
  },

  promotions: {
    // backgroundImage: 'linear-gradient(180deg, #483700 0%, #000000 100%)',
    // borderRadius: '10px',
    //background: theme.palette.primary.dark1,
  },
  root: {
    zIndex: 0,
    boxShadow:
      '0px -1px 8px -1px rgb(0 0 0 / 38%), 0px 15px 5px 0px rgb(0 0 0 / 5%), 0px 1px 31px 10px rgb(0 0 0 / 15%)',
    '& .affiliate-action-btn': {
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      '& a': {
        borderRadius: '5px',
        fontSize: '14px',
        fontWeight: 500,
        width: '100%',
        textTransform: 'uppercase',
        textDecoration: 'none',
        textAlign: 'center',
        paddingTop: theme.spacing(2) - 5,
        paddingBottom: theme.spacing(2) - 5,
      },
      '& .register': {
        background: theme.palette.secondary.dark,
        color: theme.palette.text.primary,
      },
    },
    '& .action-btn': {
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.57)',
      '& a': {
        borderRadius: '3px',
        fontSize: '14px',
        fontWeight: 500,
        width: '45%',
        textTransform: 'uppercase',
        textDecoration: 'none',
        textAlign: 'center',
        paddingTop: theme.spacing(2) - 5,
        paddingBottom: theme.spacing(2) - 5,
      },
      '& .register': {
        background: theme.palette.primary.dark,
        color: theme.palette.text.primary,
      },
      '& .login': {
        background: theme.palette.secondary.dark,
        color: theme.palette.text.primary,
      },
      '& .skip': {
        color: theme.palette.text.primary,
      },
    },
    "& .MuiBadge-badge": {
      color: "white",
      backgroundColor: "#c13332",
      position: "absolute",
      right: '-3px',
      top: '-5px',
    },
  },
});

export default styles;
