const styles = theme => ({
    root: {
    },
    sessionDialog: {
      '& .MuiPaper-root': {
        '& .MuiDialog-paper': {
          '& .CustomDialog-paperScrollPaper-123': {
            bottom: '100',
            maxWidth: '90%',
          }
        }
      },
      '& .MuiDialogTitle-root': {
        '& .MuiIconButton-root': {
          float: 'right',
          '& svg': {
            fontSize: '30px',
            paddingBottom: 0,
          },
        },
      },
      '& .MuiDialogContent-root': {
        '& .title': {
          fontSize: '16px',
          fontWeight: 700,
          marginTop: 0,
        },
        '& .desc': {
          fontSize: '14px',
          fontWeight: 400,
          color: theme.palette.text.secondary,
        },
      },
      '& .action-btn': {
        display: 'flex',
        justifyContent: 'center',
        width: '90%',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        '& a': {
          borderRadius: '18px',
          fontSize: '14px',
          fontWeight: 500,
          width: '48%',
          textTransform: 'uppercase',
          textDecoration: 'none',
          textAlign: 'center',
          paddingTop: theme.spacing(2) - 5,
          paddingBottom: theme.spacing(2) - 5,
        },
        '& .cancel': {
          background: '#1A1A1A',
          color: '#ffffff',
          border: '1px solid'
        },
        '& .proceed': {
          background: theme.palette.secondary.dark,
          color: theme.palette.text.primary,
        },
      },
    },
  });

  export default styles;
