import { all, call, takeLatest, put } from 'redux-saga/effects';
import authSvc from '../utils/services/authentication';
import CommonSvc from '../utils/services/common';
import {
  setLoginSuccess,
  setLoginError,
} from '../actions/authentication';
import { setLoading } from '../actions/common';
import { setPlayerUnauthorized } from '../actions/player';
import {
  socketConnect,
  taskCancel,
} from '../actions/socket';
import { LOGIN_USER, LOGOUT_USER, KICK_USER } from '../constants/authentication';
import { getDetail, removeDetails } from '../utils/helpers/common';

function* loginUser(action) {
  const { payload } = action;
  try {
    //const requestIP = yield call(CommonSvc.getIP);
    //const { ip } = requestIP.data;
    const request = yield call(
      authSvc.login,
      //Object.assign(payload, { ip_address: ip }),
      Object.assign(payload),
    );

    const { token } = request.data;

    yield put(setLoginError({}));
    yield put(setLoginSuccess(token));
    yield put(socketConnect(token));
    window.location.href = '/';
  } catch (err) {
    const { errors } = err.response.data;
    yield put(setLoginError(errors));
  } finally {
    yield put(setLoading(false));
  }
}

function* logoutUser() {
  try {
    yield call(authSvc.logout, {
      owsport: !!getDetail('owSports'),
    });
  } catch (err) {
    //err.response.data
  } finally {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
    localStorage.setItem('popupCount', 0)
    localStorage.setItem('selectedOption', 'local')
    removeDetails('loaded')
    yield put(setPlayerUnauthorized(true));
    yield put(taskCancel());
  }
}

function* kickUser() {
  try {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
    localStorage.setItem('popupCount', 0)
    yield put(taskCancel());
  } catch (err) {
    
  } finally {
    // yield put(setPlayerUnauthorized(true));
    removeDetails('owSports');
    removeDetails('token');
    removeDetails('loaded')
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
    // window.location.reload(true);
    window.location.href = '/?rand=' + Math.random().toString(36).slice(2);
  }
}

function* authenticationWatcher() {
  yield all([
    takeLatest(LOGIN_USER, loginUser),
    takeLatest(LOGOUT_USER, logoutUser),
    takeLatest(KICK_USER, kickUser),
  ]);
}

export default authenticationWatcher;