import {
    GET_PAGE,
    PAGE_SUCCESS,
    PAGE_ERROR,
    GET_BRAND_GAME_SETUP,
    SET_BRAND_GAME_SETUP,
} from '../constants/page';

export const getPage = (payload) => {
    return {
        type: GET_PAGE,
        payload
    };
};

export const setPage = page => {
    return {
        type: PAGE_SUCCESS,
        page
    };
};

export const errorPage = errors => {
  return {
      type: PAGE_ERROR,
      errors
  };
};

export const getBrandGameSetup = (payload) => {
    return {
      type: GET_BRAND_GAME_SETUP,
      payload
    };
  };

  export const setBrandGameSetup = (payload) => {
    return {
      type: SET_BRAND_GAME_SETUP,
      payload
    };
  };