import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/styles';
import withWidth, {
  isWidthUp,
  isWidthDown,
} from '@material-ui/core/withWidth';
import AppBar from '@material-ui/core/AppBar';

import Sidenav from '../components/sidenav/sidenav.component';
import ContentToolbar from '../components/content-toolbar/content-toolbar.component';
import ContentFooter from '../components/content-footer/content-footer.component';
import MenuSidenav from '../components/menu-sidenav/menu-sidenav.component';
import SessionModal from '../../containers/session-modal';
import GameMessageModal from '../../containers/game-message-modal';

// Actions
import {
  toggleSidenav,
  setSidenavOpen,
  toggleSidenavVariant,
} from '../../actions/layout';

import scss from './layout-fifteenbet.module.scss';
import styles from './layout-fifteenbet.style';

class LayoutFifteenBet extends React.Component {
  // Set the initial layout state when the layout is initialised
  constructor(props) {
    super(props);
    const variant = isWidthDown('sm', props.width)
      ? 'temporary'
      : 'persistent';
    props.toggleSidenavVariant(variant);
    props.setSidenavOpen(variant === 'persistent');
  }

  // Update the layout state when a going from mobile to desktop and vice versa
  /* eslint-disable-next-line */
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      isWidthDown('sm', this.props.width) &&
      isWidthUp('md', nextProps.width)
    ) {
      this.props.toggleSidenavVariant('persistent');
      this.props.setSidenavOpen(true);
    } else if (
      isWidthDown('sm', nextProps.width) &&
      isWidthUp('md', this.props.width)
    ) {
      this.props.toggleSidenavVariant('temporary');
      this.props.setSidenavOpen(false);
    }
  }

  render() {
    const { children, classes } = this.props;
    const {
      backTo,
      hasFavourites,
      hasFilter,
      hasReload,
      includeBack,
      location,
      noHeader,
      noSidenav,
      noFooter,
      title,
    } = children.props;

    return (
      <div
        className={classNames(
          scss['layout-fifteenbet-wrapper'],
          classes.wrapper,
        )}
      >
        {!noSidenav && (
          <Sidenav>
            <MenuSidenav />
          </Sidenav>
        )}
        <main className={scss['layout-fifteenbet-main']}>
          {!noHeader && (
            <AppBar color="default" position="static">
              <ContentToolbar
                backTo={backTo}
                hasFavourites={hasFavourites}
                hasFilter={hasFilter}
                hasReload={hasReload}
                title={title}
                includeBack={includeBack}
              />
            </AppBar>
          )}
          <div className={scss['layout-fifteenbet-content-wrapper']}>
            <div className={scss['layout-fifteenbet-content']}>
              {children}
            </div>
          </div>
          {!noFooter && (
            <ContentFooter pathname={location.pathname} />
          )}
        <SessionModal />
        <GameMessageModal />
        </main>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    layout: {
      sidenavOpen: state.layout.sidenavOpen,
    },
  };
}

LayoutFifteenBet.defaultProps = {
  hasFavourites: false,
  hasFilter: false,
  includeBack: false,
  noFooter: false,
  title: '',
};

LayoutFifteenBet.propTypes = {
  classes: PropTypes.shape({
    wrapper: PropTypes.string,
  }).isRequired,
  children: PropTypes.shape({
    props: PropTypes.object,
  }).isRequired,
  hasFavourites: PropTypes.bool,
  hasFilter: PropTypes.bool,
  includeBack: PropTypes.bool,
  noFooter: PropTypes.bool,
  width: PropTypes.string.isRequired,
  title: PropTypes.string,
  toggleSidenavVariant: PropTypes.func.isRequired,
  setSidenavOpen: PropTypes.func.isRequired,
};

export default compose(
  withWidth(),
  withStyles(styles, { withTheme: true }),
  connect(
    mapStateToProps,
    {
      toggleSidenav,
      toggleSidenavVariant,
      setSidenavOpen,
    },
  ),
)(LayoutFifteenBet);
