import { takeLatest, put/* , select, call */ } from 'redux-saga/effects';
import { saveState, loadState } from '../localStorage';

import {
  GET_SESSIONS,
  SET_SESSIONS,
  SAVE_LOGIN_SESSIONS
} from '../constants/session';

const getItems = state => state.sessions;
const storage = loadState();

function* getSessions() {
  try {
    yield put({
      type: SET_SESSIONS,
      payload: (storage && storage.token) ? storage : getItems
    });
  } catch (error) {
    // console.log(`Get Sessions:${error}`);
  }
}

function* setSessions(action) {
  const { token, user, language } = action.payload;
  // const { token, user } = yield select(getItems);

  try {
    saveState({
      token,
      user,
      language
    });

    yield put({
      type: SET_SESSIONS,
      payload: action.payload
    });
  } catch (error) {
    // console.log(`Set Sessions:${error}`);
    // console.log("Login error:", err);
    /* yield put({
            type: SET_USER_LOGIN_ERROR,
            payload: {validate:err.message}
        }); */
  }
}

function* sessionWatcher() {
  yield takeLatest(SAVE_LOGIN_SESSIONS, setSessions);
  yield takeLatest(GET_SESSIONS, getSessions);
}

export default sessionWatcher;
