import React from 'react';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';

const CustomNumberFormat = ({ inputRef, onChange, ...other }) => (
  <NumberFormat
    {...other}
    getInputRef={inputRef}
    onValueChange={(values) => {
      onChange({
        target: {
          name: other.name,
          value: values.value
        }
      });
    }}
    thousandSeparator
    decimalSeparator={false}
  />
);

CustomNumberFormat.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};

export default CustomNumberFormat;
