const styles = () => ({
  paperScrollPaper: {
    position: 'absolute',
    bottom: 0,
    margin: 0,
    maxWidth: '100%',
    width: '100%',
  },
  root: {
    '& .MuiDialogTitle-root': {
      padding: 0,
      '& .MuiTypography-root': {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'flex-end',
        alignItems: 'center',
        '& p': {
          margin: 0,
          position: 'absolute',
          textAlign: 'center',
          width: '100%',
        },
        '& .MuiIconButton-root': {
          '& svg': {
            fontSize: '30px',
            paddingBottom: 0,
            width: '14px'
          },
        },
      },
    },
    '& .MuiDialogContent-root': {
      paddingTop: 0,
      scrollBehavior: 'smooth',
      overflowX: 'hidden',
      backgroundColor: '#0F1D26',
    },
  },
});

export default styles;
