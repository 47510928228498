import React, { useRef, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core';

import styles from './styles';
import CustomFab from '../fab';
import { CloseIconMaintenance } from '../joabet.icons';

const CustomizableDialog = ({
  actions,
  onClose,
  children,
  classes,
  customClass,
  customScrollPaper,
  fullScreen,
  hasHeader,
  openDialog,
  title,
  titleContent,
  noBackDrop,
  hasCustomHeader,
  hasClose
}) => {
  const [positionX, setPositionX] = useState(0);
  const ref = useRef();
  const onScroll = useCallback(() => {
    setPositionX(ref.current.scrollTop);
  });

  let paperScrollPaper = '';
  if(!fullScreen){
    if(!customScrollPaper)
    {
      paperScrollPaper = classes.paperScrollPaper;
    }else{
      paperScrollPaper = customScrollPaper;
    }
  }

  return (
    <Dialog
      className={`${customClass} ${classes.root}`}
      classes={{
        paperScrollPaper
      }}
      open={openDialog}
      fullScreen={fullScreen}
      onBackdropClick={noBackDrop ? () => false : onClose}
      TransitionComponent={Slide}
      TransitionProps={{ direction: 'up' }}
    >
      {hasHeader && !titleContent && (
        <DialogTitle className="dialog-title">
          <p>{title}</p>
          <IconButton onClick={onClose}>
            <CloseIconMaintenance />
          </IconButton>
        </DialogTitle>
      )}
      {hasHeader && titleContent && (
        <DialogTitle className="dialog-title">
          {titleContent}
        </DialogTitle>
      )}
      {hasCustomHeader && (
        <div className="dialog-title" >
          <Grid container item xs={12} md={12} lg={12} >
            <Grid item xs={11} md={11} lg={11} >
              {titleContent}
            </Grid>
            <Grid item xs={1} md={1} lg={1}>
              {
                hasClose ? 
                <IconButton onClick={onClose} style={{float:'right',padding:'0px'}} className='button'>
                  <CloseIconMaintenance/>
                </IconButton> : ''
              }
            </Grid>
          </Grid>
        </div>
      )}
      <DialogContent className="dialog-content" ref={ref} onScroll={onScroll}>
        {children}
      </DialogContent>
      {actions && <DialogActions>{actions}</DialogActions>}
      <CustomFab elem={ref} position={positionX} />
    </Dialog>
  );
};

CustomizableDialog.defaultProps = {
  actions: null,
  onClose: null,
  customClass: '',
  fullScreen: false,
  hasHeader: false,
  openDialog: false,
  title: '',
  titleContent: null,
};

CustomizableDialog.propTypes = {
  actions: PropTypes.node,
  onClose: PropTypes.func,
  classes: PropTypes.shape({
    paperScrollPaper: PropTypes.string,
    root: PropTypes.string,
  }).isRequired,
  children: PropTypes.node.isRequired,
  customClass: PropTypes.string,
  customScrollPaper: PropTypes.string,
  fullScreen: PropTypes.bool,
  hasHeader: PropTypes.bool,
  openDialog: PropTypes.bool,
  title: PropTypes.string,
  titleContent: PropTypes.node,
};

export default withStyles(styles)(CustomizableDialog);
