import { all, call, put, takeLatest, select } from 'redux-saga/effects';

import {
  GET_LANDING_PAGE_WINNER_LIST_SUMMARY,
  GET_LANDING_PAGE_WITHDRAWAL_SUMMARY,
  GET_LANDING_PAGE_WINNER_LIST_MONTHLY,
  UPDATE_LANDING_PAGE_WITHDRAWAL_SUMMARY,
  GET_HOME_PAGE_CAROUSEL,
  GET_HOME_PAGE_CAROUSEL_SETTING,
} from '../constants/home';
import homeSvc from '../utils/services/home';
import { setWithdrawalSummary, setWinnerListSummary, updateWithdrawalSummary, setWinnerListMonthly, setHomePageCarousel, setHomePageCarouselSetting } from '../actions/home';

function* fetchWithdrawalSummary() {
  try {
    const request = yield call(
      homeSvc.getWithdrawalSummary
    );
    yield put(setWithdrawalSummary(request.data));
  } catch (err) {
    const { errors } = err.response.data;
    console.log(errors);
  } finally {
  }
}

function* fetchWinnerListSummary() {
    try {
      const request = yield call(
        homeSvc.getWinnerListSummary
      );
  
      yield put(setWinnerListSummary(request.data.data));
    } catch (err) {
      const { errors } = err.response.data;
      console.log(errors);
    } finally {
    }
  }

function* fetchWinnerListMonthly() {
  try {
    const request = yield call(
      homeSvc.getWinnerListMonthly
    );

    yield put(setWinnerListMonthly(request.data.data));
  } catch (err) {
    const { errors } = err.response.data;
    console.log(errors);
  } finally {
  }
}

function* updateWithdrawalSummaryRealTime(action) {
  const withdrawalSummary = yield select(state => state.home.withdrawalSummary);
  
  if( withdrawalSummary.length < 10 ) {
    withdrawalSummary.splice(0,0,action.payload);
  } else {
    withdrawalSummary.pop();
    withdrawalSummary.splice(0,0,action.payload);
  }

  yield put(setWithdrawalSummary(
    withdrawalSummary
  ));
}

function* fetchHomePageCarousel(action) {
  try {
    const request = yield call(
      homeSvc.getHomePageCarousel,
      action.params
    );

    yield put(setHomePageCarousel(request.data));
  } catch (err) {
    console.log(err)

  } finally {
  }
}

function* fetchHomePageCarouselSetting() {
  try {
    const request = yield call(
      homeSvc.getHomePageCarouselSetting
    );

    yield put(setHomePageCarouselSetting(request.data));
  } catch (err) {
 
  } finally {
  }
}

export default function* homeWatcher() {
  yield all([
      takeLatest(GET_LANDING_PAGE_WITHDRAWAL_SUMMARY, fetchWithdrawalSummary),
      takeLatest(GET_LANDING_PAGE_WINNER_LIST_SUMMARY, fetchWinnerListSummary),
      takeLatest(GET_LANDING_PAGE_WINNER_LIST_MONTHLY, fetchWinnerListMonthly),
      takeLatest(UPDATE_LANDING_PAGE_WITHDRAWAL_SUMMARY, updateWithdrawalSummaryRealTime),
      takeLatest(GET_HOME_PAGE_CAROUSEL, fetchHomePageCarousel),
      takeLatest(GET_HOME_PAGE_CAROUSEL_SETTING, fetchHomePageCarouselSetting),
    ]);
}
