import { SET_NOTIFICATION } from '../constants/notification';

/* eslint-disable */
export const setNotification = ({msg, type}) => ({
  type: SET_NOTIFICATION,
  payload: {
    msg,
    type
  }
});
