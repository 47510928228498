import {
  CANCEL_ONGOING_PROMOTION,
  CHANGE_PLAYER_PASSWORD,
  CHECK_PLAYER_SUBSCRIPTION,
  GET_ALL_GAMES_WALLET,
  GET_BANK_ACCOUNT,
  GET_GAMES_WALLET,
  GET_PLAYER_BONUS,
  GET_PLAYER_BONUS_HISTORY,
  GET_PLAYER_CASH,
  GET_PLAYER_CURRENT_TRANSACTIONS,
  GET_PLAYER_INFO,
  GET_PLAYER_MESSAGES,
  GET_PLAYER_TRANSACTIONS,
  GET_ONGOING_PROMOTION,
  LOAD_MORE_BONUS,
  LOAD_MORE_BONUS_SUCCESS,
  LOAD_MORE_CASH,
  LOAD_MORE_CASH_SUCCESS,
  LOAD_MORE_TRANSACTIONS,
  LOAD_MORE_TRANSACTIONS_SUCCESS,
  LOAD_MORE_MESSAGES,
  LOAD_MORE_MESSAGES_SUCCESS,
  ONGOING_PROMOTION_ERROR,
  REDEEM_BONUS,
  SET_ALL_GAMES_WALLET,
  SET_BANK_DETAILS,
  SET_ERROR,
  SET_GAME_WALLET,
  SET_MESSAGE_TO_VIEW,
  SET_ONGOING_PROMOTION,
  SET_PLAYER_HAS_PENDING_BET,
  SET_PLAYER_BANK_ACCOUNT,
  SET_PLAYER_BONUS,
  SET_PLAYER_CASH,
  SET_PLAYER_CURRENT_TRANSACTIONS,
  SET_PLAYER_EXISTING_SUBSCRIPTION,
  SET_PLAYER_INFO,
  SET_PLAYER_MESSAGES,
  SET_PLAYER_TRANSACTIONS,
  SET_RESPONSE_MESSAGE,
  SET_UNAUTHORIZED,
  UPDATE_PLAYER_INFO,
  ONGOING_PROMOTION_SUCCESS,
  SET_PLAYER_BONUS_HISTORY,
  LOAD_MORE_BONUS_HISTORY,
  LOAD_MORE_BONUS_HISTORY_SUCCESS,
  PLAYER_PROMOTION_MIN_DEPOSIT_SUCCESS,
  GET_PLAYER_PPROMOTION_MIN_DEPOSIT,
  SET_ALL_GAMES_WALLET_STATUS,
  INITIAL_STATUS,
  UPDATE_PLAYER_PROVIDER_WALLET,
  SEND_MESSAGE,
  UPDATE_PLAYER_BADGE_COUNT,
  GET_PLAYER_DEPOSIT_ACCOUNT,
  SET_PLAYER_DEPOSIT_ACCOUNT,
  ERROR_PLAYER_DEPOSIT_ACCOUNT,
  GET_PAYMENT_TRON_EXCHANGE,
  SET_PAYMENT_TRON_EXCHANGE,
  GET_PAYMENT_TRON_EXCHANGE_BALANCE,
  SET_PAYMENT_TRON_EXCHANGE_BALANCE,
  GET_PAYMENT_TRON_EXCHANGE_PURCHASE,
  SET_PAYMENT_TRON_EXCHANGE_PURCHASE,
  RESET_PAYMENT_TRON_EXCHANGE_BALANCE,
  RESET_PAYMENT_TRON_EXCHANGE_PURCHASE,
  SET_PAYMENT_TRON_EXCHANGE_BTN_DISABLED,
  GET_DEPOSIT_OPTION,
  SET_DEPOSIT_OPTION,
  SET_BALANCE_INQUIRY_BTN_DISABLED,
  GET_CHUNG_DAM_INFO,
  SET_CHUNG_DAM_INFO,
  GET_DEPOSIT_PROMOTION,
	SET_DEPOSIT_PROMOTION,
  SET_UPDATE_PLAYER_INFO_SUCCESS,
  SET_MESSAGE_SUCCESS,
  GET_CHECK_DEPOSIT_PROMOTION,
  SET_CHECK_DEPOSIT_PROMOTION
} from '../constants/player';

export const changePlayerPassword = params => ({
  type: CHANGE_PLAYER_PASSWORD,
  params,
});

export const checkPlayerSubscription = provider => ({
  type: CHECK_PLAYER_SUBSCRIPTION,
  provider,
});

export const getAllGamesWallet = () => ({
  type: GET_ALL_GAMES_WALLET,
});

export const getBankAccount = params => ({
  type: GET_BANK_ACCOUNT,
  params
});

export const getGamesWallet = (providers = []) => ({
  type: GET_GAMES_WALLET,
  providers,
});

export const getPlayerBonus = params => ({
  type: GET_PLAYER_BONUS,
  params,
});

export const getPlayerBonusHistory = params => ({
  type: GET_PLAYER_BONUS_HISTORY,
  params,
});

export const getPlayerCash = params => ({
  type: GET_PLAYER_CASH,
  params,
});

export const getPlayerCurrentTransactions = () => ({
  type: GET_PLAYER_CURRENT_TRANSACTIONS,
});

export const getPlayerInfo = () => ({
  type: GET_PLAYER_INFO,
});

export const getPlayerMessages = params => ({
  type: GET_PLAYER_MESSAGES,
  params,
});

export const getPlayerTransactions = params => ({
  type: GET_PLAYER_TRANSACTIONS,
  params,
});

export const getPlayerPromotionMinDeposit = params => ({
  type: GET_PLAYER_PPROMOTION_MIN_DEPOSIT,
  params,
});

export const setPlayerPromotionMinDeposit = promotionMinDeposit => ({
  type: PLAYER_PROMOTION_MIN_DEPOSIT_SUCCESS,
  promotionMinDeposit,
});

export const loadMoreBonus = params => ({
  type: LOAD_MORE_BONUS,
  params,
});

export const loadMoreBonusHistory = params => ({
  type: LOAD_MORE_BONUS_HISTORY,
  params,
});

export const loadMoreCash = params => ({
  type: LOAD_MORE_CASH,
  params,
});

export const loadMoreTransactions = params => ({
  type: LOAD_MORE_TRANSACTIONS,
  params,
});

export const loadMoreMessages = params => ({
  type: LOAD_MORE_MESSAGES,
  params,
});

export const redeemBonus = subscription => ({
  type: REDEEM_BONUS,
  subscription,
});

export const setAllGamesWallet = balance => ({
  type: SET_ALL_GAMES_WALLET,
  balance,
});

export const setAllGamesWalletStatus = allGameWalletStatus => ({
  type: SET_ALL_GAMES_WALLET_STATUS,
  allGameWalletStatus
});

export const setBankDetails = params => ({
  type: SET_BANK_DETAILS,
  params,
});

export const setErrors = errors => ({
  type: SET_ERROR,
  errors,
});

export const setGameWallet = balances => ({
  type: SET_GAME_WALLET,
  balances,
});

export const setMessageToView = (messageID, messageType) => ({
  type: SET_MESSAGE_TO_VIEW,
  messageID,
  messageType
});

export const setMoreBonus = bonus => ({
  type: LOAD_MORE_BONUS_SUCCESS,
  bonus,
});

export const setMoreBonusHistory = bonus => ({
  type: LOAD_MORE_BONUS_HISTORY_SUCCESS,
  bonus,
});

export const setMoreCash = cash => ({
  type: LOAD_MORE_CASH_SUCCESS,
  cash,
});

export const setMoreTransactions = transactions => ({
  type: LOAD_MORE_TRANSACTIONS_SUCCESS,
  transactions,
});

export const setMoreMessages = messages => ({
  type: LOAD_MORE_MESSAGES_SUCCESS,
  messages,
});

export const setPlayerBonus = (bonus, page = 1, status = INITIAL_STATUS) => ({
  type: SET_PLAYER_BONUS,
  bonus,
  page,
  status
});

export const setPlayerBonusHistory = (bonus, page = 1) => ({
  type: SET_PLAYER_BONUS_HISTORY,
  bonus,
  page,
});

export const setPlayerCash = (cash, page = 1, status = INITIAL_STATUS) => ({
  type: SET_PLAYER_CASH,
  cash,
  page,
  status,
});

export const setPlayerCurrentTransactions = transactions => ({
  type: SET_PLAYER_CURRENT_TRANSACTIONS,
  transactions,
});

export const setPlayerExistingSubscription = subscription => ({
  type: SET_PLAYER_EXISTING_SUBSCRIPTION,
  subscription,
});

export const getOngoingPromotion = params => ({
  type: GET_ONGOING_PROMOTION,
  params,
});

export const setOngoingPromotion = subscription => ({
  type: SET_ONGOING_PROMOTION,
  subscription,
});

export const setOngoingPromotionError = error => ({
  type: ONGOING_PROMOTION_ERROR,
  error,
});

export const setOngoingPromotionSuccess = success => ({
  type: ONGOING_PROMOTION_SUCCESS,
  success,
});

export const cancelOngoingPromotion = params => ({
  type: CANCEL_ONGOING_PROMOTION,
  params,
});

export const setPlayerHasPendingBet = hasPendingBet => ({
  type: SET_PLAYER_HAS_PENDING_BET,
  hasPendingBet,
});

export const setPlayerInfo = data => ({
  type: SET_PLAYER_INFO,
  data,
});

export const setPlayerMessages = (messages, page = 1, status = INITIAL_STATUS) => ({
  type: SET_PLAYER_MESSAGES,
  messages,
  page,
  status
});

export const setPlayerTransactions = (transactions, page = 1, status = INITIAL_STATUS) => ({
  type: SET_PLAYER_TRANSACTIONS,
  transactions,
  page,
  status
});

export const setPlayerUnauthorized = (status = false) => ({
  type: SET_UNAUTHORIZED,
  status,
});

export const setResponseMessage = (msg, show, msg_type = '') => ({
  type: SET_RESPONSE_MESSAGE,
  msg,
  show,
  msg_type,
});

export const updatePlayerInfo = params => ({
  type: UPDATE_PLAYER_INFO,
  params,
});

export const setPlayerBankAccount = bankAccount => ({
  type: SET_PLAYER_BANK_ACCOUNT,
  bankAccount,
});

export const updatePlayerProviderWallet = params => ({
  type: UPDATE_PLAYER_PROVIDER_WALLET,
  params
});

export const updatePlayerBadgeCount = params => ({
  type: UPDATE_PLAYER_BADGE_COUNT,
  params
});

export const sendMessage = params => ({
  type: SEND_MESSAGE,
  params,
});

export const getDepositAccount = () => ({
  type: GET_PLAYER_DEPOSIT_ACCOUNT
});

export const setDepositAccount = params => ({
  type: SET_PLAYER_DEPOSIT_ACCOUNT,
  params,
});

export const errorDepositAccount = params => ({
  type: ERROR_PLAYER_DEPOSIT_ACCOUNT,
  params,
});

export const getPaymentTronExchange = () => ({
  type: GET_PAYMENT_TRON_EXCHANGE,
});

export const setPaymentTronExchange = params => ({
  type: SET_PAYMENT_TRON_EXCHANGE,
  params
});

export const getPaymentTronExchangeBalance = () => ({
  type: GET_PAYMENT_TRON_EXCHANGE_BALANCE,
});

export const setPaymentTronExchangeBalance = params => ({
  type: SET_PAYMENT_TRON_EXCHANGE_BALANCE,
  params
});

export const resetPaymentTronExchangeBalance = () => ({
  type: RESET_PAYMENT_TRON_EXCHANGE_BALANCE,
});

export const resetPaymentTronExchangePurchase = () => ({
  type: RESET_PAYMENT_TRON_EXCHANGE_PURCHASE,
});

export const getPaymentTronExchangePurchase = () => ({
  type: GET_PAYMENT_TRON_EXCHANGE_PURCHASE,
});

export const setPaymentTronExchangePurchase = params => ({
  type: SET_PAYMENT_TRON_EXCHANGE_PURCHASE,
  params
});

export const setPaymentTronExchangeBtnDisabled = params => ({
  type: SET_PAYMENT_TRON_EXCHANGE_BTN_DISABLED,
  params
});

export const getDepositOption = params => ({
  type: GET_DEPOSIT_OPTION,
  params
});

export const setDepositOption = params => ({
  type: SET_DEPOSIT_OPTION,
  params
});

export const setBalanceInquiryBtnDisabled = params => ({
  type: SET_BALANCE_INQUIRY_BTN_DISABLED,
  params
});

export const getChungDamInfo = () => ({
  type: GET_CHUNG_DAM_INFO,
});

export const setChungDamInfo = params => ({
  type: SET_CHUNG_DAM_INFO,
  params
});

export const getDepositPromotion = params => ({
  type: GET_DEPOSIT_PROMOTION,
  params
});

export const setDepositPromotion = params => ({
  type: SET_DEPOSIT_PROMOTION,
  params
});

export const setUpdatePlayerInfoSuccess = params => ({
  type: SET_UPDATE_PLAYER_INFO_SUCCESS,
  params
});

export const setMessageSuccess = params => ({
  type: SET_MESSAGE_SUCCESS,
  params
});

export const getCheckDepositPromotion = () => ({
  type: GET_CHECK_DEPOSIT_PROMOTION,
});

export const setCheckDepositPromotion = params => ({
  type: SET_CHECK_DEPOSIT_PROMOTION,
  params
});