const styles = theme => ({
  paperScrollPaper: {
    maxWidth: '98%',
    width: '100%',
    margin: '20px',
    //adding lines 7-18 dialog-[title|content] to override styles on notice modal in tab menu
    //cannot override styles under notice-modal
    '& .dialog-title': {
      padding: theme.spacing(1),
      textAlign: 'left',
      fontSize: '1rem',
      color: theme.palette.text.default,
      background: '#262626', //adding fixed values becuase theme is not reflecting custom value
    },
    '& .dialog-content': {
      padding: theme.spacing(1),
      textAlign: 'left',
      fontSize: '0.75em',
      background: '#1A1A1A', //adding fixed values becuase theme is not reflecting custom value
    },
  },
  root: {
    '& .MuiDialogActions-root': {
      // flex: 0 0 auto;
      // display: flex;
      // padding: 8px;
      // align-items: center;
      // justify-content: flex-end;
      background: '#28383F',
      paddingBottom: '24px',
    },
    '& .MuiDialogTitle-root': {
      padding: 0,
      background: '#1D2C31',
      '& .MuiTypography-root': {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '16px',
        fontWeight: '700',
        '& p': {
          margin: 0,
          position: 'absolute',
          textAlign: 'center',
          width: '100%',
        },
        '& .MuiIconButton-root': {
          '& svg': {
            fontSize: '30px',
            paddingBottom: 0,
          },
        },
      },
    },
    '& .MuiDialogContent-root': {
      scrollBehavior: 'smooth',
      flex: '1 1 auto',
      overflowY: 'auto',
      justifyContent: 'center',
      background: '#28383F',
      fontSize: '14px',
      textAlign: 'center',
      padding: '24px',
    },
  },
});

export default styles;
