import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/styles/withStyles';
import Card from '@material-ui/core/Card';
import { useTranslation } from 'react-i18next';

import styles from './styles';
import { TrailingIcon } from '../fifteenbet.icons';

const CustomCardDropdown = ({
  classes,
  currency,
  details,
  disabled,
  handleClick,
  label,
  titleLabel,
  dropIcon,
  WalletIcon
}) => {
  const { root } = classes;
  const { t } = useTranslation();
  return (
    <div className={root}>
      <p className="title">{t(titleLabel)}</p>
      <Card
        className="dropdown"
        onClick={!disabled ? handleClick : null}
        style={{
          borderBottom: `2px solid ${
            disabled
              ? 'rgba(255, 255, 255, 0.3)'
              : details
              ? '#8EB50D'
              : '#FFF'
          }`,
        }}
      >
          <div className="name">
            <div>
            {!details && <p className="placeholder">{t(label)}</p>}
            {
              details && details.icon ?
                <img src={details.icon} className="providerIcon" /> :
                <p>{details ? t(details.name) : ''}</p>
            }
            </div>
            {details && details.name != 'no balance' && (
            <div className="value">
              <span className="currency">{currency}</span>
              <span className="amount">{details.value}</span>
            </div>
          )}
          </div>
          <div className="iconContainer">
          {!disabled && <TrailingIcon className={dropIcon}/>}
          </div>
      </Card>
    </div>
  );
};

CustomCardDropdown.defaultProps = {
  currency: '',
  details: null,
  disabled: false,
  handleClick: null,
  label: '',
  titleLabel: '',
};

CustomCardDropdown.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
  }).isRequired,
  currency: PropTypes.string,
  details: PropTypes.shape({
    currency: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string,
  }),
  disabled: PropTypes.bool,
  handleClick: PropTypes.func,
  label: PropTypes.string,
  titleLabel: PropTypes.string,
};

export default withStyles(styles)(CustomCardDropdown);
