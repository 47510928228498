const styles = theme => ({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      marginTop: 0,
      '& .form': {
        marginTop: theme.spacing(2),
      },
      '& .title': {
        fontSize: '24px',
        fontWeight: 500,
        marginTop: theme.spacing(3),
        //marginBottom: theme.spacing(4),
        textAlign: 'center',
      },
      '& .forgot-pass': {
        float: 'right',
        marginTop: theme.spacing(3),
        color: theme.palette.text.secondary,
        textDecoration: 'none',
        fontSize: '14px',
        fontWeight: 400,
        paddingRight: theme.spacing(1) / 2,
      },
      '& .submit': {
        marginTop: '10px',
      },
      '& .register': {
        backgroundColor: '#374950',
        color: '#FEFEFE',
        fontSize: '14px',
        fontWeight: 700
      },
      '& .problem-login': {
        // fontSize: '0.8125em',
        textAlign: 'center',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        color: theme.palette.text.secondary,
        '& span': {
          fontWeight: 700,
          color: theme.palette.secondary.main,
        },
        fontSize: '13px',
        fontWeight: 400,
        lineHeight: '18px'
      },
      '& .separator': {
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        marginTop: '0.7em',
        marginBottom: '0.7em',
        color: theme.palette.text.secondary,
      },
      '& .separator::before': {
        marginRight: '.5em',
        content: 'attr(data-content)',
        flex: 1,
        borderBottom: `1px solid ${theme.palette.text.secondary}`,
      },
      '& .separator::after': {
        marginLeft: '0.5em',
        content: 'attr(data-content)',
        flex: 1,
        borderBottom: `1px solid ${theme.palette.text.secondary}`,
      },
      '& #keep-logged-in .MuiFormControlLabel-label': {
        fontWeight:400,
        fontSize: '13px',
        color: '#9FA2AB'
      },
      ' & .MuiFilledInput-underline:hover:before': {
        borderBottom: 0
      },
      '& .MuiFilledInput-adornedEnd': {
        background: '#28383F'
      },
      '& .MuiFilledInput-root:hover': {
        backgroundColor: '#28383F !important'
      }
    },
  });
  
  export default styles;
  