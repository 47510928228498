import API from './index';

const slotSvc = {
  getGames: params => new API().get('/slot/games', params),
  getGameCategories: params =>
    new API().get('/gameCategories', params),
  getRecentGames: params => new API().get('/recentSlotTest', params),
  launchGame: params => new API().post('/casino/play', params),
  launchGameDemo: params => new API().post('/casino/open', params)
};

export default slotSvc;
