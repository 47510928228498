import axios from 'axios';
import API from './index';
import { BRAND } from '../../config/api';

const commonSvc = {
  getActiveProviders: () => new API().get('/activeProviders'),
  getBanks: params => new API().get('/banks', params),
  getCurrencies: () => new API().get(`/brand/${BRAND}`),
  getIP: () => axios.get('https://api.ipify.org?format=json'),
  getProviders: params => new API().get('/providers', params),
  getProvidersByProduct: params => new API().get('/providers/byProduct', params),
  getProductProviders: () => new API().get('/productProviders'),
  getProviderPromotions: params => new API().get('/providerPromotions', params),
  setFavourite: params => new API().post('/favourites', params),
  getGames: (params) => new API().get('/slot/games', params),
  getProducts: (params) => new API().get('/products', params),
  getHomeBanners: (params) => new API().get('/banners', params), 
  getRecentGames: (params) => new API().get('/recentSlotTest', params),
};

export default commonSvc;
