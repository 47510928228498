import React, {useState, useCallback} from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Checkbox from '@material-ui/core/Checkbox';
import DateFnsUtils from '@date-io/date-fns';
import ReactHTMLParse from 'react-html-parser';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import FormHelperText from '@material-ui/core/FormHelperText';

import { useTranslation } from 'react-i18next';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import Loading from '../../../assets/images/loading.gif';
import { AmountLockedIcon, DepositPromotionIcon } from '../../../components/custom/joabet.icons';

import CustomNumberFormat from '../number-format';
import CustomButton from '../button';
import CustomCardDropdown from '../card-dropdown';
import CustomWalletInfo from '../wallet-info';
import CustomDialog from '../dialog';

import modifiedResponses from '../../../utils/helpers/response.msg.modifier';
import {
  displayAmount
} from '../../../utils/helpers/common';
import styles from './styles';

const CustomForm = props => {
  const {
    children,
    classes,
    fields,
    handleClickShowPassword,
    onChange,
    customOnClick,
    onSubmit,
    submitErrors
  } = props;
  const {
    customFormControl,
    disableClickEvent,
    disabledInput,
    disabledText,
    root,
    selectedItem,
    selectStyle,
    selectMobileStyleWidth,
    selectItemLogo,
    selectItemTitle,
    formControl,
    adornmentFormControl,
    adornmentFixErrorTextFormControl,
    item,
    radioLabel,
    loaderContainer,
    loading,
    divider,
    selectItemLayout,
    checkBoxFormControl
  } = classes;
  const { t } = useTranslation();
  const [showDialog, toggleDialog] = useState(false);
  const excludedProviders = [6, 10];
  const [open, setOpen] = useState({});

  const findValue = useCallback((input) => {
    const values = input.values || [];
    const item = values.find(item => item.value === props[input.id].value) || { name: 'select wallet' };

    return {
      ...item,
      value: item.amount ? displayAmount(item.amount) : ''
    }
  });

  const generate = (input, index) =>{
    const Icon = input.icon;
    const onClick = (id) => {
      setOpen({
        [id] : { status: true }
      });
    };
    const handleClose = (id) => {
      setOpen({
        [id] : { status: false }
      });
    };

    const minYear = new Date();
    minYear.setFullYear(minYear.getFullYear() - 20);

    return {
      checkbox: (
        <FormControl key={index.toString()} className={checkBoxFormControl}>
          <FormControlLabel 
            control={
              <Checkbox 
                onChange={e =>
                  {
                    onChange({
                      ...e,
                      target: { name: input.name, value: e.target.checked },
                    });
                  }
                }
                inputProps={{
                  id: input.id,
                  name: input.id,
                }}
                size={input.size ? input.size : ""}
              />
            } 
            label={ReactHTMLParse(t(input.label))}
            labelPlacement="end"
          />
        </FormControl>
      ),
      button: (
        <FormControl
          key={index.toString()}
          className={`${formControl} ${customFormControl}`}
        >
          <CustomButton
            disabled={false}
            hasLoading
            variant="contained"
            color="red"
            type="button"
            onClick={input.onClick}
            className={classes.submit}
            showIcon={input.showIcon}
            text={input.text}
          />
        </FormControl>
      ),
      radio: (
        <FormControl key={index.toString()} className={formControl}>
          <RadioGroup
            aria-label="position"
            name="position"
            onChange={e =>
              onChange({
                ...e,
                target: { name: input.name, value: true },
              })
            }
            row
          >
            <FormControlLabel
              className={radioLabel}
              control={
                <Radio
                  checked={props[input.id].value}
                  inputProps={{
                    id: input.id,
                    name: input.id,
                  }}
                  size={input.size ? input.size : ""}
                />
              }
              label={ReactHTMLParse(t(input.label))}
              labelPlacement="end"
            />
          </RadioGroup>
        </FormControl>
      ),
      text: (
        <FormControl
          key={index.toString()}
          className={`
             ${formControl}
             ${input.customClass}
             ${input.disabled && disabledText}
           `}
        >
          <Grid
            key={index.toString()}
            container
            spacing={1}
            alignItems="center"
          >
            {/* {input.mobile && (
              <Grid item xs={3} style={{ marginTop: '24px' }}>
                <Select
                  className={
                    !!input.mobile.disabled ? 'disabled-input' : ''
                  }
                  value={props[input.mobile.id].value}
                  onChange={onChange}
                  id={input.mobile.id}
                  name={input.id}
                  inputProps={{
                    readOnly: input.mobile.disabled,
                  }}
                  fullWidth
                  variant={input.variant}
                >
                  {input.mobile.values &&
                    input.mobile.values.map((obj, key) => (
                      <MenuItem
                        key={key.toString()}
                        value={obj.value}
                      >
                        {obj.name}
                      </MenuItem>
                    ))}
                </Select>
              </Grid>
            )} */}
            <Grid item xs={input.mobile ? 12 : 12}>
              <div
                className={`${(input.name === 'phone' && window.location.pathname !== '/account-details') || input.name === 'oldnumber' || input.name === 'newnumber' ? 'mobileContainer phoneContainer' : 'mobileContainer'}
                 ${input.mobile && 'mobileContainerWithSelect'}`}
              >
                {typeof input.icon === 'function' && (
                  <IconButton
                    className={classes.iconContainerStyle}
                    aria-label="Menu"
                  >
                    {/* <MenuIcon /> */}
                    {/* <img src={Icon} /> */}
                    <Icon
                      className={
                        !!input.disabled ? 'disabled' : 'active'
                      }
                    />
                  </IconButton>
                )}
                {input.mobile && (
                  <Select
                    className={`${input.mobile && `mobileTextSelect`}
                     ${
                       !!input.mobile.disabled ? 'disabled-input' : ''
                     }`}
                    value={props[input.mobile.id].value}
                    onChange={onChange}
                    id={input.mobile.id}
                    name={input.id}
                    inputProps={{
                      readOnly: input.mobile.disabled,
                    }}
                    //fullWidth
                    variant={input.variant}
                  >
                    {input.mobile.values &&
                      input.mobile.values.map((obj, key) => (
                        <MenuItem
                          key={key.toString()}
                          value={obj.value}
                        >
                          {obj.name}
                        </MenuItem>
                      ))}
                  </Select>
                )}
                <TextField
                  className={`${input.icon ? 'withIcon' : ''}
                  ${input.mobile && 'mobileText'}
                  ${!!input.disabled ? `disabled-input` : ''}
                  ${input.customClass}`}
                  required={input.required ? false : !!props[input.id].required}
                  autoFocus={input.focus}
                  variant={input.variant}
                  InputLabelProps={{
                    shrink: input.shrink,
                    required: false
                  }}
                  disabled={input.disabled}
                  InputProps={{
                    name: input.name,
                    id: input.id,
                    readOnly: input.disabled,
                    inputComponent:
                      input.number && CustomNumberFormat,
                      startAdornment: ((input.name.includes('oldnumber') || input.name.includes('newnumber') || input.name.includes('phone')) && (input.countryCodeDisplay === undefined || input.countryCodeDisplay) )  && (
                      <InputAdornment position="start">
                          <span style={{marginRight:'5px',fontSize:'14px'}}>+82</span>{/*<DropDownIcon/>*/}
                      </InputAdornment>
                    ),
                    endAdornment: input.name.includes('bonusContainer') && (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => customOnClick()}
                        >
                          <DepositPromotionIcon/>
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  helperText={
                    submitErrors[input.id]
                      ? modifiedResponses[submitErrors[input.id][0]]
                        ? t(
                            modifiedResponses[
                              submitErrors[input.id][0]
                            ].toLowerCase(),
                          )
                        : t(submitErrors[input.id][0].toLowerCase())
                      : input.helperText || ''
                  }
                  error={!!submitErrors[input.id]}
                  label={
                    input.currencyAbbreviation
                      ? t(input.label) +
                        ' (' +
                        input.currencyAbbreviation +
                        ')'
                      : t(input.label)
                  }
                  placeholder={t(input.placeholder)}
                  onClick={customOnClick}
                  onChange={onChange}
                  margin="normal"
                  value={t(props[input.id].value) || ''}
                  multiline={input.multiline}
                  rows={input.rows}
                  fullWidth
                  //{...input}
                  onKeyDown={input.onKeyDown}
                  style={{
                    opacity: input.blur ? '0.5' : 'unset'
                  }}
                />
              </div>
              {input.bottomText !== undefined ?
                <div
                  dangerouslySetInnerHTML={{__html: input.bottomText}}
                  style={{color:'#9FA2AB',fontSize:'13px',fontWeight:'400',lineHeight:1.4,paddingLeft:'8px'}}
                />
              : null}
            </Grid>
          </Grid>
        </FormControl>
      ),
      textAdornment: (
        <Grid
          key={index.toString()}
          container
          spacing={5}
          alignItems="flex-start"
        >
          <Grid item xs={1}>
            {!input.noIcon &&
              (input.icon ? (
                <img
                  alt=""
                  src={input.icon}
                  style={{ paddingTop: '20px' }}
                />
              ) : (
                <AccountCircle />
              ))}
          </Grid>
          {input.mobile && (
            <Grid item xs={2}>
              <Select
                value={props[input.mobile.id].value}
                onChange={onChange}
                id={input.mobile.id}
                name={input.id}
                style={{ paddingTop: '20px' }}
                variant={input.variant}
              >
                {input.mobile.values &&
                  input.mobile.values.map((obj, key) => (
                    <MenuItem key={key.toString()} value={obj.value}>
                      {obj.name}
                    </MenuItem>
                  ))}
              </Select>
            </Grid>
          )}
          <Grid item xs={input.mobile ? 8 : 10} classes={{ item }}>
            <FormControl
              className={
                submitErrors[input.id] &&
                ['The number is blacklisted.'].includes(
                  submitErrors[input.id][0],
                )
                  ? adornmentFixErrorTextFormControl
                  : adornmentFormControl
              }
            >
              <TextField
                className={!!input.disabled ? 'disabled-input' : ''}
                required={!!props[input.id].required}
                autoFocus={input.focus}
                id={input.id}
                label={t(input.label)}
                error={!!submitErrors[input.id]}
                placeholder={t(input.placeholder)}
                multiline={input.multiline}
                rows={input.rows}
                variant={input.variant}
                type={
                  input.name.indexOf('password') > -1 &&
                  !input.show_password
                    ? 'password'
                    : 'text'
                }
                onChange={onChange}
                value={props[input.id].value || ''}
                InputProps={{
                  name: input.name,
                  id: input.id,
                  readOnly: input.disabled,
                  className: !!input.disabled ? disabledInput : '',
                  inputComponent: input.number && CustomNumberFormat,
                  endAdornment: input.name.indexOf('password') >
                    -1 && (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => handleClickShowPassword(index)}
                      >
                        {!input.show_password ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                helperText={
                  submitErrors[input.id]
                    ? t(
                        modifiedResponses[submitErrors[input.id][0]]
                          ? modifiedResponses[
                              submitErrors[input.id][0]
                            ]
                          : submitErrors[input.id][0].toLowerCase(),
                      )
                    : input.helperText || ''
                }
                onKeyDown={input.onKeyDown}
              />
            </FormControl>
          </Grid>
        </Grid>
      ),
      mobile: (
        <Grid
          key={index.toString()}
          container
          //spacing={5}
          //alignItems="flex-start"
        >
          <Grid item xs={3} sm={3}>
            <FormControl
              key={index.toString()}
              className={`${formControl}
               ${selectStyle}
               ${input.customClass}`}
            >
              {input.mobile && (
                <TextField
                  select
                  value={props[input.mobile.id].value}
                  onChange={onChange}
                  id={input.mobile.id}
                  name={input.id}
                  variant={input.variant}
                  // helperText={
                  //   submitErrors[input.id]
                  //     ? t(
                  //         modifiedResponses[submitErrors[input.id][0]]
                  //           ? modifiedResponses[
                  //               submitErrors[input.id][0]
                  //             ]
                  //           : submitErrors[input.id][0].toLowerCase(),
                  //       )
                  //     : input.helperText || ''
                  // }
                >
                  {input.mobile.values &&
                    input.mobile.values.map((obj, key) => (
                      <MenuItem
                        key={key.toString()}
                        value={obj.value}
                      >
                        {obj.name}
                      </MenuItem>
                    ))}
                </TextField>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={input.mobile ? 9 : 10} sm={9}>
            <FormControl
              className={
                submitErrors[input.id] &&
                ['The number is blacklisted.'].includes(
                  submitErrors[input.id][0],
                )
                  ? `adornmentFixErrorTextFormControl ${selectMobileStyleWidth} ${divider}`
                  : `adornmentFormControl ${selectMobileStyleWidth} ${divider}`
              }
            >
              <TextField
                className={!!input.disabled ? 'disabled-input' : ''}
                required={!!props[input.id].required}
                autoFocus={input.focus}
                id={input.id}
                label={t(input.label)}
                error={!!submitErrors[input.id]}
                placeholder={t(input.placeholder)}
                multiline={input.multiline}
                rows={input.rows}
                variant={input.variant}
                type={
                  input.name.indexOf('password') > -1 &&
                  !input.show_password
                    ? 'password'
                    : 'text'
                }
                onChange={onChange}
                value={props[input.id].value || ''}
                InputProps={{
                  name: input.name,
                  id: input.id,
                  readOnly: input.disabled,
                  className: !!input.disabled ? disabledInput : '',
                  inputComponent: input.number && CustomNumberFormat,
                  endAdornment: input.name.indexOf('password') >
                    -1 && (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => handleClickShowPassword(index)}
                      >
                        {!input.show_password ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                helperText={
                  submitErrors[input.id]
                    ? t(
                        modifiedResponses[submitErrors[input.id][0]]
                          ? modifiedResponses[
                              submitErrors[input.id][0]
                            ]
                          : submitErrors[input.id][0].toLowerCase(),
                      )
                    : input.helperText || ''
                }
                onKeyDown={input.onKeyDown}
              />
            </FormControl>
          </Grid>
        </Grid>
      ),
      password: (
        <FormControl
          key={index.toString()}
          className={`${formControl} ${input.customClass}`}
        >
          <TextField
            InputLabelProps={{required: false}}
            fullWidth
            required={!!props[input.id].required}
            type={!input.show_password ? input.type : 'text'}
            label={t(input.label)}
            margin="normal"
            variant={input.variant}
            onChange={onChange}
            placeholder={t(input.placeholder)}
            value={props[input.id].value || ''}
            InputProps={{
              name: input.name,
              id: input.id,
              readOnly: input.disabled,
              endAdornment: input.name
                .toLowerCase()
                .indexOf('password') > -1 && (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => handleClickShowPassword(index)}
                  >
                    {!input.show_password ? (
                      <VisibilityOff color="disabled" />
                    ) : (
                      <Visibility color="disabled" />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            helperText={
              submitErrors[input.id]
                ? t(
                    modifiedResponses[submitErrors[input.id][0]]
                      ? modifiedResponses[submitErrors[input.id][0]]
                      : submitErrors[input.id][0].toLowerCase(),
                  )
                : input.helperText || ''
            }
            error={!!submitErrors[input.id]}
          />
        </FormControl>
      ),
      date: (
        <div
          key={index.toString()}
          className={`${formControl} ${'mobileContainer'}`}
        >
          <div className={`${'mobileContainer'}`}>
            {typeof input.icon === 'function' && (
              <IconButton
                className={classes.iconContainerStyle}
                aria-label="Menu"
              >
                {/* <MenuIcon /> */}
                {/* <img src={Icon} /> */}
                <Icon
                  className={!!input.disabled ? 'disabled' : 'active'}
                />
              </IconButton>
            )}
            <FormControl
              key={index.toString()}
              className={formControl}
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  required={!!props[input.id].required}
                  ampm={false}
                  margin="normal"
                  label={t(input.label)}
                  disableFuture
                  format="yyyy-MM-dd"
                  KeyboardButtonProps=""
                  inputVariant={input.variant}
                  className={`${input.icon ? 'withIcon' : ''}
               ${
                 input.disabled
                   ? `${disableClickEvent} disabled-input`
                   : ''
               }`}
                  helperText={
                    submitErrors[input.id]
                      ? t(
                          modifiedResponses[submitErrors[input.id][0]]
                            ? modifiedResponses[
                                submitErrors[input.id][0]
                              ]
                            : submitErrors[input.id][0].toLowerCase(),
                        )
                      : input.helperText || ''
                  }
                  maxDate={minYear}
                  error={!!submitErrors[input.id]}
                  autoOk
                  value={props[input.id].value}
                  onChange={e => {
                    onChange({
                      target: { name: input.name, value: e },
                    });
                  }}
                />
              </MuiPickersUtilsProvider>
            </FormControl>
          </div>
        </div>
      ),
      loader: (
        <div className={loaderContainer}>
          <img alt="loading" className={loading} src={Loading} />
        </div>
      ),
      header: (
          <div className={input.customClass}>{input.name}</div>
      ),
      customSelect: (
        <div key={index.toString()}>
          <CustomCardDropdown
            currency={input.currency || ''}
            disabled={input.disabled ? true : false}
            titleLabel="wallet option"
            label="select wallet"
            handleClick={() => toggleDialog(!showDialog)}
            details={findValue(input)}
          />
          <CustomDialog
            fullScreen
            //customClass={filterDialog}
            openDialog={showDialog}
            hasHeader
            title={t('select wallet')}
            onClose={() => {
              toggleDialog(!showDialog);
            }}
          >
            {input.values &&
              input.values
                .filter(item => {
                  return !(
                    excludedProviders.indexOf(item.value) > -1
                  );
                })
                .map((item, index) => {
                  return (
                    <CustomWalletInfo
                      key={index.toString()}
                      currency={input.currency || ''}
                      amount={displayAmount(item.amount)}
                      //  hideAmountLocked={type === 'to'}
                      hasLockedAmount={
                        input.amountLockedProviders &&
                        input.amountLockedProviders[item.value] &&
                        input.amountLockedProviders[item.value]
                          .isSubscribed
                      }
                      name={item.name}
                      disabled={item.disabled}
                      isTitle={item.isTitle}
                      isProvider={
                        item.name !== 'main wallet' &&
                        item.name !== 'all games wallet'
                      }
                      icon={item.icon}
                      MainWalletIcon={item.MainWalletIcon}
                      sharedWallet={
                        input.sharedWallet &&
                        input.sharedWallet[item.value] &&
                        input.sharedWallet[item.value].products
                          .length > 1
                      }
                      sharedWalletDetails={
                        input.sharedWallet &&
                        input.sharedWallet[item.value]
                      }
                      handleClick={() => {
                        !item.disabled &&
                          onChange({
                            target: {
                              name: input.name,
                              id: input.id,
                              value: item.value,
                            },
                          });
                        toggleDialog(!showDialog);
                      }}
                      isBoldWalletName={true}
                    />
                  );
                })}
          </CustomDialog>
        </div>
      ),
      select: (
        <div
          key={index.toString()}
          className={`${formControl} ${'mobileContainer'}`}
        >
          <div className={`${'mobileContainer'}`}>
            {typeof input.icon === 'function' && (
              <IconButton
                className={`${classes.iconContainerStyle} ${
                  input.values !== undefined &&
                  input.values.length > 0
                    ? ''
                    : false
                }`}
                aria-label="Menu"
              >
                {/* <MenuIcon /> */}
                {/* <img src={Icon} /> */}
                <Icon
                  className={!!input.disabled ? 'disabled' : 'active'}
                />
              </IconButton>
            )}
            <FormControl
              key={index.toString()}
              className={`
                ${formControl}
                ${input.customClass ? input.customClass : selectStyle}
              `}
              margin="normal"
              error={!!submitErrors[input.id]}
            >
              <InputLabel
                className="labelStyle"
                required={!!props[input.id].required}
                htmlFor={input.id}
                id={input.id}
                onClick={() => onClick(input.id)}
              >
                {t(input.label.toLowerCase())}
              </InputLabel>
              <Select
                fullWidth
                disabled={input.disabled ? true : false}
                className={`${input.icon ? 'withIcon' : ''}
              ${
                !!input.disabled ? 'disabled-input' : 'customSelect'
              }`}
                value={props[input.id].value || ''}
                onChange={onChange}
                onOpen={() => onClick(input.id)}
                onClose={handleClose}
                open={open[input.id] && open[input.id].status}
                variant={input.variant}
                margin="none"
                renderValue={
                  input.hasCustomRender && input.values.length > 0
                    ? value => {
                        let idx = input.values.findIndex(
                          item => item.value === value,
                        );
                        const finalValue =
                          value === 'all_wallet' || value === '0'
                            ? t(input.values[idx].name.toLowerCase())
                            : input.values[idx].name;
                        return finalValue;
                      }
                    : null
                }
                id={input.id}
                labelId={input.id}
                label={t(input.label)}
                inputProps={{
                  name: input.name,
                  id: input.id,
                  readOnly: input.disabled,
                }}
                helpertext={
                  submitErrors[input.id]
                    ? t(
                        modifiedResponses[submitErrors[input.id][0]]
                          ? modifiedResponses[
                              submitErrors[input.id][0]
                            ]
                          : submitErrors[input.id][0].toLowerCase(),
                      )
                    : input.helperText || ''
                }
                error={!!submitErrors[input.id]}
              >
                {input.values &&
                  input.values.map((obj, key) => {
                    const { WalletIcon } = obj;
                    return (
                      <MenuItem
                        classes={{ root: selectedItem }}
                        className={`${selectItemLayout}
                     ${obj.isTitle ? selectItemTitle : ''}
                     ${obj.hidden ? 'hidden' : ''}`}
                        disabled={obj.disabled}
                        key={key.toString()}
                        value={obj.value}
                      >
                        {typeof obj.icon === 'function' &&
                        obj.icon ? (
                          <obj.icon />
                        ) : (
                          ''
                          //  WalletIcon !== undefined ? <WalletIcon /> : ''
                        )}
                        {t(
                          obj.isTitle
                            ? obj.name.toLowerCase()
                            : obj.name,
                        )}{' '}
                        {obj.balance
                          ? '(' + displayAmount(obj.balance) + ')'
                          : ''}
                        {obj.disabled &&
                        obj.name !== t('no wallet available') &&
                        obj.value ? (
                          <AmountLockedIcon className="lockIcon" />
                        ) : (
                          ''
                        )}
                      </MenuItem>
                    );
                  })}
              </Select>
              {!!submitErrors[input.id] ? <FormHelperText>{t(submitErrors[input.id])}</FormHelperText> : null}
            </FormControl>
          </div>
        </div>
      ),
      selectAdornment: (
        <Grid
          key={index.toString()}
          container
          spacing={5}
          alignItems="flex-end"
        >
          <Grid xs={1} item>
            {input.icon ? (
              <img alt="" src={input.icon} />
            ) : (
              <AccountCircle />
            )}
          </Grid>
          <Grid item xs={10} classes={{ item }}>
            <FormControl className={adornmentFormControl}>
              <Select
                fullWidth
                label={t(input.label)}
                value={props[input.id].value || ''}
                onChange={onChange}
                margin="none"
                inputProps={{
                  name: input.name,
                  id: input.id,
                  readOnly: input.disabled,
                }}
                helperText={
                  submitErrors[input.id]
                    ? t(
                        modifiedResponses[submitErrors[input.id][0]]
                          ? modifiedResponses[
                              submitErrors[input.id][0]
                            ]
                          : submitErrors[input.id][0].toLowerCase(),
                      )
                    : input.helperText || ''
                }
                error={!!submitErrors[input.id]}
              >
                {input.values &&
                  input.values.map((obj, key) => (
                    <MenuItem
                      classes={{ selected: selectedItem }}
                      key={key.toString()}
                      value={obj.value}
                    >
                      {t(obj.name)}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      ),
    }[input.type];
  }

  return (
    <form onSubmit={onSubmit} className={root}>
      {fields.map((input, index) => [
        input.title_label ? (
          <p key="title-label">{input.title_label}</p>
        ) : (
          ''
        ),
        generate(input, index),
      ])}
      {children}
    </form>
  );
};

CustomForm.defaultProps = {
  children: null,
  classes: {},
  fields: [],
  submitErrors: {},
  handleClickShowPassword: null,
  customOnClick: null,
  onSubmit: e => e.preventDefault(),
};

CustomForm.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.shape({
    adornmentFormControl: PropTypes.string,
    disableClickEvent: PropTypes.string,
    formControl: PropTypes.string,
    item: PropTypes.string,
    radioLabel: PropTypes.string,
    root: PropTypes.string,
    selectedItem: PropTypes.string,
    selectItemLogo: PropTypes.string,
    selectItemTitle: PropTypes.string,
    selectStyle: PropTypes.string,
    selectMobileStyleWidth: PropTypes.string,
    checkBoxFormControl: PropTypes.string
  }),
  fields: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func,
  customOnClick: PropTypes.func,
  onSubmit: PropTypes.func,
  handleClickShowPassword: PropTypes.func,
  submitErrors: PropTypes.shape({}),
};

export default withStyles(styles)(CustomForm);
