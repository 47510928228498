import axios from 'axios';
import { ROOT_URL } from '../../config/api';
import { getDetail } from '../helpers/common';

class API {
  constructor() {
    this.token = getDetail('token') ? getDetail('token') : '';
  }

  get(url, params = {}) {
    return axios.get(`${ROOT_URL}${url}`, {
      headers: this.token ? { Authorization: `Bearer ${this.token}` } : {},
      params
    });
  }

  post(url, params = {}) {
    return axios.post(
      `${ROOT_URL}${url}`,
      params,
      this.token ? { headers: { Authorization: `Bearer ${this.token}` } } : {}
    );
  }
}

export default API;
