import {
  GET_GAMES,
  GET_GAMES_SUCCESS,
  GET_GAME_CATEGORIES,
  GET_GAME_CATEGORIES_SUCCESS,
  GET_RECENT_GAMES,
  GET_RECENT_GAMES_SUCCESS,
  PROCEED_PLAY_GAME,
  SLOTS_PLAY,
  LAUNCH_GAME, 
  SET_PLAY_SUCCESS, 
  SET_FAVOURITE_SLOTS,
  SET_FAVOURITE_SUCCESS,
  OPEN_CASINO_ERROR,
  RESET_OPEN_CASINO_ERROR,
  SET_INITIAL_SLOT,
} from '../constants/slot.games';

export const getGames = () => ({
  type: GET_GAMES,
});

export const getGamesSuccess = response => ({
  type: GET_GAMES_SUCCESS,
  response,
});

export const getGameCategories = params => ({
  type: GET_GAME_CATEGORIES,
  params, 
});

export const getGameCategoriesSuccess = response => ({
  type: GET_GAME_CATEGORIES_SUCCESS,
  response,
});

export const getRecent = () => ({
  type: GET_RECENT_GAMES,
});

export const getRecentSuccess = response => ({
  type: GET_RECENT_GAMES_SUCCESS,
  response,
});

export const playSlots = game => ({
  type: SLOTS_PLAY,
  game,
});

export const proceedPlayGame = status => ({
  type: PROCEED_PLAY_GAME,
  status,
});

export const launchGame = params => ({
  type: LAUNCH_GAME,
  params,
});

export const setPlaySuccess = response => ({
  type: SET_PLAY_SUCCESS,
  response,
});

export const setFavourite = params => ({
  type: SET_FAVOURITE_SLOTS,
  params,
});

export const setFavouriteSuccess = (
  message = '',
  status = false,
) => ({
  type: SET_FAVOURITE_SUCCESS,
  message,
  status,
});


export const openCasinoError = response => ({
  type: OPEN_CASINO_ERROR,
  response,
});

export const resetOpenCasinoError = response => ({
  type: RESET_OPEN_CASINO_ERROR,
  response,
});

export const resetSlotState = response => ({
  type: SET_INITIAL_SLOT,
  response,
});