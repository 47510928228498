import { GET_PAGE, PAGE_ERROR, PAGE_SUCCESS, SET_BRAND_GAME_SETUP } from "../constants/page";

const initialState = {
  pages: {},
  partnerPages: {},
  page: {Content:''},
  brandGameSetup: [],
  isLoading:false,
  errors:{},
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_PAGE:
      return {
          ...state,
          isLoading: true,
      }
    case PAGE_SUCCESS:
      return {
          ...state,
          page: action.page,
          isLoading:false,
      }
    case PAGE_ERROR:
        return {
            ...state,
            isLoading:false,
            errors:action.errors
        }
    case SET_BRAND_GAME_SETUP:
      return {
        ...state,
        brandGameSetup: action.payload
      };
    default:
      return state;
  }
}
