const styles = theme => ({
  disabledText: {
    '& .MuiInputBase-input': {
      // color: theme.palette.primary.dark5,
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '21px',
    },
    // '& .disabled-input label': {
    //   color: theme.palette.text.secondary,
    // },
  },
  iconContainerStyle:  {
    marginTop: `${theme.spacing(2)}px`,
    marginBottom: `${theme.spacing(1)}px`,
    background: theme.palette.primary.dark,
    borderRadius: '0px',
    paddingRight: '0px',
    //height: '49px',
    maxWidth: '10%',
    cursor: 'default',
    pointerEvents: 'none',
    borderTopLeftRadius: '3px',
    borderBottomLeftRadius: '3px',
    '& .active': {
      fill: theme.palette.text.secondary
    },
    '& .disabled': {
      fill: theme.palette.primary.dark5
    },
    ['@media (max-width:280px)']: {
      maxWidth: '15%'
    },
    ['@media (min-width:320px)']: {
      maxWidth: '12%'
    },
  },
  checkBoxFormControl: {
    paddingTop: '10px',
    '& .MuiFormControlLabel-root': {
      alignItems: 'flex-start',
      '& .MuiTypography-body1': {
        fontSize: '12px !important',
        color: '#9FA2AB',
      },
    },
    '& .MuiCheckbox-root': {
      paddingTop: 0
    },
    '& a': {
      color: theme.palette.text.default,
      textDecoration: 'none',
      fontSize: '12px'
    },
  },
  formControl: {
    '& fieldset': {
      border: 0
    },
    '& .MuiOutlinedInput-adornedEnd': {
      backgroundColor: '#28383F'
    },
    '& .MuiFormLabel-root': {
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: '21px'
    },
    width: '100%',
    '& a': {
      color: theme.palette.text.default,
      textDecoration: 'none',
    },
    //deposit tnc
    '& .caution': {
      fontSize: '11px',
      fontWeight: 400,
      '& .caution-desc':{
        color: theme.palette.text.primary,
      },
    },
    // '& .MuiFormControl-fullWidth': {
    //   width: '90%'
    // },
    // '& .MuiGrid-grid-xs-4' : {
    //   maxWidth: '28.333333% !important'
    // },
    '& .mobileContainer': {
      display: 'flex',
      alignItems: 'flex-start',
    },
    //special case if mobile as text in register lines 38-47
    '& .mobileContainerWithSelect': {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
      '& .MuiSelect-filled.MuiSelect-filled':{
        paddingTop: '0px',
        paddingRight: '0px',
        paddingBottom: '3px',
        paddingLeft: '13px',
        //fontSize: '12px'
      },
    },
    '& .mobileTextSelect': {
      width: '30%'
    },
    '& .mobileText': {
      marginTop: '0px'
    },
    '& .MuiSelect-select' : {
      borderRadius: '0px',
      padding: '16px',
      paddingLeft: '13px'
    },
    '& .MuiFilledInput-input' : {
      borderRadius: '0px'
    },
    '& .withIcon': {
      width: '100%',
      borderTopLeftRadius: '0px',
      borderBottomLeftRadius: '0px',
      '& input, select': {
        borderRadius: '0px',
        borderTopRightRadius: '3px',
        borderBottomRightRadius: '3px',
        textOverflow: 'ellipsis'
      },
      '& .MuiFilledInput-root': {
        borderTopLeftRadius: '0px',
        borderBottomLeftRadius: '0px',
      },
      '& #provider':{
        '& svg ':{
          display:'none'
        }
      },
      '& #transferFromz':{
        '& svg ':{
          display:'none'
        }
      },
      '& #transferToz':{
        '& svg ':{
          display:'none'
        }
      },
    },
    ['@media (max-width:280px)']: {
      '& .MuiFormLabel-root': {
        fontSize: '0.7rem',
        textTransform: 'capitalize'
      },
      '& .MuiSelect-iconFilled': {
        /* right: 7px; */
        right: '0px'
      },
      '& .MuiSelect-filled.MuiSelect-filled':{
        paddingRight: '16px',
        paddingLeft: '13px',
       // fontSize: '12px'
      },
      '& .MuiSelect-filled.MuiSelect-filled.Mui-disabled':{
        padding: '16px'
       // fontSize: '12px'
      },
      '& .mobileTextSelect': {
        width: '35%'
      },
    },
    ['@media (max-width:320px)']: {
      '& .MuiFormLabel-root': {
        fontSize: '0.8rem',
        textTransform: 'capitalize'
      },
      '& .MuiSelect-iconFilled': {
        /* right: 7px; */
        right: '0px'
      },
      '& .mobileTextSelect': {
        width: '35%'
      },
      '& .MuiSelect-filled.MuiSelect-filled.Mui-disabled':{
        padding: '16px',
        paddingLeft: '13px'
      },
    },
    ['@media (max-width:360px)']: {
      '& .MuiSelect-filled.MuiSelect-filled.Mui-disabled':{
        padding: '16px',
        paddingLeft: '13px'
      },
    },
    ['@media (max-width:720px)']: {
      '& .MuiSelect-filled.MuiSelect-filled.Mui-disabled':{
        padding: '16px',
        paddingLeft: '13px'
      },
    },
    ['@media (max-width:1024px)']: {
      '& .MuiSelect-filled.MuiSelect-filled.Mui-disabled':{
        padding: '16px',
        paddingLeft: '13px'
      },
    },
    '& .MuiSelect-filled.MuiSelect-filled':{
     // paddingTop: '0px',
      paddingRight: '16px',
      paddingBottom: '1px',
      paddingLeft: '13px',
      paddingTop: '1px',
     // marginTop: '8px'
    },
    '& .MuiFormHelperText-root': {
     // position: 'absolute',
      bottom: '-16px',
      marginLeft: '0px',
    },
    '& .MuiFormHelperText-root:first-letter': {
       textTransform: 'uppercase'
     },
    '& .disabled-input:before, .MuiInput-underline:hover:not(.Mui-disabled):before': {
     // borderBottom: `2px solid ${theme.palette.background.content}`,
      borderRadius: '3px'
    },
    '& .disabled-input:after': {
      borderBottom: 'none',
    },
    '& .MuiFilledInput-underline.Mui-disabled:before': {
      borderBottomStyle : 'none'
    },
    '& .MuiFilledInput-root.Mui-disabled' : {
      backgroundColor: '#292B33'
    },
    '& .disabled-input.MuiFormLabel-root.Mui-focused': {
      color: theme.palette.text.secondary,
    },
    '& .labelStyle': {
     // display: 'none',
      zIndex: 1,
      top: '-9px',
      left: '12px',
      pointerEvents: 'none',
      textTransform: 'uppercase',
      fontWeight: '500',
      fontSize: '16px',
     '& .MuiInputLabel-shrink': {
      left: '12px'
     }
    },
    '& .customSelect':{
      paddingTop: '20px'
    },
    '& .MuiSelect-filled.MuiSelect-filled':{
      marginBottom: '0px',
    },
    '& .disabled-input': {
      '& input': {
        color: theme.palette.primary.dark5,
      },
      '& svg': {
        color: theme.palette.text.secondary,
      },
      // '& .MuiInput-underline:before': {
      //   borderBottom: `2px solid ${theme.palette.background.content}`,
      // },
      // '& .MuiInput-underline:after': {
      //   borderBottom: 'none',
      // },
      // '& .MuiFormLabel-root.Mui-focused': {
      //   color: theme.palette.text.secondary,
      // },
      '& .MuiFormLabel-root.Mui-error': {
        color: theme.palette.primary.warning
      }
    },
    '& .disabled-input': {
      '& label': {
        // color: theme.palette.primary.dark5,
        color: theme.palette.text.secondary,
      },
    }
  },
  adornmentFormControl: {
    minWidth: '100%',
    '& .MuiFormHelperText-root': {
      bottom: '-16px',
    },
  },
  disabledInput: {
    color: '#939393'
  },
  divider: {
    marginLeft: '2px'
  },
  adornmentFixErrorTextFormControl: {
    minWidth: '100%',
    '& .MuiFormHelperText-root': {
      bottom: '-16px',
    }
  },
  disableClickEvent: {
    pointerEvents: 'none',
  },
  customFormControl: {
    flexDirection: 'row !important',
    justifyContent: 'center !important',
    display: 'flex !important',
    '& .MuiButton-root': {
      color: 'white',
      backgroundColor: 'red'
    },
  },
  selectedItem: {
    '&.Mui-selected': {
      borderLeft: `3px solid ${theme.palette.primary.dark}`,
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.text.primary,
      '& svg': {
        fill: theme.palette.text.primary
      }
    },
    '&.hidden': {
      display: 'none'
    },

  },
  selectStyle: {
    marginTop: theme.spacing(2),
    marginBottom: '0.5em',
    width: '100%',
    '& .MuiInputLabel-shrink': {
      transform: 'translate(0, 14.6px) scale(0.75)'
    },
  },
  selectMobileStyleWidth: {
    marginTop: theme.spacing(2),
    marginBottom: '0.5em',
    width: '99%',
    ['@media (min-width:280px)']: {
      '& .MuiFormLabel-root': {
        fontSize: '0.8rem',
        textTransform: 'capitalize'
      },
    },
    ['@media (min-width:375px)']: {
      '& .MuiFormLabel-root': {
        fontSize: '1rem',
        textTransform: 'capitalize'
      },
    }
  },
  selectItemLayout: {
    display: 'flex',
    // justifyContent: 'space-between',
    fontSize: '14px',
    color: theme.palette.text.primary,
    background: theme.palette.background.default,
    '& svg': {
      color: theme.palette.text.default,
      marginRight: '10px'
    },
    '& .lockIcon' : {
      width: '11px',
      marginLeft: 'auto',
      marginRight: 0,
      fill: '#ffffff',
      backgroundColor: '#C82626',
      borderRadius: '4px',
      padding: '0px 6px',
    }
  },
  selectItemTitle: {
    //background: 'rgba(255, 255, 255, 0.3)',
    background: theme.palette.primary.dark,
    color: theme.palette.text.primary,
    textTransform: 'uppercase',
  },
  selectItemLogo: {
    maxHeight: '30px',
    maxWidth: '55px',
    marginRight: theme.spacing(1),
  },
  itemMobileContainer: {
    marginTop: '16px',
    marginBottom: '0.5em'
  },
  item: {
    paddingRight: `${theme.spacing(1) - 3}px !important`,
    paddingTop: '1.5em !important'
  },
  itemTitle: {
    textAlign: 'center',
    marginTop: 0,
  },
  radioLabel: {
    marginRight: 0,
    marginTop: theme.spacing(3),
    alignItems: 'flex-start',
    '& .MuiIconButton-root': {
      paddingTop: 0,
    },
    '& .MuiFormControlLabel-label': {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '18px',
      color: theme.palette.text.secondary
    },
  },
  loaderContainer: {
    width: '100%',
    justifyContent: 'center',
    display: 'flex'
  },
  loading: {
    width: '3em'
  },
  root: {
    width: '100%',
    '& .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover': {
      backgroundColor: '#262626',
      '&:hover': {
        backgroundColor: '#262626',
      },
    },
    // '& label.Mui-focused':{
    //   color: theme.palette.text.default
    // },
    '& .MuiInput-underline.Mui-focused:after':{
      borderColor:  theme.palette.text.default
    },
    '& .hidden' : {
      display: 'none'
    }
  },
});

export default styles;
