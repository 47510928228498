import {
  JoaSportIcon,
  DongSenIcon,
  PowerBallIcon1,
  MainWalletIcon1
} from '../../components/custom/joabet.icons';

const cardMaskFormat = card =>
  `**********${card.substring(card.length - 4, card.length)}`;

const checkParams = values => {
  let invalidCtr = 0;
  Object.keys(values).map(item => {
    const { required, value } = values[item];

    if (!value && required || value === "00") {
      invalidCtr += 1;
    }

    return item;
  });

  return invalidCtr > 0;
};

const sanitizeAmount = amt => {
  if (amt != "null" && amt != "NaN" && amt != "undefined") {
    return String(amt).replace(/[,]/g, '');
  }
  return '0';
};

const displayAmount = (amount) =>{
  return Math.trunc(sanitizeAmount(String(amount || 0)).replace(',', ''))
    .toFixed(0)
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

const displayFormAmount = amount =>
  String(amount).split('.')[0];


const generateParams = (values, excempt = []) => {
  const params = {};

  Object.keys(values).map(item => {
    if (excempt.indexOf(item) < 0) {
      Object.assign(params, { [item]: values[item].value });
    }
    return item;
  });

  return params;
};

const getDetail = key => localStorage.getItem(key);

const getLanguage = () => getDetail('i18nextLng').slice(0, 2);

const isLogin = () => localStorage.getItem('token') !== null;

const isLogout = () => localStorage.getItem('token') === null;

const popupAlready = () => localStorage.getItem('popupCount') > 0;

const isAffiliate = pathname => pathname.indexOf('partners') > -1;

const phoneMaskFormat = phone =>
  `${phone.substring(0, 2)}*** **${phone.substring(
    phone.length - 2,
    phone.length,
  )}`;

const removeDetails = key => {
  localStorage.removeItem(key);
};

const realAmount = amt => Math.floor(Number(sanitizeAmount(amt)));

const resetFields = values => {
  const fieldValues = values;
  Object.keys(fieldValues).map(item => {
    const current = fieldValues[item].value;
    let resetVal = '';

    if (typeof current === 'boolean') {
      resetVal = false;
    } else if (typeof current === 'object') {
      resetVal = null;
    }

    fieldValues[item].value = resetVal;

    return item;
  });

  return fieldValues;
};

const scrollTop = () => window.scrollTo(0, 0);

const setDetails = (key, value) => {
  localStorage.setItem(
    key,
    typeof value === 'object' ? JSON.stringify(value) : value,
  );
};

const getProviderIcon = (providerID) => {
    let icon = MainWalletIcon1
      switch(parseInt(providerID)) {
        case 21:
          icon = JoaSportIcon;
          break;
        case 22:
          icon = DongSenIcon;
          break;
        case 23:
          icon = PowerBallIcon1
          break;
        default:
      }
      return icon;
  }

export {
  cardMaskFormat,
  checkParams,
  displayAmount,
  displayFormAmount,
  generateParams,
  getDetail,
  getLanguage,
  isAffiliate,
  isLogin,
  phoneMaskFormat,
  removeDetails,
  realAmount,
  resetFields,
  scrollTop,
  setDetails,
  popupAlready,
  getProviderIcon,
  isLogout
};
