import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
//import { useTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import { useHistory } from 'react-router-dom';
import tawkTo from "tawkto-react";
//import { isLogin } from '../../utils/helpers/common';
import styles from './styles';

import {
  SupportPageLiveChat
} from '../../components/custom/support.icons';  

const tawkToPropertyId = '5fb342e33e20f61525e40fbf';
const tawkToKey = '1f08faetr';
const hidePages = ['powerball'];

const useStyles = makeStyles((theme) => ({
  fab: {
    position: 'absolute',
    bottom: 70,
    right: theme.spacing(2),
    color: '#ffffff'
  },
  fabNoFooter: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    color: '#ffffff'
  },
}));

const TawkChatCustom = (props) => {
    const css = useStyles();
    const history = useHistory();
    //const { t } = useTranslation();
    const {classes, noFooter} = props;
    const path = history.location.pathname.split('/').pop();

    const showChatWidget = () => {
      window.Tawk_API.toggle()
    }

    useEffect(() => {
      tawkTo(tawkToPropertyId, tawkToKey);
    }, []);

    return (
        !(hidePages.includes(path)) ?
        <Fab aria-label='support' size='medium'  color='primary' onClick={showChatWidget} className={(noFooter)?css.fabNoFooter:css.fab} >
          <SupportPageLiveChat />
        </Fab>:null
    );
}

TawkChatCustom.propTypes = {
  classes: PropTypes.shape({
    //customScrollPaper: PropTypes.object,
  }).isRequired
};

export default withStyles(styles)(TawkChatCustom);
