import React,{
  useCallback,
} from 'react';
import withStyles from '@material-ui/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import styles from './styles';
import Loading from '../../../assets/images/loading.gif';

const CustomWalletInfo = props => {
  const {
    amount,
    hasLockedAmount,
    hideAmountLocked,
    classes,
    currency,
    disabled,
    handleClick,
    icon,
    isLoading,
    isComingSoon,
    name,
    sharedWallet,
    sharedWalletDetails,
    isProvider,
    isTitle,
    isCustomBalance,
    isBoldWalletName,
    MainWalletIcon
  } = props;
  const {
    amountContainer,
    amountLocked,
    amountStyle,
    balance,
    currencyStyle,
    loading,
    mainContainer,
    providerMainContainer,
    walletContainer,
    walletName,
    walletIcon,
    wallet,
    comingSoon,
    sharedWalletClass,
    sharedWalletDescription,
    titleContainer,
    bold,
    currencyCustomStyle,
    amountCustomStyle,
    mainWalletIconStyle
  } = classes;
  const { t } = useTranslation();

  const joinProducts = useCallback(() => {
    return sharedWalletDetails && sharedWalletDetails.products.map((product) => {
      return ` ${t(product)}`;
    });
  });
  if(isTitle){
   return (<div
      className={titleContainer}>
      <Grid container direction="row" className={wallet}>
        <Grid item xs={12} sm={12} className={walletContainer}>
          <span className={`${walletName} ${bold}`}>{t(name.toLowerCase())}</span>
        </Grid>
      </Grid>
    </div>);
  }
  return (
    /* eslint-disable-next-line */
    <div
      className={isProvider ? providerMainContainer : mainContainer}
      onClick={handleClick && (!hasLockedAmount || hideAmountLocked) ? () => handleClick() : null}
    >
      <Grid container direction="row" className={wallet}>
        <Grid item xs={6} sm={6} className={walletContainer}>
          { !icon ?
          <span className={`${walletName} ${isProvider ? bold : null}`}>{MainWalletIcon && <MainWalletIcon className={mainWalletIconStyle}/>} {t(name)}</span> :
          <span>
            <img  className={walletIcon} src={icon}/>
          </span>
          }
        </Grid>
        { disabled &&
          <Grid item xs={6} sm={6} className={balance}>
            <span>
              {t('not available')}
            </span>
          </Grid>
        }
        { !disabled &&
          <Grid item xs={6} sm={6} className={amountContainer}>
            {!isLoading ? (
              <Grid item xs={12} sm={12} className={balance}>
                {
                isComingSoon ?
                    (<Grid item xs={12} sm={12}>
                      <span className={comingSoon}>
                        {t('coming soon')}
                      </span>
                      </Grid>) :
                    (
                    <React.Fragment>

                    <Grid item xs={12} sm={12}>
                      <span className={isCustomBalance ? currencyCustomStyle : currencyStyle}>{currency}</span>
                      <span className={isCustomBalance ? amountCustomStyle : amountStyle}>{amount}</span>
                    </Grid>
                    </React.Fragment>
                    )
                }
                <Grid item xs={12} sm={12}>
                    <span className={amountLocked}>{hasLockedAmount && !hideAmountLocked && t('amount locked')}</span>
                </Grid>
              </Grid>
            ) : (
              <img className={loading} alt="loading" src={Loading} />
            )}
          </Grid>
        }
      </Grid>
      { sharedWallet &&
      <Grid container direction="row">
        <Grid item xs={12} sm={12} className={sharedWalletClass}>
          <span>{t('shared wallet')}</span>
        </Grid>
        <Grid item xs={12} sm={12} className={sharedWalletDescription}>
          <span>{t('balance is shared for', {
            provider: joinProducts()
          })}</span>
        </Grid>
      </Grid>
      }
    </div>
  );
};

CustomWalletInfo.defaultProps = {
  classes: {},
  handleClick: null,
  isLoading: false,
};

CustomWalletInfo.propTypes = {
  amount: PropTypes.string.isRequired,
  classes: PropTypes.shape({
    amountStyle: PropTypes.string,
    balance: PropTypes.string,
    currencyStyle: PropTypes.string,
    loading: PropTypes.string,
    mainContainer: PropTypes.string,
    walletName: PropTypes.string,
  }),
  currency: PropTypes.string.isRequired,
  handleClick: PropTypes.func,
  isLoading: PropTypes.bool,
  name: PropTypes.string.isRequired,
};

export default withStyles(styles)(CustomWalletInfo);
