import { all, call, put, takeLatest } from 'redux-saga/effects';

import {
  GET_PAGE,
  GET_BRAND_GAME_SETUP,
} from '../constants/page';
import pageSvc from '../utils/services/page';
import { setPage, setBrandGameSetup, errorPage } from '../actions/page';

function* getPage(action) {
  try {
    const request = yield call(
      pageSvc.getPage,
      action.payload.slug
    );

    yield put(setPage(request.data));
  } catch (err) {
    const { errors } = err.response.data;
    console.log(errors);
    yield put(errorPage(errors))
   // yield put(setErrors(SET_PAGE_ERRORS, errors));
  } finally {
   // yield put(setLoading(false, SET_PAGE_LOADING));
  }
}

function* getBrandGameSetup(action) {
  try {
    const request = yield call(
      pageSvc.getBrandGameSetup,
      action.payload
    );

    yield put(setBrandGameSetup(request.data.data));
  } catch (err) {
    // const { errors } = err.response.data;
    // console.log(errors);
   
  } finally {
  
  }
}

export default function* partnersWatcher() {
  yield all([
    takeLatest(GET_PAGE, getPage),
    takeLatest(GET_BRAND_GAME_SETUP, getBrandGameSetup),
  ]);
}
