import React from 'react';
import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import Skeleton from '@material-ui/lab/Skeleton';
import { useTranslation } from 'react-i18next';
import { withRouter, NavLink } from 'react-router-dom';

// Material components
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import AppBar from '@material-ui/core/AppBar';
import Drawer from '@material-ui/core/Drawer';
import Toolbar from '@material-ui/core/Toolbar';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
// Portal components
import logoImage from '../../../assets/images/navigation/top/logo.png';
import { isLogin } from '../../../utils/helpers/common';
// Actions
import { toggleSidenav } from '../../../actions/layout';

// Styles
import scss from './sidenav.module.scss';
import MenuVipInfo from '../../../components/custom/menu-vip-info';

const Sidenav = props => {
  const { theme, layout } = props;
  const { t } = useTranslation();
  const playerState = useSelector(state => state.player);
  const { playerInfo } = playerState;
  const { badge, username, vip } = playerInfo || {};
  const sidenavTheme = createMuiTheme(theme.sidenavTheme);
  const dockedClass = layout.sidenavOpen
    ? scss[`portal-sidenav-${layout.currentLayout}`]
    : '';

  if(!isLogin()){
    return (<></>);
  }

  return (
    <MuiThemeProvider theme={sidenavTheme}>
      <Drawer
        variant={layout.sidenavVariant}
        open={layout.sidenavOpen}
        onClose={props.toggleSidenav}
        classes={{
          docked: dockedClass,
          paper: scss[`portal-sidenav-${layout.currentLayout}`],
        }}
        anchor="right"
        >
        {/* top toolbar with logo */}
        <AppBar
          color="default"
          position="static"
          className={isLogin() ? scss['side-appbar-login'] : scss['side-appbar']}
          align="center"
        >
          <Toolbar className={isLogin() ? scss['side-appbar-details-login'] : scss['side-appbar-details']}>
            {!isLogin() && (
              <NavLink
                onClick={() => props.toggleSidenav()}
                className={scss['portal-toolbar-brand-link']}
                to="/"
              >
                <img
                  src={logoImage}
                  className={scss['portal-toolbar-brand']}
                  alt="logo"
                />
              </NavLink>
            )}
            <MenuVipInfo playerInfo={playerInfo}/>
            {/* {isLogin() && !!playerInfo && (
              <div className={scss['details-content']}>
                <img
                  className={scss['level-badge']}
                  src={badge}
                  alt="level"
                />
                <div>
                  <p className={scss.name}>{username}</p>
                  <p className={scss.level}>
                    {t('vip level')}
                    <span className={scss['level-indicator']}>
                      {t(vip.toLowerCase())}
                    </span>
                  </p>
                </div>
              </div>
            )}
            {isLogin() && !playerInfo && (
              <div className={scss['details-content']}>
                <Skeleton
                  className={scss['level-badge']}
                  variant="circle"
                  height={54}
                  width={53}
                />
                <div style={{ width: '70%' }}>
                  <Skeleton
                    style={{ margin: 0 }}
                    variant="text"
                    height={14}
                    width="60%"
                  />
                  <Skeleton
                    style={{ marginBottom: 0, marginTop: '8px' }}
                    variant="text"
                    height={12}
                    width="50%"
                  />
                </div>
              </div>
            )} */}
          </Toolbar>
        </AppBar>
        {/* main menu */}
        {props.children}
        {/* sidenav footer */}
        {/* <AppBar
          color="default"
          position="static"
          className={scss['side-appbar']}
        >
          <Toolbar disableGutters>
            <span className="portal-flex" />
            <IconButton onClick={props.toggleSidenav}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
        </AppBar> */}
      </Drawer>
    </MuiThemeProvider>
  );
};

function mapStateToProps(state) {
  return {
    theme: state.theme,
    layout: {
      currentLayout: state.layout.currentLayout,
      sidenavOpen: state.layout.sidenavOpen,
      sidenavVariant: state.layout.sidenavVariant,
    },
  };
}

Sidenav.propTypes = {
  toggleSidenav: PropTypes.func.isRequired,
  layout: PropTypes.shape({
    currentLayout: PropTypes.string,
    sidenavOpen: PropTypes.bool,
    sidenavVariant: PropTypes.string,
  }).isRequired,
  theme: PropTypes.shape({
    sidenavTheme: PropTypes.object,
  }).isRequired,
  children: PropTypes.shape({}).isRequired,
};

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    {
      toggleSidenav,
    },
  ),
)(Sidenav);
