import { SET_PROMOTIONS, GET_PROMOTIONS_CATEGORY, SET_PROMOTIONS_CATEGORY, SET_ACTIVE_PROMOTION } from '../constants/promotions';

const initialState = {
  list: [],
  promotionCategory: [],
  loadingCategory: false,
  activePromotion: {},
};

const promotionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PROMOTIONS:
      return {
        ...state,
        list: action.response,
      };
    case GET_PROMOTIONS_CATEGORY:
      return {
          ...state,
          loadingCategory: true
      }
    case SET_PROMOTIONS_CATEGORY:
      return {
          ...state,
          promotionCategory: action.payload,
          loadingCategory: false
      }
    case SET_ACTIVE_PROMOTION:
      return {
          ...state,
          activePromotion: action.payload,
      }
    default:
      return state;
  }
};

export default promotionsReducer;
