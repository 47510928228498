import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import ListItem from '@material-ui/core/ListItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import Button from '@material-ui/core/Button';
import { NavLink } from 'react-router-dom/';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';
import { useDispatch, useSelector } from 'react-redux';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { useTranslation } from 'react-i18next';
import Badge from '@material-ui/core/Badge';

import styles from './menu-sidenav-item.style';
import { toggleSidenav } from '../../../actions/layout';
import { logoutUser } from '../../../actions/authentication';
import { isLogin } from '../../../utils/helpers/common';
import { openSports, resetPlayState } from '../../../actions/sports';
import useComingSoon from '../../../hooks/use-coming-soon';
import {SlotsIcon} from '../../../components/custom/joabet.icons';

import EN from '../../../assets/images/en.png';
import KR from '../../../assets/images/kr.png';

const MenuSidenavItem = (props) => {
  const languageImages = {
    en: EN,
    ko: KR
  };
  const [state, setState] = useState({ open: false });
  const {
    children,
    classes,
    href,
    newTab,
    title,
    Icon,
    type,
    value,
    badge,
    badgeCount,
    image,
    menuType
  } = props
  const sportsState = useSelector(state => state.sports);
  const [openGameUrl, setOpenGameUrl] = useState(false);
  const [gameUrl, setGameUrl] = useState(null);

  const handleClick = () => {
    setState({ ...state, open: !state.open });
  };

  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { NoticeModal, openNotice } = useComingSoon();

  const iconStyle = {
    fontSize: 30
  };

  //const [showDialog, toggleDialog] = useState(false);

  const openGame = useCallback((hasConfirmation, providerID, gameID, productID, otherParams) => {
    if (!isLogin() && hasConfirmation) {
      //toggleDialog(!showDialog);
    } else {
      dispatch(openSports(isLogin(), { providerID: providerID, gameID: gameID, productID: productID, ...otherParams }));
      setOpenGameUrl(true);
    }
  });

  const menuClicked = useCallback(() => {
    const currTitle = title.toLowerCase();
    const { hostname, port, protocol } = window.location;

    if (!!newTab) {
      switch(newTab)
      {
        case 'ow-sports':
          openGame(false, 19, 0, 1);
          break;
        case 'sports-betting':
          openGame(false, 21, 0, 1, { integrationID: 21 });
          break;
        case 'sports-betting-bt1':
            openGame(false, 21, 0, 1, { integrationID: 21, isBT1: 1 });
        break;
        case 'live-betting':
          openGame(false, 21, 0, 1, { IsLive: 1 });
          break;
        case 'e-sports':
          openGame(true, 22, 0, 9);
          break;
        case 'powerball':
          openNotice();
          break;
        default:
          dispatch(toggleSidenav());
          window.open(`${protocol}//${hostname}:${port}/${newTab}`, '_blank');
          break;
      }

    } else if (currTitle === 'logout') {
      dispatch(logoutUser());
    }
    else{
      dispatch(toggleSidenav());
    }

    if (type === 'language') {
      i18n.changeLanguage(value);
      window.location.reload();
    }
  });

  const handleResetPlayState = useCallback(() => {
    dispatch(resetPlayState());
    setOpenGameUrl(false);
  });

  useEffect(() => {
    if(typeof(sportsState.url) !== 'undefined' && sportsState.url !== null && sportsState.url !== '')setGameUrl(sportsState.url);
  }, [sportsState]);

  useEffect(() => {
    if(gameUrl && gameUrl !== null && openGameUrl)
    {
       /* window.open(
         gameUrl,
         '_blank',
       ); */
       window.location.href  = gameUrl;
       dispatch(toggleSidenav());
       handleResetPlayState();
    }
  }, [gameUrl]);

  useEffect(() => {
    if(state.open){
      setTimeout(()=> {
        props.scrollToBot();
      }, 500)
    }
  }, [state.open])

  const listItemAttrs = {};
  if (href && !children) {
    listItemAttrs.to = href;
    listItemAttrs.component = NavLink;
    listItemAttrs.exact = true;
    listItemAttrs.activeClassName =
      href !== '#' ? classes.listItemActive : '';
  }

  if (type && type === 'header') {
    return (
      <ListSubheader disableSticky className={classes.root}>
        {title}
      </ListSubheader>
    );
  }

  if (type && type === 'notice') {
    return (
      <>
      <ListItem
        onClick={() => openNotice()}
        {...listItemAttrs}
        className={classes.root}
        disableGutters
      >
        <Button
          onClick={handleClick}
          classes={{
            root: classes.listItem,
            label: classes.listItemButtonLabel,
          }}
        >
          {Icon && (
            <span className={classes.listIcon}>
              <Icon className={classes.vcenter} />
            </span>
          )}
          <Typography
            variant="button"
            color="inherit"
            className={classes.listItemText}
          >
            {t(title.toLowerCase())}
          </Typography>
        </Button>
      </ListItem>
      {NoticeModal}
      </>
    );
  }

  return (
    <ListItem
      onClick={href ? () => menuClicked() : null}
      {...listItemAttrs}
      className={classes.root}
      disableGutters
    >
      <Button
        onClick={handleClick}
        classes={{
          root: classes.listItem,
          label: classes.listItemButtonLabel,
        }}
        style={menuType === 'topMenuIcon' ? {} : {borderBottomWidth:0}}
      >
        {Icon && <span className={classes.listIcon}><Icon className={classes.vcenter}/></span>}
        {value !== '' ? <img src={languageImages[value.split('-')[0]]} className={classes.languageImg} /> : null}
        <Typography
          variant="button"
          color="inherit"
          className={classes.listItemText}
        >
          {t(title.toLowerCase())}
        </Typography>
        {
          badge ?
          <Badge
            className={classes.badge}
            invisible={badgeCount > 0 ? false : true} 
            badgeContent={badgeCount}
            max={9}
          />
          : ""
        }
        {/* {!href &&
          (state.open ? (
            <KeyboardArrowDownIcon style={iconStyle} />
          ) : (
            <KeyboardArrowRightIcon style={iconStyle} />
          ))} */}
          {menuType !== 'topMenuIcon' ? !href && state.open ? <KeyboardArrowDownIcon style={iconStyle} /> : value === '' ? <KeyboardArrowRightIcon style={iconStyle} /> : null : null}
      </Button>
      {children && (
        <Collapse
          in={state.open}
          timeout="auto"
          unmountOnExit
          className={classes.nested}
        >
          {children}
        </Collapse>
      )}
    </ListItem>
  );
};

/* MenuSidenavItem.defaultProps = {
  children: null,
  href: null,
  icon: null,
  newTab: null,
  type: null,
  value: null,
  isAuth: false,
}; */

MenuSidenavItem.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    listIcon: PropTypes.string,
    listItem: PropTypes.string,
    listItemActive: PropTypes.string,
    listItemText: PropTypes.string,
    listItemButtonLabel: PropTypes.string,
    nested: PropTypes.string,
    badge: PropTypes.string,
  }).isRequired,
  children: PropTypes.shape({}),
  href: PropTypes.string,
  newTab: PropTypes.string,
  title: PropTypes.string.isRequired,
  icon: PropTypes.shape({}),
  type: PropTypes.string,
  value: PropTypes.string,
  menuType: PropTypes.string
};

export default withStyles(styles)(MenuSidenavItem);
