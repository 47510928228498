import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const SupportPageLiveChat = props => (
  <SvgIcon {...props} viewBox="0 0 22 21">
    <path d="M13.4652 15.9689C13.3357 16.131 13.1629 16.3003 12.947 16.484C12.7311 16.6677 12.4684 16.8802 12.1553 17.1216C11.8026 17.3954 11.5075 17.6403 11.27 17.8636C11.0289 18.087 10.8453 18.2887 10.7194 18.4724C10.4458 18.8687 10.3055 19.3478 10.3055 19.9097H15.1747V18.7462H12.0905C12.1733 18.6561 12.2992 18.5337 12.472 18.386C12.6411 18.2383 12.8463 18.0654 13.0838 17.8745C13.4832 17.5539 13.8215 17.2657 14.0986 17.0099C14.3757 16.7542 14.5809 16.5344 14.7176 16.3507C14.8652 16.1526 14.9731 15.94 15.0487 15.7203C15.1207 15.497 15.1603 15.2592 15.1603 14.9963C15.1603 14.7117 15.1099 14.4487 15.0055 14.2038C14.9048 13.9624 14.7536 13.7499 14.5593 13.5698C14.3649 13.3897 14.1238 13.2492 13.8359 13.1483C13.548 13.0474 13.2205 12.9934 12.8534 12.9934C12.5116 12.9934 12.1985 13.0474 11.9178 13.1555C11.6335 13.2636 11.3887 13.4149 11.1836 13.6094C10.9749 13.8039 10.8093 14.0417 10.6834 14.319C10.5574 14.5964 10.4782 14.9062 10.4494 15.2484H11.7882C11.8026 15.0503 11.8386 14.8738 11.8998 14.7225C11.9609 14.5712 12.0401 14.4451 12.1337 14.3442C12.2273 14.2434 12.3316 14.1677 12.454 14.1173C12.5763 14.0669 12.7023 14.0417 12.8391 14.0417C13.1593 14.0417 13.4041 14.1245 13.5768 14.2866C13.7496 14.4487 13.8359 14.6793 13.8359 14.971C13.8359 15.1584 13.8071 15.3277 13.7532 15.4862C13.6884 15.6483 13.5984 15.8068 13.4652 15.9689Z" fill="#33C16C" />
    <path d="M20.0223 13.0186H19.0002L15.8585 17.3954V18.3824H18.7159V19.9097H20.0187V18.3824H20.9184V17.2549H20.0187V13.0186H20.0223ZM18.7195 17.2549H17.2908L18.7195 15.2124V17.2549Z" fill="#33C16C" />
    <path d="M24.4812 12.9466H23.4987L21.397 19.9097H22.3723L24.4812 12.9466Z" fill="#33C16C" />
    <path d="M27.3602 19.9097L29.8686 13.7787V12.9466H25.093V14.1353H28.4219L25.9279 19.9097H27.3602Z" fill="#33C16C" />
    <path d="M34.1188 10.8969C33.4494 8.90846 32.4238 7.09653 31.0742 5.51154C29.7247 3.92655 28.1232 2.64776 26.3058 1.70757C24.4236 0.734963 22.3975 0.173012 20.285 0.0325246C18.1689 -0.104361 16.0924 0.191023 14.1094 0.915075C12.1949 1.61391 10.4494 2.67657 8.91995 4.07424C7.8727 5.03604 6.9622 6.12032 6.19925 7.31627C6.82184 7.33788 7.44444 7.66929 8.10302 8.34651C10.7158 4.39124 15.2107 1.9129 20.1518 2.2335C27.6194 2.7198 33.3163 9.41638 32.8484 17.1648C32.4526 23.6993 27.7993 28.9261 21.8721 30.1113C21.9476 30.558 21.9224 30.9578 21.7965 31.318C21.6453 31.7539 21.415 32.1321 21.1379 32.4635C22.2823 32.3267 23.4052 32.0565 24.4992 31.6566C26.4138 30.9578 28.1592 29.8951 29.6887 28.4975C31.2182 27.0998 32.4526 25.4356 33.3559 23.548C34.2952 21.5956 34.835 19.4955 34.9681 17.3017C35.1013 15.1079 34.817 12.9538 34.1188 10.8969Z" fill="#33C16C" />
    <path d="M16.9273 25.3563C16.2615 24.8052 15.3726 25.1222 14.6457 25.522C13.746 26.0191 13.9115 26.6675 13.3357 27.2619C12.3604 28.2633 10.2515 27.8275 9.34101 27.0134C9.03511 26.7396 8.53488 26.1704 7.95547 25.468C6.73547 23.4976 5.21678 20.9652 4.86769 20.0502C4.43583 18.9083 4.77772 16.7794 6.0625 16.2246C6.82184 15.8968 7.36886 16.2823 8.1534 15.6194C8.78679 15.0827 9.39859 14.3659 9.12148 13.5446C9.00992 13.2167 6.71028 8.46899 5.10161 8.43657C3.78445 8.41135 2.48168 9.6181 1.74392 10.6303C0.415964 12.4675 -0.253415 14.3875 0.088472 16.8946C1.09974 24.3081 7.11695 30.2842 7.20332 30.1977C8.39093 31.2964 9.62892 32.0961 10.579 32.6112C12.8031 33.8144 14.8256 33.8864 17.0137 33.2921C18.2265 32.9643 19.8171 32.179 20.2598 30.9362C20.8032 29.4233 17.1936 25.5761 16.9273 25.3563Z" fill="#33C16C" />
  </SvgIcon>
)

const SupportPageLiveChatWhite = props => (
  <SvgIcon {...props} viewBox="0 0 34 33">
    <path d="M13.4652 15.9689C13.3357 16.131 13.1629 16.3003 12.947 16.484C12.7311 16.6677 12.4684 16.8802 12.1553 17.1216C11.8026 17.3954 11.5075 17.6403 11.27 17.8636C11.0289 18.087 10.8453 18.2887 10.7194 18.4724C10.4458 18.8687 10.3055 19.3478 10.3055 19.9097H15.1747V18.7462H12.0905C12.1733 18.6561 12.2992 18.5337 12.472 18.386C12.6411 18.2383 12.8463 18.0654 13.0838 17.8745C13.4832 17.5539 13.8215 17.2657 14.0986 17.0099C14.3757 16.7542 14.5809 16.5344 14.7176 16.3507C14.8652 16.1526 14.9731 15.94 15.0487 15.7203C15.1207 15.497 15.1603 15.2592 15.1603 14.9963C15.1603 14.7117 15.1099 14.4487 15.0055 14.2038C14.9048 13.9624 14.7536 13.7499 14.5593 13.5698C14.3649 13.3897 14.1238 13.2492 13.8359 13.1483C13.548 13.0474 13.2205 12.9934 12.8534 12.9934C12.5116 12.9934 12.1985 13.0474 11.9178 13.1555C11.6335 13.2636 11.3887 13.4149 11.1836 13.6094C10.9749 13.8039 10.8093 14.0417 10.6834 14.319C10.5574 14.5964 10.4782 14.9062 10.4494 15.2484H11.7882C11.8026 15.0503 11.8386 14.8738 11.8998 14.7225C11.9609 14.5712 12.0401 14.4451 12.1337 14.3442C12.2273 14.2434 12.3316 14.1677 12.454 14.1173C12.5763 14.0669 12.7023 14.0417 12.8391 14.0417C13.1593 14.0417 13.4041 14.1245 13.5768 14.2866C13.7496 14.4487 13.8359 14.6793 13.8359 14.971C13.8359 15.1584 13.8071 15.3277 13.7532 15.4862C13.6884 15.6483 13.5984 15.8068 13.4652 15.9689Z" fill="white" />
    <path d="M20.0223 13.0186H19.0002L15.8585 17.3954V18.3824H18.7159V19.9097H20.0187V18.3824H20.9184V17.2549H20.0187V13.0186H20.0223ZM18.7195 17.2549H17.2908L18.7195 15.2124V17.2549Z" fill="white" />
    <path d="M24.4812 12.9466H23.4987L21.397 19.9097H22.3723L24.4812 12.9466Z" fill="white" />
    <path d="M27.3602 19.9097L29.8686 13.7787V12.9466H25.093V14.1353H28.4219L25.9279 19.9097H27.3602Z" fill="white" />
    <path d="M34.1188 10.8969C33.4494 8.90846 32.4238 7.09653 31.0742 5.51154C29.7247 3.92655 28.1232 2.64776 26.3058 1.70757C24.4236 0.734963 22.3975 0.173012 20.285 0.0325246C18.1689 -0.104361 16.0924 0.191023 14.1094 0.915075C12.1949 1.61391 10.4494 2.67657 8.91995 4.07424C7.8727 5.03604 6.9622 6.12032 6.19925 7.31627C6.82184 7.33788 7.44444 7.66929 8.10302 8.34651C10.7158 4.39124 15.2107 1.9129 20.1518 2.2335C27.6194 2.7198 33.3163 9.41638 32.8484 17.1648C32.4526 23.6993 27.7993 28.9261 21.8721 30.1113C21.9476 30.558 21.9224 30.9578 21.7965 31.318C21.6453 31.7539 21.415 32.1321 21.1379 32.4635C22.2823 32.3267 23.4052 32.0565 24.4992 31.6566C26.4138 30.9578 28.1592 29.8951 29.6887 28.4975C31.2182 27.0998 32.4526 25.4356 33.3559 23.548C34.2952 21.5956 34.835 19.4955 34.9681 17.3017C35.1013 15.1079 34.817 12.9538 34.1188 10.8969Z" fill="white" />
    <path d="M16.9273 25.3563C16.2615 24.8052 15.3726 25.1222 14.6457 25.522C13.746 26.0191 13.9115 26.6675 13.3357 27.2619C12.3604 28.2633 10.2515 27.8275 9.34101 27.0134C9.03511 26.7396 8.53488 26.1704 7.95547 25.468C6.73547 23.4976 5.21678 20.9652 4.86769 20.0502C4.43583 18.9083 4.77772 16.7794 6.0625 16.2246C6.82184 15.8968 7.36886 16.2823 8.1534 15.6194C8.78679 15.0827 9.39859 14.3659 9.12148 13.5446C9.00992 13.2167 6.71028 8.46899 5.10161 8.43657C3.78445 8.41135 2.48168 9.6181 1.74392 10.6303C0.415964 12.4675 -0.253415 14.3875 0.088472 16.8946C1.09974 24.3081 7.11695 30.2842 7.20332 30.1977C8.39093 31.2964 9.62892 32.0961 10.579 32.6112C12.8031 33.8144 14.8256 33.8864 17.0137 33.2921C18.2265 32.9643 19.8171 32.179 20.2598 30.9362C20.8032 29.4233 17.1936 25.5761 16.9273 25.3563Z" fill="white" />
  </SvgIcon>
)

const SupportPageHotline = props => (
  <SvgIcon viewBox="0 0 25 20" {...props}>
    {/* eslint-disable-next-line */}
    <path d="M22.9289 4.44263C22.0162 3.18529 20.4096 2.04903 18.4164 1.2434C16.4326 0.44709 14.1322 0.00469358 11.9295 3.67772e-05C9.73151 -0.00462003 7.42639 0.43312 5.44259 1.22478C3.44482 2.02575 1.83822 3.15735 0.920828 4.41003C0.0360348 5.61614 -0.215433 6.92005 0.185053 8.17273C0.445834 8.98767 1.20489 9.53252 2.07572 9.53252L5.05607 9.53718C5.05607 9.53718 5.05607 9.53718 5.06073 9.53718C5.6568 9.53718 6.21096 9.27639 6.59282 8.82468C6.97002 8.37763 7.12369 7.79087 7.02124 7.21809C7.01659 7.19946 7.01659 7.18549 7.01193 7.16686L6.8536 6.55682C7.71976 6.14236 9.68959 5.34139 11.9342 5.35536C14.1788 5.36468 16.1439 6.16565 17.0101 6.5801L16.8518 7.18549C16.8471 7.20412 16.8424 7.21809 16.8424 7.23671C16.7353 7.8095 16.889 8.39626 17.2662 8.84797C17.6434 9.30433 18.2022 9.56512 18.7983 9.56512L21.7787 9.56977C21.7787 9.56977 21.7787 9.56977 21.7833 9.56977C22.6495 9.56977 23.4085 9.02493 23.674 8.21464C24.0605 6.9573 23.809 5.6534 22.9289 4.44263Z" />
    <path d="M18.6539 12.606L16.4326 10.273C16.2976 10.1332 16.1113 10.0541 15.9157 10.0541H14.4814V9.08547C14.4814 8.69429 14.1648 8.37297 13.769 8.37297C13.3778 8.37297 13.0565 8.68964 13.0565 9.08547V10.0541H10.7793V9.08547C10.7793 8.69429 10.4626 8.37297 10.0668 8.37297C9.67097 8.37297 9.3543 8.68964 9.3543 9.08547V10.0541H7.92001C7.72442 10.0541 7.53815 10.1332 7.4031 10.273L5.18181 12.606C3.44016 14.4361 2.48086 16.8297 2.48086 19.3584V22.2875C2.48086 22.6787 2.79752 23 3.19335 23H20.6377C21.0289 23 21.3502 22.6833 21.3502 22.2875V19.3584C21.3549 16.8344 20.3956 14.4361 18.6539 12.606ZM11.9202 20.3968C9.86655 20.3968 8.19476 18.7251 8.19476 16.6714C8.19476 14.6178 9.86655 12.946 11.9202 12.946C13.9739 12.946 15.6456 14.6178 15.6456 16.6714C15.6456 18.7251 13.9739 20.3968 11.9202 20.3968Z" />
    <path d="M11.9202 14.3709C10.6536 14.3709 9.61974 15.4048 9.61974 16.6714C9.61974 17.9381 10.6536 18.9719 11.9202 18.9719C13.1869 18.9719 14.2207 17.9381 14.2207 16.6714C14.2207 15.4048 13.1869 14.3709 11.9202 14.3709Z" />
   </SvgIcon>
);

const SupportPageLeavingMessage = props => (
  <SvgIcon viewBox="0 0 25 20" {...props}>
    {/* eslint-disable-next-line */}
    <path d="M11.5 0C5.175 0 0 5.175 0 11.5C0 17.825 5.175 23 11.5 23C17.825 23 23 17.825 23 11.5C23 5.175 17.825 0 11.5 0ZM12.65 19.55H10.35V17.25H12.65V19.55ZM15.065 10.695L14.03 11.73C13.11 12.535 12.65 13.225 12.65 14.95H10.35V14.375C10.35 13.11 10.81 11.96 11.73 11.155L13.11 9.66C13.57 9.315 13.8 8.74 13.8 8.05C13.8 6.785 12.765 5.75 11.5 5.75C10.235 5.75 9.2 6.785 9.2 8.05H6.9C6.9 5.52 8.97 3.45 11.5 3.45C14.03 3.45 16.1 5.52 16.1 8.05C16.1 9.085 15.64 10.005 15.065 10.695Z" />
 </SvgIcon>
);

const SupportPageTelegram = props => (
  <SvgIcon viewBox="0 0 25 21" {...props}>
    {/* eslint-disable-next-line */}
    <path d="M0.615232 14.4436L8.68045 17.4131L11.7988 27.3361C11.9992 27.9671 12.7882 28.2022 13.3017 27.7815L17.7977 24.1563C18.2736 23.7727 18.9373 23.7603 19.4382 24.1068L27.541 29.922C28.1046 30.3179 28.8936 30.021 29.0313 29.3528L34.9801 1.13045C35.1303 0.400455 34.404 -0.205812 33.7027 0.0663894L0.615232 12.6867C-0.211327 12.996 -0.198804 14.1343 0.615232 14.4436ZM11.2979 15.8294L27.0651 6.24042C27.3532 6.0672 27.6412 6.45076 27.3908 6.67347L14.3787 18.6256C13.9153 19.0463 13.6273 19.6031 13.5396 20.2217L13.1013 23.4634C13.0387 23.8964 12.425 23.9335 12.2998 23.5252L10.5966 17.611C10.4212 16.9182 10.6968 16.2005 11.2979 15.8294Z" fill="#33C16C"/>
   </SvgIcon>
);

const SupportPageLine = props => (
  <SvgIcon viewBox="0 0 25 20" {...props}>
    {/* eslint-disable-next-line */}
    <path d="M11.9985 0C5.38527 0 0 4.39633 0 9.80035C0 14.6419 4.26979 18.6961 10.0347 19.4695C10.4237 19.5538 10.958 19.7273 11.0939 20.0647C11.2158 20.3694 11.1736 20.8334 11.1314 21.1521L10.9674 22.1785C10.9205 22.4832 10.7284 23.3737 12.0173 22.8253C13.3062 22.2816 18.9305 18.7196 21.452 15.809C23.1768 13.9108 24.0017 11.961 24.0017 9.80504C23.997 4.39633 18.6118 0 11.9985 0ZM7.30222 13.0156H4.91658C4.56975 13.0156 4.28853 12.7297 4.28853 12.3828V7.58344C4.28853 7.23661 4.57444 6.95071 4.91658 6.95071C5.26341 6.95071 5.54463 7.23661 5.54463 7.58344V11.7501H7.30222C7.64906 11.7501 7.93027 12.036 7.93027 12.3828C7.93027 12.7297 7.64906 13.0156 7.30222 13.0156ZM9.76754 12.3828C9.76754 12.7297 9.48633 13.0156 9.13481 13.0156C8.78798 13.0156 8.50676 12.7297 8.50676 12.3828V7.58344C8.50676 7.23661 8.78798 6.95071 9.13481 6.95071C9.48164 6.95071 9.76286 7.23661 9.76286 7.58344L9.76754 12.3828ZM15.509 12.3828C15.509 12.6547 15.3356 12.8937 15.0778 12.9828C15.0122 13.0015 14.9466 13.0156 14.881 13.0156C14.6701 13.0156 14.492 12.9265 14.3701 12.7625L11.9282 9.4254V12.3828C11.9282 12.7297 11.647 13.0156 11.2955 13.0156C10.9486 13.0156 10.6674 12.7297 10.6674 12.3828V7.58344C10.6674 7.3116 10.8408 7.07257 11.0986 6.98351C11.1596 6.96008 11.2346 6.95071 11.2908 6.95071C11.4876 6.95071 11.6657 7.05382 11.7876 7.20849L14.2482 10.5596V7.58344C14.2482 7.23661 14.5295 6.95071 14.8763 6.95071C15.2231 6.95071 15.5043 7.23661 15.5043 7.58344V12.3828H15.509ZM19.3617 9.34572C19.7085 9.34572 19.9897 9.63162 19.9897 9.97846C19.9897 10.3253 19.7085 10.6112 19.3617 10.6112H17.6088V11.7407H19.3617C19.7085 11.7407 19.9897 12.0266 19.9897 12.3735C19.9897 12.7203 19.7085 13.0062 19.3617 13.0062H16.976C16.6292 13.0062 16.348 12.7203 16.348 12.3735V7.58344C16.348 7.23661 16.6292 6.95071 16.976 6.95071H19.3617C19.7085 6.95071 19.9897 7.23661 19.9897 7.58344C19.9897 7.93496 19.7085 8.21617 19.3617 8.21617H17.6088V9.34572H19.3617Z" />
   </SvgIcon>
);

const SupportPageSkype = props => (
  <SvgIcon viewBox="0 0 25 20" {...props}>
    {/* eslint-disable-next-line */}
    <path d="M22.3166 13.929C23.977 6.45273 17.4297 -0.341428 9.738 0.964451C5.56008 -1.62936 0 1.30102 0 6.26874C0 7.42204 0.318616 8.50803 0.875073 9.43696C-0.673133 16.9312 5.93255 23.6266 13.6197 22.231C19.0497 25.1075 25.054 19.3769 22.3166 13.929ZM15.0019 18.439C12.9825 19.2692 9.66171 19.2737 7.65128 18.2236C4.78373 16.6978 4.27664 13.2559 6.74928 13.2559C8.61162 13.2559 8.02375 15.4278 9.90404 16.3119C10.7656 16.7113 12.6235 16.7517 13.705 16.0247C14.7685 15.3112 14.6743 14.1893 14.0954 13.6553C12.5607 12.2372 8.16735 12.8026 6.16141 10.321C5.29083 9.24399 5.12479 7.34576 6.19731 5.96808C8.06414 3.56275 13.5479 3.41466 15.9084 5.23212C18.0849 6.91495 17.497 9.15424 15.7423 9.15424C13.6287 9.15424 14.7416 6.38991 11.3311 6.38991C8.85844 6.38991 7.88464 8.14903 9.62581 9.00615C11.9818 10.1729 17.9951 9.7825 17.9951 14.3643C17.9906 16.2715 16.8238 17.6941 15.0019 18.439Z" />
   </SvgIcon>
);

const SupportPageHeadset = props => (
  <SvgIcon viewBox="0 0 23 25" {...props}>
    {/* eslint-disable-next-line */}
    <path d="M28 0C12.8365 0 0.5 12.3365 0.5 27.5V38.8932C0.5 41.7092 2.96675 44 6 44H8.75C9.47935 44 10.1788 43.7103 10.6945 43.1945C11.2103 42.6788 11.5 41.9793 11.5 41.25V27.1068C11.5 26.3774 11.2103 25.6779 10.6945 25.1622C10.1788 24.6465 9.47935 24.3568 8.75 24.3568H6.253C7.782 13.7143 16.9395 5.5 28 5.5C39.0605 5.5 48.218 13.7143 49.747 24.3568H47.25C46.5207 24.3568 45.8212 24.6465 45.3055 25.1622C44.7897 25.6779 44.5 26.3774 44.5 27.1068V44C44.5 47.0332 42.0332 49.5 39 49.5H33.5V46.75H22.5V55H39C45.0665 55 50 50.0665 50 44C53.0332 44 55.5 41.7092 55.5 38.8932V27.5C55.5 12.3365 43.1635 0 28 0Z" fill="#33C16C"/>
   </SvgIcon>
);

const SupportPageContactUs = props => (
  <SvgIcon viewBox="0 0 38 39" {...props}>
    {/* eslint-disable-next-line */}
    <path d="M34.7731 22.7565C34.4688 21.9995 33.6004 21.6284 32.8433 21.9401C32.0863 22.2444 31.7152 23.1128 32.0269 23.8699L33.7785 28.197L28.5756 25.9036C28.197 25.7403 27.774 25.7328 27.3954 25.8961C25.6215 26.6532 23.7214 27.0392 21.7471 27.0392C13.7979 27.0392 8.46133 20.8193 8.46133 15.0077C8.46133 8.36484 14.4214 2.96889 21.7471 2.96889C29.0728 2.96889 35.0329 8.36484 35.0329 15.0003C35.0329 15.5199 34.9884 16.1359 34.8993 16.7C34.7805 17.509 35.3372 18.2661 36.1462 18.3848C36.9552 18.5036 37.7123 17.9469 37.8311 17.1379C37.935 16.4254 37.9944 15.6683 37.9944 15.0003C37.9944 10.97 36.2872 7.18471 33.1848 4.34942C30.1268 1.54382 26.0668 0 21.7471 0C17.4274 0 13.3674 1.54382 10.302 4.34942C7.53356 6.88782 5.87098 10.1759 5.55182 13.7237C2.06338 16.032 0 19.7357 0 23.714C0 26.2004 0.786756 28.5607 2.27862 30.5944L0.111333 35.9607C-0.118756 36.5173 0.0222666 37.1631 0.452756 37.5861C0.7348 37.8608 1.10591 38.0018 1.48444 38.0018C1.68484 38.0018 1.89267 37.9572 2.08564 37.8756L8.33516 35.122C9.90867 35.7083 11.5712 36.0052 13.2858 36.0052C13.3229 36.0052 13.36 36.0052 13.3971 35.9978C15.8761 35.9755 18.2884 35.3298 20.3814 34.1051C22.2518 33.014 23.7956 31.5073 24.8719 29.7334C25.9258 29.5479 26.9575 29.2584 27.9521 28.8724L35.9161 32.3832C36.1091 32.4648 36.3095 32.5093 36.5173 32.5093C36.8959 32.5093 37.267 32.3683 37.549 32.0937C37.9795 31.6706 38.1205 31.0323 37.8904 30.4682L34.7731 22.7565ZM13.2858 33.0289C13.2635 33.0289 13.2487 33.0289 13.2264 33.0289C11.7123 33.0215 10.2501 32.7246 8.89182 32.1382C8.51329 31.9749 8.0828 31.9824 7.71169 32.1456L4.23067 33.682L5.35142 30.9061C5.55924 30.394 5.46276 29.8076 5.10649 29.3846C3.71111 27.7517 2.96889 25.7922 2.96889 23.714C2.96889 21.3389 3.96347 19.0974 5.71511 17.3903C6.25693 20.3517 7.79333 23.187 10.1091 25.4137C12.9889 28.1822 16.7965 29.7928 20.9084 29.9858C18.9712 31.8859 16.2101 33.0289 13.2858 33.0289Z" fill="white"/>
    <path d="M21.6729 16.5516C22.4927 16.5516 23.1573 15.8869 23.1573 15.0671C23.1573 14.2473 22.4927 13.5827 21.6729 13.5827C20.8531 13.5827 20.1884 14.2473 20.1884 15.0671C20.1884 15.8869 20.8531 16.5516 21.6729 16.5516Z" fill="white"/>
    <path d="M27.6107 16.5516C28.4305 16.5516 29.0951 15.8869 29.0951 15.0671C29.0951 14.2473 28.4305 13.5827 27.6107 13.5827C26.7908 13.5827 26.1262 14.2473 26.1262 15.0671C26.1262 15.8869 26.7908 16.5516 27.6107 16.5516Z" fill="white"/>
    <path d="M15.7351 16.5516C16.5549 16.5516 17.2196 15.8869 17.2196 15.0671C17.2196 14.2473 16.5549 13.5827 15.7351 13.5827C14.9153 13.5827 14.2507 14.2473 14.2507 15.0671C14.2507 15.8869 14.9153 16.5516 15.7351 16.5516Z" fill="white"/>
  </SvgIcon>
);

const SupportPageFaq = props => (
  <SvgIcon viewBox="0 0 22 21" {...props}>
    {/* eslint-disable-next-line */}
  <path d="M17.5 0.823547C15.2115 0.823547 13.4615 2.57355 13.4615 4.86201V12.9389C13.4615 15.2274 15.2115 16.9774 17.5 16.9774H25.5769L30.9615 22.362V16.9774C33.25 16.9774 35 15.2274 35 12.9389V4.86201C35 2.57355 33.25 0.823547 30.9615 0.823547H17.5ZM23.1377 4.86201H25.4504L27.7227 12.9389H25.7035L25.1556 10.9197H23.1363L22.6329 12.9389H20.8654L23.1377 4.86201ZM24.2308 6.20816C24.0962 6.74662 23.9454 7.40355 23.8094 7.80739L23.4325 9.57355H25.0304L24.6508 7.80605C24.3829 7.40355 24.2308 6.74662 24.2308 6.20816ZM4.03846 14.2851C1.75 14.2851 0 16.0351 0 18.3235V26.4005C0 28.6889 1.75 30.4389 4.03846 30.4389V35.8235L9.42308 30.4389H17.5C19.7885 30.4389 21.5385 28.6889 21.5385 26.4005V18.3235H17.5C14.9423 18.3235 12.915 16.5735 12.2419 14.2851H4.03846ZM10.2227 18.197C12.5112 18.197 13.5881 20.0816 13.5881 22.2355C13.5881 24.1201 12.9406 25.3155 11.8637 25.8539C12.4021 26.1232 13.0402 26.2659 13.7133 26.4005L13.2098 27.7466C12.2675 27.4774 11.2902 27.0574 10.3479 26.6522C10.2133 26.5176 9.97769 26.527 9.84308 26.527C8.22769 26.3924 6.73077 25.0543 6.73077 22.362C6.73077 20.0735 8.06885 18.197 10.2227 18.197ZM10.2227 19.6697C9.14577 19.6697 8.62346 20.8812 8.62346 22.362C8.62346 23.9774 9.14577 25.0543 10.2227 25.0543C11.2996 25.0543 11.8623 23.8428 11.8623 22.362C11.8623 20.8812 11.2996 19.6697 10.2227 19.6697Z" fill="#33C16C"/>
  </SvgIcon>
);

const SupportPageEmail = props => (
  <SvgIcon viewBox="0 0 25 13" {...props}>
    <path d="M33.2 0H1.8C0.8 0 0 0.784314 0 1.76471V20.8824C0 21.8627 0.8 22.6471 1.8 22.6471H33.2C34.2 22.6471 35 21.8627 35 20.8824V1.76471C35 0.784314 34.2 0 33.2 0ZM32.5 1.27451L18.5 11.3726C18.2 11.5686 17.9 11.6667 17.5 11.6667C17.1 11.6667 16.7 11.5686 16.5 11.3726L2.5 1.27451H32.5ZM25 12.1569L32.6 21.2745H2.3L9.9 12.1569C10.1 11.8627 10.1 11.4706 9.8 11.2745C9.5 11.0784 9.1 11.0784 8.9 11.3726L1.3 20.3922V2.15686L15.6 12.549C16.1 12.9412 16.8 13.1373 17.4 13.1373C18 13.1373 18.7 12.9412 19.2 12.549L33.6 2.15686V20.3922L26 11.3726C25.8 11.0784 25.3 11.0784 25.1 11.2745C24.9 11.4706 24.8 11.8627 25 12.1569Z" fill="#33C16C"/>
  </SvgIcon>
);
    

const SupportPageAffiliateEmail = props => (
  <SvgIcon viewBox="0 0 25 20" {...props}>
    <path d="M6.10999 15.5444V15.0096C6.10999 14.2966 6.74571 13.7261 7.52271 13.7261H27.5126C28.2896 13.7261 28.9253 14.2966 28.9253 15.0096V15.5444L17.5177 21.9261L6.10999 15.5444ZM17.9062 23.2096C17.6589 23.3522 17.3764 23.3522 17.1645 23.2096L6.10999 17.0418V27.7731C6.10999 28.4862 6.74571 29.0566 7.52271 29.0566H27.5126C28.2896 29.0566 28.9253 28.4862 28.9253 27.7731V17.0418L17.9062 23.2096Z" fill="#33C16C" />
    <path d="M29.5257 8.84176C31.9444 8.84176 33.9052 6.86247 33.9052 4.42088C33.9052 1.9793 31.9444 0 29.5257 0C27.1071 0 25.1463 1.9793 25.1463 4.42088C25.1463 6.86247 27.1071 8.84176 29.5257 8.84176Z" fill="#33C16C" />
    <path d="M5.79213 8.84176C8.21081 8.84176 10.1715 6.86247 10.1715 4.42088C10.1715 1.9793 8.21081 0 5.79213 0C3.37345 0 1.41271 1.9793 1.41271 4.42088C1.41271 6.86247 3.37345 8.84176 5.79213 8.84176Z" fill="#33C16C" />
    <path d="M29.2432 10.4818C27.1948 10.4818 25.217 10.9096 23.4511 11.7296V12.5496H28.5722C29.4551 12.5496 30.1615 13.1913 30.1615 13.9757V18.3609H35V11.7296C33.2694 10.9096 31.2916 10.4818 29.2432 10.4818Z" fill="#33C16C" />
    <path d="M5.79213 10.4818C7.84057 10.4818 9.81837 10.9096 11.5843 11.7296V12.5496H6.46317C5.58022 12.5496 4.87387 13.1913 4.87387 13.9757V18.3609L0 18.3966V11.7296C1.76589 10.9096 3.70838 10.4818 5.79213 10.4818Z" fill="#33C16C" />
  </SvgIcon>
);


export {
  SupportPageLiveChat,
  SupportPageLiveChatWhite,
  SupportPageHotline,
  SupportPageLeavingMessage,
  SupportPageTelegram,
  SupportPageLine,
  SupportPageSkype,
  SupportPageHeadset,
  SupportPageContactUs,
  SupportPageFaq,
  SupportPageEmail,
  SupportPageAffiliateEmail,
};
