import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import CustomNotification from '../../components/custom/notification';
import { setNotification } from '../../actions/notification';

const Notification = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { notificationContent } = useSelector(state => state.notification)
  const [open, setOpen] = React.useState(false);

  const handleClose = useCallback(() => {
    setOpen(false);
    dispatch(setNotification({msg: '', type: notificationContent.type}));
  });

  useEffect(() => {
    if(notificationContent.msg !== ''){
      setOpen(true)
    }
  }, [notificationContent])

  return (
    <div>
      <CustomNotification
        type={notificationContent.type}
        message={t(notificationContent.msg)}
        onClose={handleClose}
        mode={1}
        vertical="bottom"
        open={open}
      />
    </div>
  );
};

export default Notification;
