import {
  all,
  call,
  put,
  select,
  takeLatest,
  takeLeading
} from 'redux-saga/effects';
import {
  CHANGE_PLAYER_PASSWORD,
  CHECK_PLAYER_SUBSCRIPTION,
  GET_ALL_GAMES_WALLET,
  GET_BANK_ACCOUNT,
  GET_GAMES_WALLET,
  GET_ONGOING_PROMOTION,
  GET_PLAYER_INFO,
  GET_PLAYER_BONUS,
  GET_PLAYER_BONUS_HISTORY,
  GET_PLAYER_CASH,
  GET_PLAYER_CURRENT_TRANSACTIONS,
  GET_PLAYER_MESSAGES,
  GET_PLAYER_TRANSACTIONS,
  LOAD_MORE_BONUS,
  LOAD_MORE_CASH,
  LOAD_MORE_TRANSACTIONS,
  REDEEM_BONUS,
  SET_BANK_DETAILS,
  SET_MESSAGE_TO_VIEW,
  UPDATE_PLAYER_INFO,
  CANCEL_ONGOING_PROMOTION,
  LOAD_MORE_BONUS_HISTORY,
  GET_PLAYER_PPROMOTION_MIN_DEPOSIT,
  REQUESTING_STATUS,
  SUCCESS_REQUEST_STATUS,
  UPDATE_PLAYER_PROVIDER_WALLET,
  SEND_MESSAGE,
  LOAD_MORE_MESSAGES,
  UPDATE_PLAYER_BADGE_COUNT,
  GET_PLAYER_DEPOSIT_ACCOUNT,
  SET_PLAYER_DEPOSIT_ACCOUNT,
  GET_PAYMENT_TRON_EXCHANGE,
  GET_PAYMENT_TRON_EXCHANGE_BALANCE,
  GET_PAYMENT_TRON_EXCHANGE_PURCHASE,
  GET_DEPOSIT_OPTION,
  SET_BALANCE_INQUIRY_LOADING,
  GET_CHUNG_DAM_INFO,
  GET_DEPOSIT_PROMOTION,
  GET_CHECK_DEPOSIT_PROMOTION,
} from '../constants/player';
import {
  INITIAL_STATUS,
  REQUESTING_GET_ONGOING_PROMOTION,
  HAS_ONGOING_PROMOTION,
  NO_ONGOING_PROMOTION,
} from '../constants/promotions';
import {
  getPlayerInfo as getInfo,
  setAllGamesWallet,
  setGameWallet,
  setMoreBonus,
  setMoreCash,
  setMoreTransactions,
  setMoreMessages,
  setOngoingPromotion,
  setOngoingPromotionError,
  setOngoingPromotionSuccess,
  setPlayerBankAccount,
  setPlayerBonus,
  setPlayerCash,
  setPlayerCurrentTransactions,
  setPlayerExistingSubscription,
  setPlayerHasPendingBet,
  setPlayerInfo,
  setPlayerMessages,
  setPlayerPromotionMinDeposit,
  setPlayerTransactions,
  setPlayerUnauthorized,
  setErrors,
  setResponseMessage,
  setPlayerBonusHistory,
  setMoreBonusHistory,
  setAllGamesWalletStatus,
  getDepositAccount,
  setDepositAccount,
  errorDepositAccount,
  setPaymentTronExchange,
  setPaymentTronExchangeBalance,
  setPaymentTronExchangePurchase,
  setDepositOption,
  setBalanceInquiryBtnDisabled,
  setChungDamInfo,
  setDepositPromotion,
  setUpdatePlayerInfoSuccess,
  setMessageSuccess,
  setCheckDepositPromotion,
} from '../actions/player';

import {
  showSessionModal
} from '../actions/session';
import { showFilter } from '../actions/filter';
import { setGames, setLoading } from '../actions/common';
import { realAmount } from '../utils/helpers/common';
import playerSvc from '../utils/services/player';
import { setNoBank } from '../actions/wallet';

function* cancelOngoingPromotion(action) {
  try {
    yield call(
      playerSvc.cancelOngoingPromotion,
      action.params,
    );
    yield put(setOngoingPromotion({status: INITIAL_STATUS}));
    yield put(setOngoingPromotionSuccess(true));

  } catch (err) {
   // const { subscription, hasPendingBet } = err.response.data;

    //yield put(setOngoingPromotion({...subscription, status: HAS_ONGOING_PROMOTION}));
    //yield put(setPlayerHasPendingBet(hasPendingBet));
    const { status } = err.response || {};

    if (status === 403) {
      yield put(showSessionModal())
    }
    yield put(setOngoingPromotionError(err.response.data));

    console.log(err.response.data);
  }
}

function* changePlayerPassword(action) {
  try {
    const request = yield call(
      playerSvc.changePassword,
      action.params,
    );
    const { message } = request.data;
    yield put(setErrors({}));
    yield put(setResponseMessage(message, true));
  } catch (err) {
    const { data } = err.response;
    const { status } = err.response || {};

    if (status === 403) {
      yield put(showSessionModal())
    }
    yield put(setErrors(data.errors));
  } finally {
    yield put(setLoading(false));
  }
}

function* checkPlayerPendingSubscription(action) {
  try {
    const { provider } = action;

    yield call(playerSvc.checkPlayerSubscription, { provider });
  } catch (err) {
    const { subscription } = err.response.data;
    const { status } = err.response || {};

    if (status === 403) {
      yield put(showSessionModal())
    }

    yield put(setPlayerExistingSubscription(subscription));
  }
}

function* updatePlayerProviderWallet(action) {
  const player = yield select(state => state.player);
  const { providerID, balance } = action.params;
  player.playerInfo.withdrawAmount = balance - player.playerInfo.wageringAmount;
  if(providerID === 0){
    yield put(setPlayerInfo({
      ...player.playerInfo,
      wallet: balance,
    }));
  }


  // comment since no more provider wallet only main wallet needed
  // const providerWalletIndex = gameWallets.findIndex(
  //   wallet => wallet.id === String(providerID),
  // );
  // const updatedWallet = [...gameWallets];
  // updatedWallet[providerWalletIndex] = {
  //   id: String(providerID),
  //   balance: realAmount(balance),
  // };

  // yield put(setGameWallet(updatedWallet));
  // yield* recalculateAllGamesWallet(updatedWallet);

}

function* recalculateAllGamesWallet(gameWallets) {
  let allGamesBalance = 0;
  gameWallets.map(item => {
    const { balance } = item;
    allGamesBalance += realAmount(balance);
  });
  yield put(setAllGamesWallet(allGamesBalance));
}

function* getAllGamesWallet() {
  try {
    let allGamesBalance = 0;
    const { activeProviders } = yield select(state => state.common);
    yield put(setAllGamesWalletStatus(REQUESTING_STATUS));
    const allRequest = yield all(
      activeProviders.map(provider =>
        call(playerSvc.getProviderBalance, { provider }),
      ),
    );

    const balances = allRequest.map(item => {
      const { data } = item;
      const { id, balance } = data;

      allGamesBalance += realAmount(balance);

      return {
        id,
        balance,
      };
    });

    yield put(setGameWallet(balances));
    yield put(setAllGamesWallet(allGamesBalance));
  } catch (err) {
    const { status } = err.response || {};
    yield put(setAllGamesWalletStatus(INITIAL_STATUS));

    if (status === 403) {
      yield put(showSessionModal())
    }
    console.log(err);
  }
}

function* getBankAccount(action) {
  try {
    const request = yield call(
      playerSvc.getBankAccount,
      action.params,
    );
    const { data } = request;
    yield put(setPlayerBankAccount(data.data));
    if(data.bankAccount.length === 0){
      yield put(setNoBank(true));
    }
  } catch (err) {
    const { status } = err.response || {};

    if (status === 403) {
      yield put(showSessionModal())
    }
    console.log(err.response);
  } finally {
   // yield put(showFilter(false));
   // yield put(setLoading(false));
  }
}

function* getGamesWallet(action) {
  try {
    const { activeProviders } = yield select(state => state.common);
    let { providers } = action;

    if (!providers.length) {
      providers = activeProviders;
    }

    const allRequest = yield all(
      providers.map(provider =>
        call(playerSvc.getProviderBalance, {
          provider: provider,
        }),
      ),
    );

    const balances = allRequest.map(item => {
      const { data } = item;
      const { id, balance } = data;

      return {
        id,
        balance,
      };
    });

    yield put(setGameWallet(balances));
  } catch (err) {
    const { status } = err.response || {};

    if (status === 403) {
      yield put(showSessionModal())
    }
    console.log(err);
  }
}

function* getOngoingPromotion(action) {
  yield put(setOngoingPromotion({status: REQUESTING_GET_ONGOING_PROMOTION}));
  try {
    yield call(
      playerSvc.getOngoingPromotion,
      action.params,
    );
    yield put(setOngoingPromotion({status: NO_ONGOING_PROMOTION}));
  } catch (err) {
    const { subscription, hasPendingBet, balance } = err.response.data;
    const { status } = err.response || {};

    if (status === 403) {
      yield put(showSessionModal())
    }

    yield put(setOngoingPromotion({...subscription, balance, status: HAS_ONGOING_PROMOTION}));
    yield put(setPlayerHasPendingBet(hasPendingBet));
  }
}

function* getPlayerPromotionMinDeposit(action) {
  try {
    const response = yield call(
      playerSvc.getPlayerPromotionMinDeposit,
      action.params,
    );
    const { MinDeposit } = response.data;
    yield put(setPlayerPromotionMinDeposit(MinDeposit));
  } catch (err) {
   const { status } = err.response || {};

   if (status === 403) {
     yield put(showSessionModal())
   }
  }
}

function* getPlayerBonus(action) {
  try {
    const request = yield call(
      playerSvc.getPlayerBonus,
      action.params,
    );
    const { data, meta } = request.data;

    yield put(setPlayerBonus(data, meta.last_page, SUCCESS_REQUEST_STATUS));
  } catch (err) {
    const { status } = err.response || {};

    if (status === 403) {
      yield put(showSessionModal())
    }
    console.log(err.response.data);
  } finally {
    yield put(showFilter(false));
    yield put(setLoading(false));
  }
}

function* getPlayerBonusHistory(action) {
  try {
    const request = yield call(
      playerSvc.getPlayerBonusHistory,
      action.params,
    );
    const { data, meta } = request.data;

    yield put(setPlayerBonusHistory(data, meta.last_page));
  } catch (err) {
    const { status } = err.response || {};

    if (status === 403) {
      yield put(showSessionModal())
    }
    console.log(err.response.data);
  } finally {
    yield put(showFilter(false));
    yield put(setLoading(false));
  }
}

function* getPlayerCash(action) {
  try {
    const request = yield call(
      playerSvc.getPlayerCash,
      action.params,
    );
    const { data, meta } = request.data;

    yield put(setPlayerCash(data, meta.last_page, SUCCESS_REQUEST_STATUS));
  } catch (err) {
    const { status } = err.response || {};

    if (status === 403) {
      yield put(showSessionModal())
    }
    console.log(err.response.data);
  } finally {
    yield put(showFilter(false));
    yield put(setLoading(false));
  }
}

function* getPlayerCurrentTransactions() {
  try {
    const request = yield call(playerSvc.getPlayerTransactions, {
      date: '3',
      transaction_type: 0,
      provider: {},
      gmt: '+09: 00',
      operator: '+',
      hours: '09',
      minutes: '/00',
    });
    const { data } = request.data;

    yield put(setPlayerCurrentTransactions(data));
  } catch (err) {
    const { status } = err.response || {};

    if (status === 403) {
      yield put(showSessionModal())
    }
    console.log(err.response.data);
  }
}

function* getPlayerInfo() {
  try {
    let playerResponse = yield call(playerSvc.getPlayerInfo);
    try {
      const unreadMessageResponse = yield call(playerSvc.getPlayerUnreadMessageCount);
      const unreadNoticeResponse = yield call(playerSvc.getPlayerUnreadNoticeCount);

      playerResponse.data = {
        ...playerResponse.data,
        unread_message_ids: unreadMessageResponse.data.unread_message_ids,
        unread_message_replies_count: unreadMessageResponse.data.unread_message_replies_count,
        unread_notice_count: unreadNoticeResponse.data.unread_notice_count
      }
    }catch(err){
    }
    yield put(setPlayerInfo(playerResponse.data));
  } catch (err) {
    const { status } = err.response || {};

    if (status === 403) {
      yield put(showSessionModal())
    }
  }
}

function* getPlayerMessages(action) {
  try {
    yield put(setLoading(true));
    const request = yield call(
      playerSvc.getPlayerMessages,
      action.params,
    );
    const { data, meta} = request.data;

    yield put(setPlayerMessages(data, meta.last_page, SUCCESS_REQUEST_STATUS));
  } catch (err) {
    const { status } = err.response || {};

    if (status === 403) {
      yield put(showSessionModal())
    }
    console.log(err.response.data);
  } finally {
    yield put(showFilter(false));
    yield put(setLoading(false));
  }
}

function* getPlayerTransactions(action) {
  try {
    const request = yield call(
      playerSvc.getPlayerTransactions,
      action.params,
    );
    const { data, meta } = request.data;

    yield put(setPlayerTransactions(data, meta.last_page, SUCCESS_REQUEST_STATUS));
  } catch (err) {
    const { status } = err.response || {};

    if (status === 403) {
      yield put(showSessionModal())
    }
    console.log(err.response.data);
  } finally {
    yield put(setLoading(false));
    yield put(showFilter(false));
  }
}

function* loadMoreBonus(action) {
  try {
    const request = yield call(
      playerSvc.getPlayerBonus,
      action.params,
    );
    const { data } = request.data;

    yield put(setMoreBonus(data));
  } catch (err) {
    const { status } = err.response || {};

    if (status === 403) {
      yield put(showSessionModal())
    }
    console.log(err.response.data);
  } finally {
    yield put(setLoading(false));
  }
}

function* loadMoreBonusHistory(action) {
  try {
    const request = yield call(
      playerSvc.getPlayerBonusHistory,
      action.params,
    );
    const { data } = request.data;

    yield put(setMoreBonusHistory(data));
  } catch (err) {
    const { status } = err.response || {};

    if (status === 403) {
      yield put(showSessionModal())
    }
    console.log(err.response.data);
  } finally {
    yield put(setLoading(false));
  }
}

function* loadMoreCash(action) {
  try {
    const request = yield call(
      playerSvc.getPlayerCash,
      action.params,
    );
    const { data } = request.data;

    yield put(setMoreCash(data));
  } catch (err) {
    const { status } = err.response || {};

    if (status === 403) {
      yield put(showSessionModal())
    }
    console.log(err.response.data);
  } finally {
    yield put(setLoading(false));
  }
}

function* loadMoreTransactions(action) {
  try {
    const request = yield call(
      playerSvc.getPlayerTransactions,
      action.params,
    );
    const { data } = request.data;

    yield put(setMoreTransactions(data));
  } catch (err) {
    const { status } = err.response || {};

    if (status === 403) {
      yield put(showSessionModal())
    }
    console.log(err.response.data);
  } finally {
    yield put(setLoading(false));
  }
}

function* loadMoreMessages(action) {
  try {
    const request = yield call(
      playerSvc.getPlayerMessages,
      action.params,
    );
    const { data } = request.data;

    yield put(setMoreMessages(data));
  } catch (err) {
    const { status } = err.response || {};

    if (status === 403) {
      yield put(showSessionModal())
    }
    console.log(err.response.data);
  } finally {
    yield put(setLoading(false));
  }
}

function* redeemBonus(action) {
  const { subscription } = action;
  const { playerBonus, bonusLastPage } = yield select(
    state => state.player,
  );
  const holder = [...playerBonus];
  const index = playerBonus.findIndex(
    item => item.ID === subscription.subscription,
  );

  try {
    const request = yield call(playerSvc.redeemBonus, subscription);
    const { message } = request.data;
    holder[index].Status = 'processing';

    if (subscription.provider) {
      holder[index].ProviderID = subscription.provider;
      holder[index].ProviderName = subscription.providerName;
    }

    yield put(setResponseMessage('bonus success message', true, 'success'));
  } catch (err) {
    const { errors, message } = err.response.data;
    const { status } = err.response || {};

    if (status === 403) {
      yield put(showSessionModal())
    }

     if (errors.ongoing_subscription) {
      yield put(
        setResponseMessage(
          errors.ongoing_subscription[0], true, 'error'
        ),
      );
    } else {
      yield put(setResponseMessage('bonus failed message', true, 'error'));
    }

    
  } finally {
    if(index > -1){
      delete holder[index].onProcess;
    }
    yield put(setPlayerBonus(holder, bonusLastPage));
  }
}

function* setBankDetails(action) {
  try {
    const request = yield call(
      playerSvc.submitBankDetails,
      action.params,
    );
    const { message } = request.data;

    yield put(setErrors({}));
    yield put(setResponseMessage('player bank account success', true));
    yield put(getInfo());
  } catch (err) {
    const { data } = err.response;
    const { status } = err.response || {};

    if (status === 403) {
      yield put(showSessionModal())
    }
    yield put(setErrors(data.errors));
  } finally {
    yield put(setLoading(false));
  }
}

//when message is viewed
//update status, badge count for message or notice and unread_message_ids
function* setMessageToView(action) {
  try {
    yield call(playerSvc.getPlayerMessages, action.messageID);
    const player = yield select(state => state.player);
    const { playerMessages, messagesLastPage, playerInfo } = player;
    const { unread_message_ids } = playerInfo;
    const updatedPlayerInfo = {...playerInfo};

    //find message in store and update status and count(message list view right badge count)
    const messageIdx = playerMessages.findIndex(msg => msg.MessageID === action.messageID);
    const updatedMessages = [...playerMessages]
    updatedMessages[messageIdx].Status = "View";
    updatedMessages[messageIdx].unread_replies_count = 0;
    yield put(setPlayerMessages(updatedMessages, messagesLastPage, SUCCESS_REQUEST_STATUS));
    //find message in store and update status and count(message list view right badge count)

    //update unread_message_ids, remove selected message from unread_message_ids list
    let updated_unread_message_ids = [...unread_message_ids];
    updated_unread_message_ids = updated_unread_message_ids.filter(id => id !== action.messageID);
    updatedPlayerInfo.unread_message_ids = updated_unread_message_ids;
    //update unread_message_ids

    //update player info badge count(left menu badge count)
    switch(action.messageType){
      case 'notice':
        if(updatedPlayerInfo.unread_notice_count > 0){
          updatedPlayerInfo.unread_notice_count--;
        }
        break;
        case 'message':
        if(updatedPlayerInfo.unread_message_replies_count > 0){
          updatedPlayerInfo.unread_message_replies_count--;
        }
        break;
        default:
    }
    //update player info badge count(left menu badge count)
    yield put(setPlayerInfo(updatedPlayerInfo));
  } catch (err) {
    const { status } = err.response || {};

    if (status === 403) {
      yield put(showSessionModal())
    }
    console.log(err.response.data);
  }
}

//when player badge count is updated from websocket
//update notice or message menu badge count and unread_message_ids list
function* updatePlayerBadgeCount(action) {
    const player = yield select(state => state.player);
    const { playerMessages, messagesLastPage, playerInfo } = player;
    const updatedPlayerInfo = {...playerInfo};
    const { unread_message_ids } = playerInfo;
    const { DateCreated, Message, MessageID, Subject, parentID, msgAction, status } = action.params;
    let updatedMessages, parentMessageIdx;

    switch (action.params.messageType) {
      case 'notice':
        updatedMessages = [...playerMessages];
        if(msgAction === 'add'){
          updatedPlayerInfo.unread_notice_count++;
          //insert new notice to playerMessage list
          updatedMessages = [
            {
              DateCreated,
              Message,
              MessageID,
              Status: 'Read',
              Messages: [],
              Subject,
              Type: 'Notice',
              unread_replies_count: 0
            },
            ...playerMessages]
        }else{
          if(updatedPlayerInfo.unread_notice_count > 0 && status === "unread")
            updatedPlayerInfo.unread_notice_count--;
          updatedMessages = updatedMessages.filter(msg => msg.MessageID !== MessageID)
        }

        yield put(setPlayerMessages(updatedMessages, messagesLastPage, SUCCESS_REQUEST_STATUS));
        //insert new notice to playerMessage list
        break;
      case 'message':
        //check if message id already in unread message id list to avoid duplicate badge count
        //if not add to unread message id list
        if (action.params.msgAction === 'add') {
          if (
            !unread_message_ids.includes(action.params.parentID)
          ) {
            updatedPlayerInfo.unread_message_replies_count++;
            updatedPlayerInfo.unread_message_ids.push(
              action.params.parentID,
            );
          }
        }else{
          try {
              const request = yield call(playerSvc.getPlayerUnreadMessageCount);
              const { unread_message_replies_count } = request.data;
              //if there is parent unread thread recount, meaning no more unread on parent thread
              //remove parentID from unread_message_ids
              if(unread_message_replies_count !== updatedPlayerInfo.unread_message_replies_count){
                updatedPlayerInfo.unread_message_ids = unread_message_ids.filter(id => id != action.params.parentID)
              }
              updatedPlayerInfo.unread_message_replies_count = unread_message_replies_count; //update latest parent thread count
            } catch (err) {
              const { status } = err.response || {};

              if (status === 403) {
                yield put(showSessionModal())
              }
              console.log(err)
            }
        }

        updatedMessages = [...playerMessages];
        parentMessageIdx = playerMessages.findIndex(msg => msg.MessageID === parentID) //find parent message
        //insert reply to inner message
        if(playerMessages.length > 0){
          if(action.params.msgAction === 'add'){
            const newMessage = {
                DateCreated,
                Message,
                Sender: 'Customer Service'
              };
            updatedMessages[parentMessageIdx].Messages.push(newMessage);
          }else{ //delete old message in inner message
            const updatedInnerMessages = updatedMessages[parentMessageIdx].Messages.filter(msg => msg.MessageID !== MessageID)
            updatedMessages[parentMessageIdx].Messages = [...updatedInnerMessages];
          }
        }

        //update inner message badge count and status
        yield* updatePlayerMessageThreadBadgeCount(action);
        break;
      default:
    }

    yield put(setPlayerInfo(updatedPlayerInfo));
}

function* updatePlayerMessageThreadBadgeCount(action) {
  const player = yield select(state => state.player);
  const { playerMessages, messagesLastPage } = player;

  if(typeof playerMessages === 'object' && playerMessages.length > 0){
  //find message in store and update status and count(message list view right badge count)
  const messageIdx = playerMessages.findIndex(
    msg => msg.MessageID === action.params.parentID,
  );
  const updatedMessages = [...playerMessages];

  //update message thread counter
  if(action.params.msgAction === 'add'){
    updatedMessages[messageIdx].unread_replies_count++;
  }else{ //decrement
     if (updatedMessages[messageIdx].unread_replies_count > 0) {
       updatedMessages[messageIdx].unread_replies_count--;
     }
  }

  //update status
  //decrement menu badge counter if inner message replies are deleted
  if(updatedMessages[messageIdx].unread_replies_count === 0){
    updatedMessages[messageIdx].Status = 'View';
  }else{
    updatedMessages[messageIdx].Status = 'Read';
  }

  yield put(
    setPlayerMessages(
      updatedMessages,
      messagesLastPage,
      SUCCESS_REQUEST_STATUS,
    ),
  );
  //find message in store and update status and count(message list view right badge count)
  }
}

function* updatePlayerInfo(action) {
  try {
    const request = yield call(playerSvc.updatePlayerInfo, action.params);
    yield put(
      setUpdatePlayerInfoSuccess(request.data)
    );
    yield put(setErrors({}));
    // yield put(
    //   setResponseMessage('player details success', true),
    // );
    yield put(getInfo());
  } catch (err) {
    const { data } = err.response;
    yield put(setErrors(data.errors));
    const { status } = err.response || {};

    if (status === 403) {
      yield put(showSessionModal())
    }
  } finally {
    yield put(setLoading(false));
  }
}

function* sendMessage(action) {
  try {
    yield put(setLoading(true));
    const request = yield call(playerSvc.sendMessage, action.params);
    yield put(
      setMessageSuccess(request.data)
    );
    yield put(setErrors({}));
    // yield put(setResponseMessage('message sent', true, 'success'));
  } catch (err) {
    const { data } = err.response;
    yield put(setErrors(data.errors));
    const { status } = err.response || {};

    if (status === 403) {
      yield put(showSessionModal())
    }

    if (data.errors && data.errors.message === 'pending message') {
      yield put(setResponseMessage(data.errors.message, true, 'error'));
    }
    
  } finally {
    yield put(setLoading(false));
  }
}

function* getPlayerDepositAccount(action) {
  try {
    const request = yield call(playerSvc.getDepositAccount, action.params);
    yield put(setDepositAccount(request.data));
  } catch (err) {
    const { data } = err.response;
    const { status } = err.response || {};

    yield put(errorDepositAccount(data));
  }
}

function* getPaymentTronExchange(action) {
  try {
    const request = yield call(playerSvc.getPaymentTronExchange);
    yield put(setPaymentTronExchange(request.data));
  } catch (err) {
    const { data } = err.response;
  }
}

function* getPaymentTronExchangeBalance(action) {
  
  try {
    yield put(setLoading(true, SET_BALANCE_INQUIRY_LOADING));
    const request = yield call(playerSvc.getPaymentTronExchangeBalance);
    yield put(setPaymentTronExchangeBalance(request.data));
  } catch (err) {
    const { data } = err.response;
  }
  finally {
    yield put(setLoading(false, SET_BALANCE_INQUIRY_LOADING));
    yield put(setBalanceInquiryBtnDisabled(false));
  }
}

function* getPaymentTronExchangePurchase(action) {
  try {
    const request = yield call(playerSvc.getPaymentTronExchangePurchase);
    yield put(setPaymentTronExchangePurchase(request.data));
  } catch (err) {
    const { data } = err.response;
  }
}

function* getDepositOption(action) {
  try {
    const request = yield call(playerSvc.getDepositOption, action.params);
    yield put(setDepositOption(request.data.data));
  } catch (err) {
    const { data } = err.response;
  }
}

function* getChungDamInfo(action) {
  try {
    const request = yield call(playerSvc.getChungDamInfo);
    if (request.data.data !== null) {
      yield put(setChungDamInfo(request.data.data));
    }
  } catch (err) {
    const { data } = err.response;
  }
}

function* getDepositPromotion(action) {
  try {
    const request = yield call(playerSvc.getDepositPromotion,action.params);
    if (request.data !== null) {
      yield put(setDepositPromotion(request.data));
    }
  } catch (err) {
    const { data } = err.response;
  }
}

function* getCheckDepositPromotion(action) {
  try {
    yield call(playerSvc.getDepositPromotionCheck);

  } catch (err) {
    const { data } = err.response;

    yield put(setCheckDepositPromotion(!data.success));
  }
}

export default function* playerWatcher() {
  yield all([
    takeLatest(CANCEL_ONGOING_PROMOTION, cancelOngoingPromotion),
    takeLatest(CHANGE_PLAYER_PASSWORD, changePlayerPassword),
    takeLatest(
      CHECK_PLAYER_SUBSCRIPTION,
      checkPlayerPendingSubscription,
    ),
    takeLatest(GET_ALL_GAMES_WALLET, getAllGamesWallet),
    takeLatest(GET_GAMES_WALLET, getGamesWallet),
    takeLatest(GET_PLAYER_BONUS, getPlayerBonus),
    takeLatest(GET_PLAYER_BONUS_HISTORY, getPlayerBonusHistory),
    takeLatest(GET_PLAYER_CASH, getPlayerCash),
    takeLeading(
      GET_PLAYER_CURRENT_TRANSACTIONS,
      getPlayerCurrentTransactions,
    ),
    takeLatest(GET_BANK_ACCOUNT, getBankAccount),
    takeLatest(GET_ONGOING_PROMOTION, getOngoingPromotion),
    takeLatest(GET_PLAYER_INFO, getPlayerInfo),
    takeLatest(GET_PLAYER_MESSAGES, getPlayerMessages),
    takeLeading(GET_PLAYER_TRANSACTIONS, getPlayerTransactions),
    takeLatest(LOAD_MORE_BONUS, loadMoreBonus),
    takeLatest(LOAD_MORE_BONUS_HISTORY, loadMoreBonusHistory),
    takeLatest(LOAD_MORE_CASH, loadMoreCash),
    takeLatest(LOAD_MORE_TRANSACTIONS, loadMoreTransactions),
    takeLatest(LOAD_MORE_MESSAGES, loadMoreMessages),
    takeLatest(REDEEM_BONUS, redeemBonus),
    takeLatest(SET_BANK_DETAILS, setBankDetails),
    takeLatest(SET_MESSAGE_TO_VIEW, setMessageToView),
    takeLatest(UPDATE_PLAYER_INFO, updatePlayerInfo),
    takeLatest(GET_PLAYER_PPROMOTION_MIN_DEPOSIT, getPlayerPromotionMinDeposit),
    takeLatest(UPDATE_PLAYER_PROVIDER_WALLET, updatePlayerProviderWallet),
    takeLatest(SEND_MESSAGE, sendMessage),
    takeLatest(UPDATE_PLAYER_BADGE_COUNT, updatePlayerBadgeCount),
    takeLatest(GET_PLAYER_DEPOSIT_ACCOUNT, getPlayerDepositAccount),
    takeLatest(GET_PAYMENT_TRON_EXCHANGE, getPaymentTronExchange),
    takeLatest(GET_PAYMENT_TRON_EXCHANGE_BALANCE, getPaymentTronExchangeBalance),
    takeLatest(GET_PAYMENT_TRON_EXCHANGE_PURCHASE, getPaymentTronExchangePurchase),
    takeLatest(GET_DEPOSIT_OPTION, getDepositOption),
    takeLatest(GET_CHUNG_DAM_INFO, getChungDamInfo),
    takeLatest(GET_DEPOSIT_PROMOTION, getDepositPromotion),
    takeLatest(GET_CHECK_DEPOSIT_PROMOTION, getCheckDepositPromotion),
  ]);
}
