import { OPEN_GAME, OPEN_GAME_SUCCESS, OPEN_GAME_ERROR, RESET_OPEN_GAME_ERROR, SET_INITIAL_GAME } from '../constants/game';
  
export const openGame = (isLogin) => ({
    type: OPEN_GAME,
    isLogin
});
  
export const openGameSuccess = (url) => ({
    type: OPEN_GAME_SUCCESS,
    url
});
  
export const openGameError = response => ({
    type: OPEN_GAME_ERROR,
    sucess: false,
    errors: response,
    loading: false
});

export const resetOpenGameError = response => ({
    type: RESET_OPEN_GAME_ERROR,
    response,
});

export const resetGameState = response => ({
    type: SET_INITIAL_GAME,
    response,
});
