export const GET_PROMOTIONS = 'GET_PROMOTIONS';
export const SET_PROMOTIONS = 'SET_PROMOTIONS';

//ongoing promotion statuses
export const INITIAL_STATUS = 'INITIAL_STATUS';
export const HAS_ONGOING_PROMOTION = 'HAS_ONGOING_PROMOTION';
export const NO_ONGOING_PROMOTION = 'NO_ONGOING_PROMOTION';
export const REQUESTING_GET_ONGOING_PROMOTION = 'REQUESTING_GET_ONGOING_PROMOTION';

export const GET_PROMOTIONS_CATEGORY = 'GET_PROMOTIONS_CATEGORY';
export const SET_PROMOTIONS_CATEGORY = 'SET_PROMOTIONS_CATEGORY';

export const SET_ACTIVE_PROMOTION = 'SET_ACTIVE_PROMOTION';