import {
    SOCKET_CONNECT,
    SOCKET_CANCEL_ALL_TASK,
    SOCKET_SEND_MESSAGE,
  } from '../constants/socket';

  const socketConnect = (token) => ({
    type: SOCKET_CONNECT,
    token
  });

  const taskCancel = () => ({
    type: SOCKET_CANCEL_ALL_TASK,
  });

  const sendMessage = payload => ({
    type: SOCKET_SEND_MESSAGE,
    payload
  })

  export {
    socketConnect,
    taskCancel,
    sendMessage,
  }
