const styles = theme => ({
    root: {
        backgroundColor: '#0F1D26',
        '& .heading': {
            color: '#33C16C',
            fontWeight: 700,
            fontSize: '23px',
            lineHeight: '34.5px',
            textAlign: 'center',
            textTransform: 'uppercase'
        },
        '& .box': {
            backgroundColor: '#1D2C31',
            borderRadius: '6px',
            padding: '18px'
        },
        '& .marginBottom15': {
            marginBottom: '15px'
        },
        '& .marginBottom30': {
            marginBottom: '30px'
        },
        '& p': {
            margin: 0,
            fontWeight: 500,
            fontSize: '14px',
            lineHeight: '21px',
            textAlign: 'center'
        },
        '& .browser': {
            textAlign: 'center',
            marginBottom: '40px'
        },
        '& .browser p': {
            marginTop: '10px',
            fontWeight: 700,
            fontSize: '16px',
            lineHeight: '24px'
        }
    },
    header: {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: '#1D2C31',
        paddingTop: '18px',
        paddingBottom: '15px'
    },
    logo: {
        height: '29px'
    },
    content: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        margin: '30px 18px 0 18px',
        color: '#FFFFFF',
        '& .warning': {
            marginBottom: '30px'
        }
    }
});

export default styles;
