import { OPEN_SPORTS_SUCCESS, SET_INITIAL_SPORTS, OPEN_SPORTS_ERROR } from '../constants/sports';

const initialState = {
  url: null,
  result: [],
  errors: []
};

const sportsReducer = (state = initialState, action) => { 
  switch (action.type) {
    case SET_INITIAL_SPORTS:
      return initialState; 
    case OPEN_SPORTS_SUCCESS: 
      return {
        ...state,
        url: action.providerID === 25 || action.providerID === 23 ? null : action.url,
        result: action.providerID === 25 || action.providerID === 23 ? action.url : []
      };
    case OPEN_SPORTS_ERROR:
      return {
        ...state,
        errors: action.response
      }
    default:
      return state;
  }
};

export default sportsReducer;
