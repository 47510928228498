import { createStore, applyMiddleware, compose } from 'redux';
import throttle from 'lodash.throttle';
import createSagaMiddleware from 'redux-saga';
import rootReducer from './reducers/root';
import rootSaga from './sagas/root';
import { isLogin } from './localStorage';

const inititalState = {}; // loadState();
const sagaMiddleware = createSagaMiddleware();

const store = createStore(
  rootReducer,
  inititalState,
  compose(applyMiddleware(sagaMiddleware)),
);

store.subscribe(
  throttle(() => {
    if (isLogin() !== true) {
      //    store.dispatch({
      // type: 'CLEAR_SESSIONS'
      // });
    }

    // console.log("storage is login: " + isLogin());
    // if(!isLogin())console.log(this.props.location.pathname);
    /* saveState({
    todos: store.getState().todos
  }); */
  }, 1000),
);

sagaMiddleware.run(rootSaga);

export default store;
