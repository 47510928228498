import { combineReducers } from 'redux';

import auth from './authentication';
import casino from './live.casino';
import common from './common';
import filter from './filter';
import layout from './layout';
import partners from './partners';
import player from './player';
import promotions from './promotions';
import register from './register';
import session from './session';
import slot from './slot.games';
import sports from './sports';
import theme from './theme';
import wallet from './wallet';
import page from './page';
import rebate from './rebate';
import notification from './notification';
import home from './home';
import notice from './popup.notice';
import minigame from './minigame';
import betconstruct from './betconstruct';
import game from './game';

// Combine with other reducers we may add in the future
const todoApp = combineReducers({
  auth,
  casino,
  common,
  filter,
  layout,
  partners,
  player,
  promotions,
  register,
  session, // localStorage.getItem('session') // loadState()?loadState():{}
  slot,
  sports,
  theme,
  wallet,
  page,
  rebate,
  notification,
  home,
  notice,
  minigame,
  betconstruct,
  game
});

export default todoApp;
