import { 
  SET_LANDING_PAGE_WITHDRAWAL_SUMMARY,
  SET_LANDING_PAGE_WINNER_LIST_SUMMARY,
  SET_LANDING_PAGE_WINNER_LIST_MONTHLY,
  UPDATE_LANDING_PAGE_WITHDRAWAL_SUMMARY,
  SET_HOME_PAGE_CAROUSEL,
  SET_HOME_PAGE_CAROUSEL_SETTING,
} from "../constants/home";

const initialState = {
    withdrawalSummary: [],
    winnerListSummary: [],
    winnerListMonthly: [],
    homePageCarousel: [],
    homePageCarouselSetting: {},
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_LANDING_PAGE_WITHDRAWAL_SUMMARY: 
      return { 
          ...state, 
          withdrawalSummary: action.payload, 
      }; 
    case SET_LANDING_PAGE_WINNER_LIST_SUMMARY: 
      return { 
          ...state, 
          winnerListSummary: action.payload, 
      };
    case SET_LANDING_PAGE_WINNER_LIST_MONTHLY: 
      return { 
          ...state, 
          winnerListMonthly: action.payload, 
      };
    case SET_HOME_PAGE_CAROUSEL: 
      return { 
          ...state, 
          homePageCarousel: action.payload, 
      };
    case SET_HOME_PAGE_CAROUSEL_SETTING: 
      return { 
          ...state, 
          homePageCarouselSetting: action.payload,
      };
    default:
      return state;
  }
}
