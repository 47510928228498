import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const HomeIcon = props => (
  <SvgIcon {...props} >
    { /* eslint-disable-next-line */ }
    <path d="M0 24V8L11.0688 0L22 8V24H13.9562V14.5333H8.04375V24H0ZM2.0625 22H5.9125V12.5333H15.95V22H19.9375V9L11.0688 2.53333L2.0625 9V22Z" />
  </SvgIcon>
);

const SupportIcon = props => (
  <SvgIcon {...props} >
    { /* eslint-disable-next-line */ }
    <path d="M6.78 10.74C7.14 10.74 7.38 10.62 7.62 10.38C7.86 10.14 7.98 9.84 7.98 9.54C7.98 9.18 7.86 8.94 7.62 8.7C7.38 8.46 7.08 8.34 6.78 8.34C6.42 8.34 6.18 8.46 5.94 8.7C5.7 8.94 5.58 9.24 5.58 9.54C5.58 9.9 5.7 10.14 5.94 10.38C6.18 10.62 6.42 10.74 6.78 10.74ZM12.12 10.74C12.48 10.74 12.72 10.62 12.96 10.38C13.2 10.14 13.32 9.84 13.32 9.54C13.32 9.18 13.2 8.94 12.96 8.7C12.72 8.46 12.42 8.34 12.12 8.34C11.76 8.34 11.52 8.46 11.28 8.7C11.04 8.94 10.92 9.24 10.92 9.54C10.92 9.84 11.04 10.14 11.28 10.38C11.46 10.62 11.76 10.74 12.12 10.74ZM17.22 10.74C17.58 10.74 17.82 10.62 18.06 10.38C18.3 10.14 18.42 9.84 18.42 9.54C18.42 9.18 18.3 8.94 18.06 8.7C17.82 8.46 17.52 8.34 17.22 8.34C16.86 8.34 16.62 8.46 16.38 8.7C16.14 8.94 16.02 9.24 16.02 9.54C16.02 9.84 16.14 10.14 16.38 10.38C16.56 10.62 16.86 10.74 17.22 10.74ZM0 24V1.8C0 1.32 0.18 0.9 0.54 0.54C0.9 0.18 1.32 0 1.8 0H22.2C22.68 0 23.1 0.18 23.46 0.54C23.82 0.9 24 1.32 24 1.8V17.4C24 17.88 23.82 18.3 23.46 18.66C23.1 19.02 22.68 19.2 22.2 19.2H4.8L0 24ZM1.8 19.68L4.08 17.4H22.2V1.8H1.8V19.68Z" />
  </SvgIcon>
);

const PromoIcon = props => (
  <SvgIcon {...props} >
    { /* eslint-disable-next-line */ }
    <path d="M1.875 19.0737V22.1053H23.125V19.0737H1.875ZM1.875 5.05263H5.8125C5.6875 4.86316 5.5625 4.61053 5.5625 4.35789C5.5 4.04211 5.4375 3.78947 5.4375 3.53684C5.4375 2.52632 5.75 1.70526 6.4375 1.01053C7.125 0.315789 8 0 8.9375 0C9.5625 0 10.1875 0.189474 10.75 0.505263C11.3125 0.821053 11.75 1.26316 12.0625 1.76842L12.625 2.58947L13.125 1.76842C13.4375 1.26316 13.875 0.821053 14.375 0.505263C14.8125 0.189474 15.375 0 16 0C17 0 17.8125 0.315789 18.5625 1.01053C19.3125 1.70526 19.625 2.52632 19.5625 3.53684C19.5625 3.78947 19.5625 3.97895 19.5 4.23158C19.4375 4.48421 19.375 4.73684 19.25 5.05263H23.125C23.625 5.05263 24.0625 5.2421 24.4375 5.62105C24.8125 6 25 6.4421 25 6.94737V22.1053C25 22.6105 24.8125 23.0526 24.4375 23.4316C24.0625 23.8105 23.625 24 23.125 24H1.875C1.375 24 0.9375 23.8105 0.5625 23.4316C0.1875 23.0526 0 22.6105 0 22.1053V6.94737C0 6.4421 0.1875 6 0.5625 5.62105C0.9375 5.2421 1.375 5.05263 1.875 5.05263ZM1.875 15.6632H23.125V6.94737H15.3125L18.4375 11.3684L16.9375 12.5053L12.5 6.25263L8.0625 12.5053L6.5625 11.3684L9.6875 7.01053H1.875V15.6632ZM9 5.24211C9.4375 5.24211 9.875 5.05263 10.1875 4.73684C10.4375 4.42105 10.625 4.04211 10.625 3.53684C10.625 3.03158 10.4375 2.65263 10.125 2.33684C9.8125 2.02105 9.4375 1.89474 9 1.89474C8.5625 1.89474 8.125 2.08421 7.8125 2.4C7.5 2.71579 7.3125 3.09474 7.3125 3.6C7.3125 4.10526 7.5 4.42105 7.8125 4.73684C8.125 5.05263 8.5 5.24211 9 5.24211ZM16 5.24211C16.5 5.24211 16.875 5.05263 17.1875 4.73684C17.5 4.42105 17.6875 4.04211 17.6875 3.53684C17.6875 3.03158 17.5 2.71579 17.1875 2.4C16.875 2.08421 16.5 1.89474 16 1.89474C15.5625 1.89474 15.1875 2.08421 14.875 2.4C14.5625 2.71579 14.375 3.09474 14.375 3.53684C14.375 3.97895 14.5625 4.42105 14.875 4.73684C15.1875 5.05263 15.5625 5.24211 16 5.24211Z" />
  </SvgIcon>
);


const AccountIcon = props => (
  <SvgIcon {...props} >
    { /* eslint-disable-next-line */ }
    <path d="M12.4802 11.6256C10.7633 11.6256 9.36344 11.0936 8.28051 10.0296C7.19757 8.96552 6.6561 7.5665 6.6561 5.83251C6.6561 4.09852 7.19097 2.69294 8.2607 1.61576C9.33043 0.538588 10.7369 0 12.4802 0C14.2235 0 15.6366 0.538588 16.7195 1.61576C17.8024 2.69294 18.3439 4.09852 18.3439 5.83251C18.3439 7.54023 17.8024 8.93268 16.7195 10.0099C15.6366 11.087 14.2235 11.6256 12.4802 11.6256ZM0 24V20.4138C0 19.4417 0.244321 18.6076 0.732964 17.9113C1.22161 17.2151 1.86212 16.6831 2.65452 16.3153C4.39778 15.5534 6.06841 14.9754 7.6664 14.5813C9.26439 14.1872 10.869 13.9901 12.4802 13.9901C14.0914 13.9901 15.6894 14.1872 17.2742 14.5813C18.859 14.9754 20.523 15.5665 22.2662 16.3547C23.085 16.6962 23.7454 17.2151 24.2472 17.9113C24.7491 18.6076 25 19.4548 25 20.4532V24H0ZM2.25832 21.7537H22.7021V20.4532C22.7021 20.0066 22.5766 19.5928 22.3257 19.2118C22.0747 18.8309 21.7644 18.5484 21.3946 18.3645C19.7042 17.5501 18.1656 16.9918 16.7789 16.6897C15.3922 16.3875 13.9593 16.2365 12.4802 16.2365C11.0011 16.2365 9.56154 16.3875 8.16165 16.6897C6.76175 16.9918 5.22979 17.5501 3.56577 18.3645C3.19599 18.5484 2.88563 18.8309 2.63471 19.2118C2.38378 19.5928 2.25832 20.0066 2.25832 20.4532V21.7537ZM12.4802 9.37931C13.5103 9.37931 14.3621 9.04434 15.0357 8.37438C15.7092 7.70443 16.046 6.85714 16.046 5.83251C16.046 4.80788 15.7092 3.96059 15.0357 3.29064C14.3621 2.62069 13.5103 2.28571 12.4802 2.28571C11.4501 2.28571 10.5983 2.62069 9.92472 3.29064C9.25119 3.96059 8.91442 4.80788 8.91442 5.83251C8.91442 6.85714 9.25119 7.70443 9.92472 8.37438C10.5983 9.04434 11.4501 9.37931 12.4802 9.37931Z" />
  </SvgIcon>
);

const WalletIcon = props => (
  <SvgIcon {...props} >
    { /* eslint-disable-next-line */ }
    <path d="M17.4541 14.1333C17.979 14.1333 18.5039 13.9333 18.8976 13.4667C19.2913 13 19.5538 12.5333 19.5538 11.9333C19.5538 11.4 19.357 10.8667 18.8976 10.4667C18.4383 10.0667 17.979 9.8 17.4541 9.8C16.9291 9.8 16.4042 10 16.0105 10.4667C15.6168 10.8667 15.3543 11.4 15.3543 11.9333C15.3543 12.5333 15.5512 13 16.0105 13.4667C16.4042 13.9333 16.9291 14.1333 17.4541 14.1333ZM1.9685 24C1.44357 24 0.984252 23.8 0.590551 23.4C0.19685 23 0 22.5333 0 22V2C0 1.46667 0.19685 1 0.590551 0.6C0.984252 0.2 1.44357 0 1.9685 0H21.6535C22.1785 0 22.6378 0.2 23.0315 0.6C23.4252 1 23.622 1.46667 23.622 2V6.46667H21.6535V2H1.9685V22H21.6535V17.5333H23.622V22C23.622 22.5333 23.4252 23 23.0315 23.4C22.6378 23.8 22.1785 24 21.6535 24H1.9685ZM13.7139 18.2C12.9921 18.2 12.4016 18 11.9423 17.5333C11.4829 17.0667 11.2861 16.4667 11.2861 15.8V8.26667C11.2861 7.53333 11.4829 6.93333 11.9423 6.46667C12.4016 6.06667 12.9921 5.8 13.7139 5.8H22.5722C23.294 5.8 23.8845 6 24.3438 6.46667C24.8031 6.86667 25 7.46667 25 8.26667V15.8667C25 16.6 24.8031 17.2 24.3438 17.6667C23.8845 18.1333 23.294 18.3333 22.5722 18.3333L13.7139 18.2ZM23.0315 16.2V7.8H13.2546V16.2H23.0315Z" />
  </SvgIcon>
);

export { HomeIcon, SupportIcon, PromoIcon, WalletIcon, AccountIcon };
