import API from './index';

const RegisterSvc = {
  canRegister: params => new API().get('/canRegister', params),
  doRegister: params => new API().post('/register', params),
  verify: params => new API().post('/verify', params),
  doPartnerRegister: params => new API().post('/register-affiliate', params),
  verifyPartner: params => new API().post('/verifyAffiliate', params),
  getPartner: params => new API().get('/partner', params),
  getSMSSetting: () =>  new API().get('/registration/sms')
};

export default RegisterSvc;
