import {
    GET_POPUP_NOTICE,
    SET_POPUP_NOTICE,
} from '../constants/popup.notice';

export const getPopupNotice = (payload) => {
    return {
        type: GET_POPUP_NOTICE,
        payload
    };
};

export const setPopUpNotice = content => {
    return {
        type: SET_POPUP_NOTICE,
        content
    };
};