import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { OPEN_MINIGAME } from '../constants/minigame';
import { getLanguage } from '../utils/helpers/common';
import sportSvc from '../utils/services/sports';
import { openMinigameSuccess, openMinigameError } from '../actions/minigame';
import { showGameMessage } from '../actions/common';

import { 
    resetOpenCasinoError 
} from '../actions/slot.games';

import { 
    resetOpenLiveCasinoError 
} from '../actions/live.casino';
import {
    resetPlayState,
} from '../actions/sports';

function* openMinigame(action) {
    try {
        let params = {
            brandID: 5,
            isMobile: 1,
            gameType: 'minigame',
            lang: getLanguage(),
            mode: 0,
            gameID: 0,
            integrationID: 30,
            productID: 11,
            providerID: 30,
            hashid: ''
        };

        if (action.isLogin) {
            const playerState = yield select(state => state.player);

            if(playerState.playerInfo !== null) {
                params = {
                    ...params,
                    currency: playerState.playerInfo.player.currency.Abbreviation,
                    mode: 1,
                    launchType: 'html5',
                    playerID: playerState.playerInfo.player.PlayerID,
                    username: playerState.playerInfo.username
                };
            }
        }

        const request = yield call(sportSvc.openSports, params);
        yield put(openMinigameSuccess(request.data.game_url));
    } catch(err) {
        const { status } = err.response || {};

        if (status !== 200) {  
            yield put(resetOpenCasinoError())
            yield put(resetOpenLiveCasinoError())
            yield put(resetPlayState())
            yield put(openMinigameError(err.response.data.errors));
            yield put(showGameMessage());
        }
    }
}

export default function* miniGameWatcher() {
    yield all([takeLatest(OPEN_MINIGAME, openMinigame)]);
}
