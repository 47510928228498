const styles = theme => ({
  root: {
    flexDirection: 'column',
    alignItems: 'start',
    display: 'flex',
    paddingBottom: 0,
    paddingTop: 0
  },
  badge: {
    color: theme.palette.text.primary,
    right: localStorage.getItem('i18nextLng') === 'ko-KR' ? '94px' : '110px',
    '& span': {
      background: '#A10000',
      right: '90px',
      top: '-10px'
    }
  },
  list: {
    backgroundColor: theme.palette.background.paper
  },
  listItem: {
    textAlign: 'left',
    textTransform: 'none',
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
    width: '100%',
    minHeight: 48,
    alignItems: 'center',
    boxSizing: 'border-box',
    paddingLeft: 16,
    paddingRight: 14,
    borderRadius: '0 !important',
    // color: '#939393'
    color: theme.palette.text.secondary,
    borderBottomStyle: 'solid',
    borderBottomColor: 'rgb(97 97 97 / 50%)',
    borderBottomWidth: '0.1px'
  },
  listItemActive: {
    backgroundColor: theme.palette.action.hover,
    '& button': {
      color: theme.palette.action.color
    },
    '& svg': {
      fill: '#fff !important'
    }
  },
  vcenter: {
    verticalAlign: 'middle'
  },
  listItemButtonLabel: {
    flexDirection: 'row'
  },
  listItemText: {
    flex: '1 1 100%',
    textTransform: 'none',
  },
  listIcon: {
    // height: 15,
    paddingLeft: 0,
    paddingRight: 16,
    // color: theme.palette.text.default, //default color
  },
  nested: {
    width: '100%',
    '& > div:first-of-type': {
      backgroundColor: '#23353C',
      margin: '0 20px',
      borderRadius: '6px'
    }
  },
  languageImg: {
    borderRadius: '50%',
    width: '25px',
    height: '25px',
    marginRight: '10px'
  }
});

export default styles;
