
export const loadState = () => {
  try {
    const serializedState = localStorage.getItem('session');
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('session', serializedState);
  } catch (err) {
    // ignore write errors
    // console.log(err);
  }
};

export const isLogin = () => {
  try {
    const serializedState = localStorage.getItem('session');
    if (serializedState === null) {
      return false;
    }
    const data = JSON.parse(serializedState);

    return !!((data.token && data.token !== ''));
  } catch (err) {
    return false;
  }
};
