const styles = theme => ({
  error: {
    backgroundColor: '#d32f2f'
  },
  root: {
    color: theme.palette.text.primary,
    '& .success-icon': {
      fill: theme.palette.secondary.main
    },
    '& .notifContainer': {
      display: 'flex',
      '& .bodyText': {
        fontSize: '13px',
        fontWeight: 500
      },
    },
    '& .iconContainer':{
      alignSelf: 'center',
      marginRight: theme.spacing(1),
      '& svg': {
        width: '20px',
        height: '20px',
      },
    }
  },
  success: {
    backgroundColor: theme.palette.text.default
  }
});


export default styles;
