import {
  all,
  call,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';
import {
  SET_WALLET_ERRORS,
  SET_WALLET_LOADING,
  SET_WALLET_RESPONSE_MESSAGE,
  SUBMIT_DEPOSIT,
  SUBMIT_TRON_DEPOSIT,
  SUBMIT_TRANSFER_REQUEST,
  SUBMIT_PLAYER_WITHDRAWAL,
  SUBMIT_P2P_TRANSFER_REQUEST,
  RESEND_SMS,
  GET_DEPOSIT_COUNT,
} from '../constants/wallet';
import walletService from '../utils/services/wallet';
import {
  setErrors,
  setLoading,
  setResponseMessage,
} from '../actions/common';
import {
  getPlayerCurrentTransactions,
  getPlayerInfo,
  setPaymentTronExchangeBtnDisabled
} from '../actions/player';
import {
  setNoBank,
  submitDepositSuccess,
  submitTronDepositSuccess,
  submitTransferSuccess,
  submitWithdrawalSuccess,
  setDepositSuccess,
  setWithdrawalSuccess,
  setDepositCount,
  setDepositError,
} from '../actions/wallet';
import {
  showSessionModal
} from '../actions/session';
import { realAmount } from '../utils/helpers/common';
import { BRAND } from '../config/api';

function* submitDeposit(action) {
  try {
    yield put(setLoading(true, SET_WALLET_LOADING));
    const { params } = action;
    params.amount = realAmount(params.amount);
    params.provider = parseInt(params.provider, 10);

    const request = yield call(
      walletService.submitDeposit,
      action.params,
    );
    const { success } = request.data;

    if (action.showSuccessMessage) {
      yield put(
        setResponseMessage(
          SET_WALLET_RESPONSE_MESSAGE,
          'Bank details has been resent successfully.',
          'success',
          true,
        ),
      );
    } else {
      yield put(setErrors(SET_WALLET_ERRORS));
      yield put(submitDepositSuccess(success));
      yield put(setDepositSuccess(request.data));
    }
  } catch (err) {


    const { errors, message } = err.response.data;
    const { status } = err.response || {};
    yield put(setDepositError(err.response.data));
    if (errors.deposit) {
      yield put(
        setResponseMessage(
          SET_WALLET_RESPONSE_MESSAGE,
          errors.deposit,
          'error',
          true,
        ),
      );
    }

    // if (errors.subscription) {
    //   yield put(
    //     setResponseMessage(
    //       SET_WALLET_RESPONSE_MESSAGE,
    //       errors.subscription,
    //       'error',
    //       true,
    //       status,
    //     ),
    //   );
    // }
  
    // if (errors.player_blacklisted || errors.player_closed) {
    //   yield put(
    //     setResponseMessage(
    //       SET_WALLET_RESPONSE_MESSAGE,
    //       'deposit request failed please contact cs',
    //       'error',
    //       false,
    //     ),
    //   );
    // }
    
    if (errors.chungdam && errors.chungdam.error.depositor) {
      yield put(
        setResponseMessage(
          SET_WALLET_RESPONSE_MESSAGE,
          'please update your profile to proceed deposit',
          'error',
          true,
        ),
      );
    }

    if (errors.chungdam && errors.chungdam.message === 'User has pending request') {
      yield put(
        setResponseMessage(
          SET_WALLET_RESPONSE_MESSAGE,
          'pending deposit',
          'error',
          true,
        ),
      );
    }
 
    if (status === 403) {
      yield put(showSessionModal())
    } else if (status >= 500) {
      yield put(
        setResponseMessage(
          SET_WALLET_RESPONSE_MESSAGE,
          'Error encountered while processing request.',
          'error',
          true,
        ),
      );
    }

    if (message && message.toLowerCase() === 'no bank' && !message.includes('max claim limit')) { //temporary fix no bank and max claim is included in error message, to avoid no bank trigger
      yield put(setNoBank(true));
    } else if (status >= 400 && status < 500) {
      yield put(setErrors(SET_WALLET_ERRORS, {"non_500": ["non_500"]}));
    } else {
      yield put(setErrors(SET_WALLET_ERRORS, errors));
    }
  } finally {
    yield put(setLoading(false, SET_WALLET_LOADING));
  }
}

function* submitTronDeposit(action) {
  try {
    yield put(setLoading(true, SET_WALLET_LOADING));
    const { params } = action;
    params.amount = realAmount(params.amount);

    const request = yield call(
      walletService.submitTronDeposit,
      action.params,
    );
    const { success } = request.data;

    yield put(setErrors(SET_WALLET_ERRORS));
    yield put(submitTronDepositSuccess(success));
  } catch (err) {
    const { errors, message } = err.response.data;
    const { status } = err.response || {};

    if (errors.deposit) {
      yield put(
        setResponseMessage(
          SET_WALLET_RESPONSE_MESSAGE,
          errors.deposit,
          'error',
          true,
        ),
      );
    }

    if (errors.player_blacklisted) {
      yield put(
        setResponseMessage(
          SET_WALLET_RESPONSE_MESSAGE,
          'deposit request failed please contact cs',
          'error',
          true,
        ),
      );
    }
    
    if (status === 403) {
      yield put(showSessionModal())
    }

    if (message && message.toLowerCase() === 'no bank') {
      // yield put(setNoBank(true));
    } else {
      yield put(setErrors(SET_WALLET_ERRORS, errors));
    }
  } finally {
    yield put(setLoading(false, SET_WALLET_LOADING));
    yield put(setPaymentTronExchangeBtnDisabled(false))
  }
}

function* resendSMS(action) {
  try {
    yield put(setLoading(true, SET_WALLET_LOADING));
    const { params } = action;
    params.amount = realAmount(params.amount);

    const request = yield call(
      walletService.postResend,
      action.params,
    );

    const { success } = request.data;

    if (action.showSuccessMessage) {
      yield put(
        setResponseMessage(
          SET_WALLET_RESPONSE_MESSAGE,
          'Bank details has been resent successfully.',
          'success',
          true,
        ),
      );
    } else {
      yield put(setErrors(SET_WALLET_ERRORS));
      yield put(submitDepositSuccess(success));
    }
  } catch (err) {
    const { errors, message } = err.response.data;
    const { status } = err.response || {};

     if(errors.phone) {
      yield put(
        setResponseMessage(
          SET_WALLET_RESPONSE_MESSAGE,
          'sms code unsuccessful',
          'error',
          true,
        ),
      );
    }

    if (status === 403) {
      yield put(showSessionModal())
    }

    if (message && message.toLowerCase() === 'no bank') {
      yield put(setNoBank(true));
    } else {
      yield put(setErrors(SET_WALLET_ERRORS, errors));
    }
  } finally {
    yield put(setLoading(false, SET_WALLET_LOADING));
  }
}

function* submitTransferRequest(action) {
  try {
    yield put(setLoading(true, SET_WALLET_LOADING));
    const playerState = yield select(state => state.player);
    const { playerInfo } = playerState;
    const { player, username } = playerInfo;
    const { currency } = player;
    const { params, toMainWallet, all } = action;
    const { amount, provider, subscription } = params;
    const transferParams = {
      params: {
        amount: realAmount(amount),
        brand: BRAND,
        currency: currency.Abbreviation,
        provider,
        username,
      },
      toMainWallet,
      all
    };

    if (subscription) {
      transferParams.params = {
        ...transferParams.params,
        subscription,
      };
    }

    const request = yield call(
      walletService.transfer,
      transferParams,
    );
    const { success } = request.data;

    yield put(getPlayerCurrentTransactions());
    yield put(getPlayerInfo());
    yield put(submitTransferSuccess(success));
  } catch (err) {
    const { errors } = err.response.data;
    const { status } = err.response || {};

    if (status === 403) {
      yield put(showSessionModal())
    }

    yield put(setErrors(SET_WALLET_ERRORS, errors));
  } finally {
    yield put(setLoading(false, SET_WALLET_LOADING));
  }
}

function* submitP2PTransferRequest(action) {
  try {
    yield put(setLoading(true, SET_WALLET_LOADING));
    const playerState = yield select(state => state.player);
    const { playerInfo } = playerState;
    const { player, username } = playerInfo;
    const { currency } = player;
    const { params, toMainWallet, all } = action;
    const { amount, from, to } = params;

    const transferParams = {
      params: {
        amount: realAmount(amount),
        brand: BRAND,
        currency: currency.Abbreviation,
        username,
        providerTo:  to,
        providerFrom: from
      }
    };

    let request = yield call(
      walletService.transfer,
      {
        params: {
          ...transferParams.params
        },
        toMainWallet: false,
        all: false
      }
    );

    const { success } = request.data;

    yield put(getPlayerCurrentTransactions());
    yield put(getPlayerInfo());
    yield put(submitTransferSuccess(success));
  } catch (err) {
    let { errors } = err.response.data;
    const { status } = err.response || {};
    //set to generic error if coming from other api
    if(errors && errors.amount && typeof errors.amount === 'string'){
      errors = { errors: 'something went wrong' };
    }

    if (status === 403) {
      yield put(showSessionModal())
    }

    yield put(setErrors(SET_WALLET_ERRORS, errors));
  } finally {
    yield put(setLoading(false, SET_WALLET_LOADING));
  }
}

function* submitWithdrawal(action) {
  try {
    yield put(setLoading(true, SET_WALLET_LOADING));
    const { params } = action;
    params.amount = realAmount(params.amount);

    const request = yield call(
      walletService.submitWithdrawal,
      action.params,
    );
    const { success } = request.data;

    yield put(getPlayerInfo());
    yield put(setErrors(SET_WALLET_ERRORS));
    yield put(submitWithdrawalSuccess(success));
    yield put(setWithdrawalSuccess(request.data));
  } catch (err) {
    const { errors } = err.response.data;
    const { status } = err.response || {};

    // if (errors.player_blacklisted) {
    //   yield put(
    //     setResponseMessage(
    //       SET_WALLET_RESPONSE_MESSAGE,
    //       'withdrawal request failed please contact cs',
    //       'error',
    //       true,
    //     ),
    //   );
    // }

    if (status === 403) {
      yield put(showSessionModal())
    }

    if (errors.transaction) {
      yield put(
        setResponseMessage(
          SET_WALLET_RESPONSE_MESSAGE,
          errors.transaction[0],
          'error',
          true,
        ),
      );
    } else {
      yield put(setErrors(SET_WALLET_ERRORS, err.response.data));

    }
  } finally {
    yield put(setLoading(false, SET_WALLET_LOADING));
  }
}

function* fetchDepositCount() {
  try {
    const result = yield call(walletService.getDepositCount);
    yield put(setDepositCount(result.data.depositCount));
  } catch (error) {
    
  }
}

export default function* walletWatcher() {
  yield all([
    takeLatest(SUBMIT_DEPOSIT, submitDeposit),
    takeLatest(SUBMIT_TRON_DEPOSIT, submitTronDeposit),
    takeLatest(SUBMIT_PLAYER_WITHDRAWAL, submitWithdrawal),
    takeLatest(SUBMIT_TRANSFER_REQUEST, submitTransferRequest),
    takeLatest(SUBMIT_P2P_TRANSFER_REQUEST, submitP2PTransferRequest),
    takeLatest(RESEND_SMS, resendSMS),
    takeLatest(GET_DEPOSIT_COUNT, fetchDepositCount)
  ]);
}
