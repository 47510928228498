import { OPENING_BETCONSTRUCT_SUCCESS, OPENING_BETCONSTRUCT_ERROR, SET_INITIAL_BETCONSTRUCT } from '../constants/betconstruct';

const initialState = {
    url: null,
    loading: true,
    success: false,
    errors: []
};

const betConstructReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_INITIAL_BETCONSTRUCT:
            return initialState;
        case OPENING_BETCONSTRUCT_SUCCESS:
            return {
                ...state,
                url: action.url,
                loading: false,
                success: true,
                errors: []
            }
        case OPENING_BETCONSTRUCT_ERROR:
            return {
                ...state,
                errors: action.errors
            }
        default:
            return state;
    }
};

export default betConstructReducer;
