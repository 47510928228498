import {
  all,
  call,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';
import {
  GET_ACTIVE_PROVIDERS,
  GET_BANKS,
  GET_CURRENCIES,
  GET_PROVIDERS,
  GET_PRODUCT_PROVIDERS,
  GET_PROVIDER_PROMOTIONS,
  SET_FAVOURITE,
  SET_FAVOURITE_LOADING,
  GET_GAMES_COMMON,
  GET_PRODUCTS,
  //CHECK_TRANSFER_DIALOG,
  GET_HOME_BANNERS,
  GET_RECENT_GAMES
} from '../constants/common';
import {
  setActiveProviders,
  setBanks,
  setCurrency,
  setCurrencyInfos,
  setLoading,
  setProductProviders,
  setProviderPromotions,
  setProviders,
  setFavouriteSuccess,
  setGames,
  setProducts,
  setArcadeGames,
  setGamesByProvider,
  setHomeBanners,
  setAmountLockedProviders,
  setSharedWallet,
  setSelectFilteredProviders,
  setRecentGames
} from '../actions/common';
import {
  showSessionModal
} from '../actions/session';
import commonSvc from '../utils/services/common';
import { getGamesSuccess, getRecentSuccess } from '../actions/slot.games';

import {
  MainWalletIcon1
} from '../components/custom/joabet.icons';

function* getActiveProviders() {
  try {
    const request = yield call(commonSvc.getActiveProviders);
    yield put(
      setActiveProviders(request.data.map(provider => provider.id)),
    );
  } catch (err) {
    const { status } = err.response || {};

    if (status === 403) {
      yield put(showSessionModal())
    }
    // err.response.data
  }
}

function* getBanks(action) {
  try {
    const { currency } = action;
    const request = yield call(commonSvc.getBanks, { currency });
    const { data } = request.data;

    yield put(setBanks(data));
  } catch (err) {
    const { status } = err.response || {};

    if (status === 403) {
      yield put(showSessionModal())
    }
    // err.response.data
  }
}

function* getCurrencies(action) {  
  try {
    const { includeName } = action;
    const request = yield call(commonSvc.getCurrencies);
    const { currencies } = request.data;

    const abbreviations = currencies.map(item => ({
      name: `${item.Abbreviation} ${
        includeName ? `- ${item.Name}` : ''
      }`,
      value: item.CurrencyID,
      abbr: item.Abbreviation, 
      fullName: item.Name.toLowerCase()
    }));
    const phoneCodes = currencies.map(item => ({
      name: `+${item.country.PhoneCode}`,
      value: item.country.PhoneCode,
    }));

    yield put(setCurrencyInfos(currencies));
    yield put(setCurrency(abbreviations, phoneCodes));
  } catch (err) {
    const { status } = err.response || {};

    if (status === 403) {
      yield put(showSessionModal())
    }
    // console.log(err.response.data);
  }
}

function* getProviders(action) {
  try {
    const { params } = action;
    const request = yield call(commonSvc.getProviders, {
      ...params,
      status: 1,
    });
    const { data } = request.data;
    const amountLockedProviders = data.reduce((list, item) => {
      list[item.ID] = item.HasPendingBets;
      return list;
    }, {});

    yield put(setProviders(data));
    yield put(setAmountLockedProviders(amountLockedProviders));
  } catch (err) {
    const { status } = err.response || {};

    if (status === 403) {
      yield put(showSessionModal())
    }
    // console.log(err);
  }
}

function* populateSelectWallet(providers){
  let holder = providers.map(item => ({
    id: item.value ? item.value.toString() : '',
    name: item.name,
    product_id: item.product_id,
    disabled: item.maintenance,
    isTitle: item.isTitle,
    icon: item.icon,
    value: '0.00',
  }));

  yield put(setSelectFilteredProviders(holder));
}

function* getProductProviders() {
  try {
    const request = yield call(commonSvc.getProductProviders);
    const { data } = request.data;
    const providers = [
      { name: 'main wallet', MainWalletIcon: MainWalletIcon1},
      { name: 'all games wallet',},
    ];

    const productsPerProvider = {};
    data.map(item => {
      const { ID, Name, Providers } = item;

      providers.push({
        disabled: true,
        isTitle: true,
        name: Name,
      });

      Providers.map(prodProvider => {
        productsPerProvider[prodProvider.ID] = Object.keys(productsPerProvider).includes(String(prodProvider.ID)) ? {
          ...productsPerProvider[prodProvider.ID]
        } : {
          products: [],
          name: prodProvider.Name,
        };
        productsPerProvider[prodProvider.ID].products.push(Name.toLowerCase());
        providers.push({
          icon: prodProvider.LogoLight,
          name: prodProvider.Name,
          product_id: ID,
          value: prodProvider.ID,
          maintenance: prodProvider.UnderMaintenance
        });

        return prodProvider;
      });
      return item;
    });

    yield put(setSharedWallet(productsPerProvider));
    yield put(setProductProviders(providers));

    //load initial wallet options for dropdown and for wallet
    //so that its easy to add amount values per provider
    yield* populateSelectWallet(providers);
    } catch (err) {
    const { status } = err.response || {};

    if (status === 403) {
      yield put(showSessionModal())
    }

  }
}

function* getProviderPromotions(action) {
  try {
    const request = yield call(
      commonSvc.getProviderPromotions,
      action.params,
    );
    const { data } = request.data;
    const promotions = [
      { name: 'no thanks', value: '0' },
      ...data.map(item => ({ name: item.DepositTitle, value: item.ID })),
    ];

    yield put(setProviderPromotions(promotions));
  } catch (err) {
    const { status } = err.response || {};

    if (status === 403) {
      yield put(showSessionModal())
    }

  }
}

function* setFavourite(action) {
  try {
    const { params } = action;
    yield put(setLoading(true, SET_FAVOURITE_LOADING));
    yield call(commonSvc.setFavourite, params);

    //const games = (params.product === 8)?yield select(state => state.common.arcadeGames):yield select(state => state.common.games);
    const games = (params.byProvider)?yield select(state => state.common.gamesByProvider[params.product]):yield select(state => state.common.games)

    if (params.game) {
      const index = games.findIndex(item => item.ID === params.game);
      games[index].FavStatus = params.status;
      if(params.byProvider)
      {
        yield put(setGamesByProvider(games, params.product));
      }
      else
      {
        yield put(getGamesSuccess(games));
      }

    } else {
      const { providers } = yield select(state => state.common);
      const index = providers.findIndex(
        item => item.ID === params.provider,
      );

      providers[index].Status = params.status;

      yield put(setProviders(providers, true));
    }

    yield put(
      setFavouriteSuccess(
        `game has been ${
          !params.status ? 'removed from' : 'added to'
        } favourites.`,
        true,
      ),
    );
  } catch (err) {
    const { status } = err.response || {};

    if (status === 403) {
      yield put(showSessionModal())
    }
    // err.response.data
  } finally {
    yield put(setLoading(false, SET_FAVOURITE_LOADING));
  }
}

function* getGames(action) {
  try {
    const { params } = action;
    const request = yield call(commonSvc.getGames, {
      ...params,
      status: 1,
      isMobile: 1
    });

    const { data } = request.data;

    if(params.byProvider)
    {
      yield put(setGamesByProvider(data, params.product));
    }
    else
    {
      yield put(setGames(data));
    }


  } catch (err) {
    const { status } = err.response || {};

    if (status === 403) {
      yield put(showSessionModal())
    }
  }
}

function* getRecentGames(action) {
  try {
    const { params } = action;
    const request = yield call(commonSvc.getRecentGames, {
      ...params,
      status: 1,
      isMobile: 1
    });
    const { data } = request.data;

    yield put(setRecentGames(data));
  } catch (err) {
    const { status } = err.response || {};

    if (status === 403) {
      yield put(showSessionModal())
    }
    // err.response.data
  }
}

function* getProducts(action) {
  try {
    const { params } = action;
    const request = yield call(commonSvc.getProducts, {
      ...params,
      status: 1,
      isMobile: 1
    });
    const { data } = request.data;

    yield put(setProducts(data));
  } catch (err) {
    // console.log(err);
  }
}

function* getHomeBanners(action) {
  try {
    const { params } = action;
    const request = yield call(commonSvc.getHomeBanners, {
      ...params,
      status: 1,
      //isMobile: 1
    });

    const { data } = request.data;
    yield put(setHomeBanners(data));

  } catch (err) {
    // console.log(err);
  }
}

export default function* commonWatcher() {
  yield all([
    takeLatest(GET_ACTIVE_PROVIDERS, getActiveProviders),
    takeLatest(GET_BANKS, getBanks),
    takeLatest(GET_CURRENCIES, getCurrencies),
    takeLatest(GET_PROVIDERS, getProviders),
    takeLatest(GET_PRODUCT_PROVIDERS, getProductProviders),
    takeLatest(GET_PROVIDER_PROMOTIONS, getProviderPromotions),
    takeLatest(SET_FAVOURITE, setFavourite),
    takeLatest(GET_GAMES_COMMON, getGames),
    takeLatest(GET_PRODUCTS, getProducts),
    takeLatest(GET_HOME_BANNERS, getHomeBanners),
    takeLatest(GET_RECENT_GAMES, getRecentGames)
  ]);
}
