import ComingSoonModal from './coming-soon-modal';
import NoticeModal from './notice-modal';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isLogin, popupAlready } from '../../utils/helpers/common';

const useComingSoon = () => {
  // const hostnames = ['localhost', 'joa-mob-test.joa15s.com', 'mfrontend-staging.joa15s.com', 'daebak89.com', 'm.daebak89.com'];
  // const showNotice = hostnames.includes(window.location.hostname)
  const { t } = useTranslation();
  const [openDialog, setOpenDialog] = useState(false);
  // const [openDialogNotice, setOpenDialogNotice] = useState(showNotice);
  // const [openDialogDepositNotice, setDialogDepositNotice] = useState(!showNotice);

  const [openUrgentNotice, setOpenUrgentNotice] = useState(false);
  const [openNoticeOne, setOpenNoticeOne] = useState(false);
  const [openNoticeTwo, setOpenNoticeTwo] = useState(false);
	const [openNoticeThree, setOpenNoticeThree] = useState(false);

  const popupNoticeState = useSelector(state => state.notice);
  const contents  = popupNoticeState.content

  const [urgentContent, setUrgentContent] = useState([]);
  // const [layer, setLayer] = useState([]);
  const [noticeOneContent, setNoticeOneContent] = useState([]);
  const [noticeTwoContent, setNoticeTwoContent] = useState([]);
  const [noticeThreeContent, setNoticeThreeContent] = useState([]);

  const [hasNoticeOne, setHasNoticeOne] = useState(false);
  const [hasNoticeTwo, setHasNoticeTwo] = useState(false);
  const [hasNoticeThree, setHasNoticeThree] = useState(false);

  const close = useCallback(() => {
    setOpenDialog(false);
  });
  const open = useCallback(() => {
    setOpenDialog(true);
  });

  useEffect(() => {
    if (contents.length > 0) {
      let holder = contents;
      if(isLogin()){
        holder = contents.filter(
          content => content.Display === 2
        )
      } else {
        holder = contents.filter(
          content => content.Display === 1
        )
      }
      const urgent = holder.filter(
        content => content.ParentID === 0
      )

      const noticeOne = holder.filter(
        i => i.Order === 0
      )
      const noticeTwo = holder.filter(
        i => i.Order === 1
      )
      const noticeThree = holder.filter(
        i => i.Order === 2
      )

      if (urgent.length > 0) {
        setUrgentContent(urgent[0])
      }

      if (noticeOne.length > 0) {
        setNoticeOneContent(noticeOne[0])
        setHasNoticeOne(true)
      }

      if (noticeTwo.length > 0) {
        setNoticeTwoContent(noticeTwo[0])
        setHasNoticeTwo(true)
      }

      if (noticeThree.length > 0) {
        setNoticeThreeContent(noticeThree[0])
        setHasNoticeThree(true)
      }

      if (urgent.length > 0) {
        setOpenUrgentNotice(true)
      } else if (noticeOne.length > 0) {
        setOpenNoticeOne(true)
      } else if (noticeTwo.length > 0) {
        setOpenNoticeTwo(true)
      } else if (noticeThree.length > 0) {
        setOpenNoticeThree(true)
      } else {
        // if(!popupAlready()){
        //   localStorage.setItem('popupCount', 1)
        // }
      }
    }
   }, [contents]);

  // const closeNotice = useCallback(() => {
  //   setOpenDialogNotice(false);
  //   setDialogDepositNotice(true);
  // });
  // const openNotice = useCallback(() => {
  //   setOpenDialogNotice(true);
  // });
  // const closeDepositNotice = useCallback(() => {
  //   setDialogDepositNotice(false);
  //   if(isLogin() && !popupAlready()){
  //     localStorage.setItem('popupCount', 1)
  //   }
  // });
  // const openDepositNotice = useCallback(() => {
  //   setDialogDepositNotice(true);
  // });

  const closeUrgentNotice = useCallback(() => {
    setOpenUrgentNotice(false);

    if (hasNoticeOne) {
      setOpenNoticeOne(true)
    } else if (hasNoticeTwo) {
      setOpenNoticeTwo(true)
    } else if (hasNoticeThree) {
      setOpenNoticeThree(true)
    } else {
      // if(isLogin() && !popupAlready()){
      //   localStorage.setItem('popupCount', 1)
      // }
    }
    
  });

  const showUrgentNotice = useCallback(() => {
    setOpenUrgentNotice(true);
  });

  const closeNoticeOne= useCallback(() => {
    setOpenNoticeOne(false);

    if (hasNoticeTwo) {
      setOpenNoticeTwo(true)
    } else if (hasNoticeThree) {
      setOpenNoticeThree(true)
    } else {
      // if(isLogin() && !popupAlready()){
      //   localStorage.setItem('popupCount', 1)
      // }
    }
  });

  const closeNoticeTwo= useCallback(() => {
    setOpenNoticeTwo(false);

    if (hasNoticeThree) {
      setOpenNoticeThree(true)
    } else {
      // if(isLogin() && !popupAlready()){
      //   localStorage.setItem('popupCount', 1)
      // }
    }
  });

  const closeNoticeThree= useCallback(() => {
    setOpenNoticeThree(false);
    // if(isLogin() && !popupAlready()){
    //   localStorage.setItem('popupCount', 1)
    // }
  });

  return {
    ComingSoonModal: (
      <ComingSoonModal openDialog={openDialog} close={close} />
    ),
    ComingSoonPartnerModal: (
      <ComingSoonModal
        openDialog={openDialog}
        close={close}
        title={t('partners coming soon title')}
        body={t('partners coming soon text')}
      />
    ),
    ComingSoonESportsModal: (
      <ComingSoonModal
        openDialog={openDialog}
        close={close}
        title={t('esports coming soon title')}
        body={t('esports coming soon text')}
      />
    ),
    UrgentNotice: (
      <NoticeModal
        templateName={'notice'}
        openDialog={openUrgentNotice}
        close={closeUrgentNotice}
        title={t('notice title')}
        body={t('notice body')}
        enableShowOnce={true}
        content={urgentContent}
      />
    ),
    NoticeOne: (
      <NoticeModal
        templateName={'second-notice'}
        openDialog={openNoticeOne}
        close={closeNoticeOne}
        title={t('deposit notice title')}
        body={t('deposit notice body')}
        enableShowOnce={true}
        content={noticeOneContent}
      />
   ),
    NoticeTwo: (
      <NoticeModal
        templateName={'notice'}
        openDialog={openNoticeTwo}
        close={closeNoticeTwo}
        title={t('deposit notice title')}
        body={t('deposit notice body')}
        enableShowOnce={true}
        content={noticeTwoContent}
      />
    ),
    NoticeThree: (
      <NoticeModal
        templateName={'second-notice'}
        openDialog={openNoticeThree}
        close={closeNoticeThree}
        title={t('deposit notice title')}
        body={t('deposit notice body')}
        enableShowOnce={true}
        content={noticeThreeContent}
      />
    ),
    openComingSoonDialog: open,
    // openNotice: openNotice,
  };
};

export default useComingSoon;
