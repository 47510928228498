import {
  LOGIN_USER,
  LOGOUT_USER,
  SET_USER_INFO,
  SET_USER_LOGIN_ERROR,
  SET_AUTHENTICATION,
  KICK_USER,
} from '../constants/authentication';

const logoutUser = () => ({
  type: LOGOUT_USER,
});

const validateUser = payload => ({
  type: LOGIN_USER,
  payload,
});

const setLoginSuccess = data => ({
  type: SET_USER_INFO,
  data,
});

const setLoginError = errors => ({
  type: SET_USER_LOGIN_ERROR,
  errors,
});

const setAuthentication = status => ({
  type: SET_AUTHENTICATION,
  status,
});

const kickUser = () => ({
  type: KICK_USER,
});

export {
  logoutUser,
  setAuthentication,
  setLoginError,
  setLoginSuccess,
  validateUser,
  kickUser
};
