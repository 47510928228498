import API from './index';

const walletService = {
  transfer: ({ params, toMainWallet, all }) => {
    let endpoint = '';
    if(all){
      endpoint = 'transferAll';
      delete params.provider;
    }else{
      endpoint = 'transfer';
    }
    return new API().post(`/${endpoint}`, params)
  },
  transferAll: ({ params }) => new API().post(`/transferAll`, params),
  submitDeposit: params => new API().post('/deposit', params),
  submitTronDeposit: params => new API().post('/tron/deposit', params),
  submitWithdrawal: params => new API().post('/withdrawal', params),
  postResend: params => new API().post('/resend', params),
  getDepositCount: () => new API().get('/checkDepositCount'),
};

export default walletService;
