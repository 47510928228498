import { all } from 'redux-saga/effects';
import commonWatcher from './common';
import authenticationWatcher from './authentication';
import liveCasinoWatcher from './live.casino';
import partnersWatcher from './partners';
import playerWatcher from './player';
import promotionsWatcher from './promotions';
import registerWatcher from './register';
import sessionWatcher from './session';
import slotGamesWatcher from './slot.games';
import walletWatcher from './wallet';
import sportsWatcher from './sports';
import pageWatcher from './page';
import rebateWatcher from './rebate';
import socketWatcher from './socket';
import homeWatcher from './home';
import popupNoticeWatcher from './popup.notice';
import miniGameWatcher from './minigame';
import betConstructWatcher from './betconstruct';
import gameWatcher from './game';


export default function* rootSaga() {
  yield all([
    commonWatcher(),
    authenticationWatcher(),
    partnersWatcher(),
    playerWatcher(),
    promotionsWatcher(),
    liveCasinoWatcher(),
    registerWatcher(),
    sessionWatcher(),
    slotGamesWatcher(),
    sportsWatcher(),
    walletWatcher(),
    pageWatcher(),
    rebateWatcher(),
    socketWatcher(),
    homeWatcher(),
    popupNoticeWatcher(),
    miniGameWatcher(),
    betConstructWatcher(),
    gameWatcher()
  ]);
}
