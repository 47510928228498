// SESSIONS
export const GET_SESSIONS = 'GET_SESSIONS';
export const SET_SESSIONS = 'SET_SESSIONS';
export const SAVE_LOGIN_SESSIONS = 'SAVE_LOGIN_SESSIONS';
export const CLEAR_SESSIONS = 'CLEAR_SESSIONS';
export const CHECK_SESSIONS = 'CHECK_SESSIONS';
export const SHOW_SESSION_MODAL = 'SHOW_SESSION_MODAL';
export const HIDE_SESSION_MODAL = 'HIDE_SESSION_MODAL';

//SESSION_STATUS
export const INITIAL_SESSION_STATUS = 'INITIAL_SESSION_STATUS';
export const UNAUTHORIZED_SESSION_STATUS= 'UNAUTHORIZED_SESSION_STATUS';
export const AUTHORIZED_SESSION_STATUS = 'AUTHORIZED_SESSION_STATUS';

// TRANSACTIONS

