import API from './index';

const homeSvc = {
    getWithdrawalSummary: () => new API().get('/landing/withdrawals'),
    getWinnerListSummary: () => new API().get('/landing/winnerList'),
    getWinnerListMonthly: () => new API().get('/landing/winnerList30Days'),
    getHomePageCarousel:  params => new API().get('/homePageCarousel',params),
    getHomePageCarouselSetting:  () => new API().get('/homePageCarouselSetting'),
};

export default homeSvc;
