import { all, call, put, takeLatest } from 'redux-saga/effects';
import { GET_PROMOTIONS, GET_PROMOTIONS_CATEGORY } from '../constants/promotions';
import { setPromotions, setPromotionsCategory } from '../actions/promotions';

import promotionsSvc from '../utils/services/promotions';
import { getLanguage } from '../utils/helpers/common';

function* getPromotions() {
  try {
    const request = yield call(promotionsSvc.getPromotions, {
      application: 1,
      lang: getLanguage(),
    });
    const { data } = request;

    yield put(setPromotions(data));
  } catch (err) {
    //err.response.data
  }
}

function* getPromotionsCategory() {
  try {
    const request = yield call(promotionsSvc.getPromotionsCategory);
    const { data } = request;

    yield put(setPromotionsCategory(data));
  } catch (err) {
    //err.response.data
  }
}

export default function* promotionsWatcher() {
  yield all([takeLatest(GET_PROMOTIONS, getPromotions)]);
  yield all([takeLatest(GET_PROMOTIONS_CATEGORY, getPromotionsCategory)]);
}
