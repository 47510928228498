import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ROOT_URL } from '../../config/api';
import PropTypes from 'prop-types';

export function usePageContent({templateName}){
	const [state, setState] = useState({
    content: '',
  });
  const { i18n } = useTranslation();

	const getContent = useCallback(async () => {
		const lang = i18n.language.slice(0, 2);
		const response = await fetch(
			`${ROOT_URL}/getPage?slug=${templateName}&lang=${lang}`,
		);
		const data = await response.json();
		const content = data.content;
		setState({ content });
	});

	useEffect(() => {
		if (templateName !== '') getContent();
	}, []);

	return {
		pageState: state
	}
};

usePageContent.defaultProps = {
	templateName: ''
};

usePageContent.propTypes = {
  templateName: PropTypes.string,
};