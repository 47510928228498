const styles = theme => ({
  wallet: {
    margin: 0,
    fontSize: '0.875em',
    fontWeight: 600,
    color: theme.palette.text.secondary,
    //textTransform: 'uppercase',
    marginBottom: '2px'
  },
  walletContainer: {
    display: 'flex',
    paddingTop: '1em',
    paddingBottom: '1em',
    width: '100%'
  },
  walletIcon: {
    height: '1.3em',
    paddingRight: '16px',
    fill: theme.palette.secondary.dark
  },
  balance: {
    margin: 0,
    fontWeight: 600,
  //  marginBottom: theme.spacing(1),
    fontSize: '14px',
    color: theme.palette.secondary.dark
  },
  hr: {
    borderBottomColor: 'rgb(97 97 97 / 50%)',
    borderBottomStyle: 'solid',
    borderBottomWidth: '0.1px',
    width: '100%',
   // marginTop: '0.5em'
  },
  plus: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(2),
    position: 'absolute',
    right: 0,
    top: 0
  },
  list: {
    paddingTop: 0
  },
  listItem: {
    textAlign: 'left'
  },
  drawerHeader: theme.mixins.toolbar,
  nested: {
    paddingLeft: theme.spacing(),
    textAlign: 'left'
  },
  contentWrapper: {
    width: '285px',
    backgroundColor: theme.palette.background.default,
    position: 'relative',
    overflow: 'hidden',
    flex: '1 1 100%',
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    '& .initial-wallet-details': {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      textDecoration: 'none',
      background: theme.palette.primary.dark1,
      minHeight: '48px',
    }
  },
  content: {
    width: '100%',
    minWidth: '100%',
    height: '100%',
    minHeight: '100px',
    margin: '0',
    overflowY: 'auto',
    '-ms-overflow-style': 'none',
    '&::-webkit-scrollbar': {
      width: '0'
    }
  },
  skeleton: {
    marginTop: 0,
    marginBottom: theme.spacing(1)
  },
  toolbarContainer: {
    flexDirection: 'row',
    boxSizing: 'border-box',
    display: 'flex',
    padding: '0 16px',
    position: 'relative'
  }

});

export default styles;
