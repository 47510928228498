import {
  REFRESH_WALLET,
  SET_NO_BANK,
  SUBMIT_DEPOSIT,
  SUBMIT_DEPOSIT_SUCCESS,
  SUBMIT_TRON_DEPOSIT_SUCCESS,
  SUBMIT_TRANSFER_REQUEST,
  SUBMIT_TRANSFER_REQUEST_SUCCESS,
  SUBMIT_WITHDRAWAL_SUCCESS,
  SUBMIT_PLAYER_WITHDRAWAL,
  SUBMIT_P2P_TRANSFER_REQUEST,
  RESEND_SMS,
  SUBMIT_TRON_DEPOSIT,
  SET_DEPOSIT_SUCCESS,
  SET_WITHDRAWAL_SUCCESS,
  GET_DEPOSIT_COUNT,
  SET_DEPOSIT_COUNT,
  SET_DEPOSIT_ERROR,
} from '../constants/wallet';

export const submitPlayerDeposit = (
  params,
  showSuccessMessage = false,
) => ({
  type: SUBMIT_DEPOSIT,
  params,
  showSuccessMessage,
});

export const resendSMS = (
  params,
  showSuccessMessage = false,
) => ({
  type: RESEND_SMS,
  params,
  showSuccessMessage,
});

export const refreshWallet = refresh => ({
  type: REFRESH_WALLET,
  refresh,
});

export const setNoBank = (status = false) => ({
  type: SET_NO_BANK,
  status,
});

export const submitDepositSuccess = (isSuccess = false) => ({
  type: SUBMIT_DEPOSIT_SUCCESS,
  isSuccess,
});

export const setDepositSuccess = (params) => ({
  type: SET_DEPOSIT_SUCCESS,
  params,
});

export const submitTronDepositSuccess = (isSuccess = false) => ({
  type: SUBMIT_TRON_DEPOSIT_SUCCESS,
  isSuccess,
});

export const submitTransferRequest = (params, toMainWallet, all) => ({
  type: SUBMIT_TRANSFER_REQUEST,
  params,
  toMainWallet,
  all
});

export const submitP2PTransferRequest = (params, toMainWallet, all) => ({
  type: SUBMIT_P2P_TRANSFER_REQUEST,
  params
});

export const submitTransferSuccess = (isSuccess = false) => ({
  type: SUBMIT_TRANSFER_REQUEST_SUCCESS,
  isSuccess,
});

export const submitWithdrawalSuccess = (isSuccess = false) => ({
  type: SUBMIT_WITHDRAWAL_SUCCESS,
  isSuccess,
});

export const submitPlayerWithdrawal = params => ({
  type: SUBMIT_PLAYER_WITHDRAWAL,
  params,
});

export const setWithdrawalSuccess = (params) => ({
  type: SET_WITHDRAWAL_SUCCESS,
  params,
});

export const submitPlayerTronDeposit = (params) => ({
  type: SUBMIT_TRON_DEPOSIT,
  params,
});

export const getDepositCount = () => ({
  type: GET_DEPOSIT_COUNT
});

export const setDepositCount = (payload) => ({
  type: SET_DEPOSIT_COUNT,
  payload
});

export const setDepositError = (payload) => ({
  type: SET_DEPOSIT_ERROR,
  payload
});