const styles = theme => ({
    root: {
        width: '100%',
        '& .wallet-container': {
            display: 'flex',
            // paddingTop: '1em',
            // paddingBottom: '1em',
            alignItems: 'center',
            display: 'flex',
            //paddingLeft: theme.spacing(2),
            //paddingRight: theme.spacing(2),
            textDecoration: 'none',
            background: '#23353C',
            padding: '15px',
            borderRadius: '6px',
            '& .wallet': {
                margin: 0,
                fontSize: '0.875em',
                //fontWeight: 600,
                color: theme.palette.text.primary,
                marginBottom: '2px',
                opacity: '0.6'
            },
            '& .walletIcon': {
                height: '1.3em',
                paddingRight: '16px',
                fill: theme.palette.secondary.dark
            },
            '& .balance': {
                margin: 0,
                fontWeight: "bold",
                fontSize: '1em',
                color: theme.palette.secondary.dark,
                textAlign: 'right'
            },
        }
    }
})

export default styles;