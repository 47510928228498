import {
  all,
  call,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';
import {
  GET_REBATE_BALANCE,
  GET_REBATE_MINIMUM,
  SUBMIT_REBATE_TRANSFER_REQUEST,
  GET_PLAYER_REBATE_TRANSACTIONS,
  LOAD_MORE_REBATE_TRANSACTIONS,
  UPDATE_REBATE_BALANCE,
} from '../constants/rebate';
import {
  SET_WALLET_ERRORS,
  SET_WALLET_LOADING
} from '../constants/wallet';
import {
  setRebateBalance ,
  submitRebateTransferSuccess,
  setPlayerRebateTransactions,
  setMoreRebateTransactions,
  setRebateMinimum
} from '../actions/rebate';
import {
  getPlayerCurrentTransactions,
  getPlayerInfo,
} from '../actions/player';
import {
  showSessionModal
} from '../actions/session';
import { showFilter } from '../actions/filter';
import {
  setErrors,
  setLoading,
} from '../actions/common';
import { realAmount } from '../utils/helpers/common';
import { BRAND } from '../config/api';
import rebateSvc from '../utils/services/rebate';
import { SUCCESS_REQUEST_STATUS } from '../constants/common';

function* getRebateBalance(action) {
  try {
    const request = yield call(
      rebateSvc.getRebateBalance,
      action.params,
    );

    yield put(setRebateBalance(request));
  } catch (err) {
    const { errors } = err.response.data;
    const { status } = err.response || {};

    if (status === 403) {
      yield put(showSessionModal())
    }
  }
}

function* updateRebateBalance(action) {
  const { balance } = action.params;
  const params = {
    data: {
      balance,
    },
  };

  try {
    yield put(setRebateBalance(params));
  } catch (err) {
    console.log(err);
  }
}

function* getRebateMinimum(action) {
  try {
    const request = yield call(rebateSvc.getRebateMinimum);
    const { rebateMinAmount } = request.data;
    yield put(setRebateMinimum(rebateMinAmount));
  } catch (err) {
    const { status } = err.response || {};

    if (status === 403) {
      yield put(showSessionModal())
    }
  }
}

function* submitRebateTransferRequest(action) {
  try {
    yield put(setLoading(true, SET_WALLET_LOADING));
    const playerState = yield select(state => state.player);
    const { playerInfo } = playerState;
    const { player, username } = playerInfo;
    const { currency } = player;
    const { params, } = action;
    const { amount, provider, } = params;
    const transferParams = {
        amount: realAmount(amount),
        brand: BRAND,
        currency: currency.Abbreviation,
        provider,
        username,
    };

    const request = yield call(
      rebateSvc.transfer,
      transferParams,
    );
    const { success } = request.data;

    yield put(getPlayerCurrentTransactions());
    yield put(getPlayerInfo());
    yield put(submitRebateTransferSuccess(success));
  } catch (err) {
    const { errors } = err.response.data;
    const { status } = err.response || {};

    if (status === 403) {
      yield put(showSessionModal())
    }

    yield put(setErrors(SET_WALLET_ERRORS, errors));
  } finally {
    yield put(setLoading(false, SET_WALLET_LOADING));
  }
}

function* getPlayerRebateTransactions(action) {
  try {
    const request = yield call(
      rebateSvc.transactions,
      action.params,
    );
    const { data, meta } = request.data;

    yield put(setPlayerRebateTransactions(data, meta.last_page));
  } catch (err) {
    const { status } = err.response || {};

    if (status === 403) {
      yield put(showSessionModal())
    }
  } finally {
    yield put(setLoading(false));
    yield put(showFilter(false));
  }
}

function* loadMoreRebateTransactions(action) {
  try {
    const request = yield call(
      rebateSvc.transactions,
      action.params,
    );
    const { data } = request.data;

    yield put(setMoreRebateTransactions(data));
  } catch (err) {
    const { status } = err.response || {};

    if (status === 403) {
      yield put(showSessionModal())
    }
  } finally {
    yield put(setLoading(false));
  }
}

export default function* rebateWatcher() {
  yield all([
    takeLatest(GET_REBATE_BALANCE, getRebateBalance),
    takeLatest(SUBMIT_REBATE_TRANSFER_REQUEST, submitRebateTransferRequest),
    takeLatest(GET_PLAYER_REBATE_TRANSACTIONS, getPlayerRebateTransactions),
    takeLatest(LOAD_MORE_REBATE_TRANSACTIONS, loadMoreRebateTransactions),
    takeLatest(GET_REBATE_MINIMUM, getRebateMinimum),
    takeLatest(UPDATE_REBATE_BALANCE, updateRebateBalance),
  ]);
}