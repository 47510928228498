import {
  SET_AUTHENTICATION,
  SET_USER_INFO,
  SET_USER_LOGIN_ERROR,
} from '../constants/authentication';
import { SET_LOADING } from '../constants/common';
import { setDetails } from '../utils/helpers/common';

// const portalData = JSON.parse(sessionStorage.getItem('portalData'));

const initialState = {
  errors: {},
  isAuthenticated: false,
  showLoading: false,
};

const authenticationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_AUTHENTICATION:
      return {
        ...state,
        isAuthenticated: action.status,
      };
    case SET_LOADING:
      return {
        ...state,
        showLoading: action.show,
      };
    case SET_USER_INFO:
      setDetails('token', action.data);
      return {
        ...state,
        isAuthenticated: true,
      };
    case SET_USER_LOGIN_ERROR:
      return {
        ...state,
        errors: action.errors,
        isAuthenticated: false,
      };
    default:
      return state;
  }
};

export default authenticationReducer;
