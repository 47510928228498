import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const SideMenuTotalBalanceIcon = props => (
  <SvgIcon width="27" height="25" viewBox="0 0 27 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
  <path d="M18.4951 14.7328C19.0811 14.7328 19.6027 14.5042 20.0598 14.0471C20.5169 13.59 20.7454 13.0684 20.7454 12.4824C20.7454 11.8964 20.5169 11.3807 20.0598 10.9353C19.6027 10.4899 19.0811 10.2672 18.4951 10.2672C17.9325 10.2672 17.4226 10.4899 16.9655 10.9353C16.5084 11.3807 16.2799 11.8964 16.2799 12.4824C16.2799 13.0684 16.5084 13.59 16.9655 14.0471C17.4226 14.5042 17.9325 14.7328 18.4951 14.7328ZM2.03938 25C1.52368 25 1.05485 24.8008 0.632911 24.4023C0.210971 24.0038 0 23.5349 0 22.9958V2.03938C0 1.52368 0.210971 1.05485 0.632911 0.632911C1.05485 0.210971 1.52368 0 2.03938 0H22.9606C23.4998 0 23.9744 0.210971 24.3847 0.632911C24.7949 1.05485 25 1.52368 25 2.03938V6.78622H22.9606V2.03938H2.03938V22.9958H22.9606V18.2489H25V22.9958C25 23.5349 24.7949 24.0038 24.3847 24.4023C23.9744 24.8008 23.4998 25 22.9606 25H2.03938ZM14.557 18.9522C13.7834 18.9522 13.1681 18.7236 12.711 18.2665C12.2539 17.8094 12.0253 17.2058 12.0253 16.4557V8.61463C12.0253 7.81763 12.2539 7.19644 12.711 6.75106C13.1681 6.30567 13.7834 6.08298 14.557 6.08298H23.91C24.6835 6.08298 25.2989 6.30567 25.756 6.75106C26.2131 7.19644 26.4416 7.81763 26.4416 8.61463V16.4557C26.4416 17.2058 26.2131 17.8094 25.756 18.2665C25.2989 18.7236 24.6835 18.9522 23.91 18.9522H14.557ZM24.4374 16.948V8.12236H14.0295V16.948H24.4374Z" fill={props.fill === undefined ? '#A5ABAD' : props.fill} />
  </SvgIcon>
  
)

const SideMenuTransactionHistory = props => (
<SvgIcon width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<path d="M11 5.42857V10.1743C11 10.3567 11.073 10.5263 11.202 10.6536L14.6437 14.0507L15.6148 13.0922L12.375 9.89442V5.42857H11Z" fill="#A5ABAD"/>
<path d="M12.375 0C7.53672 0 3.53203 3.54554 2.86172 8.14286H0L3.4375 11.5357L6.875 8.14286H4.24961C4.90703 4.29621 8.29297 1.35714 12.375 1.35714C16.9254 1.35714 20.625 5.00871 20.625 9.5C20.625 13.9913 16.9254 17.6429 12.375 17.6429C9.81836 17.6429 7.44648 16.502 5.86953 14.5087L4.78672 15.3442C6.62578 17.6683 9.39297 19 12.375 19C17.6816 19 22 14.7377 22 9.5C22 4.26228 17.6816 0 12.375 0Z" fill="#A5ABAD"/>
</SvgIcon>
);

const SideMenuBankDetails = props => (
  <SvgIcon width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
  <path d="M3.75 20V11.25H6.25V20H3.75ZM11.25 20V11.25H13.75V20H11.25ZM0 25V22.5H25V25H0ZM18.75 20V11.25H21.25V20H18.75ZM0 8.75V6.25L12.5 0L25 6.25V8.75H0ZM5.5625 6.25H19.4375L12.5 2.8125L5.5625 6.25Z" fill="#A5ABAD"/>
  </SvgIcon>
);

const SideMenuBonus = props => (
  <SvgIcon width="24" height="14" viewBox="0 0 24 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
  <path d="M15.1125 7.91997C14.8453 7.91997 14.5781 8.1568 14.5781 8.9447C14.5781 9.73715 14.8453 9.96942 15.1125 9.96942C15.3797 9.96942 15.6469 9.7326 15.6469 8.9447C15.6469 8.1568 15.3797 7.91997 15.1125 7.91997Z" fill="#A5ABAD"/>
  <path d="M8.8875 4.02602C8.62031 4.02602 8.35312 4.26285 8.35312 5.05075C8.35312 5.8432 8.62031 6.07547 8.8875 6.07547C9.15469 6.07547 9.42188 5.83865 9.42188 5.05075C9.42188 4.26285 9.15469 4.02602 8.8875 4.02602Z" fill="#A5ABAD"/>
  <path d="M24 6.31685V2.23162H23.2969C22.4203 2.23162 21.7031 1.53481 21.7031 0.683149V0H2.29688V0.683149C2.29688 1.53481 1.57969 2.23162 0.703125 2.23162H0V6.31685H2.00156V7.68315H0V11.7684H0.703125C1.57969 11.7684 2.29688 12.4652 2.29688 13.3169V14H21.7031V13.3169C21.7031 12.4652 22.4203 11.7684 23.2969 11.7684H24V7.68315H21.9984V6.31685H24ZM6.62812 5.05075C6.62812 3.58881 7.59844 2.75081 8.8875 2.75081C10.1766 2.75081 11.1469 3.58881 11.1469 5.05075C11.1469 6.52635 10.1766 7.35068 8.8875 7.35068C7.59844 7.35068 6.62812 6.52635 6.62812 5.05075ZM8.07187 11.1262L13.875 2.86923H15.9281L10.125 11.1262H8.07187ZM15.1125 11.2446C13.8234 11.2446 12.8531 10.4203 12.8531 8.9447C12.8531 7.48276 13.8234 6.64476 15.1125 6.64476C16.4016 6.64476 17.3719 7.48276 17.3719 8.9447C17.3719 10.4203 16.4016 11.2446 15.1125 11.2446Z" fill="#A5ABAD"/>
  </SvgIcon>
);

const SideMenuRebate = props => (
  <SvgIcon viewBox="0 0 25 25" {...props}>
    {/* eslint-disable-next-line */}
    <path d="M0.132901 15.706C0.584592 18.6097 2.14616 21.165 4.52076 22.8943C6.41787 24.2752 8.66342 25.0108 10.9735 25.0108C11.5542 25.0108 12.1221 24.9721 12.7028 24.8689C18.691 23.9268 22.8078 18.2871 21.8657 12.286C21.414 9.38228 19.8525 6.827 17.4779 5.09766C15.4388 3.61353 12.9997 2.89083 10.5089 3.00698L12.3415 1.26474L11.1413 0L8.32788 2.68434L7.06314 3.88455L8.26335 5.14929L10.9477 7.96268L12.2124 6.76247L10.5089 4.96861C12.5867 4.85246 14.6258 5.44611 16.3293 6.68504C18.278 8.10464 19.5686 10.2082 19.9428 12.5828C20.7171 17.5127 17.3359 22.1458 12.4189 22.9201C10.0314 23.2944 7.64389 22.7136 5.69516 21.294C3.74643 19.8744 2.45589 17.7708 2.08163 15.3962C1.56541 12.1441 2.86886 8.86606 5.46286 6.8399L4.26265 5.29125C1.08791 7.7691 -0.486562 11.7569 0.132901 15.706Z" />
    <path d="M12.0834 19.6163V18.4806C13.4255 18.3258 14.729 17.4869 14.729 15.8737C14.729 13.6927 12.7028 13.4346 11.2187 13.2539C10.2766 13.1378 9.54099 13.0087 9.54099 12.4409C9.54099 11.6407 10.6638 11.5504 11.1542 11.5504C11.8769 11.5504 12.6383 11.8859 12.8964 12.3247L12.9739 12.4538L14.458 11.7698L14.3805 11.6149C13.8256 10.4792 12.8319 10.1566 12.0834 10.0147V9.02093H10.3411V10.0147C8.71504 10.2599 7.74713 11.1503 7.74713 12.4409C7.74713 14.5445 9.65714 14.751 11.0509 14.9187C12.2899 15.0607 12.8706 15.3704 12.8706 15.8866C12.8706 16.8675 11.5155 16.9449 11.1026 16.9449C10.1863 16.9449 9.29579 16.4803 9.03768 15.8737L8.97315 15.7189L7.35997 16.39L7.4245 16.5448C7.902 17.6676 8.93444 18.3645 10.3411 18.5323V19.6034H12.0834V19.6163Z" />
   </SvgIcon>
);

const SideMenuProfile = props => (
  <SvgIcon width="22" height="27" viewBox="0 0 22 27" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
  <path d="M22 24.306C21.9437 20.7502 20.7934 17.5199 18.7491 15.1754C16.7172 12.8491 13.9665 11.5654 10.9969 11.5654C8.02728 11.5654 5.2765 12.8491 3.24467 15.1754C1.20659 17.5199 0.0625178 20.7442 0 24.294C1.15658 24.8484 5.92668 27 11.0031 27C16.4859 27 20.9309 24.8665 22 24.306Z" fill="#A5ABAD"/>
  <path d="M11.0031 10.6071C14.0416 10.6071 16.5047 8.23265 16.5047 5.30357C16.5047 2.37449 14.0416 0 11.0031 0C7.9647 0 5.50156 2.37449 5.50156 5.30357C5.50156 8.23265 7.9647 10.6071 11.0031 10.6071Z" fill="#A5ABAD"/>
  </SvgIcon>
);

const SideMenuMessage = props => (
<svg width="24" height="16" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<path d="M22.7627 0H1.23257C0.553017 0 0 0.553017 0 1.23257V14.7674C0 15.4423 0.553017 16 1.23257 16H22.7674C23.4423 16 24 15.447 24 14.7674V1.23257C23.9953 0.553017 23.4423 0 22.7627 0ZM22.3035 0.923257L12.7053 8.12185C12.5319 8.25308 12.2695 8.33743 11.9977 8.33275C11.7258 8.33275 11.4634 8.25308 11.29 8.12185L1.69186 0.923257H22.3035ZM17.1763 8.5952L22.4066 15.0533C22.4112 15.058 22.4159 15.0627 22.4253 15.0721H1.57001C1.57469 15.0674 1.58407 15.0627 1.58875 15.0533L6.81898 8.5952C6.97832 8.39836 6.95021 8.10779 6.74868 7.94376C6.55185 7.78442 6.26128 7.81254 6.10193 8.01406L0.923257 14.4112V1.49971L10.737 8.85765C11.1072 9.13415 11.5524 9.25132 11.9977 9.256C12.4429 9.256 12.8881 9.13415 13.2583 8.85765L23.0721 1.49971V14.4112L17.8934 8.01406C17.734 7.81722 17.4435 7.78442 17.2466 7.94376C17.0451 8.10779 17.017 8.39836 17.1763 8.5952Z" fill="#A5ABAD"/>
</svg>
);

const SideMenuHelpCenter = props => (
  <SvgIcon width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
  <path d="M11 18.3333C11.4278 18.3333 11.7891 18.1854 12.0841 17.8897C12.3799 17.5947 12.5278 17.2333 12.5278 16.8056C12.5278 16.3778 12.3799 16.0164 12.0841 15.7214C11.7891 15.4257 11.4278 15.2778 11 15.2778C10.5722 15.2778 10.2109 15.4257 9.91589 15.7214C9.62011 16.0164 9.47222 16.3778 9.47222 16.8056C9.47222 17.2333 9.62011 17.5947 9.91589 17.8897C10.2109 18.1854 10.5722 18.3333 11 18.3333ZM9.9 13.6278H12.1611C12.1611 12.8944 12.2426 12.3546 12.4056 12.0083C12.5685 11.662 12.9148 11.2241 13.4444 10.6944C14.1574 9.98148 14.6618 9.38544 14.9576 8.90633C15.2525 8.42804 15.4 7.88333 15.4 7.27222C15.4 6.19259 15.0333 5.32156 14.3 4.65911C13.5667 3.99748 12.5787 3.66667 11.3361 3.66667C10.2157 3.66667 9.26363 3.94167 8.47978 4.49167C7.69511 5.04167 7.15 5.80556 6.84444 6.78333L8.86111 7.57778C9.0037 7.02778 9.28889 6.58452 9.71667 6.248C10.1444 5.9123 10.6435 5.74444 11.2139 5.74444C11.7639 5.74444 12.2222 5.89193 12.5889 6.18689C12.9556 6.48267 13.1389 6.875 13.1389 7.36389C13.1389 7.71018 13.0269 8.07685 12.8028 8.46389C12.5787 8.85093 12.2019 9.2787 11.6722 9.74722C11 10.338 10.5368 10.903 10.2826 11.4424C10.0275 11.9827 9.9 12.7111 9.9 13.6278ZM2.44444 22C1.77222 22 1.19656 21.7609 0.717444 21.2826C0.239148 20.8034 0 20.2278 0 19.5556V2.44444C0 1.77222 0.239148 1.19656 0.717444 0.717444C1.19656 0.239148 1.77222 0 2.44444 0H19.5556C20.2278 0 20.8034 0.239148 21.2826 0.717444C21.7609 1.19656 22 1.77222 22 2.44444V19.5556C22 20.2278 21.7609 20.8034 21.2826 21.2826C20.8034 21.7609 20.2278 22 19.5556 22H2.44444ZM2.44444 19.5556H19.5556V2.44444H2.44444V19.5556Z" fill="#A5ABAD"/>
  </SvgIcon>
);

const SideMenuChangePassword = props => (
  <SvgIcon width="25" height="12" viewBox="0 0 25 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
  <path d="M6.52174 8C5.92391 8 5.41232 7.804 4.98696 7.412C4.56087 7.02067 4.34783 6.55 4.34783 6C4.34783 5.45 4.56087 4.979 4.98696 4.587C5.41232 4.19567 5.92391 4 6.52174 4C7.11957 4 7.63116 4.19567 8.05652 4.587C8.48261 4.979 8.69565 5.45 8.69565 6C8.69565 6.55 8.48261 7.02067 8.05652 7.412C7.63116 7.804 7.11957 8 6.52174 8ZM6.52174 12C4.71014 12 3.17029 11.4167 1.90217 10.25C0.634058 9.08333 0 7.66667 0 6C0 4.33333 0.634058 2.91667 1.90217 1.75C3.17029 0.583333 4.71014 0 6.52174 0C7.73551 0 8.83623 0.275 9.82391 0.825C10.8109 1.375 11.5942 2.1 12.1739 3H21.7391L25 6L20.1087 10.5L17.9348 9L15.7609 10.5L13.4511 9H12.1739C11.5942 9.9 10.8109 10.625 9.82391 11.175C8.83623 11.725 7.73551 12 6.52174 12ZM6.52174 10C7.53623 10 8.42862 9.71667 9.19891 9.15C9.96848 8.58333 10.4801 7.86667 10.7337 7H14.1304L15.7065 8.025L17.9348 6.5L19.8641 7.875L21.9022 6L20.8152 5H10.7337C10.4801 4.13333 9.96848 3.41667 9.19891 2.85C8.42862 2.28333 7.53623 2 6.52174 2C5.32609 2 4.30254 2.39167 3.45109 3.175C2.59964 3.95833 2.17391 4.9 2.17391 6C2.17391 7.1 2.59964 8.04167 3.45109 8.825C4.30254 9.60833 5.32609 10 6.52174 10Z" fill="#A5ABAD"/>
  </SvgIcon>
);

const SideMenuNotice = props => (
  <SvgIcon width="25" height="23" viewBox="0 0 25 23" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
  <path d="M12.4594 16.1246C11.7289 16.1246 11.1607 16.6975 11.1607 17.4342C11.1607 18.1708 11.7289 18.7438 12.4594 18.7438C13.1088 18.7438 13.7581 18.1708 13.6769 17.516C13.7581 16.6975 13.1899 16.1246 12.4594 16.1246Z" fill="#A5ABAD"/>
  <path d="M24.3912 20.7082C25.2029 19.3167 25.2029 17.6797 24.3912 16.2064L16.3555 2.20996C15.5438 0.818505 14.0828 0 12.5406 0C10.9172 0 9.53734 0.818505 8.72565 2.20996L0.608766 16.2883C-0.202922 17.6797 -0.202922 19.3986 0.608766 20.79C1.42045 22.1815 2.80032 23 4.4237 23H20.414C22.1185 23 23.5795 22.0996 24.3912 20.7082ZM22.6055 19.726C22.1997 20.4626 21.388 20.9537 20.4951 20.9537H4.50487C3.61201 20.9537 2.88149 20.5445 2.39448 19.726C1.98864 18.9893 1.98864 18.0071 2.39448 17.2705L10.4302 3.27402C10.836 2.53737 11.6477 2.04626 12.5406 2.04626C13.4334 2.04626 14.164 2.53737 14.651 3.27402L22.6867 17.2705C23.0925 18.0071 23.0114 18.9075 22.6055 19.726Z" fill="#A5ABAD"/>
  <path d="M12.2159 7.03915C11.5666 7.20285 11.2419 7.7758 11.2419 8.43061C11.2419 8.83986 11.3231 9.24911 11.3231 9.65836C11.4042 11.2135 11.4854 12.7687 11.5666 14.3238C11.5666 14.8149 11.9724 15.2242 12.5406 15.2242C13.0276 15.2242 13.4334 14.8149 13.5146 14.3238C13.5146 13.9964 13.5146 13.669 13.5146 13.4235C13.5958 12.4413 13.5958 11.4591 13.6769 10.395C13.6769 9.74021 13.7581 9.08541 13.7581 8.43061C13.7581 8.18505 13.7581 8.02135 13.6769 7.7758C13.4334 7.20285 12.7841 6.9573 12.2159 7.03915Z" fill="#A5ABAD"/>
  </SvgIcon>

);

export {
  SideMenuTotalBalanceIcon,
  SideMenuTransactionHistory,
  SideMenuBankDetails,
  SideMenuBonus,
  SideMenuRebate,
  SideMenuProfile,
  SideMenuMessage,
  SideMenuHelpCenter,
  SideMenuChangePassword,
  SideMenuNotice
};
