import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import {
    socketConnect
} from '../../actions/socket';
import { getDetail } from '../../utils/helpers/common';

const SocketConnect = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(socketConnect(getDetail('token')))
    }, [])

    return [];
}

export default SocketConnect;
