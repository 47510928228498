import { OPEN_MINIGAME_SUCCESS, OPEN_MINIGAME_ERROR, SET_INITIAL_MINIGAME } from '../constants/minigame';

const initialState = {
    url: null,
    loading: true,
    success: false,
    errors: []
};

const minigameReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_INITIAL_MINIGAME:
            return initialState;
        case OPEN_MINIGAME_SUCCESS:
            return {
                ...state,
                url: action.url,
                loading: false,
                success: true,
                errors: []
            }
        case OPEN_MINIGAME_ERROR:
            return {
                ...state,
                errors: action.errors
            }
        default:
            return state;
    }
};

export default minigameReducer;
