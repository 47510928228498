export const GET_GAMES = 'GET_GAMES';
export const GET_GAMES_SUCCESS = 'GET_GAMES_SUCCESS';
export const GET_GAME_CATEGORIES = 'GET_GAME_CATEGORIES';
export const GET_GAME_CATEGORIES_SUCCESS = 'GET_GAME_CATEGORIES_SUCCESS';
export const GET_RECENT_GAMES = 'GET_RECENT_GAMES';
export const GET_RECENT_GAMES_SUCCESS = 'GET_RECENT_GAMES_SUCCESS';
export const SET_SLOTS_LOADING = 'SET_SLOTS_LOADING';
export const PROCEED_PLAY_GAME = 'PROCEED_PLAY_GAME';
export const SLOTS_PLAY = 'SLOTS_PLAY';
export const LAUNCH_GAME = 'LAUNCH_GAME';
export const SET_PLAY_SUCCESS = 'SET_PLAY_SUCCESS';
export const SET_FAVOURITE_SLOTS = 'SET_FAVOURITE_SLOTS';
export const SET_FAVOURITE_SUCCESS = 'SET_FAVOURITE_SUCCESS';
export const SET_FAVOURITE_LOADING = 'SET_FAVOURITE_LOADING';
export const OPEN_CASINO_ERROR = 'OPEN_CASINO_ERROR';
export const RESET_OPEN_CASINO_ERROR = 'RESET_OPEN_CASINO_ERROR';
export const SET_INITIAL_SLOT = 'SET_INITIAL_SLOT';



