import React, { useCallback, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import ReactHTMLParser from 'react-html-parser';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import styles from './styles';
import { ROOT_URL } from '../../../config/api';

import CustomButton from '../../../components/custom/button';
import CustomizableDialog from '../../../components/custom/customizable-dialog';

import { usePageContent } from '../../use-page-content';
import { isLogin, popupAlready } from '../../../utils/helpers/common';
import { S3 } from "../../../config/api";

const NoticeModal = ({ classes, openDialog, close, title, body, templateName = '', enableShowOnce = false, content = []}) => {
    const { t } = useTranslation();
    const { pageState } = usePageContent({templateName});
    const showOnce = isLogin() && popupAlready() && enableShowOnce;
    const [customHeader, setCustomHeader] = useState(false);

    useEffect(() =>{
      // setTimeout(()=> {
        setCustomHeader(true)
      // }, 1100)
    },[])

    if(showOnce) return(<></>);

    if(content.length === 0) return(<></>);

    return (
      <CustomizableDialog
        customScrollPaper={classes.customScrollPaper}
        customClass={classes.customDialog}
        onClose={close}
        noBackDrop={true}
        hasCustomHeader={customHeader}
        titleContent={
          <>
            <div>
              <Typography variant="h6">
                {/* {content.HeaderTitle ? content.HeaderTitle : ''} */}
              </Typography>
            </div>
          </>
        }
        // hasHeader
        openDialog={openDialog}
        hasClose={ (content.RequireClose === 0)  ? false : true}
      >
        <div className='img-container'>
        {
          Object.keys(content).length !== 0 ?
          content.Format === 2 ? <h3><div className='text-content'>{ReactHTMLParser(content.Content)}</div></h3> :
          <img src={process.env.REACT_APP_BO_STORAGE+content.ImageMobilePath} style={{width:'100%',height:'auto',display: 'block'}}/> : ''
        }
        
        {
          content.RequireConfirm === 1 ? 
            content.Format === 1 ?
              <div className='btn-container'>
                <CustomButton
                  fullWidth
                  variant="contained"
                  color="secondary"
                  type="button"
                  onClick={close}
                  text={t('confirm')}
                />
              </div>
            : content.Format === 2 ?
              <div className='btn-container-2'>
                <CustomButton
                  fullWidth
                  variant="contained"
                  color="secondary"
                  type="button"
                  onClick={close}
                  text={t('confirm')}
                />
              </div>
            : ''
          : ''
        }
        </div>
      </CustomizableDialog>
    );
}

NoticeModal.propTypes = {
  classes: PropTypes.shape({
    customScrollPaper: PropTypes.object,
    customDialog: PropTypes.string,
  }).isRequired,
  openDialog: PropTypes.bool.isRequired,
  close: PropTypes.func,
  title: PropTypes.string,
  body: PropTypes.string,
};

export default withStyles(styles)(NoticeModal);