import io from "socket.io-client";
import { WEBSOCKET_URL } from "../../config/api";

async function connect(token){
    return await io( WEBSOCKET_URL, {
        //"reconnection": false,
        //"upgrade": false,
        "transports": ["websocket"],
        "query": {
            token,
            brandID: "1"
            //"isBo": "1"
        }
    });
}

function addListener(socket) {
    // Listeners
    socket.on("connect", (data) => {
        console.info("[Socket] Connected.");
    });

    socket.on("disconnect", () => {
        console.info("[Socket] Disconnected.");
    });

    socket.on("error", (err) => {
        console.log('[Socket]', err);
    });

    socket.on("connect_error", (err) => {
        console.log('[Socket]', err);
    });

    socket.on("reconnect_failed", (err) => {
        console.log('[Socket] Reconnect failed.');
    });
}

export {
    connect,
    addListener
};
