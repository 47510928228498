import { eventChannel } from 'redux-saga';
import { fork, take, call, put, cancel, all, takeLatest } from 'redux-saga/effects';

import {
  SOCKET_CONNECT,
  SOCKET_CANCEL_ALL_TASK,
  SOCKET_SEND_MESSAGE
} from '../constants/socket';

import {
  updatePlayerBadgeCount,
  updatePlayerProviderWallet,
} from '../actions/player'

import { connect as createConnection, addListener } from '../utils/services/socket.connection';
import { updateRebateBalance, loadTransferRebateData } from '../actions/rebate';
import { setNotification } from '../actions/notification';
import { updateWithdrawalSummary } from '../actions/home';
import { kickUser } from '../actions/authentication';

import { resetOpenCasinoError } from '../actions/slot.games';
import { resetPlayState } from '../actions/sports';
import { resetOpenMinigameError } from '../actions/minigame';
import { resetOpenLiveCasinoError} from '../actions/live.casino';
import {
  hideGameMessage
} from '../actions/common';
import axios from 'axios';
import { ROOT_URL, BRAND_TOKEN } from '../config/api';

//TODO: once user is connected, need to connect again
function* connect(action) {
  if(action.token){
    const socket = yield call(createConnection, action.token);

    yield fork(flow, socket);
  }
}

function subscribe(socket) {
  return eventChannel(emit => { //TODO: transfer to addListener then decouple listeners to each file
    addListener(socket);
    socket.on('message', (msg) =>{
      //do your logic here for each msg action cases
      //  emit({
      //   type: 'ACTION_CONSTANT_TO_DISPATCH', // can be called to dispatch other saga listener
      //   msg
      //  });
      // or emit(setGameWallet({ msg }))
      if (msg.action) {
        const { balance, providerID } = msg;
        switch (msg.action) {
          case 'closeGame':
              window.location.href = '/';
            break;
          case 'walletUpdated':
            emit(
              updatePlayerProviderWallet({
                balance,
                providerID,
              }),
            );
            //will add notification later as enhancement
            // emit(
            //   setNotification({
            //     msg: 'wallet updated',
            //   }),
            // );
            break;
          case 'rebateWalletUpdated':
            emit(updateRebateBalance({ balance }));

            if(localStorage.getItem('token') !== undefined) {
              axios.get(`${ROOT_URL}/rebate-transfer-check`, {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem('token')}`,
                  'brand-token': BRAND_TOKEN
                }
              })
              .then(response => {
                emit(loadTransferRebateData(response.data));
              });
            }

            // emit(
            //   setNotification({
            //     msg: 'rebate wallet updated',
            //   }),
            // );
            break;
          // case 'depositRejected':
          //   emit(
          //     setNotification({
          //       msg: 'deposit rejected',
          //       type: 'error',
          //     }),
          //   );
          //   break;
          case 'playerNotified':
            if (typeof msg.message === 'object') {
              if (msg.message.type === 'notice') {
                //message params
                // message: {
                // DateDeleted: "2021-08-15 15:52:13"
                // MessageID: 104
                // action: "delete"
                // count: -1
                // type: "notice"
                // status: "read" || "unread"
                // }
                const {
                  DateCreated,
                  Message,
                  MessageID,
                  Subject,
                  action,
                  status,
                } = msg.message;
                emit(
                  updatePlayerBadgeCount({
                    messageType: 'notice',
                    DateCreated,
                    Message,
                    MessageID,
                    Subject,
                    status,
                    msgAction: action
                  }),
                );
              } else if (msg.message.type === 'message') {
                //message params
                // message: {
                // DateCreated: "2021-08-15 12:06:34",
                // Message: "<p>test</p>",
                // Subject: "test",
                // action: "add",
                // count: 1,
                // messageID: 23,
                // parentID: 22,
                // type: "message"
                // }
                const {
                  DateCreated,
                  Message,
                  messageID,
                  parentID,
                  Subject,
                  action
                } = msg.message;
                emit(
                  updatePlayerBadgeCount({
                    messageType: 'message',
                    parentID,
                    MessageID: messageID,
                    Message,
                    Subject,
                    DateCreated,
                    msgAction: action
                  }),
                );
              } else if (!msg.message.hasOwnProperty('type')) {
                //type doesnt exist means deposit/withdrawal approve/reject message toast
                emit(
                  setNotification({
                    msg: msg.message.text,
                  }),
                );
              }
            } else if (typeof msg.message === 'string') {
              emit(
                setNotification({
                  msg: msg.message,
                }),
              );
            }
            break;
          case 'gameMaintenanceUpdated':
            // if(window.location.pathname.includes("live-sports") && msg.providerID === 25) {
            //     window.location.reload();
            // } else if(window.location.pathname.includes("sports-betting") && msg.providerID == 21) {
            //     window.location.reload();
            // }
            
            // emit(resetOpenCasinoError())
            // emit(resetOpenMinigameError())
            // emit(resetOpenMinigameError())
            // emit(resetOpenLiveCasinoError())
            
            let pathname = ['/live-sports','/minigame/cockfighting'];
            let providerIDs = [51,30] // betradar,cockfighting
            if (pathname.includes(window.location.pathname) && providerIDs.includes(msg.providerID) ) {
                window.location.reload();
            }else{
              // emit(hideGameMessage())
            }
          case 'brandNotified':
            if (msg.params && msg.params.message.action === "withdrawalApproved") {
                const params = {
                  PlayerId: msg.playerUser,
                  Amount: msg.amount,
                  Date: msg.dateTime,
                }

                emit(
                  updateWithdrawalSummary(params),
                );
            }
          break;
          case 'kickPlayer':
            caches.keys().then((names) => {
              names.forEach((name) => {
                caches.delete(name);
              });
            });
            emit(kickUser())
          break;
          case 'kickAllPlayer':
            caches.keys().then((names) => {
              names.forEach((name) => {
                caches.delete(name);
              });
            });
            emit(kickUser())
          break;
          default:
            break;
        }
      }
    });
    socket.on('test', (msg) => {
      emit({
        type: 'SHOW_SESSION_MODAL'
      });
    });
    return () => {};
  });
}

function* read(socket) {
  const channel = yield call(subscribe, socket);
  while (true) {
    let action = yield take(channel);
    yield put(action);
  }
}

function* write(socket) {
  while (true) {
    const { payload } = yield take(SOCKET_SEND_MESSAGE);

    // sample call to emit message just use sendMessage action
    // Example: yield put(sendMessage({ actionName: 'playerDeposited', ...params }));
    //          or if in container, hooks and components
    //          dispatch(sendMessage({ actionName: 'playerDeposited', ...params }))
    socket.emit('message', payload);
  }
}

function* handleIO(socket) {
  yield fork(read, socket); //do task in the background prevent blocking
  yield fork(write, socket); //TODO: emit message to socket server
}

function* flow(socket) {
    const task = yield fork(handleIO, socket);
    yield take(SOCKET_CANCEL_ALL_TASK); //wait for logout action
    yield cancel(task); //abort forked process
}

function* socketWatcher() {
  yield all([
    takeLatest(SOCKET_CONNECT, connect),
  ]);
}

export default socketWatcher;
