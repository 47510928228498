const styles = theme => ({
    root: {
        display: 'flex',
        // paddingTop: '1em',
        // paddingBottom: '1em',
        width: '100%',
        alignItems: 'center',
        display: 'flex',
        // paddingLeft: theme.spacing(2),
        // paddingRight: theme.spacing(2),
        textDecoration: 'none',
        background: theme.palette.primary.dark1,
        '& .level-badge': {
            width: '28px',
            height: '28px',
            marginRight: '8px',
            display: 'inline-block',
            verticalAlign: 'middle'
        },
        '& .details-content': {
            display: 'flex',
            alignItems: 'center',
            width: '100%',
        },
        '& .welcome': {
            fontSize: '0.875em'
        },
        '& .level': {
            margin: '0',
            marginTop: '8px',
            fontSize: '0.875em',
            color: theme.palette.text.secondary,
            textAlign: 'left',
            fontWeight: '600',
        },
        '& .level-indicator': {
            color: '#B2590E',
            fontSize: '0.875em',
            padding: '1px 4px',
            marginLeft: '4px',
            border: '1px solid #B2590E',
            borderRadius: '2px',
        },
        '& .name': {
            margin: '0',
            fontSize: '14px',
            fontWeight: 'bold',
            color: '#33C16C',
            textAlign: 'left'
        }
    }
})

export default styles;