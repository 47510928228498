import { all, call, put, takeLatest } from 'redux-saga/effects';

import {
  GET_POPUP_NOTICE,
} from '../constants/popup.notice';
import popupNoticeSvc from '../utils/services/popup.notice';
import { setPopUpNotice } from '../actions/popup.notice';

function* getPopupNotice(action) {
  try {
    const request = yield call(
        popupNoticeSvc.getPopupNotice,
        action.payload
    );

    yield put(setPopUpNotice(request.data));
  } catch (err) {
    const { errors } = err.response.data;
    console.log(errors);
  
  } finally {

  }
}

export default function* popupNoticeWatcher() {
  yield all([takeLatest(GET_POPUP_NOTICE, getPopupNotice)]);
}
