import { all, call, put, takeLatest, select } from 'redux-saga/effects';
import {
  DO_REGISTER,
  VERIFY_REGISTRATION_CODE,
  GET_REGISTER_PARTNER,
  DO_REGISTER_PARTNER,
  VERIFY_REGISTRATION_CODE_PARTNER,
  GET_CAN_REGISTER,
  GET_SMS_SETTING
} from '../constants/register';
import {
  setCanRegister,
  setMsg,
  setPartner,
  setRegisterErrors,
  setRegisterSuccess,
  setVerifSuccess,
  setWelcomePromotions,
  setSMSSetting
} from '../actions/register';
import { setLoading } from '../actions/common';
import { setDetails } from '../utils/helpers/common';
import Svc from '../utils/services/register';
import CommonSvc from '../utils/services/common';

import { GET_WELCOME_PROMOTIONS } from '../constants/register';

import promotionsSvc from '../utils/services/promotions';
import { getLanguage } from '../utils/helpers/common';

function* getCanRegister(action) {
  try {
    const request = yield call(Svc.canRegister, action.params);
    const { data } = request;

    yield put(setCanRegister(data.CanSignUp));
    // yield put(setWelcomePromotions(data));
  } catch (err) {
  // console.log(err)
    //err.response.data
  }
}

function* getWelcomePromotions() {
  try {
    const request = yield call(promotionsSvc.getPromotions, {
      application: 1,
      lang: getLanguage(),
      welcome: true,
      limit: 6
    });
    const { data } = request.data;

    yield put(setWelcomePromotions(data));
  } catch (err) {
    //err.response.data
  }
}

function* doRegister(action) {
  try {
    /* const requestIP = yield call(CommonSvc.getIP);
    const { ip } = requestIP.data; */
    //const request = yield call(Svc.doRegister, Object.assign(action.params, { ip_address: ip }));
    const registerState = yield select(state => state.register);
    const request = yield call(Svc.doRegister, Object.assign(action.params, { lang: getLanguage() }));
    const { success } = request.data;

    if(registerState.sms.player) {
      if (action.isResendCode) {
        yield put(setMsg('success', 'Code has been sent successfully.', true));
      } else {
        yield put(setRegisterSuccess(success));
      }
    } else {
      const request = yield call(Svc.verify, Object.assign(action.params));
      const { data } = request.data;
      const { token } = data;
  
      setDetails('token', token);
      yield put(setVerifSuccess(true));
    }
  } catch (err) {
    yield put(setRegisterErrors(err.response.data.errors));
  } finally {
    yield put(setLoading(false));
  }
}

function* getPartner(action) {
  try {
    const response = yield call(Svc.getPartner, action.params);

    yield put(setPartner(response.data[0]));
  } catch (err) {
  //  yield put(setRegisterErrors(err.response.data.errors));
  } finally {
   // yield put(setLoading(false));
  }
}

function* verifyCode(action) {
  try {
    /* const requestIP = yield call(CommonSvc.getIP);
    const { ip } = requestIP.data; */
    //const request = yield call(Svc.verify, Object.assign(action.params, { ip_address: ip }));
    const request = yield call(Svc.verify, Object.assign(action.params, { lang: getLanguage() }));
    const { data } = request.data;
    const { token } = data;

    setDetails('token', token);
    yield put(setVerifSuccess(true));
  } catch (err) {
    const { errors } = err.response.data;

    yield put(setMsg('error', errors.code[0], true));
  } finally {
    yield put(setLoading(false));
  }
}

function* doRegisterPartner(action) {
  try {
    /* const requestIP = yield call(CommonSvc.getIP);
    const { ip } = requestIP.data; */
    //const request = yield call(Svc.doPartnerRegister, Object.assign(action.params, { ip_address: ip }));
    const request = yield call(Svc.doPartnerRegister, Object.assign(action.params));
    const { success } = request.data;

    if (action.isResendCode) {
      yield put(setMsg('success', 'code has been sent successfully', true));
    } else {
      yield put(setRegisterSuccess(success));
    }
  } catch (err) {
    yield put(setRegisterErrors(err.response.data.errors));
  } finally {
    yield put(setLoading(false));
  }
}

function* verifyPartnerCode(action) {
  try {
    /* const requestIP = yield call(CommonSvc.getIP);
    const { ip } = requestIP.data; */
    //const request = yield call(Svc.verifyPartner, Object.assign(action.params, { ip_address: ip }));
    const request = yield call(Svc.verifyPartner, Object.assign(action.params));

    yield put(setMsg('success', 'verification completed', true));
    yield put(setVerifSuccess(true));
  } catch (err) {
    const { errors } = err.response.data;

    yield put(setMsg('error', errors.code[0], true));
  } finally {
    yield put(setLoading(false));
  }
}

function* getSMSSetting() {
  try {
    const request = yield call(Svc.getSMSSetting);
    yield put(setSMSSetting(request.data));
  } catch(err) {

  }
}

export default function* registerWatcher() {
  yield all([
    takeLatest(DO_REGISTER, doRegister),
    takeLatest(VERIFY_REGISTRATION_CODE, verifyCode),
    takeLatest(DO_REGISTER_PARTNER, doRegisterPartner),
    takeLatest(VERIFY_REGISTRATION_CODE_PARTNER, verifyPartnerCode),
    takeLatest(GET_REGISTER_PARTNER, getPartner),
    takeLatest(GET_WELCOME_PROMOTIONS, getWelcomePromotions),
    takeLatest(GET_CAN_REGISTER, getCanRegister),
    takeLatest(GET_SMS_SETTING, getSMSSetting)
  ]);
}
