export const GET_REBATE_BALANCE = 'GET_REBATE_BALANCE';
export const SET_REBATE_BALANCE = 'SET_REBATE_BALANCE';
export const UPDATE_REBATE_BALANCE = 'UPDATE_REBATE_BALANCE';
export const GET_REBATE_MINIMUM = 'GET_REBATE_MINIMUM';
export const SET_REBATE_MINIMUM = 'SET_REBATE_MINIMUM';
export const SUBMIT_REBATE_TRANSFER_REQUEST = 'SUBMIT_REBATE_TRANSFER_REQUEST';
export const SUBMIT_REBATE_TRANSFER_REQUEST_SUCCESS =
  'SUBMIT_REBATE_TRANSFER_REQUEST_SUCCESS';

export const GET_PLAYER_REBATE_TRANSACTIONS = 'GET_PLAYER_REBATE_TRANSACTIONS';
export const SET_PLAYER_REBATE_TRANSACTIONS = 'SET_PLAYER_REBATE_TRANSACTIONS';
export const LOAD_MORE_REBATE_TRANSACTIONS = 'LOAD_MORE_REBATE_TRANSACTIONS';
export const LOAD_MORE_REBATE_TRANSACTIONS_SUCCESS = 'LOAD_MORE_REBATE_TRANSACTIONS_SUCCESS';

export const SET_PLAYER_REBATE_TRANSACTIONS_STATUS = 'SET_PLAYER_REBATE_TRANSACTIONS_STATUS';
export const SET_REBATE_TO = 'SET_REBATE_TO';
export const LOAD_TRANSFER_REBATE_DATA = 'LOAD_TRANSFER_REBATE_DATA';
