import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getRebateBalance } from '../actions/rebate';
import {
  getAllGamesWallet,
} from '../actions/player';

export default function usePlayerBalance(){
  const dispatch = useDispatch();
  const { rebateWallet } = useSelector(state => state.rebate);
  const { activeProviders } = useSelector(state => state.common);
  const { gameWallets, allGamesWallet, playerInfo } = useSelector(state => state.player);
  const player = useSelector(state => state.player);
  const {
    balance: rebateBalance,
  } = rebateWallet || { balance: 0 };

  useEffect(() => {
    if (!rebateWallet) dispatch(getRebateBalance());
  }, []);

  useEffect(() => {
    if (gameWallets.length === 0) dispatch(getAllGamesWallet());
  }, [activeProviders]);

  return {
    rebateBalance,
    allGamesWallet: playerInfo && playerInfo.wallet,
    isLoading: !playerInfo || allGamesWallet === null
   }
};
