import {
  GET_REBATE_BALANCE,
  SET_REBATE_BALANCE,
  GET_PLAYER_REBATE_TRANSACTIONS,
  SET_PLAYER_REBATE_TRANSACTIONS,
  SET_PLAYER_REBATE_TRANSACTIONS_STATUS,
  SUBMIT_REBATE_TRANSFER_REQUEST,
  SUBMIT_REBATE_TRANSFER_REQUEST_SUCCESS,
  LOAD_MORE_REBATE_TRANSACTIONS,
  LOAD_MORE_REBATE_TRANSACTIONS_SUCCESS,
  SET_REBATE_MINIMUM,
  GET_REBATE_MINIMUM,
  SET_REBATE_TO,
  UPDATE_REBATE_BALANCE,
  LOAD_TRANSFER_REBATE_DATA
} from '../constants/rebate';

export const setTo = transferTo => ({
  type: SET_REBATE_TO,
  transferTo
});

export const getRebateBalance = () => ({
  type: GET_REBATE_BALANCE,
});

export const setRebateBalance = params => ({
  type: SET_REBATE_BALANCE,
  params
});

export const updateRebateBalance = params => ({
  type: UPDATE_REBATE_BALANCE,
  params
});

export const getRebateMinimum = () => ({
  type: GET_REBATE_MINIMUM,
});

export const setRebateMinimum = minimum => ({
  type: SET_REBATE_MINIMUM,
  minimum
});

export const submitRebateTransferRequest = (params) => ({
  type: SUBMIT_REBATE_TRANSFER_REQUEST,
  params
});

export const submitRebateTransferSuccess = (isSuccess = false) => ({
  type: SUBMIT_REBATE_TRANSFER_REQUEST_SUCCESS,
  isSuccess,
});

export const setPlayerRebateTransactions = (transactions, page = 1) => ({
  type: SET_PLAYER_REBATE_TRANSACTIONS,
  transactions,
  page,
});

export const getPlayerRebateTransactions = params => ({
  type: GET_PLAYER_REBATE_TRANSACTIONS,
  params,
});

export const setPlayerRebateTransactionsStatus = status => ({
  type: SET_PLAYER_REBATE_TRANSACTIONS_STATUS,
  status
});

export const loadMoreRebateTransactions = params => ({
  type: LOAD_MORE_REBATE_TRANSACTIONS,
  params,
});

export const setMoreRebateTransactions = transactions => ({
  type: LOAD_MORE_REBATE_TRANSACTIONS_SUCCESS,
  transactions,
});

export const loadTransferRebateData = payload => ({
  type: LOAD_TRANSFER_REBATE_DATA,
  payload
});
