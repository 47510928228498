export const CHANGE_PLAYER_PASSWORD = 'CHANGE_PLAYER_PASSWORD';
export const CANCEL_ONGOING_PROMOTION= 'CANCEL_ONGOING_PROMOTION';
export const CHECK_PLAYER_SUBSCRIPTION = 'CHECK_PLAYER_SUBSCRIPTION';
export const GET_ALL_GAMES_WALLET = 'GET_ALL_GAMES_WALLET';
export const GET_BANK_ACCOUNT = 'GET_BANK_ACCOUNT';
export const GET_GAMES_WALLET = 'GET_GAMES_WALLET';
export const GET_ONGOING_PROMOTION = 'GET_ONGOING_PROMOTION';
export const GET_PLAYER_BONUS = 'GET_PLAYER_BONUS';
export const GET_PLAYER_BONUS_HISTORY = 'GET_PLAYER_BONUS_HISTORY';
export const GET_PLAYER_CASH = 'GET_PLAYER_CASH';
export const GET_PLAYER_CURRENT_TRANSACTIONS = 'GET_PLAYER_CURRENT_TRANSACTIONS';
export const GET_PLAYER_INFO = 'GET_PLAYER_INFO';
export const GET_PLAYER_MESSAGES = 'GET_PLAYER_MESSAGES';
export const GET_PLAYER_TRANSACTIONS = 'GET_PLAYER_TRANSACTIONS';
export const GET_PLAYER_PPROMOTION_MIN_DEPOSIT = 'GET_PROMOTION_MIN_DEPOSIT';
export const PLAYER_PROMOTION_MIN_DEPOSIT_SUCCESS = 'PLAYER_PROMOTION_MIN_DEPOSIT_SUCCESS'
export const PLAYER_PROMOTION_MIN_DEPOSIT_ERROR = 'PLAYER_PROMOTION_MIN_DEPOSIT_ERROR'
export const LOAD_MORE_BONUS = 'LOAD_MORE_BONUS';
export const LOAD_MORE_BONUS_SUCCESS = 'LOAD_MORE_BONUS_SUCCESS';
export const LOAD_MORE_BONUS_HISTORY = 'LOAD_MORE_BONUS_HISTORY';
export const LOAD_MORE_BONUS_HISTORY_SUCCESS = 'LOAD_MORE_BONUS_HISTORY_SUCCESS';
export const LOAD_MORE_CASH = 'LOAD_MORE_CASH';
export const LOAD_MORE_CASH_SUCCESS = 'LOAD_MORE_CASH_SUCCESS';
export const LOAD_MORE_TRANSACTIONS = 'LOAD_MORE_TRANSACTIONS';
export const LOAD_MORE_TRANSACTIONS_SUCCESS = 'LOAD_MORE_TRANSACTIONS_SUCCESS';
export const LOAD_MORE_MESSAGES = 'LOAD_MORE_MESSAGES';
export const LOAD_MORE_MESSAGES_SUCCESS = 'LOAD_MORE_MESSAGES_SUCCESS';
export const ONGOING_PROMOTION_ERROR = 'ONGOING_PROMOTION_ERROR';
export const ONGOING_PROMOTION_SUCCESS = 'ONGOING_PROMOTION_SUCCESS';
export const REDEEM_BONUS = 'REDEEM_BONUS';
export const SET_ALL_GAMES_WALLET = 'SET_ALL_GAMES_WALLET';
export const SET_BANK_DETAILS = 'SET_BANK_DETAILS';
export const SET_ERROR = 'SET_ERROR';
export const SET_GAME_WALLET = 'SET_GAME_WALLET';
export const SET_MESSAGE_TO_VIEW = 'SET_MESSAGE_TO_VIEW';
export const SET_ONGOING_PROMOTION = 'SET_ONGOING_PROMOTION';
export const SET_ONGOING_PROMOTION_STATUS = 'SET_ONGOING_PROMOTION_STATUS';
export const SET_PLAYER_BONUS = 'SET_PLAYER_BONUS';
export const SET_PLAYER_BONUS_HISTORY = 'SET_PLAYER_BONUS_HISTORY';
export const SET_PLAYER_CASH = 'SET_PLAYER_CASH';
export const SET_PLAYER_BANK_ACCOUNT = 'SET_PLAYER_BANK_ACCOUNT';
export const SET_PLAYER_CURRENT_TRANSACTIONS = 'SET_PLAYER_CURRENT_TRANSACTIONS';
export const SET_PLAYER_EXISTING_SUBSCRIPTION = 'SET_PLAYER_EXISTING_SUBSCRIPTION';
export const SET_PLAYER_HAS_PENDING_BET = 'SET_PLAYER_HAS_PENDING_BET';

export const GET_DEPOSIT_PROMOTION = 'GET_DEPOSIT_PROMOTION';
export const SET_DEPOSIT_PROMOTION = 'SET_DEPOSIT_PROMOTION';

export const SET_PLAYER_INFO = 'SET_PLAYER_INFO';
export const SET_PLAYER_MESSAGES = 'SET_PLAYER_MESSAGES';
export const SET_PLAYER_TRANSACTIONS = 'SET_PLAYER_TRANSACTIONS';
export const SET_RESPONSE_MESSAGE = 'SET_RESPONSE_MESSAGE';
export const SET_UNAUTHORIZED = 'SET_UNAUTHORIZED';
export const SEND_MESSAGE = 'SEND_MESSAGE';
export const UPDATE_PLAYER_INFO = 'UPDATE_PLAYER_INFO';
export const SET_UPDATE_PLAYER_INFO_SUCCESS = 'SET_UPDATE_PLAYER_INFO_SUCCESS';

export const SET_ALL_GAMES_WALLET_STATUS = 'SET_ALL_GAMES_WALLET_STATUS';

export const INITIAL_STATUS = 'INITIAL_STATUS';
export const REQUESTING_STATUS = 'REQUESTING_STATUS';
export const SUCCESS_REQUEST_STATUS = 'SUCCESS_REQUEST_STATUS';

export const UPDATE_PLAYER_PROVIDER_WALLET = 'UPDATE_PLAYER_PROVIDER_WALLET';
export const UPDATE_PLAYER_BADGE_COUNT = 'UPDATE_PLAYER_BADGE_COUNT';

export const GET_PLAYER_DEPOSIT_ACCOUNT = "GET_PLAYER_DEPOSIT_ACCOUNT";
export const SET_PLAYER_DEPOSIT_ACCOUNT = "SET_PLAYER_DEPOSIT_ACCOUNT";
export const ERROR_PLAYER_DEPOSIT_ACCOUNT = "ERROR_PLAYER_DEPOSIT_ACCOUNT";
export const CLEAR_PLAYER_DEPOSIT_ACCOUNT = "CLEAR_PLAYER_DEPOSIT_ACCOUNT";

export const GET_PAYMENT_TRON_EXCHANGE = "GET_PAYMENT_TRON_EXCHANGE";
export const SET_PAYMENT_TRON_EXCHANGE = "SET_PAYMENT_TRON_EXCHANGE";
export const GET_PAYMENT_TRON_EXCHANGE_BALANCE = "GET_PAYMENT_TRON_EXCHANGE_BALANCE";
export const SET_PAYMENT_TRON_EXCHANGE_BALANCE = "SET_PAYMENT_TRON_EXCHANGE_BALANCE";
export const RESET_PAYMENT_TRON_EXCHANGE_BALANCE = "RESET_PAYMENT_TRON_EXCHANGE_BALANCE";
export const GET_PAYMENT_TRON_EXCHANGE_PURCHASE = "GET_PAYMENT_TRON_EXCHANGE_PURCHASE";
export const SET_PAYMENT_TRON_EXCHANGE_PURCHASE = "SET_PAYMENT_TRON_EXCHANGE_PURCHASE";
export const RESET_PAYMENT_TRON_EXCHANGE_PURCHASE = "RESET_PAYMENT_TRON_EXCHANGE_PURCHASE";
export const SET_PAYMENT_TRON_EXCHANGE_BTN_DISABLED = "SET_PAYMENT_TRON_EXCHANGE_BTN_DISABLED";
export const GET_DEPOSIT_OPTION = "GET_DEPOSIT_OPTION";
export const SET_DEPOSIT_OPTION = "SET_DEPOSIT_OPTION";
export const SET_BALANCE_INQUIRY_LOADING = "SET_BALANCE_INQUIRY_LOADING";
export const SET_BALANCE_INQUIRY_BTN_DISABLED = "SET_BALANCE_INQUIRY_BTN_DISABLED";

export const GET_CHUNG_DAM_INFO = "GET_CHUNG_DAM_INFO";
export const SET_CHUNG_DAM_INFO = "SET_CHUNG_DAM_INFO";

export const SET_MESSAGE_SUCCESS = "SET_MESSAGE_SUCCESS";

export const GET_CHECK_DEPOSIT_PROMOTION = "GET_CHECK_DEPOSIT_PROMOTION";
export const SET_CHECK_DEPOSIT_PROMOTION = "SET_CHECK_DEPOSIT_PROMOTION";
