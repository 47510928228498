import React from 'react';
import PropTypes from 'prop-types';
import rtl from 'jss-rtl';
import { create } from 'jss';
import { JssProvider } from 'react-jss';
import { withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import compose from 'recompose/compose';
import IdleTimer from 'react-idle-timer';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { jssPreset, createGenerateClassName } from '@material-ui/styles';
// import { createMuiTheme } from '@material-ui/core/styles';
import { isLogin } from './utils/helpers/common';
import { logoutUser } from './actions/authentication';

import Routes from './routes';
import './axiosSettings';

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const generateClassName = createGenerateClassName();

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false
    };
    this.idleTimer = null
    this.handleOnAction = this.handleOnAction.bind(this)
    this.handleOnActive = this.handleOnActive.bind(this)
    this.handleOnIdle = this.handleOnIdle.bind(this)
  }

  UNSAFE_componentWillMount() {
    // test
    // const test = (localStorage.getItem("test"))?JSON.parse(localStorage.getItem("test")):{};
    // console.log(test.name + "-" + test.age);
    // console.log(this.props.location.pathname);
    // if(!localStorage.getItem("test") /* && this.props.location.pathname !== '/login' */ )
    //  {
    //    this.setState({redirect: true});
    //    console.log(this.props.location.pathname);
    //  }
    // end test
  }

  componentDidMount() {
    // once the component is loaded set the redirect to false
    // this.setState({redirect: false});
  }


  UNSAFE_componentWillReceiveProps(nextProps) {
    if (document.body) {
      document.body.dir = nextProps.themeConfig.contentTheme.direction;
    }
  }

  handleOnAction (event) {
    // console.log('user did something', event)
  }

  handleOnActive (event) {
    // console.log('user is active', event)
    // console.log('time remaining', this.idleTimer.getRemainingTime())
    this.idleTimer.reset();
  }

  handleOnIdle (event) {
    console.log('user is idle', event)
    console.log('last active', this.idleTimer.getLastActiveTime())
    this.props.logoutUser();

  }

  render() {
    const childProps = {};
    const { themeConfig, layoutConfig } = this.props;

    if (this.state.redirect === true) {
      return (
        <Redirect to="/login" />
      );
    }

    sessionStorage.setItem(
      'portalData',
      JSON.stringify({
        theme: {
          ...themeConfig
        },
        layout: {
          ...layoutConfig
        }
      })
    );

    const materialTheme = createMuiTheme({
      ...themeConfig.contentTheme,
       overrides: {
        ...themeConfig.contentTheme.overrides,
        MuiButton: {
          root: {
            fontWeight: 700,
          },
        },
        MuiMenu: {
          list: {
            background: themeConfig.contentTheme.palette.background.paper
          }
        },
        MuiMenuItem:{
          root:{
            color: themeConfig.contentTheme.palette.text.secondary,
            fontWeight: 500
          }
        },
        MuiDialog:{
          paper: {
            backgroundColor: themeConfig.contentTheme.palette.background.paper,
          }
        },
        MuiPaper:{
          root: {
            backgroundColor: themeConfig.contentTheme.palette.primary.dark,
          }
        },
        MuiInputBase: {
          input: {
            height: '0.7em',
            // maxHeight: '11px'
          }
        },
        MuiInputLabel: {
          formControl:{
            top: '-3px'
          }
        },
        MuiSelect: {
          selectMenu: {
            minHeight: '1.123em',
            height: '18px',
          },
          select: {
            borderRadius: '3px',
            padding: '15px',
            '&:focus': {
              backgroundColor: "none"
            },
          },
        },
        MuiFilledInput: {
          root: {
            borderRadius: '3px',
            backgroundColor: themeConfig.contentTheme.palette.primary.dark6,
            height: '48px',
            marginRight: '1px',
            ['@media (hover: none)']: {
              '&:hover': {
                backgroundColor: `${themeConfig.contentTheme.palette.primary.dark6} !important`,
              },
            },
            "&$focused": { // increase the specificity for the pseudo class
              backgroundColor: themeConfig.contentTheme.palette.primary.dark6,
            },
          },
          input: {
            background: themeConfig.contentTheme.palette.primary.dark6,
            borderRadius: '3px',
            fontSize: '14px'
          },
          adornedEnd: {
            background: themeConfig.contentTheme.palette.primary.dark6,
          },
          underline: {
            '&:before, &:after':{
              borderBottom: `none`,
            }
          }
        }
       },
    });
    if(this.props.tReady === undefined) return (<></>);

    return (
      <React.Fragment>
        {
          isLogin() ?
          <IdleTimer
            ref={ref => { this.idleTimer = ref }}
            timeout={1000 * 60 * 60}
            onActive={this.handleOnActive}
            onIdle={this.handleOnIdle}
            onAction={this.handleOnAction}
            debounce={250}
            crossTab={{
                emitOnAllTabs: true
            }}
          />
          : <></>
        }
        <JssProvider jss={jss} generateClassName={generateClassName}>
          <MuiThemeProvider theme={materialTheme}>
            <Routes childProps={childProps} layout={layoutConfig} />
          </MuiThemeProvider>
        </JssProvider>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    themeConfig: state.theme,
    layoutConfig: state.layout
  };
}

App.propTypes = {
  themeConfig: PropTypes.shape({
    contentTheme: PropTypes.shape({
      direction: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  layoutConfig: PropTypes.shape({}).isRequired,
  logoutUser: PropTypes.func.isRequired,
};

export default compose(
  withTranslation('translations'),
  withRouter,
  connect(mapStateToProps, {logoutUser})
)(App);
