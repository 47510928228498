import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/styles/withStyles';
import Popover from '@material-ui/core/Popover';
import ArrowDropDownTwoToneIcon from '@material-ui/icons/ArrowDropDownTwoTone';
import styles from './styles';
import enFlag from '../../../../../assets/images/en.png';
import krFlag from '../../../../../assets/images/kr.png';

const defaultLang = 'ko-KR';

const LanguageSwitcher = ({ classes }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const { i18n } = useTranslation();
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const languages = ['ko-KR','en-US'];

    const [lang, setLang] = useState(defaultLang);
    const [altenativeLanguage, setAltenativeLanguage] = useState('ko-KR');

    const languageInfo = {
        'en-US': {
            image: enFlag,
            text: 'English',
            altText: 'EN'
        },
        'en-GB': {
            image: enFlag,
            text: 'English',
            altText: 'EN'
        },
        'ko-KR': {
            image: krFlag,
            text: '한국어',
            altText: 'KR'
        }
    };

    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        setLang(lng);
        setAnchorEl(null);
        window.location.reload();
    };

    useEffect(() => {
        const altLang = i18n.language === 'ko-KR' ? 'en-US' : 'ko-KR';
        setAltenativeLanguage(altLang);
      }, [lang]);
  
    useEffect(() => {
        const newLang = i18n.language ? i18n.language : defaultLang;
        setLang(newLang);
    }, []);

    return (
        <div className={classes.root}>
            <div className="display" onClick={handleClick}>
                <img src={languageInfo[!languages.includes(lang)  ? 'ko-KR'  : lang].image}  alt={languageInfo[!languages.includes(lang)  ? 'ko-KR'  : lang].altText} />
                <ArrowDropDownTwoToneIcon />
            </div>
            <Popover id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
                }}
                transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
                }}
            >
                <Button onClick={() => changeLanguage(altenativeLanguage)} variant="contained" color="primary" style={{backgroundColor:"#043529",border:`1px solid #00765A`}} disableElevation>
                    <img src={languageInfo[altenativeLanguage].image} style={{width:'16px',height:'16px',marginRight:'5px',borderRadius:'50%'}} />
                    <span style={{color: "#FFFFFF",fontSize:'11px',textTransform:'none'}}>{languageInfo[altenativeLanguage].text}</span>
                </Button>
            </Popover>
        </div>
    );
}

export default withStyles(styles)(LanguageSwitcher);