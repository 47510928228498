import {
  GET_CAN_REGISTER,
  CAN_REGISTER_SUCCESS,
  DO_REGISTER,
  SET_MSG,
  SET_REGISTER_ERRORS,
  SET_REGISTER_SUCCESS,
  SET_VERIF_SUCCESS,
  VERIFY_REGISTRATION_CODE,
  GET_REGISTER_PARTNER,
  SET_REGISTER_PARTNER_SUCCESS,
  GET_WELCOME_PROMOTIONS,
  SET_WELCOME_PROMOTIONS,
  DO_REGISTER_PARTNER,
  VERIFY_REGISTRATION_CODE_PARTNER,
  RESET_SUCCESS,
  RESET_VERIFY_MESSAGE,
  GET_SMS_SETTING,
  SET_SMS_SETTING
} from '../constants/register';

export const getCanRegister = (params) => ({
  type: GET_CAN_REGISTER,
  params,
});

export const setCanRegister = (canRegister = false) => ({
  type: CAN_REGISTER_SUCCESS,
  canRegister,
});

export const doRegister = (params, isResendCode = false) => ({
  type: DO_REGISTER,
  params,
  isResendCode
});

export const setMsg = (msgType, msg, show) => ({
  type: SET_MSG,
  msgType,
  msg,
  show
});

export const setRegisterErrors = errors => ({
  type: SET_REGISTER_ERRORS,
  errors
});

export const setRegisterSuccess = success => ({
  type: SET_REGISTER_SUCCESS,
  success
});

export const setVerifSuccess = success => ({
  type: SET_VERIF_SUCCESS,
  success
});

export const verifyCode = params => ({
  type: VERIFY_REGISTRATION_CODE,
  params
});

export const getPartner = (params) => ({
  type: GET_REGISTER_PARTNER,
  params
});

export const setPartner = (partner) => ({
  type: SET_REGISTER_PARTNER_SUCCESS,
  partner
});

export const getWelcomePromotions = () => ({
  type: GET_WELCOME_PROMOTIONS
});

export const setWelcomePromotions = welcomePromotions => ({
  type: SET_WELCOME_PROMOTIONS,
  welcomePromotions
});

export const doRegisterPartner = (params, isResendCode = false) => ({
  type: DO_REGISTER_PARTNER,
  params,
  isResendCode
});

export const verifyPartnerCode = params => ({
  type: VERIFY_REGISTRATION_CODE_PARTNER,
  params
});

export const resetSuccess = () => ({
  type: RESET_SUCCESS
});

export const resetVerifyMessage = () => ({
  type: RESET_VERIFY_MESSAGE
});

export const getSMSSetting = () => ({
  type: GET_SMS_SETTING
});

export const setSMSSetting = (payload) => ({
  type: SET_SMS_SETTING,
  payload
});
