import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, NavLink } from 'react-router-dom';

// Material components
import { withStyles } from '@material-ui/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import themeStyles from './content-footer.theme.style';
import useComingSoon from '../../../hooks/use-coming-soon';
import {
  HomeIcon,
  SupportIcon,
  PromoIcon,
  WalletIcon,
  AccountIcon
} from '../../../components/custom/bottom.navigation';

import { isLogin } from '../../../utils/helpers/common';
import LoginDialog from '../content-toolbar/login-dialog';

 import {
   setNavOpened,
   toggleSidenav
 } from '../../../actions/layout';
 import { IS_PROD } from '../../../config/api';
 import Badge from '@material-ui/core/Badge';

const ContentFooter = (props) => {
  const { classes, pathname } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    btn, icon, label, selected, promotions
  } = classes;
  const { ComingSoonModal, ComingSoonPartnerModal, openComingSoonDialog } = useComingSoon();
  //const [index, setState] = useState(0);

  const [value, setValue] = useState("");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const openNav = () => {
    dispatch(toggleSidenav())
    dispatch(setNavOpened('bottomAccountIcon'))
  }

  useEffect(() => {
    setValue(pathname);
  }, [props]);

  const navLinkProps = {
    to: '/partners/register',
  };

  const [loginDialogToggle, setLoginDialogToggle] = useState(false);

  const player = useSelector(state => state.player);
  const { playerInfo } = player;
  const { unread_notice_count } = playerInfo || {};

  return (
    <div className={classes.root}>
      {
        // isAffiliate(pathname) &&
        // !(pathname.indexOf('/partners/register') > -1) &&
        // <div className="affiliate-action-btn">
        //   {/* {ComingSoonPartnerModal} */}
        //   <NavLink
        //     activeStyle={{ display: 'block' }}
        //     className="register"
        //     {...navLinkProps}
        //   >
        //     {t('join us now as joabet partner')}
        //   </NavLink>
        // </div>
      }

        <BottomNavigation
          onChange={handleChange}
          value={value}
          showLabels
        >
          <BottomNavigationAction
            component={Link}
            to="/"
            label={t('home')}
            icon={<HomeIcon className={icon} />}
            className={btn}
            classes={{ label, selected }}
            value='/'
          />
          <BottomNavigationAction
            component={Link}
            to="/support"
            label={t('support')}
            icon={<SupportIcon className={icon} />}
            className={btn}
            classes={{ label, selected }}
            value='/support'
          />
          <BottomNavigationAction
            className={`${btn} ${promotions}`}
            classes={{ label, selected }}
            component={Link}
            to={`${isLogin() ? '/promotions': '#'}`}
            label={t('promos')}
            icon={<PromoIcon className={icon} />}
            value='/promotions'
            onClick={() => {
              if(!isLogin()) {
                setLoginDialogToggle(true);
              }
            }}
          />
          {isLogin() 
            ?
          <BottomNavigationAction
            component={Link}
            to="/wallet"
            label={t('wallet')}
            icon={<WalletIcon className={icon} />}
            className={btn}
            classes={{ label, selected }}
            value='/wallet'
          />
            :
            <BottomNavigationAction
              component={Link}
              to="#"
              label={t('wallet')}
              icon={<WalletIcon className={icon} />}
              className={btn}
              classes={{ label, selected }}
              value='/wallet'
              onClick={() => setLoginDialogToggle(!loginDialogToggle)}
            />
          }
          {isLogin() 
            ?
          <BottomNavigationAction
            component={Link}
            onClick={openNav}
            to="#"
            label={t('account')}
            icon={
              <Badge badgeContent={unread_notice_count} max={9}>
                <AccountIcon className={icon} />
              </Badge>
            }
            className={classes.btn}
            classes={{ label, selected }}
            value='/account'
          />
            :
            <BottomNavigationAction
              component={Link}
              to="#"
              label={t('account')}
              icon={<AccountIcon className={icon} />}
              className={classes.btn}
              classes={{ label, selected }}
              value='/account'
              onClick={() => setLoginDialogToggle(!loginDialogToggle)}
            />
          }
        </BottomNavigation>
      {
        // !isLogin() &&
        // !isAffiliate(pathname) &&
        // !(pathname.indexOf('login') > -1) &&
        // <div className="action-btn">
        //   {ComingSoonModal}
        //   <NavLink
        //     activeStyle={{ display: 'none' }}
        //     disabled={true}
        //     to="#"
        //     className="register"
        //   >
        //     {t('register')}
        //   </NavLink>
        //   <NavLink
        //     activeStyle={{ display: pathname.indexOf('register') || pathname.indexOf('/partners/register')  > -1 ? 'none' : 'block' }}
        //     style={{ display: pathname.indexOf('register') > -1 || pathname.indexOf('/partners/register') > -1 ? 'none' : 'block' }}
        //     to="/login"
        //     className="login"
        //   >
        //     {t('login')}
        //   </NavLink>
        //   <NavLink
        //     style={{ display: pathname.indexOf('register') > -1 || pathname.indexOf('/partners/register')> -1 ? 'block' : 'none' }}
        //     to="/"
        //     className="skip"
        //   >
        //     {t('skip')}
        //   </NavLink>
        // </div>
      }
      <LoginDialog open={loginDialogToggle} openHandle={() => setLoginDialogToggle(!loginDialogToggle)} />
    </div>
  );
};

ContentFooter.defaultProps = {
  pathname: ''
};

ContentFooter.propTypes = {
  classes: PropTypes.shape({
    btn: PropTypes.string,
    icon: PropTypes.string,
    label: PropTypes.string,
    selected: PropTypes.string,
    root: PropTypes.string,
    promotions: PropTypes.string
  }).isRequired,
  pathname: PropTypes.string
};


export default withStyles(themeStyles)(ContentFooter);
