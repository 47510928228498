import { SET_POPUP_NOTICE } from "../constants/popup.notice";

const initialState = {
  content: [],
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_POPUP_NOTICE:

      return {
          ...state,
          content: action.content
      }
    default:
      return state;
  }
}
