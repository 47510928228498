const styles = theme => ({
  root: {},
  customDialog: {
    '& .MuiPaper-root': {
      '& .MuiDialogContent-root':{
        padding: '0px',
        textAlign: 'left',
        '& .title': {
          fontSize: '1rem'
        },
        '& .secondary-color': {
          color: theme.palette.text.default,
          //test
        },
        '& .img-container': {
          position: 'relative',
          '& .text-content' : {
            // height: '400px',
            overflowY: 'auto',
            paddingLeft: '5px',
            paddingRight: '5px',
          }
        },
        '& .btn-container': {
          position: 'absolute',
          bottom: '30px',
          left: '0',
          right: '0',
          padding: '10px'
        },
        '& .btn-container-2': {
          marginBottom: '20px',
          paddingLeft: '10px',
          paddingRight: '10px',
        }
      },
      '& .MuiTypography-root': {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '8px',
        color: theme.palette.text.default,
        textTransform: 'uppercase',
        fontSize: '1rem',
        '& p': {
          margin: 0,
          position: 'absolute',
          textAlign: 'center',
          width: '100%',
        },
        '& .MuiIconButton-root': {
          padding: 0,
          '& svg': {
            fontSize: '20px',
            paddingBottom: 0,
            fill: theme.palette.text.secondary
          },
        },
      },
    },
  },
  customScrollPaper: {
    backgroundColor: 'transparent !important',
    border: '0px',
	  overflow: 'unset !important',
      '& .button': {
          position: 'absolute',
          height: '35px',
          width: '35px',
          backgroundColor: '#141F22 !important',
          borderRadius: '50%',
          opacity: 1,
          right: '-19px !important',
          top: '1px !important',
          zIndex: '15',
      }
  }
});

  export default styles;
