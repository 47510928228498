import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/styles/withStyles';
import { useTranslation } from 'react-i18next';
import ReactHtmlParser from "react-html-parser";
import styles from './styles';
import CustomForm from '../../../../components/custom/form';
import CustomButton from '../../../../components/custom/button';
import CustomNotification from '../../../../components/custom/notification';
import useComingSoon from '../../../../hooks/use-coming-soon';
import {
  validateUser,
  setAuthentication,
  setLoginError,
} from '../../../../actions/authentication';
import { setLoading } from '../../../../actions/common';
import { setPlayerInfo } from '../../../../actions/player';
import {
  checkParams,
  generateParams,
  isLogin,
} from '../../../..//utils/helpers/common';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { withRouter } from 'react-router-dom';

// import FormGroup from '@material-ui/core/FormGroup';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Checkbox from '@material-ui/core/Checkbox';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const LoginDialog = ({ classes, history, open, openHandle }) => {
  const valuesDefaults = {
    login_name: {
      required: true,
      value: '',
    },
    password: {
      required: true,
      value: '',
    },
    deviceUrl: {
      value: window.location.href,
    }
  };
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loginState = useSelector(state => state.auth);
  const registerState = useSelector(state => state.register);
  const { errors, isAuthenticated, showLoading } = loginState;
  const { canRegister } = registerState || {};
  const { ComingSoonModal, openComingSoonDialog } = useComingSoon();
  const [fields, setFields] = useState([
    {
      label: t('id'),
      type: 'text',
      id: 'login_name',
      error: 'ID is required.',
      name: 'login_name',
     // icon: UserID,
      variant: 'filled'
    },
    {
      label: t('password'),
      type: 'password',
      id: 'password',
      error: 'Password is required.',
      name: 'password',
      show_password: false,
     // icon: Password,
      variant: 'filled'
    },
  ]);

  const [values, setValues] = useState(valuesDefaults);
  const [showMessage, toggleMessage] = useState({
    message: '',
    show: false,
  });
  const { root } = classes;

  // useEffect(() => {
  //   if(localStorage.getItem("appToken")){
  //     history.push('/')
  //   }
  //   if (isLogin()) {
  //     history.push('/');
  //   }

  //   dispatch(setPlayerInfo(null));
  // }, []);

  // useEffect(() => {
  //   if (isAuthenticated) {
  //     history.push('/');
  //     dispatch(setAuthentication(false));
  //   }
  // }, [isAuthenticated]);

  useEffect(() => {
    if (errors && errors.login_name) {
      let message = errors.login_name[0];

      toggleMessage({
        ...showMessage,
        message,
        show: true,
      });
      dispatch(setLoginError({}));
    }
  }, [errors]);

  function handleChange(event) {
    const { name, value } = event.target;

    setValues({
      ...values,
      [name]: { ...values[name], value },
    });
  }

  const doLogin = useCallback(e => {
    e.preventDefault();

    const params = generateParams(values);
    params.domain = window.location.hostname === "localhost" ? "w365b.com" : window.location.hostname;
    dispatch(setLoading(true));
    dispatch(validateUser(params));
  });

  const handleClickShowPassword = useCallback(index => {
    const holder = [...fields];

    holder[index].show_password = !holder[index].show_password;

    setFields(holder);
  });

  const toRegister = useCallback(() => {
    openHandle();
    history.push('/register');
  });

  const closeHandle = () => {
    const protectedGames = ['/minigame/cockfighting', '/live-vegas'];
    openHandle();
    setValues(valuesDefaults);

    if(protectedGames.includes(window.location.pathname)) {
      history.push('/');
    }
  }

  return (
    <Dialog
            id="login-modal"
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={closeHandle}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            fullWidth
        >
            <DialogTitle id="alert-dialog-slide-title" style={{padding: '5px 15px 0px 15px',textAlign:'right'}}>
                <IconButton aria-label="close" onClick={closeHandle} style={{padding:0}}>
                    <CloseIcon style={{color:'#9FA2AB'}} />
                </IconButton>
            </DialogTitle>
            <DialogContent style={{padding:0}}>
    <div className={root}>
      {ComingSoonModal}
      <div className="title" style={{marginTop:0,fontWeight:700,fontSize:'23px'}}>{t('members login')}</div>
      <CustomForm
        onSubmit={doLogin}
        className="form"
        onChange={handleChange}
        fields={fields}
        handleClickShowPassword={handleClickShowPassword}
        {...values}
      >
        {/* <FormGroup row id="keep-logged-in">
          <FormControlLabel control={<Checkbox name="checkedC" />} label={t('keep me logged in')} />
        </FormGroup> */}
        <CustomButton
          fullWidth
          hasLoading
          customClass="submit"
          variant="contained"
          color="secondary"
          type="submit"
          disabled={checkParams(values)}
          showIcon={showLoading}
          text={t('login')}
        />
      </CustomForm>
      {canRegister && (
        <>
          <div className="separator" data-content="">
            {t('or')}
          </div>
          <CustomButton
            fullWidth
            hasLoading
            customClass="register"
            variant="contained"
            onClick={toRegister}
            //showIcon={showLoading}
            text={t('register')}
          />
        </>
      )}
      <div className="problem-login">
        {ReactHtmlParser(t('problem logging in'))}
        <span>{t('cs support email')}</span>
      </div>

      <CustomNotification
        type="error"
        message={showMessage.message}
        onClose={() =>
          toggleMessage({ ...showMessage, message: '', show: false })
        }
        open={showMessage.show}
      />
    </div>
    </DialogContent>
    </Dialog>
  );
};

LoginDialog.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default withStyles(styles)(withRouter(LoginDialog));
