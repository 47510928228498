import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { OPEN_GAME } from '../constants/game';
import { getLanguage } from '../utils/helpers/common';
import sportSvc from '../utils/services/sports';
import { openGameSuccess, openGameError } from '../actions/game';
import { showGameMessage } from '../actions/common';

import { 
    resetOpenCasinoError 
} from '../actions/slot.games';

import { 
    resetOpenLiveCasinoError 
} from '../actions/live.casino';
import {
    resetPlayState,
} from '../actions/sports';

import games from '../config/games';

function* openGame(action) {
    try {
        let params, request, url;
        const language = getLanguage();
        const game = games.find(game => game.route === window.location.pathname);

        if (action.isLogin) {
            const playerState = yield select(state => state.player);

            // if(playerState.playerInfo !== null) {
                params = {
                    brandID: 5,
                    currency: playerState.playerInfo.player.currency.Abbreviation,
                    gameID: game.gameID,
                    gameType: game.gameType,
                    hashid: '',
                    isMobile: 1,
                    lang: language,
                    launchType: 'html5',
                    mode: 1,
                    playerID: playerState.playerInfo.player.PlayerID,
                    productID: game.productID,
                    providerID: game.providerID,
                    integrationID: game.integrationID,
                    username: playerState.playerInfo.username
                };
            // }
        } else {
            params = {
                IsLive: 0,
                gameID: game.gameID,
                mode: 0,
                isMobile: 1,
                gameType: game.gameType,
                lang: language,
                integrationID: game.integrationID,
                providerID: game.providerID,
                productID: game.productID,
                domain: window.location.hostname === 'localhost' ? 'mfrontend-test.15gaming.com' : window.location.hostname
            };
        }

        request = yield call(sportSvc.openSports, params);
        url = (request.data.game_url === '' || request.data.game_url === null) ? request.data.url : request.data.game_url;
        yield put(openGameSuccess(url));
    } catch(err) {
        const { status } = err.response || {};

        if (status !== 200) {
            yield put(resetOpenCasinoError())
            yield put(resetOpenLiveCasinoError())
            yield put(resetPlayState())
            yield put(openGameError(err.response.data.errors));
            yield put(showGameMessage());
        }
    }
}

export default function* gameWatcher() {
    yield all([takeLatest(OPEN_GAME, openGame)]);
}
