const styles = theme => ({
  root: {
    '& .loading': {
      height: '20px',
      verticalAlign: 'middle'
    },
    '& .balance-container': {
      fontSize: '9px',
      fontWeight: 700,
    '& .balance-item': {
      textAlign: 'right'
    },
    '& .balance-item': {
        textAlign: 'right',
        '& .balance-title': {
          color: '#B6B6B6'
        },
        '& .balance-value': {
          color: theme.palette.primary.main,
          // fontWeight: 'bold'
        }
      }
    }
  }
});

export default styles;
