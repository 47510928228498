export const DO_REGISTER = 'DO_REGISTER';
export const GET_CAN_REGISTER = 'GET_CAN_REGISTER';
export const CAN_REGISTER_SUCCESS = 'CAN_REGISTER_SUCCESS';
export const SET_MSG = 'SET_MSG';
export const SET_REGISTER_ERRORS = 'SET_REGISTER_ERRORS';
export const SET_REGISTER_SUCCESS = 'SET_REGISTER_SUCCESS';
export const SET_VERIF_SUCCESS = 'SET_VERIF_SUCCESS';
export const VERIFY_REGISTRATION_CODE = 'VERIFY_REGISTRATION_CODE';
export const GET_REGISTER_PARTNER = 'GET_REGISTER_PARTNER';
export const SET_REGISTER_PARTNER_SUCCESS = 'SET_REGISTER_PARTNER_SUCCESS';
export const SET_PARTNER_STATUS = 'SET_PARTNER_STATUS';
export const GET_WELCOME_PROMOTIONS = 'GET_WELCOME_PROMOTIONS';
export const SET_WELCOME_PROMOTIONS = 'SET_WELCOME_PROMOTIONS';

export const DO_REGISTER_PARTNER = 'DO_REGISTER_PARTNER';
export const VERIFY_REGISTRATION_CODE_PARTNER = 'VERIFY_REGISTRATION_CODE_PARTNER';

export const RESET_SUCCESS = 'RESET_SUCCESS';
export const RESET_VERIFY_MESSAGE = 'RESET_VERIFY_MESSAGE';

export const GET_SMS_SETTING = 'GET_SMS_SETTING';
export const SET_SMS_SETTING = 'SET_SMS_SETTING';
