import {
  LOAD_MORE_BONUS_SUCCESS,
  LOAD_MORE_BONUS_HISTORY_SUCCESS,
  LOAD_MORE_CASH_SUCCESS,
  LOAD_MORE_TRANSACTIONS_SUCCESS,
  LOAD_MORE_MESSAGES_SUCCESS,
  ONGOING_PROMOTION_ERROR,
  ONGOING_PROMOTION_SUCCESS,
  PLAYER_PROMOTION_MIN_DEPOSIT_SUCCESS,
  SET_ALL_GAMES_WALLET,
  SET_ERROR,
  SET_GAME_WALLET,
  SET_ONGOING_PROMOTION,
  SET_PLAYER_BONUS,
  SET_PLAYER_BONUS_HISTORY,
  SET_PLAYER_CASH,
  SET_PLAYER_BANK_ACCOUNT,
  SET_PLAYER_CURRENT_TRANSACTIONS,
  SET_PLAYER_EXISTING_SUBSCRIPTION,
  SET_PLAYER_INFO,
  SET_PLAYER_MESSAGES,
  SET_PLAYER_TRANSACTIONS,
  SET_RESPONSE_MESSAGE,
  SET_UNAUTHORIZED,
  SET_PLAYER_HAS_PENDING_BET,
  SUCCESS_REQUEST_STATUS,
  SET_ALL_GAMES_WALLET_STATUS,
  SET_PLAYER_DEPOSIT_ACCOUNT,
  ERROR_PLAYER_DEPOSIT_ACCOUNT,
  SET_PAYMENT_TRON_EXCHANGE,
  SET_PAYMENT_TRON_EXCHANGE_BALANCE,
  SET_PAYMENT_TRON_EXCHANGE_PURCHASE,
  RESET_PAYMENT_TRON_EXCHANGE_BALANCE,
  RESET_PAYMENT_TRON_EXCHANGE_PURCHASE,
  SET_PAYMENT_TRON_EXCHANGE_BTN_DISABLED,
  SET_DEPOSIT_OPTION,
  SET_BALANCE_INQUIRY_LOADING,
  SET_BALANCE_INQUIRY_BTN_DISABLED,
  SET_CHUNG_DAM_INFO,
  SET_DEPOSIT_PROMOTION,
  SET_UPDATE_PLAYER_INFO_SUCCESS,
  SET_MESSAGE_SUCCESS,
  SET_CHECK_DEPOSIT_PROMOTION,
} from "../constants/player";
import { SET_LOADING } from "../constants/common";
import { INITIAL_STATUS } from "../constants/promotions";

const initialState = {
  allGamesWallet: null,
  bonusLastPage: 1,
  cashLastPage: 1,
  bonusHistoryLastPage: 1,
  messagesLastPage: 1,
  errors: {},
  gameWallets: [],
  isUnauthorized: false,
  message: {
    show: false,
    msg: '',
    type: '',
  },
  pendingSubscription: null,
  playerBonus: [],
  playerCash: [],
  playerCurrentTransactions: [],
  playerInfo: null,
  playerMessages: [],
  playerTransactions: [],
  playerBankAccount: {
    minWithdrawal: 0,
    maxWithdrawal: 0,
    minDeposit: 0,
    maxDeposit: 0,
  },
  promotionMinDeposit: 0,
  showLoading: false,
  subscription: {},
  subscriptionError: {},
  transactionsLastPage: 1,
  hasPendingBet: false,
  allGamesWalletStatus: INITIAL_STATUS,
  playerInfoStatus: INITIAL_STATUS,
  playerBonusStats: INITIAL_STATUS,
  playerMessageStatus: INITIAL_STATUS,
  depositAccount: [],
  depositAccountErrors: [],
  depositTron: {},
  depositTronBalance: {},
  depositTronPurchase: {
    url: '',
    content:{
      action_url: '',
      mb_id: '',
      mb_password: '',
    }
  },
  depositTronBtnDisbled: false,
  optionDeposit:[],
  balanceInquiryLoading: false,
  balanceInquiryBtnDisabled: false,
  chungDamInfo: {
    minDepositAmount: 10000,
    maxDepositAmount: 5000000
  },
  depositPromotion: {},
  updatePlayerInfoSuccess: {},
  messageSuccess: {},
  activePromotion: false,
};

const playerReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_MORE_BONUS_SUCCESS:
      return {
        ...state,
        playerBonus: [...state.playerBonus, ...action.bonus]
      };
    case LOAD_MORE_CASH_SUCCESS:
      return {
        ...state,
        playerCash: [...state.playerCash, ...action.cash]
      };
    case LOAD_MORE_BONUS_HISTORY_SUCCESS:
      return {
        ...state,
        playerBonusHistory: [...state.playerBonusHistory, ...action.bonus]
      };
    case LOAD_MORE_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        playerTransactions: [
          ...state.playerTransactions,
          ...action.transactions
        ]
      };
    case LOAD_MORE_MESSAGES_SUCCESS:
      return {
        ...state,
        playerMessages: [
          ...state.playerMessages,
          ...action.messages
        ]
      };
    case SET_ALL_GAMES_WALLET:
      return {
        ...state,
        allGamesWallet: action.balance,
        allGamesWalletStatus: SUCCESS_REQUEST_STATUS
      };
    case SET_ALL_GAMES_WALLET_STATUS:
      return {
        ...state,
        allGamesWalletStatus: action.allGameWalletStatus
      };
    case SET_ERROR:
      return {
        ...state,
        errors: action.errors
      };
    case SET_GAME_WALLET:
      return {
        ...state,
        gameWallets: action.balances
      };
    case SET_LOADING:
      return {
        ...state,
        showLoading: action.show
      };
      case SET_PLAYER_BANK_ACCOUNT:
        return {
          ...state,
          playerBankAccount: action.bankAccount,
        };
    case SET_PLAYER_BONUS:
      return {
        ...state,
        playerBonus: action.bonus,
        bonusLastPage: action.page,
        playerBonusStatus: action.status,
      };
    case SET_PLAYER_BONUS_HISTORY:
      return {
        ...state,
        playerBonusHistory: action.bonus,
        bonusHistoryLastPage: action.page
      };
    case SET_PLAYER_CASH:
      return {
        ...state,
        playerCash: action.cash,
        cashLastPage: action.page,
        playerCashStatus: action.status,
      };
    case SET_PLAYER_CURRENT_TRANSACTIONS:
      return {
        ...state,
        playerCurrentTransactions: action.transactions
      };
    case SET_ONGOING_PROMOTION:
      return {
        ...state,
        subscription: action.subscription,
      };
    case PLAYER_PROMOTION_MIN_DEPOSIT_SUCCESS:
        return {
          ...state,
          promotionMinDeposit: action.promotionMinDeposit,
        };
    case ONGOING_PROMOTION_ERROR:
        return {
          ...state,
          subscriptionError: action.error,
        };
    case ONGOING_PROMOTION_SUCCESS:
        return {
          ...state,
          subscriptionSuccess: action.success,
        };
    case SET_PLAYER_EXISTING_SUBSCRIPTION:
      return {
        ...state,
        pendingSubscription: action.subscription
      };
    case SET_PLAYER_HAS_PENDING_BET:
      return {
        ...state,
        hasPendingBet: action.hasPendingBet
      };
    case SET_PLAYER_INFO:
      return {
        ...state,
        playerInfo: action.data
      };
    case SET_PLAYER_MESSAGES:
      return {
        ...state,
        playerMessages: action.messages,
        messagesLastPage: action.page,
        playerMessageStatus: action.status
      };
    case SET_PLAYER_TRANSACTIONS:
      return {
        ...state,
        playerTransactions: action.transactions,
        transactionsLastPage: action.page,
        playerTransactionStatus: action.status,
      };
    case SET_RESPONSE_MESSAGE:
      return {
        ...state,
        message: {
          ...state.message,
          msg: action.msg,
          show: action.show,
          type: action.msg_type
        }
      };
    case SET_UNAUTHORIZED:
      return {
        ...state,
        isUnauthorized: action.status
      };
    case SET_PLAYER_DEPOSIT_ACCOUNT:
      return {
          ...state,
          depositAccount: action.params
      };
    case ERROR_PLAYER_DEPOSIT_ACCOUNT: 
      return {
        ...state,
        depositAccountErrors: action.params,
        depositAccount: []
      }
    case SET_PAYMENT_TRON_EXCHANGE:
      return {
          ...state,
          depositTron: action.params
      };
    case SET_PAYMENT_TRON_EXCHANGE_BALANCE:
      return {
          ...state,
          depositTronBalance: action.params
      };
    case RESET_PAYMENT_TRON_EXCHANGE_BALANCE:
      return {
          ...state,
          depositTronBalance: {}
    };
    case RESET_PAYMENT_TRON_EXCHANGE_PURCHASE:
      return {
          ...state,
          depositTronPurchase: {}
    };
    case SET_PAYMENT_TRON_EXCHANGE_PURCHASE:
      return {
          ...state,
          depositTronPurchase: action.params
    };
    case SET_PAYMENT_TRON_EXCHANGE_BTN_DISABLED:
      return {
          ...state,
          depositTronBtnDisbled: action.params
    };
    case SET_DEPOSIT_OPTION:
      return {
          ...state,
          optionDeposit: action.params
    };
    case SET_BALANCE_INQUIRY_LOADING:
      return {
        ...state,
        balanceInquiryLoading: action.show
    };
    case SET_BALANCE_INQUIRY_BTN_DISABLED:
      return {
        ...state,
        balanceInquiryBtnDisabled: action.params
    };
    case SET_CHUNG_DAM_INFO:
      return {
        ...state,
        chungDamInfo: action.params
    };
    case SET_DEPOSIT_PROMOTION:
      return {
        ...state,
        depositPromotion: action.params
	  };
    case SET_UPDATE_PLAYER_INFO_SUCCESS:
      return {
        ...state,
        updatePlayerInfoSuccess: action.params
	  };
    case SET_MESSAGE_SUCCESS:
      return {
        ...state,
        messageSuccess: action.params
	  };
    case SET_CHECK_DEPOSIT_PROMOTION:
      return {
        ...state,
        activePromotion: action.params
	  };
    default:
      return state;
  }
};

export default playerReducer;
