export const REFRESH_WALLET = 'REFRESH_WALLET';
export const SET_NO_BANK = 'SET_NO_BANK';
export const SET_WALLET_ERRORS = 'SET_WALLET_ERRORS';
export const SET_WALLET_LOADING = 'SET_WALLET_LOADING';
export const SET_WALLET_RESPONSE_MESSAGE =
  'SET_WALLET_RESPONSE_MESSAGE';
export const SUBMIT_DEPOSIT = 'SUBMIT_DEPOSIT';
export const SUBMIT_TRON_DEPOSIT = 'SUBMIT_TRON_DEPOSIT';
export const SUBMIT_TRON_DEPOSIT_SUCCESS = 'SUBMIT_TRON_DEPOSIT_SUCCESS';
export const SUBMIT_DEPOSIT_SUCCESS = 'SUBMIT_DEPOSIT_SUCCESS';
export const SUBMIT_TRANSFER_REQUEST = 'SUBMIT_TRANSFER_REQUEST';
export const SUBMIT_TRANSFER_REQUEST_SUCCESS =
  'SUBMIT_TRANSFER_REQUEST_SUCCESS';
export const SUBMIT_WITHDRAWAL_SUCCESS = 'SUBMIT_WITHDRAWAL_SUCCESS';
export const SUBMIT_PLAYER_WITHDRAWAL = 'SUBMIT_PLAYER_WITHDRAWAL';
export const SUBMIT_P2P_TRANSFER_REQUEST = 'SUBMIT_P2P_TRANSFER_REQUEST';
export const RESEND_SMS = 'RESEND_SMS';
export const SET_DEPOSIT_SUCCESS = 'SET_DEPOSIT_SUCCESS';
export const SET_WITHDRAWAL_SUCCESS = 'SET_WITHDRAWAL_SUCCESS';
export const GET_DEPOSIT_COUNT = 'GET_DEPOSIT_COUNT';
export const SET_DEPOSIT_COUNT = 'SET_DEPOSIT_COUNT';
export const SET_DEPOSIT_ERROR = 'SET_DEPOSIT_ERROR';