import {
  GET_SESSIONS,
  SAVE_LOGIN_SESSIONS,
  CHECK_SESSIONS,
  SHOW_SESSION_MODAL,
  HIDE_SESSION_MODAL,
  CLEAR_SESSIONS
} from '../constants/session';


export const getSessions = () => {
  const params = {
    type: GET_SESSIONS
    // payload
  };
  return params;
};

export const setSessions = (payload) => {
  const params = {
    type: SAVE_LOGIN_SESSIONS,
    payload
  };
  return params;
};

export const checkSessions = () => {
  const params = {
    type: CHECK_SESSIONS
  };
  return params;
};

export const showSessionModal = () => {
  return {
    type: SHOW_SESSION_MODAL
  }
}

export const hideSessionModal = () => {
  return {
    type: HIDE_SESSION_MODAL
  }
}

export const clearSession = () => {
  return {
    type: CLEAR_SESSIONS
  }
}
