import {
  GET_PROMOTIONS,
  SET_PROMOTIONS,
  GET_PROMOTIONS_CATEGORY,
  SET_PROMOTIONS_CATEGORY,
  SET_ACTIVE_PROMOTION,
} from '../constants/promotions.js';

export const getPromotions = () => ({
  type: GET_PROMOTIONS,
});

export const setPromotions = response => ({
  type: SET_PROMOTIONS,
  response,
});

export const getPromotionsCategory = () => ({
  type: GET_PROMOTIONS_CATEGORY,
});

export const setPromotionsCategory = payload => ({
  type: SET_PROMOTIONS_CATEGORY,
  payload,
});

export const setActivePromotion = payload => ({
  type: SET_ACTIVE_PROMOTION,
  payload,
});
