import React from 'react';
import { withStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';


import Loading from '../../../../../assets/images/loading.gif';
import {
  displayAmount,
} from '../../../../../utils/helpers/common';

import usePlayerBalance from '../../../../../hooks/usePlayerBalance';

import themeStyles from './styles';

function Balance(props){
  const { classes } = props;
  const {
    rebateBalance,
    allGamesWallet,
    isLoading
  } = usePlayerBalance();

  const { t } = useTranslation();
  return (
    <div className={classes.root}>
      <div className="balance-container">
        <div className="balance-item">
          <span className="balance-title">{t('total balance')}: </span>
          <span className="balance-value">
            {isLoading ?
              <img className={'loading'} alt="loading" src={Loading} /> : displayAmount(`${allGamesWallet}`)
            }
          </span>
        </div>
        <div className="balance-item">
          <span className="balance-title">{t('rebate label')}: </span>
          <span className="balance-value">
            {isLoading ?
              <img className={'loading'} alt="loading" src={Loading} /> : displayAmount(`${rebateBalance}`)
            }
          </span>
        </div>
      </div>
    </div>
  )
};

export default withStyles(themeStyles)(Balance);
