import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import WebFont from 'webfontloader';
import { Provider } from 'react-redux';
import ClearCache from 'react-clear-cache';
import './index.css';
import App from './app.component';
import i18n from './translations/i18n';
import ScrollToTop from './components/custom/scroll-to-top';
import NotSupported from './containers/browser-restriction';
import registerServiceWorker from './registerServiceWorker';

import store from './store';
import { isLogin, isLogout } from './utils/helpers/common';
import {
  isChrome,
  isSafari,
  isFirefox,
  isEdge,
  isIE,
  isOpera
} from "react-device-detect"; 

// WebFont.load({
//   google: {
//     families: ['Roboto:300,400,400i,500,600,700'],
//   },
// });

// const store = createStore(portalApp);
window.addEventListener('storage', function(){
    const count = localStorage.getItem("reloadCount");
    if(isLogout() && count === null){
        localStorage.setItem('reloadCount', 1)
        window.location.reload();
    }else if(isLogin()){
        localStorage.removeItem('reloadCount')
    }
})

const userBrowserDetection = () => {
  return isChrome || isFirefox || isSafari || isEdge
}

ReactDOM.render(
  <Provider store={store}>
    <ClearCache>
        {({isLatestVersion, emptyCacheStorage}) =>
            {
                !isLatestVersion &&
                    emptyCacheStorage() &&
                    localStorage.clear()
                return null
            }
        }
    </ClearCache>
    <Router>
      <I18nextProvider i18n={i18n}>
        <ScrollToTop />
        {userBrowserDetection() ?
            <App /> : <NotSupported/>
	      }
      </I18nextProvider>
    </Router>
  </Provider>,
  document.getElementById('root'),
);
registerServiceWorker();
