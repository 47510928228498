import { OPEN_BETCONSTRUCT, OPENING_BETCONSTRUCT_SUCCESS, OPENING_BETCONSTRUCT_ERROR, RESET_OPEN_BETCONSTRUCT_ERROR, SET_INITIAL_BETCONSTRUCT } from '../constants/betconstruct';
  
export const openBetConstruct = (isLogin) => ({
    type: OPEN_BETCONSTRUCT,
    isLogin
});
  
export const openingBetConstructSuccess = (url) => ({
    type: OPENING_BETCONSTRUCT_SUCCESS,
    url
});
  
export const openingBetConstructError = response => ({
    type: OPENING_BETCONSTRUCT_ERROR,
    sucess: false,
    errors: response,
    loading: false
});

export const resetOpenBetConstructError = response => ({
    type: RESET_OPEN_BETCONSTRUCT_ERROR,
    response,
});

export const resetBetConstructState = response => ({
    type: SET_INITIAL_BETCONSTRUCT,
    response,
});
