import {
  PLAY,
  PLAY_LIVE_CASINO,
  PLAY_SUCCESS,
  PROCEED_PLAY_CASINO_GAME,
  RESET_PLAY_STATE,
  GET_GAME_LIVE_CASINO_CATEGORIES,
  GET_GAME_LIVE_CASINO_CATEGORIES_SUCCESS,
  OPEN_LIVE_CASINO_ERROR,
  RESET_OPEN_LIVE_CASINO_ERROR,
} from '../constants/live.casino';

export const play = params => ({
  type: PLAY,
  params,
});

export const playLiveCasino = params => ({
  type: PLAY_LIVE_CASINO,
  params,
});

export const proceedToPlay = (status = false) => ({
  type: PROCEED_PLAY_CASINO_GAME,
  status,
});

export const setPlaySuccess = response => ({
  type: PLAY_SUCCESS,
  response,
});

export const resetPlayState = response => ({
  type: RESET_PLAY_STATE,
  response,
});

export const getGameCategories = params => ({
  type: GET_GAME_LIVE_CASINO_CATEGORIES,
  params, 
});

export const getGameCategoriesSuccess = response => ({
  type: GET_GAME_LIVE_CASINO_CATEGORIES_SUCCESS,
  response,
});

export const openLiveCasinoError = response => ({
  type: OPEN_LIVE_CASINO_ERROR,
  response,
});

export const resetOpenLiveCasinoError = response => ({
  type: RESET_OPEN_LIVE_CASINO_ERROR,
  response,
});