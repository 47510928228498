import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from "i18next-http-backend";
import { ROOT_URL, BRAND_TOKEN } from '../config/api';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .init({
    backend: {
      loadPath: function(lng, ns) {
        return `${ROOT_URL}/${ns}/${lng}.json`;
      },
      customHeaders: () => ({
        'brand-token': BRAND_TOKEN,
      }),
      crossDomain: false,
      withCredentials: false,
      reloadInterval: false,
    },
    fallbackLng: false,
    debug: false,
    load: 'languageOnly',
    ns: 'translations',
    defaultNS: 'translations',
    fallbackNS: false,
    // supportedLngs: ['en-US', 'ko-KR'],

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false, // not needed for react!!
      formatSeparator: ',',
    },

    react: {
      useSuspense: false,
      wait: true,
    },
  });

  //set default language to kr
i18n.on('initialized', function (options) {
  if (localStorage.getItem('loaded') === null) {
    localStorage.setItem('loaded', 1);
    i18n.changeLanguage('ko-KR');
  }
})

export default i18n;
