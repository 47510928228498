import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import List from '@material-ui/core/List';
import Skeleton from '@material-ui/lab/Skeleton';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import MenuSidenavItem from '../menu-sidenav-item/menu-sidenav-item.component';
import MenuRightWalletInfo from '../../../components/custom/menu-right-wallet-info';
import styles from './menu-sidenav.style';

import usePlayerBalance from '../../../hooks/usePlayerBalance';

import { menuAccountItems, menuItems } from '../../../config';
import {
  MainWalletIcon,
  RebateWalletIcon,
  SideMenuTotalBalanceIcon,
} from '../../../components/custom/joabet.icons';

import {
  displayAmount,
  isLogin,
  generateParams
} from '../../../utils/helpers/common';
import { toggleSidenav } from '../../../actions/layout';
import {
  getPlayerMessages,
  setPlayerMessages
} from '../../../actions/player';
import {
  INITIAL_STATUS,
} from '../../../constants/player';

import {
  getBrandGameSetup
} from '../../../actions/page';

const MenuSidenav = ({ classes }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const playerState = useSelector(state => state.player);
  const { navOpened } = useSelector(state => state.layout);
  const { playerInfo } = playerState;
  const { player, wallet, unread_message_replies_count, unread_notice_count } = playerInfo || {};
  const { playerMessages } = playerState;
  const ref = React.createRef();
  const { rebateBalance, allGamesWallet } = usePlayerBalance();
  const scrollToBot = () => {
    if(ref.current){
      ref.current.scrollTo(0, 1000);
    }
  }
  const brandGameSetup = useSelector((state) => state.page.brandGameSetup);

  const [values, setValue] = useState({
    date: { value: '30' },
    gmt: { value: '+09:00' },
    message_type: { value: 'message' },
    hours: { value: '09' },
    minutes: { value: '00' },
    operator: { value: '+' },
    per_page: { value: '10' },
    page: { value: 1 },
  });

  const [repliedMessages, setRepliedMessages] = useState([]);

  useEffect( () => {
    dispatch(getBrandGameSetup({brandID:5}))
  },[])

  const constructNavItems = itemsArray => {
    const arr = [];
    const gameMenu = ['sports','live','e-sports','live casino','slots','powerball','minigame'] 
    itemsArray.forEach(el => {
        if (gameMenu.includes(el.title)) {
          const  product = productFilter(el.productID);
          const  provider = providerFilter(el.providerID);
          
          if (product || provider) {
            arr.push(
              <MenuSidenavItem
                scrollToBot={scrollToBot}
                title={el.title}
                href={el.href}
                key={el.title}
                Icon={el.Icon}
                newTab={el.newTab}
                type={el.type}
                value={el.value || ''}
                isAuth={el.isAuth || false}
                badge={el.badge || false}
                badgeCount={playerInfo && playerInfo[el.badgeCountName]}
                menuType={navOpened}
              >
                {el.children && constructNavItems(el.children)}
              </MenuSidenavItem>,
            );
          }
        } else {
          arr.push(
            <MenuSidenavItem
              scrollToBot={scrollToBot}
              title={el.title}
              href={el.href}
              key={el.title}
              Icon={el.Icon}
              newTab={el.newTab}
              type={el.type}
              value={el.value || ''}
              isAuth={el.isAuth || false}
              badge={el.badge || false}
              badgeCount={playerInfo && playerInfo[el.badgeCountName]}
              menuType={navOpened}
            >
              {el.children && constructNavItems(el.children)}
            </MenuSidenavItem>,
          );
        }
       
    });

    return <List className={classes.list}>{arr}</List>;
  };
  const productFilter = (ProductID) => {

    if (brandGameSetup !== undefined && ProductID !== undefined) {
        return brandGameSetup.find(el => {
            return el.ProductID === ProductID
        });
    }
    return;
  }
  const providerFilter = (ProviderID) => {
    if (brandGameSetup !== undefined && ProviderID !== undefined) {
        return brandGameSetup.find(el => {
            return el.ProviderID === ProviderID
        });
    }
  }

  return (
    <div className={classes.contentWrapper}>
      {isLogin() && navOpened === "bottomAccountIcon" && (
        <>
          <div style={{marginBottom:'10px'}}>
            <NavLink
              onClick={() => dispatch(toggleSidenav())}
              to="/wallet"
              className="initial-wallet-details"
            >
              <MenuRightWalletInfo
                  playerInfo={playerInfo}
                  walletName="total balance"
                  Icon={SideMenuTotalBalanceIcon}

                  balance={displayAmount(allGamesWallet)}
                />
            </NavLink>
          </div>
          <div style={{marginBottom:'10px'}}>
          <NavLink
            onClick={() => dispatch(toggleSidenav())}
            to="/wallet"
            className="initial-wallet-details"
          >
            <MenuRightWalletInfo
              playerInfo={playerInfo}
              walletName="rebate label"
              Icon={RebateWalletIcon}
              balance={displayAmount(rebateBalance)}
            />
          </NavLink>
          </div>
        </>
      )}
      {/* <span className={classes.hr} /> */}
      {/* <div className={classes.content} ref={ref}>
        {constructNavItems(
          isLogin() ? menuItems : beforeLoginMenuItems,
        )}
      </div> */}

      <div className={classes.content} ref={ref}>
        {constructNavItems(navOpened && navOpened === "topMenuIcon" ? menuItems: menuAccountItems)}
      </div>
    </div>
  );
};

MenuSidenav.propTypes = {
  classes: PropTypes.shape({
    balance: PropTypes.string,
    content: PropTypes.string,
    contentWrapper: PropTypes.string,
    hr: PropTypes.string,
    list: PropTypes.string,
    plus: PropTypes.string,
    skeleton: PropTypes.string,
    wallet: PropTypes.string,
  }).isRequired,
};

export default withStyles(styles)(MenuSidenav);
