import { SHOW_FILTER } from "../constants/messages";

const initialState = {
  show_filter: false
};

export default function messagesReducer(state = initialState, action) {
  switch (action.type) {
    case SHOW_FILTER:
      return {
        ...state,
        show_filter: action.show_filter
      };
    default:
      return state;
  }
}
