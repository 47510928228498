import React from 'react';
//import HomeIcon from './assets/icons/Home.svg';
import {
  SideMenuBankDetails,
  HomeIcon,
  SportsIcon,
  LiveSportsIcon,
  ESportsIcon,
  VirtualSportsIcon,
  PromotionIcon,
  PromotionIcon1,
  VipIcon,
  MobileIcon,
  SupportIcon,
  TranslateIcon,
  LogoutIcon,
  PartnersIcon,
  HomepagePowerballIcon,
  HomepagePartnersIcon,
  MainWalletIcon1,
  WithdrawalIcon,
  DepositIcon2,
  TransferIcon,
  SideMenuTransactionHistory,
  SideMenuBonus,
  SideMenuRebate,
  SideMenuProfile,
  SideMenuMessage,
  SideMenuHelpCenter,
  SideMenuChangePassword,
  SideMenuNotice,
  SlotsIcon,
  LiveCasinoIcon,
  VipClubIcon,
  MiniGameGrayIcon,
  PartnerIcon,
  CockFightIcon,
  BetConstructIcon,
  PokerIcon,
  RulesIcon,
  LiveSportIcon
} from './components/custom/joabet.icons';

import Signout from './assets/icons/Signout.svg';

import themes from './themes';

const iconStyle = {
  height: '1.375em',
  verticalAlign: 'middle'
};

const languageStyle = {
  height: '15px',
  width: '25px',
};

export const configuredTheme = themes[0].theme;

export const configuredLayout = {
  currentLayout: 'joabet', // 'classic',
  notificationsOpen: false,
};

export const dates = [
  { value: '0', name: 'today' },
  { value: '1', name: 'yesterday' },
  { value: '3', name: 'last 3 days' },
  { value: '7', name: 'last 7 days' },
  { value: '30', name: 'last 30 days' },
];

export const beforeLoginMenuItems = [
  {
    title: 'home',
    href: '/',
    Icon: HomeIcon,
  },
  {
    title: 'joa sports',
    href: '/joa-sports',
   // newTab: 'joa-sports',
    href: '#',
    newTab: 'sports-betting',
    Icon: SportsIcon,
  },
  /* {
    title: '15 live',
    href: '#',
    newTab: 'br-sports',
    icon: <img alt="15-sports" src={SoccerIcon} style={iconStyle} />,
  }, */
  {
    title: 'live sports',
    href: '/live-sports',
    //newTab: 'ow-sports',
    Icon: LiveSportsIcon,
  },
  // {
  //   title: 'e-sports',
  //   href: '/login',
  //   Icon: ESportsIcon,
  // },
  {
    title: 'Virtual Sports',
    href: '/virtual-sports',
    Icon: VirtualSportsIcon,
  },
  // {
  //   title: 'powerball',
  //   href: '/powerball',
  //   Icon: HomepagePowerballIcon
  // },
  // {
  //   title: 'live casino',
  //   href: '/live-casino',
  //   icon: (
  //     <img alt="live-casino" src={CasinoIcon} style={iconStyle} />
  //   ),
  // },
  // {
  //   title: 'slot games',
  //   href: '/slot-games',
  //   icon: <img alt="slot-games" src={SlotsIcon} style={iconStyle} />,
  // },
  // {
  //   title: 'P2P',
  //   href: '/p2p',
  //   icon: (
  //     <img alt="P2P" src={P2pIcon} style={iconStyle} />
  //   ),
  // },
  // {
  //   title: 'Arcade',
  //   href: '/arcade',
  //   icon: (
  //     <img alt="Arcade" src={ArcadeIcon} style={iconStyle} />
  //   ),
  // },
  // {
  //   title: 'Fish Hunter',
  //   href: '/fish-hunter',
  //   icon: (
  //     <img alt="Fish Hunter" src={FishHunterIcon} style={iconStyle} />
  //   ),
  // },
  /* {
    title: 'mini games',
    href: '/mini-games',
    icon: <img alt="mini-games" src={MiniIcon} style={iconStyle} />,
  }, */
  {
    title: 'promotions',
    href: '/promotions',
    Icon: PromotionIcon

  },
  // {
  //   title: 'partners',
  //   href: '/partners',
  //   Icon: PartnersIcon,
  // },
  // {
  //   title: 'VIP Club',
  //   href: '/vip-club',
  //   Icon: VipIcon,
  // },
  {
    title: 'download center',
    href: '/mobile',
    Icon: MobileIcon,
  },
  {
    title: 'support',
    href: '/support',
    Icon: SupportIcon
  },
  // {
  //   title: 'live chat',
  //   href: '/live-chat',
  //   Icon: LiveChatIcon,
  // },
  {
    title: 'language settings',
    Icon: TranslateIcon,
    children: [
      {
        title: 'English',
        href: '#',
        type: 'language',
        value: 'en-US'
      },
      {
        title: '한국어',
        href: '#',
        type: 'language',
        value: 'ko-KR'
      },
    ],
  },
];

export const menuItems = [
  {
    title: 'bti sports',
    href: '/joa-sports',
   // newTab: 'joa-sports',
    href: '#',
    newTab: 'sports-betting',
    Icon: SportsIcon,
    providerID: 21,
  },
  {
    title: 'bt1',
    href: '/joa-sports',
   // newTab: 'joa-sports',
    href: '#',
    newTab: 'sports-betting-bt1',
    Icon: LiveSportIcon,
    providerID: 21,
    },
  // {
  //   title: 'betradar sports',
  //   href: '/live-sports',
  //   //newTab: 'ow-sports',
  //   Icon: LiveSportsIcon,
  //   providerID: 25,
  // },
  // {
  //   title: 'betconstruct',
  //   href: '/betconstruct',
  //   //newTab: 'ow-sports',
  //   Icon: BetConstructIcon,
  //   providerID: 32,
  // },
  // {
  //   title: 'e-sports',
  //   href: '/e-sports',
  //   //newTab: 'ow-sports',
  //   Icon: ESportsIcon,
  //   productID: 9,
  // },
  {
    title: 'live casino',
    href: '/live-casino',
    Icon: LiveCasinoIcon,
    productID: 2,
  },
  // {
  //   title: 'e-sports',
  //   href: '/e-sports',
  //   Icon: ESportsIcon,
  // },
  {
    title: 'slots',
    href: '/slots',
    Icon: SlotsIcon,
    productID: 3,
  },
  // {
  //   title: 'cockfight',
  //   href: '/minigame/cockfighting',
  //   Icon: CockFightIcon,
  //   productID: 11,
  // },
  // {
  //   title: 'powerball',
  //   href: '#',
  //   Icon: HomepagePowerballIcon,
  //   productID: 10,
  // },
  // {
  //   title: 'winner poker',
  //   href: '/live-vegas',
  //   Icon: PokerIcon,
  //   productID: 12,
  // },
  {
    title: 'promotions',
    href: '/promotions',
    Icon: PromotionIcon1
  },
  {
    title: 'vip club',
    href: '/vip-club',
    Icon: VipClubIcon
  },
  {
    title: 'rules',
    href: '/vip-club',
    Icon: RulesIcon
  },
  // {
  //   title: 'partners',
  //   href: '/partners',
  //   Icon: PartnerIcon
  // },
  // {
  //   title: 'partners',
  //   href: '/partners',
  //   Icon: HomepagePartnersIcon,
  // },
  // {
  //   title: 'Virtual Sports',
  //   href: '/virtual-sports',
  //   Icon: VirtualSportsIcon,
  // },

  // {
  //   title: 'live casino',
  //   href: '/live-casino',
  //   icon: (
  //     <img alt="live-casino" src={CasinoIcon} style={iconStyle} />
  //   ),
  // },
  // {
  //   title: 'slot games',
  //   href: '/slot-games',
  //   icon: <img alt="slot-games" src={SlotsIcon} style={iconStyle} />,
  // },
  // {
  //   title: 'P2P',
  //   href: '/p2p',
  //   icon: (
  //     <img alt="P2P" src={P2pIcon} style={iconStyle} />
  //   ),
  // },
  // {
  //   title: 'Arcade',
  //   href: '/arcade',
  //   icon: (
  //     <img alt="Arcade" src={ArcadeIcon} style={iconStyle} />
  //   ),
  // },
  // {
  //   title: 'Fish Hunter',
  //   href: '/fish-hunter',
  //   icon: (
  //     <img alt="Fish Hunter" src={FishHunterIcon} style={iconStyle} />
  //   ),
  // },
  /* {
    title: 'mini games',
    href: '/mini-games',
    icon: <img alt="mini-games" src={MiniIcon} style={iconStyle} />,
  }, */
  // {
  //   title: 'VIP Club',
  //   href: '/vip-club',
  //   Icon: VipIcon,
  // },
  // {
  //   title: 'download center',
  //   href: '/mobile',
  //   Icon: MobileIcon
  // },
  // {
  //   title: 'quick links',
  //   icon: <img alt="quick-links" src={LinkIcon} style={iconStyle} />,
  //   children: [
  //     {
  //       title: 'quick links',
  //       href: '/quick-links',
  //       icon: (
  //         <img alt="quick-links" src={LinkIcon} style={iconStyle} />
  //       ),
  //     },
  //   ],
  // },
  // {
  //   title: 'support',
  //   href: '/support',
  //   Icon: SupportIcon,
  // },
  // {
  //   title: 'live chat',
  //   href: '/live-chat',
  //   Icon: LiveChatIcon,
  // },
  // {
  //   title: 'language settings',
  //   Icon: TranslateIcon,
  //   children: [
  //     {
  //       title: 'english',
  //       href: '#',
  //       type: 'language',
  //       value: 'en-US',
  //       icon: <img alt="en" src={EN} style={languageStyle} />,
  //     },
  //     {
  //       title: '한국어',
  //       href: '#',
  //       type: 'language',
  //       value: 'ko-KR',
  //       icon: <img alt="en" src={KR} style={languageStyle} />,
  //     },
  //   ],
  // },
  // {
  //   Icon: LogoutIcon,
  //   title: 'logout',
  //   href: '#',
  //   icon: <img alt="support" src={Signout} style={iconStyle} />,
  // },
];

export const menuAccountItems = [
  {
    title: 'wallet',
    Icon: MainWalletIcon1,
    // notifCount: !!message ? `${message}` : null,
    href: '/wallet',
  },
  {
    title: 'deposit',
    Icon: DepositIcon2,
    // notifCount: !!message ? `${message}` : null,
    href: '/deposit',
  },
  {
    title: 'withdrawal',
    Icon:   WithdrawalIcon,

    // notifCount: !!message ? `${message}` : null,
    href: '/withdrawal',
  },
  {
    title: '1 on 1 message',
    Icon: SideMenuMessage,
    badge: true,
    badgeCountName: 'unread_message_replies_count',
    href: '/message',
  },
  {
    title: 'transaction history',
    Icon: SideMenuTransactionHistory,
    href: '/history',
  },
  {
    title: 'bonus / coupon / cash',
    Icon:   SideMenuBonus,
    // notifCount: !!coupon ? `${coupon}` : null,
    href: '/bonus',
  },
  {
    title: 'profile',
    Icon: SideMenuProfile,
    // notifCount: !FirstName ? '!' : null,
    href: '/account-details',
  },
  {
    Icon: SideMenuBankDetails,
    title: 'bank details',
    href: '/bank-details',
  },
  {
    title: 'change password',
    Icon: SideMenuChangePassword,
    href: '/change-password',
  },
  {
    title: 'language settings',
    Icon: TranslateIcon,
    children: [
      {
        title: 'english',
        href: '#',
        type: 'language',
        value: 'en-US'
      },
      {
        title: '한국어',
        href: '#',
        type: 'language',
        value: 'ko-KR'
      },
    ],
  },
  {
    title: 'notices',
    Icon: SideMenuNotice,
    // notifCount: !bank ? '!' : null,
    href: '/notice',
    badge: true,
    badgeCountName: 'unread_notice_count',
  },
  // {
  //   title: 'transfer',
  //   Icon:  TransferIcon,
  //   // notifCount: !!message ? `${message}` : null,
  //   href: '/transfer',
  // },
  // {
  //   title: 'rebate',
  //   Icon: SideMenuRebate,
  //   href: '/rebate',
  // },
  {
    title: 'help center',
    Icon: SideMenuHelpCenter,
    // notifCount: !bank ? '!' : null,
    href: '/support',
  },
  {
    Icon: LogoutIcon,
    title: 'logout',
    href: '#',
    icon: <img alt="support" src={Signout} style={iconStyle} />,
  },
];

export const languages = {
  'en-US': 'en',
  'ko-KR': 'kr',
};

export const languageItems = [
  { id: 1, name: 'English', flag: 'gb', code: 'en', lang: 'en-US' },
  { id: 2, name: '한국어', flag: 'kr', code: 'kr', lang: 'ko-KR' },
  //{ id: 3, name: '中文(简体)', flag: 'cn', code: 'cn' },
  //{ id: 4, name: '日本語', flag: 'jp', code: 'jp' },
];

export const types = {
  transactions: [
    { name: 'deposit', value: '1000' },
    { name: 'withdrawal', value: '2000' },
    // { name: 'transfer', value: '6000, 7000' },
  ],
  bonus: [
    { name: 'deposit', value: '1' },
    { name: 'coupon', value: '2' },
  ],
};


export const showHeaderLogoList = ['/', '/vip-club', '/powerball', '/live-sports', '/e-sports','/slots','/slots/:productid?/:gameid?','/slots/:providerID?','/live-casino','/live-casino/play/:gameid?','/live-casino/:providerID?', '/welcome-page', '/minigame/cockfighting', '/betconstruct', '/live-vegas'];


