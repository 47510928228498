import {
  all,
  call,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';
import {
  GET_GAMES,
  GET_GAME_CATEGORIES,
  GET_RECENT_GAMES,
  SLOTS_PLAY,
  SET_SLOTS_LOADING,
  LAUNCH_GAME,
  SET_FAVOURITE_LOADING, 
  SET_FAVOURITE_SLOTS
} from '../constants/slot.games';
import {
  getGamesSuccess,
  getGameCategoriesSuccess,
  proceedPlayGame,
  getRecentSuccess,
  setFavouriteSuccess,
  openCasinoError,
} from '../actions/slot.games';
import { setPlayerInfo, getPlayerInfo } from '../actions/player';
import {
  setLoading,
  toggleDepositDialog,
  toggleTransferDialog, 
  showGameMessage
} from '../actions/common';
import {
  submitTransferSuccess
} from '../actions/wallet'; 
import {
  showSessionModal
} from '../actions/session';
import slotSvc from '../utils/services/slot';
import playerSvc from '../utils/services/player';
import { realAmount } from '../utils/helpers/common';
import { BRAND } from '../config/api';
import { setPlaySuccess } from '../actions/slot.games';
import commonSvc from '../utils/services/common';
import { SET_WALLET_LOADING } from '../constants/wallet';

import {
  resetPlayState,
} from '../actions/sports';

import { 
  resetOpenLiveCasinoError 
} from '../actions/live.casino';

import { 
  resetOpenMinigameError 
} from '../actions/minigame';

function* getRecentGames() {
  try {
    //alert('slot');
    const request = yield call(slotSvc.getRecentGames, {
      product: 3,
    });
    const { data } = request.data;

    yield put(getRecentSuccess(data));
  } catch (err) {
    const { status } = err.response || {};

    if (status === 403) {
      yield put(showSessionModal())
    }
    // err.response.data
  }
}

function* getSlotGames() {
  try { 
    const request = yield call(slotSvc.getGames, { product: 3 });
    const { data } = request.data;

    yield put(getGamesSuccess(data));
  } catch (err) {
    // err.response.data
  }
}

function* getSlotGamesCategories(action) {
   
  try {   
    const { params } = action;  
    const request = yield call(slotSvc.getGameCategories, {
      product: 3,
      isMobile: true,
      provider: params.provider
    });
    const { data } = request.data;

    yield put(getGameCategoriesSuccess(data));
  } catch (err) {
    // err.response.data
  }
}

function* play(action) {
  try {
    const min_transfer = (process.env.REACT_APP_MIN_TRANSFER)?process.env.REACT_APP_MIN_TRANSFER:1;
    yield put(setLoading(true, SET_SLOTS_LOADING));
    const player = yield call(playerSvc.getPlayerInfo);
    yield put(setPlayerInfo(player.data));
    const { wallet } = player.data;
    const { game } = action;

    const request = yield call(playerSvc.getProviderBalance, {
      provider: game.ProviderID,
    });
    const { balance } = request.data;


    if (realAmount(wallet) >= min_transfer) {
      yield put(toggleTransferDialog(true));
    } else if (
      realAmount(wallet) < min_transfer &&
      realAmount(balance) === 0
    ) {
      yield put(toggleDepositDialog(true));
    } else {
      yield put(proceedPlayGame(true));
    }


  } catch (err) {
    const { status } = err.response || {};

    if (status === 403) {
      yield put(showSessionModal())
    }
    // err.response
  } finally {
    yield put(setLoading(false, SET_SLOTS_LOADING));   
  }
}

function* launchGame(action) {

  try {
    const { params } = action;
    const finalParams = { brandID: BRAND, ...params };

    const api = (params.isDemo && params.isDemo === true)?slotSvc.launchGameDemo:slotSvc.launchGame;
    const request = yield call(api, finalParams);
    const { data } = request;

    const { success, url } = data;
    yield put(
      setPlaySuccess(
        params.provider === 5
          ? { ...data, isIframe: 1, provider: params.provider }
          : {
              provider: params.provider,
              success,
              url,
              isIframe: data.is_iframe ? data.is_iframe : '',
            },
      ),
    );
  } catch (err) {
    const { status } = err.response || {};

    if (status === 403) {
      yield put(showSessionModal())
    }
    else{
      yield put(resetPlayState())
      yield put(resetOpenLiveCasinoError())
      yield put(resetOpenMinigameError())
      yield put(openCasinoError(err.response.data.errors))
      yield put(showGameMessage()); 
      yield put(toggleTransferDialog(false));
      yield put(submitTransferSuccess(false));  
    }
 

  }
}

function* setFavourite(action) { 
  try {
    const { params } = action;
    yield put(setLoading(true, SET_FAVOURITE_LOADING));
    yield call(commonSvc.setFavourite, params);

    const { games } = yield select(state => state.slot);
    const index = games.findIndex(item => item.ID === params.game);
    games[index].FavStatus = params.status;

    yield put(getGamesSuccess(games));

    yield put(
      setFavouriteSuccess(
        `game has been ${
          !params.status ? 'removed from' : 'added to'
        } favourites.`,
        true,
      ),
    );
  } catch (err) {
    const { status } = err.response || {};

    if (status === 403) {
      yield put(showSessionModal())
    }
    // err.response.data
  } finally {
    yield put(setLoading(false, SET_FAVOURITE_LOADING));
  }
}

export default function* slotGamesWatcher() {
  yield all([
    takeLatest(GET_GAMES, getSlotGames),
    takeLatest(GET_GAME_CATEGORIES, getSlotGamesCategories),
    //takeLatest(GET_RECENT_GAMES, getRecentGames),
    takeLatest(SLOTS_PLAY, play),
    takeLatest(LAUNCH_GAME, launchGame),
    takeLatest(SET_FAVOURITE_SLOTS, setFavourite) 
  ]);
}


