import {
  GET_PLAYER_REBATE_TRANSACTIONS,
  SET_REBATE_BALANCE,
  SET_REBATE_MINIMUM,
  SET_PLAYER_REBATE_TRANSACTIONS,
  SET_PLAYER_REBATE_TRANSACTIONS_STATUS,
  SUBMIT_REBATE_TRANSFER_REQUEST_SUCCESS,
  LOAD_MORE_REBATE_TRANSACTIONS,
  LOAD_MORE_REBATE_TRANSACTIONS_SUCCESS,
  SET_REBATE_TO,
  LOAD_TRANSFER_REBATE_DATA
} from '../constants/rebate';
import {
  INITIAL_STATUS,
  REQUESTING_STATUS,
  SUCCESS_REQUEST_STATUS
 } from "../constants/common";

const initialState = {
  rebateWallet: null,
  showLoading: false,
  successRebateTransfer: false,
  playerRebateTransactions: [],
  rebateTransactionsLastPage: 0,
  playerRebateTransactionsStatus: INITIAL_STATUS,
  rebateMinimum: 0,
  transferTo: null,
  transferRebateData: {}
};

const rebateReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_REBATE_TO:
      return {
        ...state,
        transferTo: action.transferTo
      }
    case SET_REBATE_MINIMUM:
      return {
        ...state,
        rebateMinimum: action.minimum
      }
    case SET_REBATE_BALANCE:
      return {
        ...state,
        rebateWallet: action.params.data
      };
    case GET_PLAYER_REBATE_TRANSACTIONS,
    LOAD_MORE_REBATE_TRANSACTIONS:
      return {
        ...state,
        playerRebateTransactionsStatus: REQUESTING_STATUS
      }
    case SET_PLAYER_REBATE_TRANSACTIONS_STATUS:
      return {
        ...state,
        playerRebateTransactionsStatus: action.status
      }
    case SET_PLAYER_REBATE_TRANSACTIONS:
        return {
          ...state,
          playerRebateTransactions: action.transactions,
          rebateTransactionsLastPage: action.page,
          playerRebateTransactionsStatus: SUCCESS_REQUEST_STATUS
        };
    case LOAD_MORE_REBATE_TRANSACTIONS_SUCCESS:
        return {
          ...state,
          playerRebateTransactions: [...state.playerRebateTransactions, ...action.transactions],
          playerRebateTransactionsStatus: SUCCESS_REQUEST_STATUS
        }
    case SUBMIT_REBATE_TRANSFER_REQUEST_SUCCESS:
      return {
        ...state,
        successRebateTransfer: action.isSuccess,
      };
    case LOAD_TRANSFER_REBATE_DATA:
      return {
        ...state,
        transferRebateData: action.payload,
      };
    default:
      return state;
  }
};

export default rebateReducer;