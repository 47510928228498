import React, { useCallback, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import ReactHTMLParser from 'react-html-parser';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import styles from './styles';

import CustomizableDialog from '../../../components/custom/customizable-dialog';

const ComingSoonModal = ({ classes, openDialog, close, title, body}) => {
    const { t } = useTranslation();

    return (
      <CustomizableDialog
        customScrollPaper={classes.customScrollPaper}
        customClass={classes.customDialog}
        onClose={close}
        titleContent={
          <>
            <div>
              <Typography variant="h6">
                {title ? title : t('coming soon title')}
              </Typography>
            </div>
            <div>
              <IconButton
                aria-label="close"
                className={classes.closeButton}
                onClick={close}
              >
                <CloseIcon />
              </IconButton>
            </div>
          </>
        }
        hasHeader
        openDialog={openDialog}
      >
        <h3>{ReactHTMLParser(body ?  body : t('coming soon text'))}</h3>
      </CustomizableDialog>
    );
}

ComingSoonModal.propTypes = {
  classes: PropTypes.shape({
    customScrollPaper: PropTypes.object,
    customDialog: PropTypes.string,
  }).isRequired,
  openDialog: PropTypes.bool.isRequired,
  close: PropTypes.func,
  title: PropTypes.string,
  body: PropTypes.string,
};

export default withStyles(styles)(ComingSoonModal);