import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { OPEN_BETCONSTRUCT } from '../constants/betconstruct';
import { getLanguage } from '../utils/helpers/common';
import sportSvc from '../utils/services/sports';
import { openingBetConstructSuccess, openingBetConstructError } from '../actions/betconstruct';
import { showGameMessage } from '../actions/common';

import { 
    resetOpenCasinoError 
} from '../actions/slot.games';

import { 
    resetOpenLiveCasinoError 
} from '../actions/live.casino';
import {
    resetPlayState,
} from '../actions/sports';

function* openBetConstruct(action) {
    try {
        let params, request;
        const integrationID = 32;
        const providerID = 32;
        const ProductID = 1;
        const language = getLanguage();

        if (action.isLogin) {
            const playerState = yield select(state => state.player);

            // if(playerState.playerInfo !== null) {
                params = {
                    brandID: 5,
                    currency: playerState.playerInfo.player.currency.Abbreviation,
                    gameID: 0,
                    gameType: 'sports',
                    hashid: '',
                    isMobile: 1,
                    lang: language,
                    launchType: 'html5',
                    mode: 1,
                    playerID: playerState.playerInfo.player.PlayerID,
                    productID: ProductID,
                    providerID: providerID,
                    integrationID: integrationID,
                    username: playerState.playerInfo.username
                };
            // }
        } else {
            params = {
                IsLive: 0,
                gameID: 0,
                mode: 0,
                isMobile: 1,
                gameType: 'sports',
                lang: language,
                integrationID: integrationID,
                providerID: providerID,
                productID: ProductID,
                domain: window.location.hostname === 'localhost' ? 'mfrontend-test.15gaming.com' : window.location.hostname
            };
        }

        request = yield call(sportSvc.openSports, params);
        yield put(openingBetConstructSuccess(request.data.game_url));
    } catch(err) {
        const { status } = err.response || {};

        if (status !== 200) {
            yield put(resetOpenCasinoError())
            yield put(resetOpenLiveCasinoError())
            yield put(resetPlayState())
            yield put(openingBetConstructError(err.response.data.errors));
            yield put(showGameMessage());
        }
    }
}

export default function* betConstructWatcher() {
    yield all([takeLatest(OPEN_BETCONSTRUCT, openBetConstruct)]);
}
