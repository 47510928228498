export const UPDATE_LAYOUT = '(LAYOUT) UPDATE_THEME_LAYOUT';
export const TOGGLE_SIDENAV = '(LAYOUT) TOGGLE_SIDENAV';
export const SET_SIDENAV_OPEN = '(LAYOUT) SET_SIDENAV_OPEN';
export const TOGGLE_SIDENAV_VARIANT = '(LAYOUT) TOGGLE_SIDENAV_VARIANT';
export const TOGGLE_NOTIFICATIONS = '(LAYOUT) TOGGLE_NOTIFICATIONS';
export const SET_FILTER_HIDDEN = '(LAYOUT) SET_FILTER_HIDDEN';
export const SET_SELECTED_SIDENAV_OPEN = '(LAYOUT) SET_SELECTED_SIDENAV_OPEN';

export const updateLayout = data => ({
  type: UPDATE_LAYOUT,
  payload: data
});

export const toggleSidenav = () => ({
  type: TOGGLE_SIDENAV
});

export const setSidenavOpen = data => ({
  type: SET_SIDENAV_OPEN,
  payload: data
});

//either upperNav or accountNav bottom
export const setNavOpened = data => ({
  type: SET_SELECTED_SIDENAV_OPEN,
  payload: data
});

export const setFilterHidden = data => ({
  type: SET_FILTER_HIDDEN,
  payload: data
});

export const toggleSidenavVariant = data => ({
  type: TOGGLE_SIDENAV_VARIANT,
  payload: data
});

export const toggleNotifications = () => ({
  type: TOGGLE_NOTIFICATIONS
});
