import {
  OPEN_SPORTS,
  OPEN_SPORTS_SUCCESS,
  SET_INITIAL_SPORTS,
  OPEN_SPORTS_ERROR
} from '../constants/sports';

export const openSports = (isLogin, params) => ({
  type: OPEN_SPORTS,
  isLogin,
  params,
});

export const openSportsSuccess = (url, providerID) => ({
  type: OPEN_SPORTS_SUCCESS,
  url,
  providerID
});

export const resetPlayState = response => ({
  type: SET_INITIAL_SPORTS,
  response,
});

export const openSportsError = response => ({
  type: OPEN_SPORTS_ERROR,
  response,
});
