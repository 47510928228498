import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import { useTranslation } from 'react-i18next';

import styles from './styles';
import CustomDialog from '../../components/custom/dialog';
import {
  BackIcon,
  CloseIcon,
  RecentIcon,
  SearchIcon,
} from '../../components/custom/fifteenbet.icons';
import TheAsteroids from '../../assets/images/favourites/the-asteroids.png';
import FengGu from '../../assets/images/favourites/feng-gu.png';

const Search = ({ classes, handleClose, open }) => {
  const { root } = classes;
  const { t } = useTranslation();
  const [searchedList] = useState([
    {
      type: 'search',
      name: 'Boston Rockets',
    },
    {
      type: 'search',
      name: 'Soccer 2019',
    },
    {
      type: 'recent',
      name: 'The Asteroids',
    },
    {
      type: 'recent',
      name: 'Rabbit Slot',
    },
    {
      type: 'recent',
      name: 'Lucky',
    },
  ]);
  const [favourites] = useState([
    {
      image: TheAsteroids,
      name: 'The Asteroids',
    },
    {
      image: FengGu,
      name: 'Feng Gu',
    },
  ]);
  const [searchableItems, setSearchableItems] = useState([]);
  const [searchableFavourites, setSearchableFavourites] = useState(
    [],
  );
  const [searchValue, setSearchValue] = useState('');

  const clear = useCallback(() => {
    setSearchValue('');
  });

  useEffect(() => {
    setSearchableItems(searchedList);
  }, [searchedList]);

  useEffect(() => {
    setSearchableFavourites(favourites);
  }, [favourites]);

  return (
    <CustomDialog
      customClass={root}
      openDialog={open}
      hasHeader
      titleContent={[
        <div key="search" className="search-input">
          <IconButton onClick={handleClose}>
            <BackIcon />
          </IconButton>
          <TextField
            value={searchValue}
            onChange={e => setSearchValue(e.target.value)}
            placeholder={t('search')}
            key="input"
            fullWidth
            autoFocus
          />
          <IconButton onClick={clear}>
            <CloseIcon />
          </IconButton>
        </div>,
        <Divider key="divider" />,
      ]}
      fullScreen
    >
      <List>
        {searchableItems.map((item, index) => (
          <ListItem key={index.toString()}>
            <ListItemIcon>
              <div>
                {item.type === 'search' && <SearchIcon />}
                {item.type === 'recent' && <RecentIcon />}
              </div>
            </ListItemIcon>
            <ListItemText>{item.name}</ListItemText>
          </ListItem>
        ))}
      </List>
      <div className="favourites-container">
        <p className="title">{t('FAVOURITES')}</p>
        <Divider />
        <List>
          {searchableFavourites.map((item, index) => (
            <ListItem key={index.toString()}>
              <ListItemIcon>
                <img className="logo" alt="logo" src={item.image} />
              </ListItemIcon>
              <ListItemText>{item.name}</ListItemText>
            </ListItem>
          ))}
        </List>
      </div>
    </CustomDialog>
  );
};

Search.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
  }).isRequired,
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default withStyles(styles)(Search);
