const styles = theme => ({
  root: {
    '& .floating-btn': {
      position: 'absolute',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
      background: 'rgba(22, 22, 22, 0.7)',
      color: theme.palette.text.primary,
    },
  },
});

export default styles;
