import {
  GET_GAMES_SUCCESS,
  GET_GAME_CATEGORIES_SUCCESS,
  GET_RECENT_GAMES_SUCCESS,
  PROCEED_PLAY_GAME,
  SET_SLOTS_LOADING,
  SET_PLAY_SUCCESS, 
  SET_FAVOURITE_SUCCESS,
  OPEN_CASINO_ERROR,
  RESET_OPEN_CASINO_ERROR,
  SET_INITIAL_SLOT,
} from '../constants/slot.games';

const initialState = {
  games: null,
  recentGames: [],
  gameCategories: [],
  slotsLoading: false,
  proceedToGame: false,
  playSuccess: null, 
  gamesChange: false,
  errors:[],
};

export default function slotGamesReducer(
  state = initialState,
  action,
) {
  switch (action.type) {
    case SET_INITIAL_SLOT:
      return initialState;
    case GET_GAMES_SUCCESS:
      return { ...state, games: action.response, gamesChange: !state.gamesChange };
    case GET_GAME_CATEGORIES_SUCCESS:
      return { ...state, gameCategories: action.response };
    case GET_RECENT_GAMES_SUCCESS:
      return { ...state, recentGames: action.response };
    case PROCEED_PLAY_GAME:
      return { ...state, proceedToGame: action.status };
    case SET_SLOTS_LOADING:
      return { ...state, slotsLoading: action.show };
    case SET_PLAY_SUCCESS:
      return { ...state, playSuccess: action.response };
    case OPEN_CASINO_ERROR:
      return {
        ...state,
        errors: action.response
    }
    case RESET_OPEN_CASINO_ERROR:
      return {
        ...state,
        errors: []
    }
    case SET_FAVOURITE_SUCCESS:
      return {
        ...state,
        favouriteMsg: {
          ...state.favouriteMsg,
          show: action.status,
          message: action.message,
        },
      };
    default:
      return state;
  }
}
