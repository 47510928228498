import React from 'react';
import { withStyles } from '@material-ui/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import styles from './styles';

import {
    MainWalletIcon,
    RebateWalletIcon
} from '../joabet.icons';

import {
displayFormAmount,
} from '../../../utils/helpers/common';

function MenuRightWalletInfo({classes, playerInfo, walletName, Icon, balance}){
    const { t } = useTranslation();
    const { player, wallet } = playerInfo || {};
    return(
        <>
        <div className={classes.root}>
            <div className="wallet-container">
                <div>
                    <Icon className='walletIcon' viewBox="0 0 35 40"/>
                </div>
                <div style={{width:'100%'}}>
                <p className='wallet'>{t(`${walletName}`)}</p>
                {playerInfo ? (
                    <p className='balance'>
                        {balance}
                    </p>
                ) : (
                    <Skeleton
                    className={'skeleton'}
                    width="100%"
                    height={14}
                    />
                )}
                </div>
             </div>
        </div>
        </>
    )
}

MenuRightWalletInfo.propTypes = {
    classes: PropTypes.shape({
        root: PropTypes.string
    }),
    walletName: PropTypes.string.isRequired,
    Icon: PropTypes.func.isRequired,
    balance: PropTypes.string.isRequired,
    playerInfo: PropTypes.shape({
        player: PropTypes.object,
        wallet: PropTypes.string
    }),
}

export default withStyles(styles)(MenuRightWalletInfo);