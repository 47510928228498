export const GET_LANDING_PAGE_WITHDRAWAL_SUMMARY = 'GET_LANDING_PAGE_WITHDRAWAL_SUMMARY';
export const GET_LANDING_PAGE_WINNER_LIST_SUMMARY = 'GET_LANDING_PAGE_WINNER_LIST_SUMMARY';
export const SET_LANDING_PAGE_WITHDRAWAL_SUMMARY = "SET_LANDING_PAGE_WITHDRAWAL_SUMMARY";
export const SET_LANDING_PAGE_WINNER_LIST_SUMMARY = "SET_LANDING_PAGE_WINNER_LIST_SUMMARY";
export const UPDATE_LANDING_PAGE_WITHDRAWAL_SUMMARY = 'UPDATE_LANDING_PAGE_WITHDRAWAL_SUMMARY';

export const GET_LANDING_PAGE_WINNER_LIST_MONTHLY = 'GET_LANDING_PAGE_WINNER_LIST_MONTHLY';
export const SET_LANDING_PAGE_WINNER_LIST_MONTHLY = "SET_LANDING_PAGE_WINNER_LIST_MONTHLY";

export const GET_HOME_PAGE_CAROUSEL = 'GET_HOME_PAGE_CAROUSEL';
export const SET_HOME_PAGE_CAROUSEL = "SET_HOME_PAGE_CAROUSEL";
export const GET_HOME_PAGE_CAROUSEL_SETTING = 'GET_HOME_PAGE_CAROUSEL_SETTING';
export const SET_HOME_PAGE_CAROUSEL_SETTING = "SET_HOME_PAGE_CAROUSEL_SETTING";