const styles = theme => ({
  amountContainer: {
    margin: 'auto'
  },
  amountLocked: {
    color: 'red',
    fontSize: '10px'
  },
  amountCustomStyle: {
    fontSize: '14px',
    fontWeight: 'bold'
  },
  amountStyle: {
    color: theme.palette.text.primary,
    fontSize: '18px',
    fontWeight: 700
  },
  balance: {
    textAlign: 'right',
    color: theme.palette.text.secondary,
    margin: 'auto'
  },
  currencyCustomStyle: {
    color: theme.palette.text.secondary,
    fontSize: '11px',
    fontWeight: 'bold',
    marginRight: theme.spacing(1) - 2,
    verticalAlign: 'middle',
  },
  currencyStyle: {
    color: theme.palette.text.primary,
    fontSize: '11px',
    fontWeight: 500,
    marginRight: theme.spacing(1) - 2,
    verticalAlign: 'middle',
  },
  loading: {
    height: '20px',
    float: 'right'
  },
  mainContainer: {
    background: theme.palette.primary.dark,
    borderRadius: '5px',
    padding: `${theme.spacing(1) + 1}px ${theme.spacing(2) - 2}px`,
    marginBottom: '0.4em',
    boxShadow: `0px 0px 3px rgba(0, 0, 0, 0.25)`,
    height: '30px',
    display: 'flex',
  },
  titleContainer: {
   // background: theme.palette.background.provider,
    borderRadius: '5px',
    padding: '0px',
    paddingTop: '1em',
    marginBottom: '0.4em',
  },
  providerMainContainer: {
    background: theme.palette.primary.dark,
    borderRadius: '5px',
    padding: `1.1em ${theme.spacing(2) - 2}px`,
    marginBottom: '0.4em',
    boxShadow: `0px 0px 32px rgba(0, 0, 0, 0.25)`
  },
  walletName: {
    color: theme.palette.text.primary,
    fontSize: '13px',
    alignSelf: 'center',
  },
  mainWalletIconStyle: {
    verticalAlign: 'middle',
    marginRight: '5px',
    color: theme.palette.text.default,
  },
  bold: {
    fontWeight: 500,
    color: theme.palette.text.secondary,
  },
  walletIcon: {
    height: '1.8em'
  },
  wallet: {
  },
  walletContainer: {
    margin: 'auto',
    display: 'flex'
  },
  comingSoon: {
    color: 'red'
  },
  sharedWalletClass: {
    fontSize: '0.9em',
    color: theme.palette.primary.main
  },
  sharedWalletDescription: {
    fontSize: '0.9em',
    color: theme.palette.text.secondary,
  }
});

export default styles;
