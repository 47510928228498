const styles = theme => ({
  root: {
    marginBottom: theme.spacing(3),
    '& .MuiPaper-root': {
      background: '#FFF',
      color: '#000',
      borderBottomLeftRadius: '2px',
      borderBottomRightRadius: '2px',
      '& .MuiDivider-root': {
        backgroundColor: 'rgba(0, 0, 0, 0.12)'
      },
      '& .search-input': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        '& .MuiIconButton-root': {
          color: '#000'
        },
        '& .MuiInput-underline:after': {
          borderBottom: 'none'
        },
        '& input': {
          color: '#000',
          height: '24px'
        }
      },
      '& .MuiList-root': {
        '& .MuiListItem-root': {
          paddingLeft: 0,
          paddingRight: 0,
          '& .MuiListItemIcon-root': {
            minWidth: '36px',
            '& .MuiSvgIcon-root': {
              color: theme.palette.text.secondary,
              fontSize: '20px'
            }
          },
          '& .MuiListItemText-root': {
            '& span': {
              fontSize: '12px'
            }
          }
        }
      },
      '& .favourites-container': {
        '& .title': {
          marginBottom: theme.spacing(1) / 2,
          marginTop: 0,
          fontSize: '12px',
          color: theme.palette.text.secondary,
          textTransform: 'uppercase'
        },
        '& .logo': {
          height: '30px',
          width: '30px',
          borderRadius: '2px'
        }
      }
    }
  }
});

export default styles;
