import {
  GET_ACTIVE_PROVIDERS,
  GET_BANKS,
  GET_CURRENCIES,
  GET_PROVIDERS,
  GET_PRODUCT_PROVIDERS,
  GET_PROVIDER_PROMOTIONS,
  SET_AMOUNT_LOCKED_PROVIDERS,
  SET_ACTIVE_PROVIDERS,
  SET_BANKS,
  SET_CURRENCY,
  SET_CURRENCY_INFOS,
  SET_FAVOURITE,
  SET_FAVOURITE_SUCCESS,
  SET_FILTERED_PROVIDERS,
  SET_LOADING,
  SET_PROVIDERS,
  SET_PRODUCT_PROVIDERS,
  SET_PROVIDER_PROMOTIONS,
  SHOW_DEPOSIT_DIALOG,
  SHOW_TRANSFER_DIALOG,
  GET_GAMES_COMMON,
  SET_GAMES_COMMON,
  GET_PRODUCTS,
  SET_PRODUCTS,
  GET_GAMES_SUCCESS,
  SET_ARCADE_GAMES,
  SET_GAMES_BY_PROVIDER,
  GET_HOME_BANNERS,
  SET_HOME_BANNERS,
  SET_SHARED_WALLET,
  SET_SELECT_FILTERED_PROVIDERS, 
  GET_RECENT_GAMES, 
  SET_RECENT_GAMES, 
  SHOW_GAME_MESSAGE, 
  HIDE_GAME_MESSAGE
} from '../constants/common';

export const getGames = (params = {}) => ({
  type: GET_GAMES_COMMON,
  params,
});

export const getActiveProviders = () => ({
  type: GET_ACTIVE_PROVIDERS,
});

export const getBanks = currency => ({
  type: GET_BANKS,
  currency,
});

export const getCurrencies = (includeName = false) => ({
  type: GET_CURRENCIES,
  includeName,
});

export const getProviders = (params = {}) => ({
  type: GET_PROVIDERS,
  params,
});

export const getProductProviders = () => ({
  type: GET_PRODUCT_PROVIDERS,
});

export const getProviderPromotions = params => ({
  type: GET_PROVIDER_PROMOTIONS,
  params,
});


export const setActiveProviders = providers => ({
  type: SET_ACTIVE_PROVIDERS,
  providers,
});

export const setBanks = banks => ({
  type: SET_BANKS,
  banks,
});

export const setCurrency = (currencies, phoneCodes) => ({
  type: SET_CURRENCY,
  currencies,
  phoneCodes,
});

export const setCurrencyInfos = infos => ({
  type: SET_CURRENCY_INFOS,
  infos,
});

export const setErrors = (type, errors = {}) => ({
  type,
  errors,
});

export const setFavourite = params => ({
  type: SET_FAVOURITE,
  params,
});

export const setFavouriteSuccess = (
  message = '',
  status = false,
) => ({
  type: SET_FAVOURITE_SUCCESS,
  message,
  status,
});

export const setFilteredProviders = providers => ({
  type: SET_FILTERED_PROVIDERS,
  providers,
});

export const setSelectFilteredProviders = providers => ({
  type: SET_SELECT_FILTERED_PROVIDERS,
  providers,
});

export const setAmountLockedProviders = amountLockedProviders => ({
  type: SET_AMOUNT_LOCKED_PROVIDERS,
  amountLockedProviders
});

export const setLoading = (show, type = SET_LOADING) => ({
  type,
  show,
});

export const setProviders = (providers, favChange=false) => ({
  type: SET_PROVIDERS,
  providers,
  favChange
});

export const setProductProviders = providers => ({
  type: SET_PRODUCT_PROVIDERS,
  providers,
});

export const setSharedWallet = sharedWallet => ({
  type: SET_SHARED_WALLET,
  sharedWallet,
});

export const setProviderPromotions = promotions => ({
  type: SET_PROVIDER_PROMOTIONS,
  promotions,
});

export const toggleDepositDialog = status => ({
  type: SHOW_DEPOSIT_DIALOG,
  status,
});

export const toggleTransferDialog = status => ({
  type: SHOW_TRANSFER_DIALOG,
  status,
});

export const setResponseMessage = (
  type,
  msg = '',
  msg_type = '',
  show = false,
  status= ''
) => ({
  type,
  msg,
  msg_type,
  show,
  status,
});

export const setGames = games => ({
  type: SET_GAMES_COMMON,
  games,
});

export const getProducts = () => ({
  type: GET_PRODUCTS,
});

export const setProducts = params => ({
  type: SET_PRODUCTS,
  params,
});

export const getGamesSuccess = response => ({
  type: GET_GAMES_SUCCESS,
  response,
});

export const setArcadeGames = games => ({
  type: SET_ARCADE_GAMES,
  games,
});

export const setGamesByProvider = (games, product) => ({
  type: SET_GAMES_BY_PROVIDER,
  games: games,
  product: product
});

export const getHomeBanners = (params = {}) => ({
  type: GET_HOME_BANNERS,
  params,
});

export const setHomeBanners = banners => ({
  type: SET_HOME_BANNERS,
  banners,
}); 

export const getRecent = (params = {}) => ({
  type: GET_RECENT_GAMES,
  params,
});

export const setRecentGames = (params = {}) => ({
  type: SET_RECENT_GAMES,
  params,
});

export const showGameMessage = () => {
  return {
    type: SHOW_GAME_MESSAGE
  }
} 

export const hideGameMessage = () => ({
  type: HIDE_GAME_MESSAGE 
});





