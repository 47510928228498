import {
  SET_PARTNERS_ERRORS,
  SET_PARTNERS_LOADING,
  SUBMIT_CONTACT_SUCCESS,
  CONTACT_SUCCESS_RESET,
  RESET_PARTNERS_ERRORS,
} from '../constants/partners';

const initialState = {
  message: null,
  errors: {},
  showLoading: false,
};

const partnersReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PARTNERS_ERRORS:
      return {
        ...state,
        errors: action.errors,
      };
    case SET_PARTNERS_LOADING:
      return {
        ...state,
        showLoading: action.show,
      };
    case SUBMIT_CONTACT_SUCCESS:
      return {
        ...state,
        message: action.response,
      };
    case CONTACT_SUCCESS_RESET:
      return {
          ...state,
          message: null
      };
    case RESET_PARTNERS_ERRORS:
      return {
          ...state,
          errors: {}
      };
    default:
      return state;
  }
};

export default partnersReducer;
