import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import ReactHTMLParser from 'react-html-parser';
import { displayAmount } from '../../utils/helpers/common';
import compose from 'recompose/compose';
import styles from './styles';

import CustomizableDialog from '../../components/custom/customizable-dialog';
import CustomNotification from '../../components/custom/notification';

import {
  cancelOngoingPromotion, setPlayerUnauthorized
} from '../../actions/player';

import {
  realAmount
} from '../../utils/helpers/common';

import Loading from '../../assets/images/loading.gif';
import { UNAUTHORIZED_SESSION_STATUS } from '../../constants/session';

const SessionModal = ({ classes }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [openDialog, setOpenDialog] = useState(false);
    const {
      showSessionModal
    } = useSelector(state => state.session)

    useEffect(() => {
      if(showSessionModal){
        setOpenDialog(true)
      }
    }, [showSessionModal])

    return (
        <CustomizableDialog
        customScrollPaper={classes.customScrollPaper}
        customClass={classes.sessionDialog}
        titleContent={t('session error')}
        actions={
          <div className="action-btn">
            <NavLink className="proceed" to="#" onClick={() => {
              dispatch(setPlayerUnauthorized(true));
            }}>
                {t('ok')}
            </NavLink>
          </div>
        }
        hasHeader
        openDialog={openDialog}
       >{ReactHTMLParser(
        t('your session has expired. you will be logged out from this page.')
      )}
      </CustomizableDialog>
    );
}

SessionModal.propTypes = {
  classes: PropTypes.shape({
    customScrollPaper: PropTypes.object,
    sessionDialog: PropTypes.string
  }).isRequired
};

export default withStyles(styles)(SessionModal);