const styles = theme => ({
  root: {
    '& .title': {
      fontSize: '12px',
      color: theme.palette.text.secondary,
      marginBottom: theme.spacing(1),
      textTransform: 'capitalize'
    },
    '& .dropdown': {
      background: theme.palette.background.content,
      borderRadius: '3px',
      paddingLeft: '1em',
      paddingRight: '0.5em',
      display: 'flex',
      justifyContent: 'space-between',
      paddingTop: '1em',
      paddingBottom: '1em',
      boxShadow: 'none',
     // minHeight: '42px',
      '& .iconContainer': {
       // position: 'relative'
       '& svg': {
         fill: theme.palette.text.secondary
       }
      },
      '& .name': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '1.5em',
        width: '100%',
        '& p': {
          margin: 0,
          color: theme.palette.text.secondary,
          //textTransform: 'uppercase',
          fontWeight: 'normal',
          fontSize: '14px'
        }
      },
      '& .placeholder': {
        margin: 0,
        color: theme.palette.text.secondary,
        fontSize: '16px'
      },
      '& .value': {
        marginRight: '0.2em',
        '& .currency': {
          marginRight: theme.spacing(1) - 2,
          fontSize: '10px',
          color: theme.palette.text.quaternary
        },
        '& .amount': {
          fontWeight: 700,
          fontSize: '17px',
        }
      },
      '& .MuiSvgIcon-root': {
        // position: 'absolute',
        // right: '-0.8em;',
        // marginTop: '-0.8em'
      },
      '& .providerIcon': {
        height: '1.8em'
      }
    },
  }
});

export default styles;
