import {
  SET_ACTIVE_PROVIDERS,
  SET_BANKS,
  SET_CURRENCY,
  SET_CURRENCY_INFOS,
  SET_FAVOURITE_LOADING,
  SET_FAVOURITE_SUCCESS,
  SET_FILTERED_PROVIDERS,
  SET_SELECT_FILTERED_PROVIDERS,
  SET_PROVIDERS,
  SET_PRODUCT_PROVIDERS,
  SET_PROVIDER_PROMOTIONS,
  SET_AMOUNT_LOCKED_PROVIDERS,
  SHOW_DEPOSIT_DIALOG,
  SHOW_TRANSFER_DIALOG,
  SET_GAMES_COMMON,
  SET_PRODUCTS,
  GET_GAMES_SUCCESS,
  SET_ARCADE_GAMES,
  SET_GAMES_BY_PROVIDER,
  SET_HOME_BANNERS,
  SET_SHARED_WALLET, 
  SET_RECENT_GAMES, 
  SHOW_GAME_MESSAGE, 
  HIDE_GAME_MESSAGE
} from '../constants/common';
const initialState = {
  banks: [],
  currencies: [],
  currencyInfo: [],
  filteredProviders: [],
  selectFilteredProviders: [],
  phoneCodes: [],
  providers: [],
  productProviders: [],
  providerBalance: {},
  providerPromotions: [],
  activeProviders: [],
  amountLockedProviders: {},
  showDepositDialog: false,
  showTransferDialog: false,
  favouritesLoading: false,
  favouriteMsg: {
    show: false,
    message: '',
  },
  products:null,
  games:null,
  arcadeGames: null,
  gamesByProvider: null,
  gamesChange: false,
  homeBanners: null, 
  changeFav: false, 
  recentGames: null, 
  gamesRecentChange: false, 
  showGameMessage:false
};

const CommonReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ACTIVE_PROVIDERS:
      return {
        ...state,
        activeProviders: action.providers,
      };
    case SET_BANKS:
      return {
        ...state,
        banks: action.banks,
      };
    case SET_CURRENCY:
      return {
        ...state,
        currencies: action.currencies,
        phoneCodes: action.phoneCodes,
      };
    case SET_CURRENCY_INFOS:
      return {
        ...state,
        currencyInfo: action.infos,
      };
    case SET_FAVOURITE_SUCCESS:
      return {
        ...state,
        favouriteMsg: {
          ...state.favouriteMsg,
          show: action.status,
          message: action.message,
        },
      };
    case SET_FAVOURITE_LOADING:
      return {
        ...state,
        favouritesLoading: action.show,
      };
    case SET_FILTERED_PROVIDERS:
      return {
        ...state,
        filteredProviders: action.providers,
      };
    case SET_SELECT_FILTERED_PROVIDERS:
        return {
          ...state,
          selectFilteredProviders: action.providers,
        };
    case SET_AMOUNT_LOCKED_PROVIDERS:
      return {
        ...state,
        amountLockedProviders: action.amountLockedProviders
      }
    case SET_PROVIDERS:  
      const isFavChange = (action.favChange)?!state.changeFav:state.changeFav;   
      return {
        ...state,
        providers: action.providers, 
        changeFav: isFavChange
      };
    case SET_PRODUCT_PROVIDERS:
      return {
        ...state,
        productProviders: action.providers,
      };
    case SET_SHARED_WALLET:
      return {
        ...state,
        sharedWallet: action.sharedWallet
      }
    case SET_PROVIDER_PROMOTIONS:
      return {
        ...state,
        providerPromotions: action.promotions,
      };
    case SHOW_DEPOSIT_DIALOG:
      return {
        ...state,
        showDepositDialog: action.status,
      };
    case SHOW_TRANSFER_DIALOG:
      return {
        ...state,
        showTransferDialog: action.status,
      };
    case SET_GAMES_COMMON:
      return {
        ...state,
        games: action.games,
      };
    case SET_PRODUCTS:
      return {
        ...state,
        products: action.params,
      };
    case GET_GAMES_SUCCESS:
      return { ...state, games: action.response };
    case SET_ARCADE_GAMES:
      return {
        ...state,
        arcadeGames: action.games,
      };
    case SET_GAMES_BY_PROVIDER:
    const games = (state.gamesByProvider)?state.gamesByProvider:[];
    games[action.product] = action.games;
    return {
      ...state,
      gamesByProvider: games,
      gamesChange: !state.gamesChange
    };
    case SET_RECENT_GAMES:
      return {
        ...state,
        recentGames: action.params,
      };
    case SET_HOME_BANNERS:
      return {
        ...state,
        homeBanners: action.banners,
      };
    case SHOW_GAME_MESSAGE:  
      return {
        ...state,
        showGameMessage: true
      }
    case HIDE_GAME_MESSAGE: 
      return {
        ...state,
        showGameMessage: false
      }
    default:
      return state;
  }
};

export default CommonReducer;
