import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

import Logo from '../../assets/images/navigation/top/logo.png';
import warning from '../../assets/images/w365/browser-restriction/warning.svg';
import chrome from '../../assets/images/w365/browser-restriction/chrome.png';
import firefox from '../../assets/images/w365/browser-restriction/firefox.png';
import safari from '../../assets/images/w365/browser-restriction/safari.png';

const BrowserRestriction = ({ classes }) => {
    const { root, header, logo, content, warningImage } = classes;

    return (
        <div className={root}>
            <div className={header}>
                <img src={Logo} className={logo} alt="" />
            </div>
            <div className={content}>
                <img src={warning} alt="" className="warning" />
                <div className="heading marginBottom15">Browser Not Supported</div>
                <div className="box marginBottom30"><p>Looks like you are using an old browser, which is not supported by this website. Please use Google Chrome, Mozilla Firefox or Edge to start playing in W365</p></div>
                <div className="heading marginBottom15">브라우저가 작동하지 않습니다</div>
                <div className="box marginBottom30"><p>우리 사이트와 호환이 안되는 구버젼 브라우저를 사용중이신거 같습니다 구글크롬, 모질라, 파이어폭스, 엣지등으로 다시 접속 해주시기 바랍니다</p></div>
                <div className="browser">
                    <img src={chrome} alt="" />
                    <p>Google Chrome</p>
                </div>
                <div className="browser">
                    <img src={firefox} alt="" />
                    <p>Mozilla Firefox</p>
                </div>
                <div className="browser">
                    <img src={safari} alt="" />
                    <p>Safari</p>
                </div>
            </div>
        </div>
    );
}


export default withStyles(styles)(BrowserRestriction);
