import React, { useState, useEffect } from 'react';
import Fab from '@material-ui/core/Fab';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/styles/withStyles';

import styles from './styles';

const CustomFab = ({ classes, elem, position }) => {
  const [show, toggleFab] = useState(false);
  let timeout = -1;

  const scrollToRef = () => {
    elem.current.scrollTo(0, 0);
  };

  useEffect(() => {
    toggleFab(!!position);
  }, [position]);

  useEffect(() => {
    if (show) {
      timeout = setTimeout(() => toggleFab(false), 3000);
    } else {
      toggleFab(false);
      clearTimeout(timeout);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [show]);

  useEffect(() => {
    scrollToRef();
  }, []);

  return show ? (
    <div className={classes.root}>
      <Fab className="floating-btn" onClick={scrollToRef}>
        <ArrowUpwardIcon />
      </Fab>
    </div>
  ) : (
    ''
  );
};

CustomFab.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
  }).isRequired,
  position: PropTypes.number.isRequired,
  elem: PropTypes.shape({}).isRequired,
};

export default withStyles(styles)(CustomFab);
