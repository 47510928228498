import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Skeleton from '@material-ui/lab/Skeleton';
import compose from 'recompose/compose';
import { withRouter } from 'react-router-dom';
import { batch, connect, useDispatch, useSelector } from 'react-redux';

// Material components
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import withTheme from '@material-ui/styles/withTheme';
// import { withTheme } from '@material-ui/styles';
import { withStyles } from '@material-ui/styles';
import { withTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import withWidth, { isWidthDown } from '@material-ui/core/withWidth';

import CustomSearch from '../../../containers/search';
import CustomButton from '../../../components/custom/button';
import ContentToolBarBalance from './components/balance';
import useComingSoon from '../../../hooks/use-coming-soon';

// Actions
import {
  updateLayout,
  toggleSidenav,
  toggleNotifications,
  setNavOpened,
} from '../../../actions/layout';
import {
  changeTheme,
  changeThemeDirection,
} from '../../../actions/theme';
import { refreshWallet } from '../../../actions/wallet';
import { getSessions, checkSessions } from '../../../actions/session';
import { showFilter } from '../../../actions/filter';
import { getActiveProviders } from '../../../actions/common';
import { setFilterHidden } from '../../../actions/layout';

//config
import { showHeaderLogoList } from '../../../config';

// Menu Items
import {
  BackIcon,
  FilterIcon1,
  ReloadIcon1,
  StarIcon,
 // MenuIcon,
  MainWalletIcon,
  WalletIcon,
  CloseIconPromotion
} from '../../../components/custom/joabet.icons';
// import {
//   MainWalletIcon
// } from '../../../components/custom/joabet.icons';
import Logo from '../../../assets/images/navigation/top/logo.png';
import LiveChat from '../../../assets/icons/liveChat.svg';
import Support from '../../../assets/icons/supportHeader.svg';
import MenuIcon from '../../../assets/icons/hamburgerMenu.svg';

// Themes
import themeStyles from './content-toolbar.theme.style';
import {
  isLogin,
  removeDetails,
} from '../../../utils/helpers/common';
import { getCanRegister } from '../../../actions/register';

import LoginDialog from './login-dialog';
import LanguageSwitcher from './components/language-switcher';

const ContentToolbar = (props) => {

  const { path } = props.match

  const [loginDialogToggle, setLoginDialogToggle] = useState(false);
  const [state, setState] = useState({
      sessions: null,
      language: null,
      isLogin: true,
      page: '',
      openSearch: false,
  });
  const { ComingSoonModal, openComingSoonDialog } = useComingSoon();

  const dispatch = useDispatch();
  const {
    backTo,
    width,
  // layout,
    classes,
    hasFavourites,
    hasFilter,
    hasReload,
    history,
    includeBack,
  //  player,
    t,
    title,
  } = props;
  const layout = useSelector(state => state.layout)
  const player = useSelector(state => state.player)
  const registerState = useSelector(state => state.register)
  const { activePromotion } = useSelector(state => state.promotions);
  const { canRegister } = registerState || {};
  const { playerInfo } = player;
  const { wallet } = playerInfo || {};
  const showBurgerMenu =
    isWidthDown('sm', width) || !layout.sidenavOpen;

  const { filterHidden } = layout;

  /* eslint-disable-next-line */
  useEffect(() => {
    dispatch(getActiveProviders());
    dispatch(getSessions());
    if(canRegister === null){
      dispatch(getCanRegister({domain: window.location.hostname === "localhost" ? "15gaming.com" : window.location.hostname}));
    }

    return () => {
      dispatch(getSessions())
    }
  }, [])

  useEffect(() => {
    if(window.location.pathname === "/register" && canRegister === false){
      history.push('/');
    }
  }, [canRegister])

  // /* eslint-disable-next-line */
  // UNSAFE_componentWillReceiveProps(nextProps) {
  //   const values = this.state;
  //   // nextProps.getSessions();
  //   values.sessions = nextProps.sessions;
  //   values.language =
  //     values.language !== null ? values.language : nextProps.language;
  //   values.isLogin = !!(
  //     nextProps.sessions.token &&
  //     nextProps.sessions.token !== null &&
  //     typeof nextProps.sessions.token !== 'undefined'
  //   );
  //   values.page = nextProps.location.pathname;

  //   this.setState(values, () => {});
  //   // nextProps.checkSessions();
  //   /* eslint-disable-next-line */
  //   //    if(nextProps.sessions.token === null || typeof(nextProps.sessions.token) === "undefined")nextProps.history.push('/login');
  // }
  const { isUnauthorized } = player;

  useEffect(() => {
    if (isUnauthorized) {
      removeDetails('owSports');
      removeDetails('token');
      clearCacheData()
      window.location.href = '/?rand=' + Math.random().toString(36).slice(2);
      localStorage.setItem('popupCount', 0)  
    }
    dispatch(checkSessions());
  }, [isUnauthorized])


  const clearCacheData = () => {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
  };

  // componentDidUpdate() {
  //   const { isUnauthorized } = this.props.player;

  //   if (isUnauthorized) {
  //     removeDetails('owSports');
  //     removeDetails('token');
  //     window.location = '/';
  //   }
  //   this.props.checkSessions();
  //   // this.checkLogin(this.props);
  //   /* eslint-disable-next-line */
  //   // if(this.props.sessions.token === null || typeof(this.props.sessions.token) === "undefined")this.props.history.push('/login');
  // }

  const redirect = (link) => {
    const { history } = props;
    history.push(link);
  }

  const checkLogin = props => {
    const { sessions } = props;
    const loggedIn = !!(
      sessions.token &&
      sessions.token !== null &&
      typeof sessions.token !== 'undefined'
    );
    if (loggedIn !== true) props.history.push('/login');
  };

  const toggleSearch = () => {
    setState({
      ...state,
      openSearch: !state.openSearch,
    });
  }

  const toFavourites = () => {
    const { history } = props;
    const { location, push } = history;
    const pathname = location.pathname.replace(/^|\/+$/g, '');
    push(`${pathname}/favourites`);
  }

  const checkShowLogo = () => {
    const { location, match } = props;
    const { pathname } = location || {};
    let path;
    if ((match.params.productid && match.params.gameid) || (match.params.gameid) || match.params.providerID) {
      path = match.path
    } else {
      path = pathname
    }
    return showHeaderLogoList.includes(path);
  }

  const openNav = () => {
    dispatch(setNavOpened('topMenuIcon'));
    dispatch(toggleSidenav());
  }

  const redirection = () => {
    console.log('sdsd')
    history.push('/promotions')
    if (path){

    }
  }

  return (
    <>
    <Toolbar className={classes.root}>
      <CustomSearch
        handleClose={toggleSearch}
        open={state.openSearch}
      />
      <div className="main-options" style={{justifyContent:'center'}}>
        <div style={{marginRight:'auto'}}>
          <div className="flex-display">
            {includeBack && (
              <IconButton
                color="inherit"
                className={classes.MuiIconButton}
                onClick={
                  backTo
                    ? () => {
                        history.push(backTo);
                        dispatch(setFilterHidden(true));
                      }
                    : history.goBack
                }
              >
                <BackIcon />
              </IconButton>
            )}
            {checkShowLogo() && (
              <IconButton
                color="inherit"
                key="logo"
                className={classes.MuiIconButton}
                onClick={() => redirect('/')}
                id="logo-btn"
              >
                <img alt="logo" className={classes.logo} src={Logo} />
              </IconButton>
            )}
            <div>
              {/* <div style={{ width: isLogin() ? '56px' : '' }}></div> */}
              {isLogin() && !title && !includeBack && hasFavourites && (
                <IconButton
                  color="inherit"
                  className={`${classes.MuiIconButton} padded-btn`}
                  onClick={() => toFavourites()}
                >
                  <StarIcon />
                </IconButton>
              )}
            </div>
          </div>
        </div>
        {!title &&
          !includeBack && [
            // <div
            //   key="wallet"
            //   className={`${isLogin() ? 'flex-display' : 'hide'}`}
            // >
            //   <div>
            //     <p className={classes.wallet}>{t('main wallet')}</p>
            //     {playerInfo ? (
            //       <p className={classes.balance}>
            //         {playerInfo.player.currency.Symbol}{' '}
            //         {displayAmount(wallet)}
            //       </p>
            //     ) : (
            //       <Skeleton
            //         className={classes.skeleton}
            //         width="100%"
            //         height={10}
            //       />
            //     )}
            //   </div>
            //   <IconButton
            //     color="inherit"
            //     className={classes.MuiIconButton}
            //     onClick={() => this.redirect('/wallet')}
            //   >
            //     <MainWalletIcon className={classes.walletIcon}/>
            //   </IconButton>
            // </div>,
            <div key="promotions" className={'flex-display'}>
              {/* when logged in */}
              {/* {isLogin() &&
              <IconButton
                color="inherit"
                className={`${classes.MuiIconButton} padded-btn`}
                onClick={() => this.redirect('/liveChat')}
              >
                <img alt="LiveChat" src={LiveChat} className={classes.iconLogo}/>
              </IconButton>
              }
              <IconButton
                color="inherit"
                className={`${classes.MuiIconButton} padded-btn promotion`}
                onClick={() => this.redirect('/support')}
              >
                <img alt="Support" src={Support} className={classes.iconLogo}/>
              </IconButton> */}

              {!includeBack && isLogin() && (
                <>
                  <ContentToolBarBalance />
                  <IconButton
                    color="inherit"
                    aria-label="open sidenav"
                    style={{
                      display: showBurgerMenu ? 'block' : 'none',
                    }}
                    onClick={openNav}
                    className={classes.MuiIconButton}
                  >
                    <img
                      src={MenuIcon}
                      className={classes.menuIconLogo}
                    />
                  </IconButton>
                </>
              )}
            </div>,
            !isLogin() && (
              <div className="action-btn" key="action-btn">
                {ComingSoonModal}
                  <CustomButton
                    fullWidth
                    hasLoading
                    customClass="login"
                    variant="contained"
                    color="secondary"
                    text={t('login')}
                    onClick={() => setLoginDialogToggle(!loginDialogToggle)}
                    id="login-btn"
                  />
                {canRegister && (
                  <NavLink
                    // onClick={() => openComingSoonDialog()}
                    to="/register"
                  >
                    <CustomButton
                      fullWidth
                      hasLoading
                      customClass="register"
                      variant="contained"
                      color="secondary"
                      text={t('register')}
                    />
                  </NavLink>
                )}
                <LanguageSwitcher />
              </div>
            ),
          ]}
        {title && <p className="page-title"> {path !== '/promotions/:id' ? t(title) :  activePromotion.Title} </p> } {path !== '/promotions/:id' ? '' : <IconButton
                color="inherit"
                className={classes.MuiIconButton}
                  onClick={(e) => {
                    redirection()
                  }}
              >
                <CloseIconPromotion />
              </IconButton>  } 
        {!filterHidden && hasFilter && (
          <IconButton
            color="inherit"
            className={`${classes.MuiIconButton} padded-btn promotion`}
            onClick={() => dispatch(showFilter(true))}
          >
            <FilterIcon1 />
          </IconButton>
        )}
        {hasReload && (
          <IconButton
            color="inherit"
            className={`${classes.MuiIconButton} padded-btn promotion`}
            onClick={() => dispatch(refreshWallet(true))}
          >
            <ReloadIcon1 className="reload-icon" />
          </IconButton>
        )}
      </div>
    </Toolbar>
    <LoginDialog open={loginDialogToggle} openHandle={() => setLoginDialogToggle(!loginDialogToggle)} />
    </>
  );
}

// function mapStateToProps(state) {
//   return {
//     ...state,
//     player: state.player,
//     sessions: state.sessions,
//     layout: {
//       sidenavOpen: state.layout.sidenavOpen,
//       filterHidden: state.layout.filterHidden
//     },
//   };
// }

ContentToolbar.defaultProps = {
  backTo: null,
  hasFavourites: false,
  hasFilter: false,
  hasReload: false,
  history: {},
  includeBack: false,
  player: {},
  sessions: {},
  title: '',
  refreshWallet: null,
  showFilter: null,
  filterHidden: false,
  setFilterHidden: null
};

ContentToolbar.propTypes = {
  backTo: PropTypes.string,
  classes: PropTypes.shape({
    balance: PropTypes.string,
    logo: PropTypes.string,
    MuiIconButton: PropTypes.string,
    root: PropTypes.string,
    skeleton: PropTypes.string,
    wallet: PropTypes.string,
  }).isRequired,
  width: PropTypes.string.isRequired,
  // layout: PropTypes.shape({
  //   sidenavOpen: PropTypes.bool,
  // }).isRequired,
  theme: PropTypes.shape({}).isRequired,
  // player: PropTypes.shape({
  //   isUnauthorized: PropTypes.bool,
  //   playerInfo: PropTypes.shape({
  //     player: PropTypes.shape({
  //       currency: PropTypes.shape({
  //         Symbol: PropTypes.string,
  //       }),
  //     }),
  //   }),
  // }),
  // sessions: PropTypes.shape({
  //   token: PropTypes.string,
  // }),
  // toggleSidenav: PropTypes.func.isRequired,
  hasFavourites: PropTypes.bool,
  history: PropTypes.shape({
    goBack: PropTypes.func,
    listen: PropTypes.func,
    location: PropTypes.object,
    push: PropTypes.func,
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  // getSessions: PropTypes.func.isRequired,
  // checkSessions: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  title: PropTypes.string,
  includeBack: PropTypes.bool,
  hasFilter: PropTypes.bool,
  hasReload: PropTypes.bool,
  filterHidden: PropTypes.bool,
  // refreshWallet: PropTypes.func,
  // showFilter: PropTypes.func,
  // setFilterHidden: PropTypes.func,
  // setNavOpened: PropTypes.func,
};

export default compose(
  withRouter,
  withWidth(),
  withTranslation('translations'),
  withStyles(themeStyles, { withTheme: true }),
  // connect(
  //   mapStateToProps,
  //   {
  //     //toggleSidenav,
  //     //toggleNotifications,
  //     // updateLayout,
  //     // changeTheme,
  //     // changeThemeDirection,
  //     //getSessions,
  //     //checkSessions,
  //     //refreshWallet,
  //     //showFilter,
  //     //setFilterHidden,
  //     // setNavOpened,
  //   },
  // ),
)(withTheme(ContentToolbar));

// export default withTheme(ContentToolbar);
