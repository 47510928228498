import {
  GET_SESSIONS,
  SET_SESSIONS,
  CLEAR_SESSIONS ,
  INITIAL_SESSION_STATUS,
  AUTHORIZED_SESSION_STATUS,
  UNAUTHORIZED_SESSION_STATUS,
  SHOW_SESSION_MODAL,
  HIDE_SESSION_MODAL
} from "../constants/session";

// const portalData = JSON.parse(sessionStorage.getItem('portalData'));

// const storage = loadState();

const initialState = {
  token: null,
  user: {},
  language: null,
  status: INITIAL_SESSION_STATUS,
  showSessionModal: false
};

const sessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SESSIONS:
      return state; // (storage && storage.token)?storage:initialState;
    case SET_SESSIONS:
      return {
        ...state,
        user: action.payload.user,
        token: action.payload.token,
        language: action.payload.language
      };
    case CLEAR_SESSIONS:
      return initialState;
    case SHOW_SESSION_MODAL:
      localStorage.setItem('popupCount', 0)
      return {
        ...state,
        showSessionModal: true
      }
    case HIDE_SESSION_MODAL:
      return {
        ...state,
        showSessionModal: false
      }
    default:
      return state;
  }
};

export default sessionReducer;
