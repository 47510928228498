import React, { useRef, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import { withStyles } from '@material-ui/core';

import styles from './styles';
import CustomFab from '../fab';
import { CloseIcon } from '../joabet.icons';

const CustomDialog = ({
  actions,
  onClose,
  children,
  classes,
  customClass,
  fullScreen,
  hasBack,
  hasHeader,
  openDialog,
  title,
  titleContent,
  key,
  renderHeader
}) => {
  const [positionX, setPositionX] = useState(0);
  const ref = useRef();
  const onScroll = useCallback(() => {
    setPositionX(ref.current.scrollTop);
  });

  return (
    <Dialog
      key={(typeof(key) && key !== null)?key:"CustomDialog"}
      className={`${customClass} ${classes.root}`}
      classes={{
        paperScrollPaper: !fullScreen ? classes.paperScrollPaper : '',
      }}
      open={openDialog}
      fullScreen={fullScreen}
      onBackdropClick={onClose}
      TransitionComponent={Slide}
      TransitionProps={{ direction: 'up' }}
    >
      {renderHeader !== undefined && renderHeader}
      {hasHeader && !titleContent && (
        <DialogTitle className="dialog-title">
            <p>{title}</p>
            <IconButton onClick={onClose}>
              <CloseIcon />
             </IconButton>
        </DialogTitle>
      )}
      {hasHeader && titleContent && (
        <DialogTitle className="dialog-title">
          {titleContent}
        </DialogTitle>
      )}
      <DialogContent ref={ref} onScroll={onScroll}>
        {children}
      </DialogContent>
      {actions && <DialogActions>{actions}</DialogActions>}
      <CustomFab elem={ref} position={positionX} />
    </Dialog>
  );
};

CustomDialog.defaultProps = {
  actions: null,
  onClose: null,
  customClass: '',
  fullScreen: false,
  hasHeader: false,
  openDialog: false,
  title: '',
  titleContent: null,
};

CustomDialog.propTypes = {
  actions: PropTypes.node,
  onClose: PropTypes.func,
  classes: PropTypes.shape({
    paperScrollPaper: PropTypes.string,
    root: PropTypes.string,
  }).isRequired,
  children: PropTypes.node.isRequired,
  customClass: PropTypes.string,
  fullScreen: PropTypes.bool,
  hasHeader: PropTypes.bool,
  openDialog: PropTypes.bool,
  title: PropTypes.string,
  titleContent: PropTypes.node,
};

export default withStyles(styles)(CustomDialog);
