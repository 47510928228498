import {
  all,
  call,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';
import { PLAY, PLAY_LIVE_CASINO, GET_GAME_LIVE_CASINO_CATEGORIES } from '../constants/live.casino';
import { play, setPlaySuccess,getGameCategoriesSuccess,openLiveCasinoError } from '../actions/live.casino';
import { /* getPlayerInfo, */ setPlayerInfo } from '../actions/player';
import casinoSvc from '../utils/services/casino';
import playerSvc from '../utils/services/player';
import { realAmount } from '../utils/helpers/common';
import {
  toggleTransferDialog,
  toggleDepositDialog,
  setProviders, 
  showGameMessage
} from '../actions/common';
import {
  submitTransferSuccess
} from '../actions/wallet';
import {
  showSessionModal
} from '../actions/session';
import { BRAND } from '../config/api';

import {
  resetPlayState,
} from '../actions/sports';

import { 
  resetOpenCasinoError 
} from '../actions/slot.games';

import { 
  resetOpenMinigameError 
} from '../actions/minigame';

function* playLiveCasino(action) {
  try {
    const { params } = action;
    const min_transfer = (process.env.REACT_APP_MIN_TRANSFER)?process.env.REACT_APP_MIN_TRANSFER:1;

    const player = yield call(playerSvc.getPlayerInfo);
    yield put(setPlayerInfo(player.data));
    const { wallet } = player.data;

    const request = yield call(playerSvc.getProviderBalance, {
      provider: params.provider,
    });
    const { balance } = request.data;

    if (realAmount(wallet) >= min_transfer) {
      yield put(toggleTransferDialog(true));
    } else if (
      realAmount(wallet) < min_transfer &&
      realAmount(balance) === 0
    ) {
      yield put(toggleDepositDialog(true));
    } else {
      yield put(play(params));
    }
  } catch (err) {
    const { status } = err.response || {};

    if (status === 403) {
      yield put(showSessionModal())
    }
  }
}

function* playGame(action) {
  try {
    const { params } = action;
    const finalParams = { brandID: BRAND, ...params };

    const request = yield call(casinoSvc.playGame, finalParams);
    const { data } = request;

    const { success, url } = data;
    yield put(
      setPlaySuccess(
        params.provider === 5 /* && params.gameType === 'slots' */
          ? { ...data, isIframe: 1, provider: params.provider }
          : {
              provider: params.provider,
              success,
              url,
              isIframe: data.is_iframe ? data.is_iframe : '',
            },
      ),
    );

    //Set providers 
    const date = new Date();
    const date_timestamp = date.getTime(); 
    const { providers } = yield select(state => state.common);
    const index = providers.findIndex(
      item => item.ID === params.provider,
    );  
    // providers[index].RecentlyPlayed = {id: params.provider, date: date_timestamp};  
    yield put(setProviders(providers, true));
    

  } catch (err) {
    const { status } = err.response || {};

    if (status === 403) {
      yield put(showSessionModal())
    }
    else{          
      yield put(resetPlayState())
      yield put(resetOpenCasinoError())
      yield put(resetOpenMinigameError())
      yield put(openLiveCasinoError(err.response.data.errors))
      yield put(showGameMessage()); 
      yield put(toggleTransferDialog(false));
      yield put(submitTransferSuccess(false));
    }
  }
}

function* getLiveCasinoCategories(action) {
   
  try {   
    const { params } = action;  
    const request = yield call(casinoSvc.getGameCategories, {
      product: 2,
      isMobile: true,
      provider: params.provider
    });
    const { data } = request.data;

    yield put(getGameCategoriesSuccess(data));
  } catch (err) {
    // err.response.data
  }
}

export default function* liveCasinoWatcher() {
  yield all([
    takeLatest(PLAY, playGame),
    takeLatest(GET_GAME_LIVE_CASINO_CATEGORIES, getLiveCasinoCategories),
    takeLatest(PLAY_LIVE_CASINO, playLiveCasino),
  ]);
}
