import { 
    GET_LANDING_PAGE_WITHDRAWAL_SUMMARY,
    GET_LANDING_PAGE_WINNER_LIST_SUMMARY,
    SET_LANDING_PAGE_WINNER_LIST_SUMMARY,
    SET_LANDING_PAGE_WITHDRAWAL_SUMMARY,
    GET_LANDING_PAGE_WINNER_LIST_MONTHLY,
    SET_LANDING_PAGE_WINNER_LIST_MONTHLY,
    UPDATE_LANDING_PAGE_WITHDRAWAL_SUMMARY,
    GET_HOME_PAGE_CAROUSEL,
    SET_HOME_PAGE_CAROUSEL,
    GET_HOME_PAGE_CAROUSEL_SETTING,
    SET_HOME_PAGE_CAROUSEL_SETTING,
} from "../constants/home"; 
 
export const getWithdrawalSummary = () => { 
    return { 
      type: GET_LANDING_PAGE_WITHDRAWAL_SUMMARY
    }; 
}; 

export const getWinnerListSummary = () => { 
    return { 
      type: GET_LANDING_PAGE_WINNER_LIST_SUMMARY
    }; 
}; 

export const getWinnerListMonthly = () => { 
  return { 
    type: GET_LANDING_PAGE_WINNER_LIST_MONTHLY
  }; 
}; 

export const setWithdrawalSummary = payload => { 
  return { 
    type: SET_LANDING_PAGE_WITHDRAWAL_SUMMARY,
    payload: payload
  }; 
}; 

export const setWinnerListSummary = payload => { 
  return { 
    type: SET_LANDING_PAGE_WINNER_LIST_SUMMARY,
    payload: payload
  }; 
}; 

export const setWinnerListMonthly = payload => { 
  return { 
    type: SET_LANDING_PAGE_WINNER_LIST_MONTHLY,
    payload: payload
  }; 
}; 

export const updateWithdrawalSummary = payload => { 
  return { 
    type: UPDATE_LANDING_PAGE_WITHDRAWAL_SUMMARY,
    payload: payload
  }; 
}; 

export const getHomePageCarousel = params => { 
  return { 
    type: GET_HOME_PAGE_CAROUSEL,
    params
  };
};

export const setHomePageCarousel = payload => { 
  return { 
    type: SET_HOME_PAGE_CAROUSEL,
    payload: payload
  };
};

export const getHomePageCarouselSetting = () => { 
  return { 
    type: GET_HOME_PAGE_CAROUSEL_SETTING
  };
};

export const setHomePageCarouselSetting = payload => { 
  return { 
    type: SET_HOME_PAGE_CAROUSEL_SETTING,
    payload: payload
  };
};