import axios from 'axios';
import { showSessionModal } from './actions/session';
import { BRAND_TOKEN } from './config/api';
import store from './store';
axios.defaults.headers.common = {
    "brand-token": BRAND_TOKEN,
};

const { dispatch } = store;
axios.interceptors.response.use(
    response => response,
    error => {
        const { status } = error.response
        if(status === 403){
            dispatch(showSessionModal())
        }
        return Promise.reject(error);
    }
)