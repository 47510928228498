import { OPEN_MINIGAME, OPEN_MINIGAME_SUCCESS, OPEN_MINIGAME_ERROR, RESET_OPEN_MINIGAME_ERROR, SET_INITIAL_MINIGAME } from '../constants/minigame';
  
export const openMinigame = (isLogin) => ({
    type: OPEN_MINIGAME,
    isLogin
});
  
export const openMinigameSuccess = (url) => ({
    type: OPEN_MINIGAME_SUCCESS,
    url
});
  
export const openMinigameError = response => ({
    type: OPEN_MINIGAME_ERROR,
    sucess: false,
    errors: response,
    loading: false
});

export const resetOpenMinigameError = response => ({
    type: RESET_OPEN_MINIGAME_ERROR,
    response,
});

export const resetMiniGameState = response => ({
    type: SET_INITIAL_MINIGAME,
    response,
});
