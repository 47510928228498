  const styles = theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        '& .display': {
            display:'flex',
            alignItems: 'center'
        },
        '& img': {
            width:'16px',
            height:'16px',
            borderRadius:'50%'
        }
    }
});

export default styles;