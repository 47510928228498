import {
  SET_MSG,
  SET_REGISTER_ERRORS,
  SET_REGISTER_SUCCESS,
  SET_REGISTER_PARTNER_SUCCESS,
  SET_PARTNER_STATUS,
  SET_VERIF_SUCCESS,
  SET_WELCOME_PROMOTIONS,
  RESET_SUCCESS,
  RESET_VERIFY_MESSAGE,
  CAN_REGISTER_SUCCESS,
  SET_SMS_SETTING
} from "../constants/register";
import { SET_LOADING } from "../constants/common";

const initialState = {
  isRegistrationSuccess: false,
  errors: {},
  showLoading: false,
  verifSuccess: false,
  canRegister: null,
  verifMsg: {
    type: "error",
    msg: "",
    show: false
  },
  partner: null,
  partnerStatus: 'INITIAL_STATE',
  welcomePromotions: [],
  sms: {}
};

const registerReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_MSG:
      return {
        ...state,
        verifMsg: {
          ...state.verifMsg,
          type: action.msgType,
          msg: action.msg,
          show: action.show
        }
      };
    case SET_REGISTER_ERRORS:
      return {
        ...state,
        errors: action.errors
      };
    case SET_REGISTER_SUCCESS:
      return {
        ...state,
        isRegistrationSuccess: action.success
      };
    case SET_REGISTER_PARTNER_SUCCESS:
      return {
        ...state,
        partner: action.partner,
        partnerStatus: 'SUCCESS'
      }
    case SET_PARTNER_STATUS:
      return {
        ...state,
        partnerStatus: action.status
      }
    case SET_LOADING:
      return {
        ...state,
        showLoading: action.show
      };
    case SET_VERIF_SUCCESS:
      return {
        ...state,
        verifSuccess: action.success
      };
    case SET_WELCOME_PROMOTIONS:
      return {
        ...state,
        welcomePromotions: action.welcomePromotions
      }
    case RESET_SUCCESS:
      return {
        ...state,
        isRegistrationSuccess: false,
        verifSuccess: false,
      }
      case CAN_REGISTER_SUCCESS:
        return {
          ...state,
          canRegister: action.canRegister
        }
    case RESET_VERIFY_MESSAGE:
      return {
        ...state,
        verifMsg: {
          type: "error",
          msg: "",
          show: false
        },
      }
    case SET_SMS_SETTING:
      return {
        ...state,
        sms: action.payload
      }
    default:
      return state;
  }
};

export default registerReducer;
