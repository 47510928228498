import { all, call, put, takeLatest } from 'redux-saga/effects';

import {
  SET_PARTNERS_ERRORS,
  SET_PARTNERS_LOADING,
  SUBMIT_CONTACT,
} from '../constants/partners';
import { submitContactSuccess } from '../actions/partners';
import { setErrors, setLoading } from '../actions/common';
import partnersSvc from '../utils/services/partners';

function* submitContact(action) {
  try {
    yield put(setLoading(true, SET_PARTNERS_LOADING));
    const request = yield call(
      partnersSvc.submitContact,
      action.params,
    );
    const { message } = request.data;

    yield put(submitContactSuccess(message));
  } catch (err) {
    const { errors } = err.response.data;

    yield put(setErrors(SET_PARTNERS_ERRORS, errors));
  } finally {
    yield put(setLoading(false, SET_PARTNERS_LOADING));
  }
}

export default function* partnersWatcher() {
  yield all([takeLatest(SUBMIT_CONTACT, submitContact)]);
}
