const styles = theme => ({
  logo: {
    height: '1.3em',
    marginRight: '0.5em',
  },
  iconLogo: {
    height: '1.2rem',
  },
  menuIconLogo: {
    //height: '0.3em',
  },
  MuiIconButton: {
    padding: '4px',
    borderRadius: 0,
  },
  wallet: {
    color: theme.palette.text.secondary,
    marginBlockEnd: 0,
    fontSize: '10px',
    textTransform: 'uppercase',
  },
  walletIcon: {
    color: theme.palette.secondary.dark,
  },
  balance: {
    marginBlockStart: 0,
    color: theme.palette.secondary.dark,
    fontSize: '10px',
  },
  skeleton: {
    marginTop: theme.spacing(1) / 2,
    marginBottom: theme.spacing(1),
  },
  root: {
    '& .action-btn': {
      margin: `${theme.spacing(1) + 2}px 0px 0`,
      display: 'flex',
      justifyContent: 'space-around',
      paddingBottom: '0.5em',
      gap: '0.5em',
      '& a': {
        textDecoration: 'none',
        fontSize: '11px',
        // borderRadius: '45px',
        // fontWeight: 500,
        // width: '100%',
        // textTransform: 'uppercase',
        // textAlign: 'center',
        // padding: theme.spacing(2) - 5,
      },
      '& .register': {
        fontSize: '11px',
        ['@media (max-width:280px)']: {
          fontSize: '0.6rem',
        },
        borderRadius: '3px',
        height: '27px',
        background: '#374950',
        color: theme.palette.text.primary,
        width: '75px',
        fontWeight: '700'
      },
      '& .login': {
        height: '27px',
        fontSize: '11px',
        // ['@media (max-width:280px)']: {
        //   fontSize: '0.6rem',
        // },
        borderRadius: '3px',
        background: theme.palette.secondary.dark,
        color: theme.palette.text.primary,
        width: '75px',
        fontWeight: '700'
      },
    },
    '& .main-options': {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      '& .hide': {
        display: 'none',
      },
      '& .reload-icon': {
        width: '15px',
      },
      '& .page-title': {
        margin: 0,
        fontSize: '23px',
        fontWeight: 700,
        textTransform: 'capitalize',
        position: 'absolute',
        // left: 0,
        width: '85%',
        textAlign: 'center',
        zIndex: -1,
        overflow:'hidden',
        whiteSpace: 'nowrap', 
        textOverflow: 'ellipsis',
      },
      ['@media (width:280px)']: {
        '& .page-title': {
          width: '80%',
          // fontSize: '17px',
        },
      },
      ['@media (width:320px)']: {
        '& .page-title': {
          width: '80%',
          // fontSize: '19px',
        },
      },
      ['@media (width:360px)']: {
        '& .page-title': {
          width: '83%',
        },
      },
      ['@media (width:540px)']: {
        '& .page-title': {
          width: '87%',
        },
      },
      ['@media (min-width:768px)']: {
        '& .page-title': {
          width: '100%',
        },
      },
      ['@media (max-width:380px)']: {
        '& .page-title': {
         
          fontSize: '19px',
        },
      },  
      ['@media (max-width:330px)']: {
        '& .page-title': {
          width: '80%',
          fontSize: '18px',
        },
      }, 
      ['@media (max-width:300px)']: {
        '& .page-title': {
          width: '80%',
          fontSize: '17px',
        },
      },
      ['@media (max-width:280px)']: {
        '& .page-title': {
          width: '80%',
          fontSize: '16px',
        },
      },
      '& .flex-display': {
        display: 'flex',
        '& .padded-btn': {
          paddingLeft: theme.spacing(1),
        },
        '& .promotion': {
          paddingLeft: theme.spacing(2),
        },
      },
    },
  },
});

export default styles;
