const styles = theme => ({
  root: {},
  customDialog: {
    '& .MuiPaper-root': {
      '& .MuiDialogContent-root':{
        padding: theme.spacing(1)
      },
      '& .MuiTypography-root': {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '8px',
        color: theme.palette.text.default,
        textTransform: 'uppercase',
        fontSize: '1rem',
        '& p': {
          margin: 0,
          position: 'absolute',
          textAlign: 'center',
          width: '100%',
        },
        '& .MuiIconButton-root': {
          padding: 0,
          '& svg': {
            fontSize: '20px',
            paddingBottom: 0,
            fill: theme.palette.text.secondary
          },
        },
      },
    },
  },
});

  export default styles;
