import {
  all,
  call,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';

import { OPEN_SPORTS } from '../constants/sports';
import sportSvc from '../utils/services/sports';
import { openSportsSuccess, openSportsError } from '../actions/sports';
import { getLanguage } from '../utils/helpers/common';
import { BRAND } from '../config/api';
import {
  showGameMessage 
} from '../actions/common';

import { 
  resetOpenCasinoError 
} from '../actions/slot.games';
import { 
  resetOpenLiveCasinoError 
} from '../actions/live.casino';
import { 
  resetOpenMinigameError 
} from '../actions/minigame';

function* openSports(action) {
  try {
    let params = {
      ...action.params,
      brandID: BRAND,
      isMobile: 1,
      gameType: 'esports',
      lang: getLanguage(),
    };

    if (action.isLogin) {
      const playerState = yield select(state => state.player);
      const { playerInfo } = playerState;
      const { player, username } = playerInfo;

      params = {
        ...params,
        currency: player.currency.Abbreviation,
        mode: 1,
        launchType: 'html5',
        playerID: player.PlayerID,
        username,
      };
    } else {
      params = {
        ...params,
        mode: 0,
      };
    }
    const request = yield call(sportSvc.openSports, params);

    if(params.providerID === 25) {
      yield put(openSportsSuccess(request.data, params.providerID));
    } else if(params.providerID === 23){
      yield put(openSportsSuccess(request.data, params.providerID));
    } else if(params.providerID === 28){
      yield put(openSportsSuccess(request.data, params.providerID));
    } else {
      const { url } = request.data;
      yield put(openSportsSuccess(url, params.providerID));
    }
  } catch (err) {
    const { status } = err.response || {};

    if (status !== 200) {  
      let error = err.response.data.errors
      error.providerID = action.params.providerID
      yield put(resetOpenCasinoError())
      yield put(resetOpenLiveCasinoError())
      yield put(resetOpenMinigameError())
      yield put(openSportsError(err.response.data.errors))
      yield put(showGameMessage())
    }
  }
}

export default function* sportsWatcher() {
  yield all([takeLatest(OPEN_SPORTS, openSports)]);
}
