const styles = () => ({
  root: {
    height: '45px',
    borderRadius: '3px',
    '& .loading': {
      height: '40px',
    },
  },
});

export default styles;
