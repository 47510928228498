import React from 'react';
import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/styles/withStyles';
import PropTypes from 'prop-types';

import styles from './styles';
import Loading from '../../../assets/images/loading.gif';

const CustomButton = ({
  disabled,
  classes,
  color,
  customClass,
  fullWidth,
  hasLoading,
  icon,
  onClick,
  showIcon,
  text,
  type,
  variant,
  underline,
  id,
  ...props
}) => (
  <Button
    disabled={disabled}
    className={`${classes.root} ${customClass}`}
    variant={variant}
    fullWidth={fullWidth}
    color={color}
    onClick={onClick}
    type={type}
    id={id}
  >
    {hasLoading && (
      <span>
        {showIcon && (
          <img alt="loading" className="loading" src='/assets/images/loading.gif' />
        )}
        {!showIcon && text}
      </span>
    )}
    {!hasLoading && !underline && (
      <span>
        {text}{' '}
      </span>
    )}

    {!hasLoading && underline && (
      <span>
       <u>{text}{' '}</u> 
      </span>
    )}
  </Button>
);

CustomButton.defaultProps = {
  disabled: false,
  color: 'default',
  customClass: '',
  hasLoading: false,
  fullWidth: false,
  icon: null,
  onClick: null,
  showIcon: false,
  text: null,
  type: 'submit',
  variant: '',
  id: ''
};

CustomButton.propTypes = {
  disabled: PropTypes.bool,
  classes: PropTypes.shape({
    root: PropTypes.string,
  }).isRequired,
  color: PropTypes.string,
  customClass: PropTypes.string,
  fullWidth: PropTypes.bool,
  hasLoading: PropTypes.bool,
  icon: PropTypes.node,
  onClick: PropTypes.func,
  showIcon: PropTypes.bool,
  text: PropTypes.node,
  type: PropTypes.string,
  variant: PropTypes.string,
  id: PropTypes.string,
};

export default withStyles(styles)(CustomButton);
