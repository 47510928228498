import React from 'react';
import { withStyles } from '@material-ui/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import styles from './styles'

import {
  isLogin
} from '../../../utils/helpers/common';

function MenuVipInfo({classes, playerInfo}){
    const { t } = useTranslation();
    const { badge, username, vip } = playerInfo || {};

    return(
        <div className={classes.root}>
          {isLogin() && !!playerInfo && (
              <div className='details-content'>
                <div>
                  {/* <span className='welcome'>{t('welcome')}, </span> */}
                  {/* <p className='level'>
                    {t('vip level')}
                    <span className='level-indicator'>
                      {t(vip.toLowerCase())}
                    </span>
                  </p> */}
              <img
                className='level-badge'
                src={badge}
                alt="level"
              />
              <span className='name'>{username}</span>
            </div>
              </div>
            )}
            {isLogin() && !playerInfo && (
              <div className='details-content'>
                {/* <Skeleton
                  className='level-badge'
                  variant="circle"
                  height={54}
                  width={53}
                /> */}
                <div style={{ width: '70%' }}>
                  <Skeleton
                    style={{ margin: 0 }}
                    variant="text"
                    height={14}
                    width="60%"
                  />
                  {/* <Skeleton
                    style={{ marginBottom: 0, marginTop: '8px' }}
                    variant="text"
                    height={12}
                    width="50%"
                  /> */}
                </div>
              </div>
            )}
        </div>
    )
}

MenuVipInfo.propTypes = {
  classes: PropTypes.shape({
      root: PropTypes.string
  }),
  playerInfo: PropTypes.shape({
      player: PropTypes.object,
      wallet: PropTypes.string
  }),
}

export default withStyles(styles)(MenuVipInfo);