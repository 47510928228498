import {
  SUBMIT_CONTACT,
  SUBMIT_CONTACT_SUCCESS,
  CONTACT_SUCCESS_RESET,
  RESET_PARTNERS_ERRORS,
} from '../constants/partners';

export const submitContact = params => ({
  type: SUBMIT_CONTACT,
  params,
});

export const submitContactSuccess = response => ({
  type: SUBMIT_CONTACT_SUCCESS,
  response,
});

export const resetContactSuccess = payload => ({
  type: CONTACT_SUCCESS_RESET,
  payload
});

export const resetPartnerErrors = () => ({
  type: RESET_PARTNERS_ERRORS,
});