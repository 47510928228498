const modifiedResponses = {
  'The password must be at least 6 characters.':
    'password validation',
  'The username must be at least 6 characters.':
    'username validation',
  'The phone must be 10 digits.': 'phone validation',
  'The username has already been taken.':
    'the username has already been taken',
  'Invalid Code. Please try again.': 'invalid verification code',
  'You have unfinished wagering requirements2':
    'you have unfinished wagering requirements',
  'The password and confirm password must match.':
    'password do not match',
  'The selected ref username is invalid.':
    'invalid id',
  'The selected ref code is invalid.':
    'partner id validation',
  'The username may only contain letters and numbers.':
    'username alphanumeric',
  'The password may not be greater than 12 characters.':
    'password validation',
  'Player password updated succesfully':
    'player password updated success',
  'Your current account number does not match with the account number you provided. Please try again.':
  'account number validation',
  'The email has already been taken.': 'the email has already been taken.',
  'The number is blacklisted.': 'the number is blacklisted.',
  'The email must be a valid email address.': 'the email must be a valid email address.',
  'The account name has already been taken.': 'duplicate bank account name',
  'The account name may only contain letters.': 'bank account name error',
  'The account name field is required.': 'this field is required',
  'The name has already been taken.': 'duplicate first name',
};

export default modifiedResponses;
