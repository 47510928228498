export const ROOT_URL = process.env.REACT_APP_LOCAL;
export const BRAND = process.env.REACT_APP_BRAND;
export const WEBSOCKET_URL = process.env.REACT_APP_WEBSOCKET;
export const IS_PROD = process.env.REACT_APP_PROD;
export const S3 = process.env.REACT_APP_S3;
export const BRAND_TOKEN = process.env.REACT_APP_BRAND_TOKEN;

/* export const GAME_URL = process.env.REACT_APP_GAME;
export const SPORT_URL = process.env.REACT_APP_SPORT; */

export function findToken() {
  return localStorage.getItem('appToken');
}
