export const GET_ACTIVE_PROVIDERS = 'GET_ACTIVE_PROVIDERS';
export const GET_BANKS = 'GET_BANKS';
export const GET_CURRENCIES = 'GET_CURRENCIES';
export const GET_PROVIDERS = 'GET_PROVIDERS';
export const GET_PRODUCT_PROVIDERS = 'GET_PRODUCT_PROVIDERS';
export const GET_PROVIDER_PROMOTIONS = 'GET_PROVIDER_PROMOTIONS';
export const SET_ACTIVE_PROVIDERS = 'SET_ACTIVE_PROVIDERS';
export const SET_BANKS = 'SET_BANKS';
export const SET_CURRENCY = 'SET_CURRENCY';
export const SET_CURRENCY_INFOS = 'SET_CURRENCY_INFOS';
export const SET_FAVOURITE = 'SET_FAVOURITE';
export const SET_FAVOURITE_LOADING = 'SET_FAVOURITE_LOADING';
export const SET_FAVOURITE_SUCCESS = 'SET_FAVOURITE_SUCCESS';
export const SET_FILTERED_PROVIDERS = 'SET_FILTERED_PROVIDERS';
export const SET_SELECT_FILTERED_PROVIDERS = 'SET_SELECT_FILTERED_PROVIDERS';
export const SET_LOADING = 'SET_LOADING';
export const SET_PROVIDERS = 'SET_PROVIDERS';
export const SET_PRODUCT_PROVIDERS = 'SET_PRODUCT_PROVIDERS';
export const SET_PROVIDER_PROMOTIONS = 'SET_PROVIDER_PROMOTIONS';
export const SET_AMOUNT_LOCKED_PROVIDERS = 'SET_AMOUNT_LOCKED_PROVIDERS';
export const SHOW_DEPOSIT_DIALOG = 'SHOW_DEPOSIT_DIALOG';
export const SHOW_TRANSFER_DIALOG = 'SHOW_TRANSFER_DIALOG';
export const GET_GAMES_COMMON = 'GET_GAMES_COMMON';
export const SET_GAMES_COMMON = 'SET_GAMES_COMMON';
export const GET_PRODUCTS = 'GET_PRODUCTS';
export const SET_PRODUCTS = 'SET_PRODUCTS';
export const GET_GAMES_SUCCESS = 'GET_GAMES_SUCCESS';
export const SET_ARCADE_GAMES = 'SET_ARCADE_GAMES';
export const SET_GAMES_BY_PROVIDER = 'SET_GAMES_BY_PROVIDER';
export const GET_HOME_BANNERS = 'GET_HOME_BANNERS';
export const SET_HOME_BANNERS = 'SET_HOME_BANNERS';
export const SET_SHARED_WALLET = 'SET_SHARED_WALLET'
export const GET_RECENT_GAMES = 'GET_RECENT_GAMES'
export const SET_RECENT_GAMES = 'SET_RECENT_GAMES'


//GENERIC STATUS
export const INITIAL_STATUS = 'INITIAL_STATUS';
export const REQUESTING_STATUS = 'REQUESTING_STATUS';
export const SUCCESS_REQUEST_STATUS = 'SUCCESS_REQUEST_STATUS';


export const SHOW_GAME_MESSAGE = 'SHOW_GAME_MESSAGE';
export const HIDE_GAME_MESSAGE = 'HIDE_GAME_MESSAGE';


