const styles = theme => ({
    root: {
      '.chat-btn': {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2)
      },
    },
    '.chat-btn': {
      position: 'absolute',
      bottom: theme.spacing(2),
      right: theme.spacing(2)
    },
  });

  export default styles;
