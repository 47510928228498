import API from './index';

const playerSvc = {
  getBankAccount: params => new API().get('/bankAccount', params),
  cancelOngoingPromotion: params =>
    new API().post('/bonus/cancel', params),
  changePassword: params => new API().post('/password', params),
  checkPlayerSubscription: params =>
    new API().get('/bonus/check', params),
  getOngoingPromotion: params =>
    new API().get('/bonus/check', params),
  getPlayerPromotionMinDeposit: params =>
    new API().get('/promotionMinDeposit', params),
  getPlayerBonus: params => new API().get('/bonus', params),
  getPlayerBonusHistory: params => new API().get('/bonusHistory', params),
  getPlayerCash: params => new API().get('/cash', params),
  getPlayerInfo: () => new API().get('/player'),
  getPlayerUnreadMessageCount: () => new API().get('/getUnreadMessageCount'),
  getPlayerUnreadNoticeCount: () => new API().get('/getUnreadNoticeCount'),
  getPlayerMessages: params =>
    new API().get(
      `/messages${typeof params !== 'object' ? `/${params}` : ''}`,
      typeof params === 'object' ? params : {},
    ),
  getPlayerTransactions: params =>
    new API().get('/transactions', params),
  getProviderBalance: params => new API().get('/getBalance', params),
  redeemBonus: subscription =>
    new API().post('/bonus/redeem', subscription),
  submitBankDetails: params => new API().post('/bankAccount', params),
  updatePlayerInfo: params => new API().post('/player', params),
  sendMessage: params => new API().post('/messages/send', params),
  getDepositAccount: params => new API().get('/playerDepositAccount', params),
  getPaymentTronExchange: () => new API().get('/paymentMerchant'),
  getPaymentTronExchangeBalance: () => new API().get('/paymentMerchantBalance'),
  getPaymentTronExchangePurchase: () => new API().get('/paymentMerchantPurchase'),
  getDepositOption: params => new API().get('/depositOptions',params),
  getChungDamInfo: () => new API().get('/chungDamInfo'),
  getDepositPromotion: params => new API().get('/depositPromotion',params),
  getDepositPromotionCheck: () => new API().get('/depositPromotionCheck')
};

export default playerSvc;
