import {
  PLAY_SUCCESS,
  PROCEED_PLAY_CASINO_GAME,
  RESET_PLAY_STATE,
  GET_GAME_LIVE_CASINO_CATEGORIES_SUCCESS,
  OPEN_LIVE_CASINO_ERROR,
  RESET_OPEN_LIVE_CASINO_ERROR,
} from '../constants/live.casino';

const initialState = {
  currentGame: null,
  proceedToGame: false,
  gameCategories: [],
  errors: [],
};

export default function providersReducer(
  state = initialState,
  action,
) {
  switch (action.type) {
    case PLAY_SUCCESS:
      return {
        ...state,
        currentGame: action.response,
      };
    case PROCEED_PLAY_CASINO_GAME:
      return { ...state, proceedToGame: action.status };
    case RESET_PLAY_STATE:
      return initialState;
    case GET_GAME_LIVE_CASINO_CATEGORIES_SUCCESS:
        return { ...state, gameCategories: action.response };
    case OPEN_LIVE_CASINO_ERROR:
      return {
        ...state,
        errors: action.response
    }
    case RESET_OPEN_LIVE_CASINO_ERROR:
      return {
        ...state,
        errors: []
    }
    default:
      return state;
  }
}
