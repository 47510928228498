import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button';
import ReactHTMLParser from 'react-html-parser';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
//import { displayAmount } from '../../utils/helpers/common';
//import compose from 'recompose/compose';
import styles from './styles';

import CustomizableDialog from '../../components/custom/customizable-dialog';
import TelegramIcon from '../../assets/icons/Telegram.svg';
import { EmailIcon, GameMaintenanceIcon, CloseIconMaintenance, TelegramIconMaintenance, EmailIconMaintenance} from '../../components/custom/joabet.icons';

import {
  hideGameMessage
} from '../../actions/common';

const redirect_url = ['/powerball', '/live-sports'];

const GameMessageModal = (props) => {
    const history = useHistory();
    const {classes} = props;
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const [underMaintenance, setUnderMaintenance] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const {
      showGameMessage
    } = useSelector(state => state.common);
    const {
      errors
    } = useSelector(state => state.sports);
    const slotErrors = useSelector(state => state.slot.errors);
    const liveCasinoErrors = useSelector(state => state.casino.errors);
    const minigameErrors = useSelector(state => state.minigame);
    const [content, setContent] = useState(null);

    const hideMessage = useCallback(() => {
      dispatch(hideGameMessage());

      if(window.location.pathname === '/minigame/cockfighting') {
        history.push('/');
      }

      // if(redirect_url.includes(window.location.pathname))
      // {
       
        // history.push('/');
        // if(typeof(document.referrer) !== 'undefined' && document.referrer !== null && document.referrer !== '' )
        // {
        //   history.goBack();
        // }
        // else
        // {
        //   history.push('/');
        // }
      // }
    });

    const hideMessageMaintenance = useCallback(() => {
      dispatch(hideGameMessage());
      
      let pathname = ['/slots','/live-casino'];
      if (errors && errors.providerID === 21) {
        
      } else {
        if (!pathname.includes(window.location.pathname) ) {
          dispatch(hideGameMessage());
          history.push('/');
        }
      }
    });

    const { language } = i18n;
    let lang = [];

    if(language !== undefined) {
      lang = language.split("-")[0]
    } else {
        lang = "ko";
    }

    useEffect(() => {
      hideGameMessage();
      return () => {
        dispatch(hideGameMessage());
      }
    }, []);

    useEffect(() => {
      const action = (showGameMessage)?showGameMessage:false;
      setOpenDialog(action);
    }, [showGameMessage]);

    useEffect(() => {
      if(errors && errors.errors && errors.errors.undermaintenance) {
        setUnderMaintenance(typeof errors.errors.undermaintenance !== 'undefined' ? errors.errors.undermaintenance : false);
        if (errors.errors.content && errors.errors.content.length !== 0) {
          setContent(typeof errors.errors.undermaintenance !== 'undefined' ? errors.errors.content : null);
        } else {
          setContent(null);
        }
      } else {
        setUnderMaintenance(false);
      }
    },[errors])

    useEffect(() => {
      if (slotErrors && slotErrors.maintenance){
        setUnderMaintenance(slotErrors.maintenance);
        if (slotErrors.content && slotErrors.content.length !== 0 ) {
          setContent(slotErrors.content);
        } else {
          setContent(null);
        }
      } else {
        setUnderMaintenance(false);
      }

    },[slotErrors])

    useEffect(() => {
      if (liveCasinoErrors && liveCasinoErrors.maintenance){
        setUnderMaintenance(liveCasinoErrors.maintenance);
        if (liveCasinoErrors.content && liveCasinoErrors.content.length !== 0 ) {
          setContent(liveCasinoErrors.content);
        } else {
          setContent(null);
        }
      } else {
        setUnderMaintenance(false);
      }

    },[liveCasinoErrors])


    useEffect(() => {
      if(minigameErrors  && minigameErrors.errors  && minigameErrors.errors.errors && minigameErrors.errors.errors.undermaintenance) {
        setUnderMaintenance(typeof minigameErrors.errors.errors.undermaintenance !== 'undefined' ? minigameErrors.errors.errors.undermaintenance : false);
        if (minigameErrors.errors.errors && minigameErrors.errors.errors.content.length !== 0) {
          setContent(typeof minigameErrors.errors.errors.undermaintenance !== 'undefined' ? minigameErrors.errors.errors.content : null);
        } else {
          setContent(null)
        }
      } else {
        setUnderMaintenance(false);
      }
    },[minigameErrors])

    return (
      underMaintenance ?
      <CustomizableDialog
        customScrollPaper={classes.customScrollPaper}
        customClass={classes.underMaintenanceDialog}
        openDialog={openDialog}
      >
        <Grid 
          container 
          className="closeButton"
        >
          <Grid item>
            <IconButton key="close" aria-label="Close" color="inherit" onClick={() => { hideMessageMaintenance() }}>
              <CloseIconMaintenance/>
            </IconButton>
          </Grid>
        </Grid>
        <Grid 
          container 
          spacing={1}
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={12} >
            <GameMaintenanceIcon className='underMaintenanceIcon'/>
            <p className="underMaintenanceTitle">{content !== null ? content[lang].Title : t('game maintenance title')}</p>
            <p className="underMaintenanceText">{ReactHTMLParser(content !== null ? content[lang].Message : t('game maintenance text'))}</p>
          </Grid>
          <Grid item xs={12} >
            <TelegramIconMaintenance />
            <p className="contactText">{t('telegram')}</p>
            <p className="contactVaue">{t('cs-telegram')}</p>
          </Grid>
          <Grid item xs={12} >
            <EmailIconMaintenance className='contactIcon'/>
            <p className="contactText">{t('cs support email inqury')}</p>
            <p className="contactVaue">{t('cs support email')}</p>
          </Grid>
        </Grid>
      </CustomizableDialog>
      :
      <CustomizableDialog
        customScrollPaper={classes.customScrollPaper}
        customClass={classes.sessionDialog}
        titleContent={t('game error')}
        actions={
          <div className="action-btn">
            <Button className="proceed" onClick={() => {
              hideMessage();
            }}>
                {t('ok')}
            </Button>
          </div>
        }
        hasHeader
        openDialog={openDialog}
      >{ReactHTMLParser(
        t('error opening game please contact cs')
      )}
      </CustomizableDialog>
    );
}

GameMessageModal.propTypes = {
  classes: PropTypes.shape({
    customScrollPaper: PropTypes.object,
    sessionDialog: PropTypes.string
  }).isRequired
};

export default withStyles(styles)(GameMessageModal);