import {
  REFRESH_WALLET,
  SET_NO_BANK,
  SET_WALLET_ERRORS,
  SET_WALLET_LOADING,
  SET_WALLET_RESPONSE_MESSAGE,
  SUBMIT_DEPOSIT_SUCCESS,
  SUBMIT_TRON_DEPOSIT_SUCCESS,
  SUBMIT_TRANSFER_REQUEST_SUCCESS,
  SUBMIT_WITHDRAWAL_SUCCESS,
  SET_DEPOSIT_SUCCESS,
  SET_WITHDRAWAL_SUCCESS,
  SET_DEPOSIT_COUNT,
  SET_DEPOSIT_ERROR,
} from '../constants/wallet';

const initialState = {
  errors: {},
  message: {
    msg: '',
    msg_type: '',
    show: false,
    status: ''
  },
  noBank: false,
  refreshWallet: false,
  successDeposit: false,
  successTronDeposit: false,
  successTransfer: false,
  successWithdraw: false,
  walletLoading: false,
  successDepositData: {},
  successWithdrawalData: {},
  depositCount: null,
  depositError: {},
};

const walletReducer = (state = initialState, action) => {
  switch (action.type) {
    case REFRESH_WALLET:
      return {
        ...state,
        refreshWallet: action.refresh,
      };
    case SET_NO_BANK:
      return {
        ...state,
        noBank: action.status,
      };
    case SET_WALLET_ERRORS: 
      return {
        ...state,
        errors: action.errors,
      };
    case SET_WALLET_LOADING: 
      return {
        ...state,
        walletLoading: action.show,
      };
    case SET_WALLET_RESPONSE_MESSAGE:
      return {
        ...state,
        message: {
          ...state.message,
          msg: action.msg,
          msg_type: action.msg_type,
          show: action.show,
          status: action.status
        },
      };
    case SUBMIT_DEPOSIT_SUCCESS:
      return {
        ...state,
        successDeposit: action.isSuccess,
      };
    case SUBMIT_TRON_DEPOSIT_SUCCESS:
      return {
        ...state,
        successTronDeposit: action.isSuccess,
      };
    case SET_DEPOSIT_SUCCESS:
      return {
        ...state,
        successDepositData: action.params,
      };
    case SUBMIT_TRANSFER_REQUEST_SUCCESS:
      return {
        ...state,
        successTransfer: action.isSuccess,
      };
    case SUBMIT_WITHDRAWAL_SUCCESS:
      return {
        ...state,
        successWithdraw: action.isSuccess,
      };
    case SET_WITHDRAWAL_SUCCESS:
      return {
        ...state,
        successWithdrawalData: action.params,
      };
    case SET_DEPOSIT_COUNT:
      return {
        ...state,
        depositCount: action.payload
      }
    case SET_DEPOSIT_ERROR:
      return {
        ...state,
        depositError: action.payload
      }
    default:
      return state;
  }
};

export default walletReducer;
