import lightBlue from '@material-ui/core/colors/lightBlue';
import pink from '@material-ui/core/colors/pink';
import green from '@material-ui/core/colors/green';
import deepOrange from '@material-ui/core/colors/deepOrange';
import indigo from '@material-ui/core/colors/indigo';
import orange from '@material-ui/core/colors/orange';

const defaultContentTheme = {
  direction: 'ltr',
  typography: {
    fontFamily: 'Barlow',
    fontSize: 14,
    subheading: {
      fontSize: '14px'
    }
  },
  palette: {
    type: 'light',
    primary: lightBlue,
    secondary: pink
  },
  status: {
    danger: 'orange'
  },
  overrides: {
    MuiAppBar: {
      colorDefault: {
        backgroundColor: '#FFF',
        color: '#000'
      }
    }
  }
};

const defaultSidenavTheme = {
  ...defaultContentTheme,
  palette: {
    ...defaultContentTheme.palette,
    type: 'dark',
    background: {
      default: '#424242'
    }
  },
  overrides: {
    MuiAppBar: {
      colorDefault: {
        backgroundColor: '#424242',
        color: '#FFF'
      }
    }
  }
};

const darkContentTheme = {
  direction: 'ltr',
  typography: {
    fontFamily: 'Barlow',
    fontSize: 14,
    subheading: {
      fontSize: '14px'
    }
  },
  palette: {
    type: 'light',
    primary: lightBlue,
    secondary: pink
  },
  status: {
    danger: 'orange'
  },
  overrides: {
    MuiAppBar: {
      colorDefault: {
        backgroundColor: '#424242',
        color: '#FFF'
      }
    }
  }
};

const nightContentTheme = {
  direction: 'ltr',
  typography: {
    fontFamily: 'Barlow',
    fontSize: 14,
    subheading: {
      fontSize: '14px'
    }
  },
  palette: {
    type: 'dark',
    primary: lightBlue,
    secondary: pink
  },
  status: {
    danger: 'orange'
  },
  overrides: {
    MuiAppBar: {
      colorDefault: {
        backgroundColor: '#424242',
        color: '#FFF'
      }
    }
  }
};

const fifteenBetTheme = {
  direction: 'ltr',
  typography: {
    fontFamily: 'Poppins',
    fontSize: 14,
    subheading: {
      fontSize: '14px'
    },
    subtitle1: {
      // fontSize: '14px',
      lineHeight: '1.5em'
    }
    /* h1: {
      fontSize: '21px',
      fontWeight: 500,
      lineHeight: '1.16667em'
    } */
  },
  palette: {
    type: 'dark',
    // primary: lightBlue,
    // secondary: pink,
    primary: {
      light: '#F9DC81',
      main: '#F7BB00',
      dark: '#E1AB04',
      contrastText: '#000000'
    },
    secondary: {
      light: '#2cc54e',
      main: '#218838',
      dark: '#0f962d',
      contrastText: '#ffffff'
    },
    mainBackground: {
      default: '#080707'
    },
    text: {
      primary: '#fff',
      secondary: '#939393',
      tertiary: '#D1D1D1',
      quaternary: '#FEFEFE'
    },
    button: {
      submit: '#2B7D05'
    },
    background: {
      default: '#080808',
      paper: '#080808',
      contrastText: '#ffffff',
      tab: '#1A1A1A',
      content: '#262626',
      provider: '#3D3D3D'
    }
    // contrastThreshold: 3
    // Used to shift a color's luminance by approximately
    //     // two indexes within its tonal palette.
    //         // E.g., shift from Red 500 to Red 300 or Red 700.
    // tonalOffset: 0.2,
  },
  status: {
    danger: 'orange'
  },
  overrides: {
    MuiAppBar: {
      colorDefault: {
        backgroundColor: '#080808',
        color: '#FFF'
      }
    }
  }
};

const joaBetTheme = {
  direction: 'ltr',
  typography: {
    fontFamily: 'Poppins',
    fontSize: 14,
    subheading: {
      fontSize: '14px'
    },
    subtitle1: {
      // fontSize: '14px',
      lineHeight: '1.5em'
    }
    /* h1: {
      fontSize: '21px',
      fontWeight: 500,
      lineHeight: '1.16667em'
    } */
  },
  palette: {
    type: 'dark',
    // primary: lightBlue,
    // secondary: pink,
    primary: {
      light: '#F9DC81',
      main:  '#33C16C',
      dark:  '#1D2C31',
      dark1: '#1D2C31', //bannertitle background
      dark2: '#41444F', //used for border in homepage bannertitle
      dark3: '#373492', //vip badge tab
      dark4: '#31343d', //table td
      dark5: '#686B76',
      dark6: '#374950',
      contrastText: '#000000'
    },
    secondary: {
      light: '#2cc54e',
      main: '#33C16C',
      dark: '#33C16C',
      contrastText: '#ffffff'
    },
    mainBackground: {
      default: '#080707'
    },
    text: {
      primary: '#fff',
      secondary: '#9FA2AB',
      tertiary: '#D1D1D1',
      quaternary: '#FEFEFE',
      default: '#33C16C'
    },
    button: {
      submit: '#2B7D05',
      fab: '#575B67'
    },
    background: {
      default: '#0F1D26',
      paper: '#1D2C31',
      contrastText: '#ffffff',
      tab: '#1A1A1A',
      content: '#282B33',
      provider: '#3D3D3D'
    }
    // contrastThreshold: 3
    // Used to shift a color's luminance by approximately
    //     // two indexes within its tonal palette.
    //         // E.g., shift from Red 500 to Red 300 or Red 700.
    // tonalOffset: 0.2,
  },
  status: {
    danger: 'orange'
  },
  overrides: {
    MuiAppBar: {
      colorDefault: {
        backgroundColor: '#1D2C31',
        color: '#FFF'
      }
    }
  }
};

const fifteenBetSidenavTheme = {
  ...fifteenBetTheme,
  palette: {
    ...fifteenBetTheme.palette,
    type: 'light',
    background: {
      default: '#1A1A1A'
    },
    action: {
      hover: '#000000',
      color: '#fff'
    }
  },
  overrides: {
    MuiAppBar: {
      colorDefault: {
        backgroundColor: '#262626',
        color: '#FFF'
      }
    }
  }
};

const joaBetSidenavTheme = {
  ...joaBetTheme,
  palette: {
    ...joaBetTheme.palette,
    type: 'dark',
    background: {
      default: '#1D2C31'
    },
    action: {
      hover: '#33C16C',
      color: '#fff'
    }
  },
  overrides: {
    MuiAppBar: {
      colorDefault: {
        backgroundColor: '#1D2C31',
        color: '#FFF'
      }
    }
  }
};

const themes = [{
  id: 'joabet-theme',
  name: 'Joa Bet Theme',
  theme: {
    contentTheme: joaBetTheme,
    sidenavTheme: {
      ...joaBetSidenavTheme
    }
  }
},{
  id: 'fifteenbet-theme',
  name: 'Fifteen Bet Theme',
  theme: {
    contentTheme: joaBetTheme,
    sidenavTheme: {
      ...fifteenBetSidenavTheme
    }
  }
}, {
  id: 'classic-light_blue-pink',
  name: 'Classic - Light Blue / Pink',
  theme: {
    contentTheme: defaultContentTheme,
    sidenavTheme: defaultSidenavTheme
  }
}, {
  id: 'classic-green-deep_orange',
  name: 'Classic - Green / Deep Orange',
  theme: {
    contentTheme: {
      ...defaultContentTheme,
      palette: {
        type: 'light',
        primary: green,
        secondary: deepOrange
      }
    },
    sidenavTheme: defaultSidenavTheme
  }
}, {
  id: 'classic-indigo-orange',
  name: 'Classic - Indigo / Orange',
  theme: {
    contentTheme: {
      ...defaultContentTheme,
      palette: {
        type: 'light',
        primary: indigo,
        secondary: orange
      }
    },
    sidenavTheme: defaultSidenavTheme
  }
}, {
  id: 'dark-light_blue-pink',
  name: 'Dark - Light Blue / Pink',
  theme: {
    contentTheme: darkContentTheme,
    sidenavTheme: defaultSidenavTheme
  }
}, {
  id: 'dark-green-deep_orange',
  name: 'Dark - Green / Deep Orange',
  theme: {
    contentTheme: {
      ...darkContentTheme,
      palette: {
        type: 'light',
        primary: green,
        secondary: deepOrange
      }
    },
    sidenavTheme: defaultSidenavTheme
  }
}, {
  id: 'dark-indigo-orange',
  name: 'Dark - Indigo / Orange',
  theme: {
    contentTheme: {
      ...darkContentTheme,
      palette: {
        type: 'light',
        primary: indigo,
        secondary: orange
      }
    },
    sidenavTheme: defaultSidenavTheme
  }
}, {
  id: 'night-light_blue-pink',
  name: 'Night - Light Blue / Pink',
  theme: {
    contentTheme: nightContentTheme,
    sidenavTheme: defaultSidenavTheme
  }
}, {
  id: 'night-green-deep_orange',
  name: 'Night - Green / Deep Orange',
  theme: {
    contentTheme: {
      ...nightContentTheme,
      palette: {
        type: 'dark',
        primary: green,
        secondary: deepOrange
      }
    },
    sidenavTheme: defaultSidenavTheme
  }
}, {
  id: 'night-indigo-orange',
  name: 'Night - Indigo / Orange',
  theme: {
    contentTheme: {
      ...darkContentTheme,
      palette: {
        type: 'dark',
        primary: indigo,
        secondary: orange
      }
    },
    sidenavTheme: defaultSidenavTheme
  }
}];

export default themes;
